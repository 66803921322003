import React from 'react';

import supportHotLineIcon from '../../../../assets/images/supportHotLineIcon.svg';
import { useHistory } from 'react-router-dom';
const SupportTicketsHotLine = () => {
  const { goBack } = useHistory();
  return (
    <div className="common_wrapper hotlineSupport pl60">
      <div
        onClick={() => goBack()}
        className="back-button-bdr backtoCardsBtn"
      />
      <h2 className="hotlineHead"> Hotline Support</h2>
      <div className="hotlineContentSec">
        <div>
          <img src={supportHotLineIcon} width="258" height="283" />
        </div>
        <div className="hotlineDtlSec">
          <span className="hotHelpTxt">
            Current Service Support available from:
          </span>
          <div className="hotlineDtlSec">
            <span className="hotweek">Monday - Friday</span>
            <span className="hotweekValue">7:30 AM - 4:40 PM PST</span>
          </div>
          <div className="hotlineDtlSec">
            <span className="hotStep">Step 1</span>
            <span className="hotStepOpt">
              Dial: <span className="hotBold">925 666 2724</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketsHotLine;
