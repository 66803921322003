/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import './styles.scss';

const { RangePicker } = DatePicker;

interface Props {
  handleOnChange: (dates: any) => void;
  defaultDates?: any[];
}

const dateFormat = 'MMM-DD';
const startPlaceholder = 'Select';
const endPlaceholder = 'range';

const CustomRangePicker: React.FC<Props> = ({
  handleOnChange,
  defaultDates,
}) => {
  const [dates, setDates] = useState<any>([]);

  const handleDates = (dates: any) => {
    if (dates) {
      const fDates = [dates[0]?.startOf('day'), dates[1]?.endOf('day')];
      handleOnChange(dates);
      setDates(fDates);
    } else {
      handleOnChange([]);
      setDates([null, null]);
    }
  };

  const onChange: RangePickerProps['onChange'] = handleDates;

  function disabledDate(current: any) {
    // if (includeWeekends) return !current.isBefore(moment());
    // Can not select sundays and predfined days
    // return (
    //   [0, 6].includes(moment(current).day()) || !current.isBefore(moment())
    // );
    return !current.isBefore(moment());
  }

  const isClear = useMemo(() => {
    return dates?.every((item: any) => item === null);
  }, [dates]);

  useEffect(() => {
    if (defaultDates) handleDates(defaultDates);
  }, []);

  return (
    <>
      <RangePicker
        className={isClear ? 'hideRangeSeparator' : ''}
        format={dateFormat}
        value={dates}
        dropdownClassName="doubleMonth searchBarDate"
        disabledDate={disabledDate}
        onChange={onChange}
        placeholder={[startPlaceholder, endPlaceholder]}
      />
    </>
  );
};

export default CustomRangePicker;
