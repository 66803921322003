import React, { useContext, useEffect, useState, memo } from 'react';
import { Carousel, notification } from 'antd';
import WinterPopUp from './WinterPopup';
import AnalyticCard from './AnalyticCard';
import { ApplicationContext } from '../../../context/AppContext';
import warrowLeft from './images/warrow_left-01.svg';
import warrowRight from './images/warrow_right-01.svg';

import { getBulletIn, getCompliance } from '../../../constants/Api';
import { useHistory } from 'react-router-dom';

import RoutesConstants from '../../../routes/RoutesConstant';
import MonthlyReport from './MonthlyReport';
import useComplaince from './useComplaince';
import './styles.scss';
import PdfViewer from '../PdfViewer';

const CustomPrevArrow: React.FC<any> = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <img src={warrowLeft} alt="" />
    </button>
  );
};

const CustomNextArrow: React.FC<any> = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <img src={warrowRight} alt="" />
    </button>
  );
};

const ComplianceContainer: React.FC = () => {
  const history = useHistory();
  const { analyticDashboardData, checkShowAnalyticDashboard, userDetails } =
    useContext(ApplicationContext);
  const [serviceBulletInData, setServiceBulletInData] = useState<any>(null);
  const [reportData, setReportData] = useState<any>(null);
  const [showAnalytics, setShowAnalytics] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [showServiceBulletIn, setShowServiceBulletIn] =
    useState<boolean>(false);
  const { updateCompliance } = useComplaince();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showPDFRenderer, setShowPDFRenderer] = useState<boolean>(false);

  const getServiceBulletInData = async () => {
    const {
      id,
      organization_id,
      organization: { api_url },
    } = userDetails;
    try {
      const response = await getBulletIn(api_url, organization_id, id);
      setServiceBulletInData({
        ...response,
      });
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  useEffect(() => {
    setShowAnalytics(analyticDashboardData?.show_analytics);
  }, [analyticDashboardData]);

  useEffect(() => {
    if (reportData) setShowReport(!reportData?.is_compliance_acknowledged);
  }, [reportData]);

  useEffect(() => {
    if (serviceBulletInData)
      setShowServiceBulletIn(!serviceBulletInData?.is_compliance_acknowledged);
  }, [serviceBulletInData]);

  const acknowledgeComplaince = async (data: any, mothlyReport?: boolean) => {
    try {
      if (mothlyReport) {
        const { compliance_id, compliance_code, report_id } = data || {};
        const payload = {
          compliance_id,
          compliance_code,
          app_name: 'web',
          is_compliance_acknowledged: true,
          report_id,
        };
        await updateCompliance(payload);
      } else {
        const { compliance_id, compliance_code, compliance_version } =
          data || {};
        const payload = {
          compliance_id,
          compliance_code,
          compliance_version,
          app_name: 'web',
          is_compliance_acknowledged: true,
        };
        await updateCompliance(payload);
      }
    } catch (error: any) {
      notification.error({ message: error?.message });
    }
  };

  const initMonthlyReportData = async () => {
    const { organization, id: userId } = userDetails;
    const { api_url, id: orgId } = organization;
    try {
      const response = await getCompliance(
        api_url,
        orgId,
        userId,
        'web',
        10007,
      );
      setReportData({ ...response });
    } catch (error: any) {
      notification.error({ message: error?.message });
    }
  };

  useEffect(() => {
    if (userDetails.organization) {
      getServiceBulletInData();
      initMonthlyReportData();
    }
  }, [userDetails]);

  const handleWinterageClose = async (showModal: boolean) => {
    setShowServiceBulletIn(false);
    if (showModal) {
      setShowPDFRenderer(true);
    }
    await acknowledgeComplaince(serviceBulletInData);
    getServiceBulletInData();
  };

  const handleCloseMonthlyReport = async () => {
    setShowReport(false);
    await acknowledgeComplaince(reportData, true);
    initMonthlyReportData();
  };

  const handleAnalyticsClose = async (analytics = false) => {
    setShowAnalytics(false);
    if (analytics) {
      await acknowledgeComplaince(analyticDashboardData);
      checkShowAnalyticDashboard();
      history.push(RoutesConstants.Analytic);
    }
  };

  return (
    <>
      {(showAnalytics || showServiceBulletIn || showReport) && (
        <div className="complaince-container">
          <Carousel
            dots={true}
            dotPosition="bottom"
            arrows={true}
            prevArrow={<CustomPrevArrow />}
            nextArrow={<CustomNextArrow />}
            afterChange={(current) => setCurrentSlide(current)}
          >
            {showReport && (
              <div>
                <MonthlyReport
                  onClose={() => handleCloseMonthlyReport()}
                  data={reportData}
                  currentSlide={currentSlide}
                />
              </div>
            )}
            {showServiceBulletIn && (
              <div>
                <WinterPopUp
                  onClose={handleWinterageClose}
                  data={serviceBulletInData}
                />
              </div>
            )}
            {showAnalytics && (
              <div>
                <AnalyticCard onClose={handleAnalyticsClose} />
              </div>
            )}
          </Carousel>
        </div>
      )}

      <PdfViewer
        reportUrl={`${serviceBulletInData?.file_path}`}
        closeModel={() => setShowPDFRenderer(false)}
        showModel={showPDFRenderer}
        title="Service Bulletin"
      />
    </>
  );
};
export default memo(ComplianceContainer);
