import React, { useContext, useEffect, useRef, useState } from 'react';
import { getCustomers, sendEmailResponse, cancelEmailResponse } from '../API';
import { ApplicationContext } from '../../../context/AppContext';
import { Button, Input, notification, Row, Space } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import { initScroller } from '../../../constants/Common';
import { mapOrganizationData } from '../../../lib/dataFormat';
import copyIcon from '../../../assets/images/copy_icon.png';
import { SearchOutlined } from '@ant-design/icons';
import sendImg from '../../../assets/images/send.svg';
import './style.css';
import AddCustomer from './AddCustomer';
import AddButton from '../../common/AddButton';
import useColumns from '../../../hooks/useColumns';
import TableExt from '../../widget/TableExt';
import constants from '../../../constants/constant';

let columnsData: any[] = [];
const title = 'Customers';
const { DOWNLOAD_SIZE } = constants;

const Customers = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const searchString = useRef('');
  const [total, setTotal] = useState(0);
  const init = useRef(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('customers');
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result = await getCustomers(pageNumber, pageSize, search);
    return {
      result,
      searchKey: search,
    };
  };

  const getTractorsList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapOrganizationData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadOrganizationsList = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  const cancelVerification = async (orgId: number) => {
    const payload = {
      org_id: orgId,
      reason: 'testing',
      action: 'cancel',
    };
    try {
      setLoader(true);
      const response = await cancelEmailResponse(payload);
      notification.success({
        message: response.msg,
      });
      activity.current = 'refresh';
      loadOrganizationsList();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const sendEmailToOrg = async (orgId: number) => {
    const payload = {
      org_id: orgId,
    };
    try {
      setLoader(true);
      const response = await sendEmailResponse(payload);
      notification.success({
        message: response.msg,
      });
      activity.current = 'refresh';
      loadOrganizationsList();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.organizations.name)}`,
        dataIndex: 'orgName',
        key: 'orgName',
        width: '200px',
        ellipsis: true,
        render: (organizationName: string) => (
          <span data-testid={`${organizationName}-organizations`}>
            {organizationName}
          </span>
        ),
      },
      {
        title: `${t(translate.organizations.administratorEmail)}`,
        dataIndex: 'adminEmail',
        key: 'adminEmail',
        width: '420px',
        render: (adminEmail: string, record: any) => (
          <span
            className="emailAlign hoverWrapper"
            data-testid={`${adminEmail}-organizations`}
          >
            <span className="emailStyle customerEmail">{adminEmail}</span>

            {adminEmail !== '-' && (
              <>
                <img
                  src={copyIcon}
                  alt="Edit"
                  height="32"
                  width="32"
                  className="marginLeftIcon hoverShow1 mr12"
                  onClick={() => {
                    navigator.clipboard.writeText(adminEmail);
                    notification.info({ message: 'Copied' });
                  }}
                />

                {record.is_email_verified ? (
                  <>
                    {record.is_resend_email_sent_status ? (
                      <span color="red" className="chip-bg red-chip">
                        {t(translate.organizations.linkExpired)}
                      </span>
                    ) : (
                      <span
                        color="green"
                        className="chip-bg green-chip"
                        data-testid={`${record.orgName}-verified-organizations`}
                      >
                        {t(translate.organizations.verified)}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {record.is_resend_email_sent_status ? (
                      <span
                        color="red"
                        className="chip-bg red-chip"
                        data-testid={`${record.orgName}-notVerified-organizations`}
                      >
                        {t(translate.organizations.notVerified)}
                      </span>
                    ) : (
                      <span
                        color="orange"
                        className="chip-bg orange-chip"
                        data-testid={`${record.orgName}-verifiedMailSent-organizations`}
                      >
                        {t(translate.organizations.verificationMailSent)}
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </span>
        ),
      },
      {
        title: `${t(translate.organizations.createdDate)}`,
        dataIndex: 'startTime',
        key: 'startTime',
        width: '200px',
      },
      {
        title: `${t(translate.organizations.invitationStatus)}`,
        dataIndex: 'is_resend_email_sent_status',
        key: 'is_resend_email_sent_status',
        width: '200px',
        render: (is_resend_email_sent_status: boolean, record: any) => (
          <>
            {record.is_email_verified ? (
              ''
            ) : (
              <>
                {is_resend_email_sent_status ? (
                  <>
                    {record.adminEmail !== '-' && (
                      <Button
                        type="primary"
                        className="inviteBtn"
                        onClick={() => sendEmailToOrg(record.id)}
                      >
                        <img src={sendImg} alt="send button" />
                        {t(translate.buttons.send)}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    type="primary"
                    className="inviteBtn inviteCancelBtn"
                    onClick={() => cancelVerification(record.id)}
                  >
                    {t(translate.buttons.cancel)}
                  </Button>
                )}
              </>
            )}
          </>
        ),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const onClose = (state?: boolean) => {
    if (state) {
      handleRefresh();
    }
    setShowForm(false);
  };

  useEffect(() => {
    loadOrganizationsList();
  }, [userDetails, pageNumber, pageSize, search]);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadOrganizationsList();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(page, DOWNLOAD_SIZE, search);
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapOrganizationData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper tabFilterWidget_wrapper customer_wrapper">
              <div className="filters_card personnelCard">
                <Space size="large">
                  <Input
                    data-testid="searchDirectoryInputField-Employees"
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />
                </Space>
                <AddButton
                  label="Add Customer"
                  width={150}
                  onClick={() => {
                    setShowForm(true);
                  }}
                />
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  placeholder="Search Customers"
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableRef={tableRef}
                  tableName="customers"
                  handleCallback={(args: any[]) => {
                    setColumns(args);
                  }}
                  columnsData={columnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>

              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Customers"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {showForm && <AddCustomer onClose={onClose} toggleWidget={showForm} />}
    </Layout>
  );
};

export default Customers;
