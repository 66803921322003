import React from 'react';
import noIncidentsImg from '../images/no-incidents-img.svg';

const NoIncedents: React.FC = () => {
  return (
    <div className="no-incidents-reported">
      <span className="noIncidentIcon">
        <img src={noIncidentsImg} alt="" />
      </span>
      <span className="noIncidentTitle">No Incidents Reported</span>
      <span className="noIncidentDesc">
        This operation had no issues today, including tickets,
        <br /> errors, hazards, or snapshots.
      </span>
    </div>
  );
};

export default NoIncedents;
