import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, Row, Select, Space, Tag, notification } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../../../locale/en_translate.json';
import InfiniteScrollTable from '../../../../common/InfiniteScrollTable';
import usePaginate from '../../../../../hooks/usePaginate';
import { ApplicationContext } from '../../../../../context/AppContext';
import { initScroller } from '../../../../../constants/Common';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { getBasestationMigData, getOrgsList } from '../../../API';
import { mapTractorMigrationData } from '../../../../../lib/dataFormat';
import useColumns from '../../../../../hooks/useColumns';
import constants from '../../../../../constants/constant';
import TableExt from '../../../../widget/TableExt';
import BasestationMigrationHistory from './BasestationMigrationHistory';
import { MigrateModal } from './MigrateModal';
import { useHistory } from 'react-router-dom';

let columnsData: any[] = [];
const { DOWNLOAD_SIZE } = constants;
const title = 'BasestationMigration';
let gfilterData: any = [];

const BasestationMigration = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const [organizationsList, setOrganizationsList] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } = useColumns(
    'BasestationMigration',
  );
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [selectedTractor, setSelectedTractor] = useState<any>(null);
  const [migrateModal, setMigrateModal] = useState(false);
  const [migrationFlag, setMigrationFlag] = useState(false);
  const [migrationHistory, setMigrationHistory] = useState();
  const [selectOrg, setSelectOrg] = useState();
  gfilterData = filterData;

  const orgMap: any = useMemo(
    () =>
      organizationsList.reduce((a: any, c: any) => {
        a[c.id] = c;
        return a;
      }, {}),
    [organizationsList],
  );

  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result = await getBasestationMigData(pageNumber, pageSize, search);
    return {
      result,
      searchKey: search,
    };
  };

  const getAllOrganizations = async () => {
    try {
      const result = await getOrgsList();
      const orgNames = result.map((data: any) => data);
      setOrganizationsList(orgNames);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getBaseStationList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapTractorMigrationData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const loadBaseStations = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBaseStationList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBaseStationList();
    }
  };

  const handleSelectOrg = (tractor_id: number, target_org_id: any) => {
    setSelectOrg(target_org_id);
    const fFiterData = gfilterData.map((tractor: any) => {
      if (tractor.id === tractor_id) {
        tractor.target_organization_id = target_org_id;
        tractor.target_organization_name = orgMap[target_org_id].name;
      }
      return tractor;
    });
    setData([...fFiterData]);
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    loadBaseStations();
  }, [userDetails, pageNumber, search]);

  const getTagColor = (status: string) => {
    if (status === null) return '';
    if (status === 'Completed') {
      return 'green';
    }
    if (status === 'Failed') {
      return 'red';
    }
    return 'blue';
  };

  const getBttonText = (item: any) => {
    if (item.migration && item.migration.status === 'Inprogress') {
      return 'In Progress';
    }
    if (item.connection === false) {
      return 'Offline';
    }
    if (item.connection === true) {
      return 'Migrate';
    }
  };

  const migrateMethod = async (item: any) => {
    if (
      (item?.migration && item?.migration?.status === 'Inprogress') ||
      item?.target_organization_id
    ) {
      if (item?.migration?.status === 'Inprogress') {
        setMigrateModal(true);
        setMigrationHistory(item);
      } else {
        if (item.target_organization_id === item.customer_organization_id) {
          return notification.error({
            message: 'source and target organization cannot be same',
          });
        } else {
          setMigrateModal(true);
          setMigrationHistory(item);
        }
      }
      setSelectedTractor({ ...item });
    } else {
      notification.error({
        message: 'Select organization.',
      });
    }
  };

  useEffect(() => {
    if (userDetails.organization) getAllOrganizations();
  }, [userDetails]);

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.tractors.name)}`,
        dataIndex: 'name',
        key: 'name',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.baseStation.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.tractors.currentorganization)}`,
        dataIndex: 'customer_organization_name',
        key: 'customer_organization_name',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },

      {
        title: `${t(translate.tractors.targetOrganization)}`,
        dataIndex: 'model_name',
        key: 'model_name',
        render: (model_name: string, record: any) => (
          <Select
            showSearch
            style={{ width: 184, marginRight: 10 }}
            className="orgNameDropDown"
            defaultValue="Select Organization"
            value={record?.target_organization_id ?? undefined}
            onSelect={(value) => handleSelectOrg(record.id, value)}
          >
            {organizationsList.map((data: any, index: number) => {
              return (
                <option value={data.id} key={index}>
                  {data?.name}
                </option>
              );
            })}
          </Select>
        ),
      },
      {
        title: `${t(translate.notifications.action)}`,
        dataIndex: 'connection',
        key: 'connection',
        ellipsis: true,
        render: (connection: string, record: any) => (
          <>
            <Button
              data-testid="sample"
              disabled={record.connection === false}
              className={
                record.migration && record.migration.status === 'Inprogress'
                  ? 'migrateBtnG'
                  : record.connection === false
                  ? 'offlineBtn'
                  : 'ant-btn-primary'
              }
              onClick={() => {
                migrateMethod(record);
              }}
            >
              {getBttonText(record)}
            </Button>
          </>
        ),
      },

      {
        title: `${t(translate.tractors.lastMigratedOn)}`,
        dataIndex: 'migrationStatus',
        key: 'migrationStatus',
        width: '180px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
        render: (migStatus: any) =>
          migStatus === null ? (
            <>{'-'}</>
          ) : (
            <Tag color={getTagColor(migStatus)}>{migStatus}</Tag>
          ),
      },
    ];
    setColumnsData(columnsData);
  }, [organizationsList]);

  const handleOnClick = (data: any) => {
    setMigrationHistory(data);
    setMigrationFlag(true);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadBaseStations();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(page, DOWNLOAD_SIZE, search);
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapTractorMigrationData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <>
      {!migrationFlag && (
        <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
          <div className="mainContent">
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="common_wrapper tabFilterWidget_wrapper">
                  <div className="filters_card personnelCard ad_filters_card pl60">
                    <div
                      onClick={() => goBack()}
                      className="back-button-bdr backtoCardsBtn"
                    />
                    <div className="displayFlex">
                      <Space size="large">
                        <Input
                          addonBefore={<SearchOutlined />}
                          placeholder="Search Basestation Migrations"
                          className="common_search"
                          onChange={(e) => handleSearch(e)}
                          autoComplete="off"
                        />
                      </Space>
                    </div>
                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      placeholder="Search Basestation Migrations"
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename={title}
                      tableRef={tableRef}
                      tableName="BasestationMigration"
                      handleCallback={(args: any[]) => {
                        setColumns(args);
                      }}
                      columnsData={columnsData}
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>

                  <div className="common_table">
                    <InfiniteScrollTable
                      columns={columns}
                      loading={loader}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={total}
                      handleLoadMore={handleLoadMore}
                      filename="Basestation Migrations"
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
          {selectedTractor && migrateModal && (
            <MigrateModal
              showModal={migrateModal}
              closeModel={() => {
                setSelectedTractor(null);
                setMigrateModal(false);
                handleRefresh();
              }}
              selectedTractor={selectedTractor}
              selectOrg={selectOrg}
              item={migrationHistory}
            />
          )}
        </Layout>
      )}

      <>
        {migrationHistory && migrationFlag && (
          <BasestationMigrationHistory
            showModal={migrationFlag}
            closeModel={() => {
              setMigrationFlag(false);
              handleRefresh();
            }}
            migrationHistory={migrationHistory}
          />
        )}
      </>
    </>
  );
};

export default BasestationMigration;
