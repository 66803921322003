import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import Badge from './images/Badge.svg';
import Box from './images/giftbox_animation1.gif';
import RoutesConstants from '../../../routes/RoutesConstant';
import { useHistory } from 'react-router-dom';
import Interweave from 'interweave';
import xiconClose from '../../../assets/images/X_icon-01.svg';
// import Box1 from './images/giftbox_animation2.gif';
import BoxOpen from './images/report_open.gif';
import Confetti from './images/giftbox_animation4.gif';
import './styles.scss';
// import { downloadURI } from '../../../constants/Common';

interface Props {
  data: any;
  onClose: (state?: boolean) => void;
  currentSlide: number;
}

const MonthlyReport: React.FC<Props> = ({ data, onClose, currentSlide }) => {
  const { push } = useHistory();
  const [openReport, setOpenReport] = useState<boolean>(false);
  const [animation3, setAnimation3] = useState<boolean>(false);

  const navigateToReports = useCallback(() => {
    push(RoutesConstants.Analytic, {
      action: 'Reports',
      data: {
        file_path: data?.file_path,
      },
    });
  }, [data]);

  useEffect(() => {
    let timer: any;
    if (openReport) {
      const gifDuration = 900;
      timer = setTimeout(() => {
        setAnimation3(true);
      }, gifDuration);
    }
    return () => clearTimeout(timer);
  }, [openReport]);

  const delayedAnimation = () => {
    setTimeout(() => {
      setOpenReport(true);
    }, 2000);
  };

  useEffect(() => {
    if (!currentSlide) delayedAnimation();
    return () => {
      setOpenReport(false);
      setAnimation3(false);
    };
  }, [currentSlide]);

  return (
    <Card
      hoverable
      className="ADCCard reportAlert"
      cover={
        <>
          <img
            src={xiconClose}
            alt=""
            className="ADCCloseIcon"
            onClick={() => onClose()}
          />
        </>
      }
    >
      <div className="ADCCardContent">
        <div className="an_n_heading">
          <div className="monthlyContentWrapper">
            <img src={Badge} alt="badge" className="badgeIcon" />
            <h3 className="title text-center">Another Month, Another Win</h3>
            <Interweave
              content={
                'Check out this month’s fleet usage and impact, including fuel savings and year-to-date trends.'
              }
              className="info"
            />

            <div className="animationSec">
              {/* {!openReport && (
                <img
                  src={Box}
                  alt="box"
                  onClick={() => setOpenReport(true)}
                  className="box1"
                />
              )} */}
              <img
                // onClick={() => setOpenReport(true)}
                src={!openReport ? Box : BoxOpen}
                alt="box"
                className={!openReport ? 'box1' : 'box3'}
              />
              {animation3 && (
                <img
                  src={Confetti}
                  alt="confetti"
                  className="blast"
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: 50,
                  }}
                />
              )}
              <Button
                className="ADCFooterBtn"
                onClick={() => navigateToReports()}
              >
                View Report
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MonthlyReport;
