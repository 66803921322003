import { Tabs } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Spinner from '../../common/Spinner';
import useTractorAnalyticsDetails from '../hooks/useTractorAnalyticsDetails';
import tractorAnalyticDetailsStore from '../store/tractorAnalyticDetails';
import Ticket, { TicketLoader } from '../ui/Ticket';
import ErrorCode from './ErrorCode';
import Hazard from './Hazard';
import NoIncedents from './NoIncedents';
import Snapshot from './Snaphot';

import error_alt from '../images/error_alt.svg';
import hazard_alt from '../images/hazard_alt.svg';
import snapshot_alt from '../images/snapshot_alt.svg';
import ticket_alt from '../images/ticket_alt.svg';

import './styles.scss';

const { TabPane } = Tabs;

interface Props {
  ticketDetailsById: (ticket: any) => void;
  handleOperationSnapClick: (snap: any) => void;
  handleOperationIndClick: (ind: any) => void;
  snapLoader: boolean;
  indLoader: boolean;
}
const AlertsContainer: React.FC<Props> = ({
  ticketDetailsById,
  handleOperationSnapClick,
  handleOperationIndClick,
  snapLoader,
  indLoader,
}) => {
  const { ticketsData, snapshots, errorCodes, guradrails } =
    tractorAnalyticDetailsStore();
  const { ticketsLoader } = useTractorAnalyticsDetails();
  const [tabId, setTabId] = useState<string>('tickets');
  const init = useRef(true);

  const onChange = (key: string) => {
    setTabId(key);
  };

  const ErrorCodeContent = useMemo(() => {
    return errorCodes.map((errorCode: any) => {
      return (
        <ErrorCode
          data={errorCode}
          key={errorCode.id}
          handleOperationIndClick={handleOperationIndClick}
        />
      );
    });
  }, [errorCodes]);

  const SnapshotContent = useMemo(() => {
    return snapshots.map((snapShot: any) => {
      return (
        <Snapshot
          data={snapShot}
          key={snapShot.id}
          handleOperationSnapClick={handleOperationSnapClick}
        />
      );
    });
  }, [snapshots]);

  const HazardContent = useMemo(() => {
    return guradrails.map((guradrails: any) => {
      return (
        <Hazard
          data={guradrails}
          key={guradrails.id}
          handleOperationSnapClick={handleOperationSnapClick}
        />
      );
    });
  }, [guradrails]);

  const emptyView = useMemo(() => {
    return [ticketsData, snapshots, guradrails, errorCodes].every(
      (item) => !item.length,
    );
  }, [ticketsData, snapshots, guradrails, errorCodes]);

  useMemo(() => {
    if (ticketsData.length) return setTabId('tickets');
    if (errorCodes.length) return setTabId('errors');
    if (guradrails.length) return setTabId('hazards');
    if (snapshots.length) return setTabId('snapshots');
  }, [ticketsData, snapshots, guradrails, errorCodes]);

  useEffect(() => {
    setTimeout(() => {
      init.current = false;
    }, 5000);
  }, []);

  if (!init.current && !indLoader && !snapLoader && emptyView)
    return <NoIncedents />;
  else if ((!init.current || !indLoader || !snapLoader) && !emptyView)
    return (
      <Tabs
        defaultActiveKey="tickets"
        key="tickets"
        activeKey={tabId}
        onChange={onChange}
        className="alertsCotainerTabs errorCodeCont"
      >
        {!!ticketsData.length && (
          <TabPane
            tab={
              <div className="alert-tab-container">
                <div>
                  <img src={ticket_alt} alt="" />
                </div>
                <div className="alt_title_txt">Tickets</div>
                <div className="alt_ticket_count">{ticketsData.length}</div>
              </div>
            }
            key="tickets"
          >
            <div className="error-code-block">
              {!ticketsData?.length && <TicketLoader loader={ticketsLoader} />}
              {ticketsData?.map((ticket) => (
                <Ticket
                  ticket={ticket}
                  key={ticket.id}
                  getDetailsById={ticketDetailsById}
                />
              ))}
            </div>
          </TabPane>
        )}
        {!!errorCodes.length && (
          <TabPane
            tab={
              <div className="alert-tab-container">
                <div>
                  <img src={error_alt} alt="" />
                </div>
                <div className="alt_title_txt">Errors</div>
                <div className="alt_ticket_count">{errorCodes.length}</div>
              </div>
            }
            key="errors"
          >
            <div className="error-code-block">{ErrorCodeContent}</div>
          </TabPane>
        )}
        {!!guradrails.length && (
          <TabPane
            tab={
              <div className="alert-tab-container">
                <div>
                  <img src={hazard_alt} alt="" />
                </div>
                <div className="alt_title_txt">Hazards</div>
                <div className="alt_ticket_count">{guradrails.length}</div>
              </div>
            }
            key="hazards"
          >
            <div className="error-code-block">{HazardContent}</div>
          </TabPane>
        )}
        {!!snapshots.length && (
          <TabPane
            tab={
              <div className="alert-tab-container">
                <div>
                  <img src={snapshot_alt} alt="" />
                </div>
                <div className="alt_title_txt">Snapshots</div>
                <div className="alt_ticket_count">{snapshots.length}</div>
              </div>
            }
            key="snapshots"
          >
            <div className="error-code-block">{SnapshotContent}</div>
          </TabPane>
        )}
      </Tabs>
    );
  else return <Spinner loader={init.current || indLoader || snapLoader} />;
};

export default AlertsContainer;
