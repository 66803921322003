import moment from 'moment';
import Point from 'ol/geom/Point';
import * as am4core from '@amcharts/amcharts4/core';
import { randomNum } from '../../constants/Common';

export const fetchName = (
  firstName: string,
  lastName: string,
  isForChart?: boolean,
): string => {
  if (
    isForChart &&
    (firstName?.includes('Support') || lastName.includes('Support'))
  ) {
    return `M. Support`;
  }
  return firstName && lastName
    ? firstName?.slice(0, 1).toLocaleUpperCase() +
        lastName?.slice(0, 1)?.toLocaleUpperCase()
    : '';
};

export const getUserName = (firstName: string, lastName: string) => {
  return lastName ? `${firstName} ${lastName}` : firstName ?? '';
};

export const getHoursAndMinutes = (timeString: string) => {
  const operations = {
    hours: timeString ? timeString?.split(':')?.[0] : 0,
    minutes: timeString ? timeString?.split(':')?.[1] : 0,
  };
  return operations;
};

export const getHrsMinsFromMins = (mins: number) => {
  if (!mins) return { hours: 0, minutes: 0 };
  const hours = Math.floor(mins / 60);
  const minutes = mins ? Number(mins) % 60 : 0;
  return {
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
  };
};
const currentDate = moment().subtract(1, 'day');
export const defaultDates = [moment().subtract(7, 'days'), currentDate];
// export const defaultDates = [
//   moment(new Date('11-28-2024')).subtract(0, 'days'),
//   moment(new Date('11-28-2024')),
// ];

export const convertDateFormat = (dateString: string): string => {
  // Split the date string into year, month, and day components
  const [year, month, day] = dateString.split('-');

  // Format the date components into 'MM/dd' format
  const formattedDate = `${month}/${day}`;

  return formattedDate;
};

export const getCurrentResolution = (screens: any) => {
  const fscreens = Object.entries(screens).filter((screen) => !!screen[1]);
  const resolution = fscreens[fscreens.length - 1] || [];
  return resolution[0];
};

export const changeTimeFormat = (epochTime: number) => {
  const date = new Date(epochTime);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return new Date(year, month, day, hours, minutes);
};

export const getColorCodeByOpType = (op_type: string) => {
  switch (op_type) {
    case 'manual':
      return '#67afb7';
    case 'idle':
      return '#cfcfcf';
    case 'op_assist':
      return '#f1ce90';
    case 'remote_av':
      return '#ec9322';
    default:
      return '#67afb7';
  }
};
export const handleSameLocationFeatures = async (
  features: any,
  distance = 5,
) => {
  const featuresByCoordinates: any = {};
  if (features && features.length > 0) {
    await features.map((feature: any) => {
      if (feature) {
        const coordinates1 = feature
          ? feature?.getGeometry()?.getCoordinates()
          : [];
        const key = coordinates1?.toString();
        if (key) {
          if (!featuresByCoordinates[key]) {
            featuresByCoordinates[key] = [];
          }

          featuresByCoordinates[key].push(feature);
        }
      }
    });

    Object.keys(featuresByCoordinates).forEach((coordString) => {
      const features = featuresByCoordinates[coordString];

      if (features.length > 1) {
        // Multiple features at the same location
        features.forEach((feature: any) => {
          const geometry: any = feature.getGeometry();
          const co = geometry.getCoordinates();
          feature.setGeometry(
            new Point([
              co[0] * Number(`1.00000${Math.floor(randomNum() * distance)}`),
              co[1] * Number(`1.00000${Math.floor(randomNum() * distance)}`),
            ]),
          );
        });
      }
    });
  }
};
export const handleSameLocationChangeFeatures = async (
  features: any,
  distance = 5,
) => {
  const featuresByCoordinates: any = [];
  if (features && features.length > 0) {
    features.reduce(
      (
        a: { data: any[][]; i: number | undefined; last_item: any },
        path_pt: any,
        i: number,
      ) => {
        if (i < features.length - 1) {
          const coords1 = path_pt.getGeometry().getCoordinates();
          const coords2 = features[i + 1].getGeometry().getCoordinates();
          const diffX = coords2[0] - coords1[0];
          const diffY = coords2[1] - coords1[1];
          if (
            (diffX > -0.002 && diffX < 0.002) ||
            (diffY < 0.002 && diffY > -0.002)
          ) {
            featuresByCoordinates.push(path_pt);
          }
        }
      },
    );

    if (featuresByCoordinates.length > 0) {
      featuresByCoordinates.forEach((feature: any) => {
        const geometry: any = feature.getGeometry();
        const co = geometry.getCoordinates();
        feature.setGeometry(
          new Point([
            co[0] * Number(`1.00000${Math.floor(randomNum() * distance)}`),
            co[1] * Number(`1.00000${Math.floor(randomNum() * distance)}`),
          ]),
        );
      });
    }
  }
};

export const findNearestTimestamp = (
  // timestamps: { created_date_time: number }[],
  timestamps: any[],
  targetTimestamp: number,
): number => {
  if (timestamps.length === 0) {
    return 0; // Return null if the array is empty
  }
  let index = 0;

  let nearestTimestamp = timestamps[0][3]; // Start by assuming the first timestamp is the nearest
  let minDifference = Math.abs(targetTimestamp - nearestTimestamp);

  // Iterate through the array to find the nearest timestamp
  for (let i = 1; i < timestamps.length; i++) {
    const currentTimestamp = timestamps[i][3];
    const difference = Math.abs(targetTimestamp - currentTimestamp);

    // Update nearestTimestamp if the current timestamp is closer to the target
    if (difference < minDifference) {
      minDifference = difference;
      nearestTimestamp = currentTimestamp;
      index = i;
    }
  }

  return index;
};
export interface selectedPath {
  created_date_time: number;
  drive_action_uuid: string;
  fromClick: mapClickType;
  drive_action_uuid_id: string;
  from: number;
  to: number;
  operator: { first_name: string; last_name: string };
}

export type mapClickType = 'default' | 'map' | 'chart';

export const removeItem = (list: string[], item: string): string[] =>
  list.filter((listItem) => listItem !== item);

export const isActiveClass = (
  list: any[],
  item: string,
  className: string,
): string => (list.includes(item) ? className : '');

export const isEmpty = (value: any) => {
  switch (typeof value) {
    case 'number':
      return false;
    case 'string':
      return value?.trim() === '';
    case 'undefined':
      return true;
    case 'object':
      return !Array.isArray(value);
    default:
      return false;
  }
};

export const convertToDecimalHours = (hours: number, minutes: number) => {
  return parseFloat((hours + minutes / 60).toFixed(2));
};
export const extractMinutes = (time: string): number => {
  // Use a regular expression to match the numerical part
  const match = time.match(/\d+/);
  // Convert the matched string to a number
  return match ? parseInt(match[0], 10) : 0;
};

export const getOperationData = (
  data: any[],
  allValuesAreMinutes: boolean,
): any[] => {
  const processedData = data?.map((item: any) => {
    if (allValuesAreMinutes) {
      return {
        ...item,
        value1: extractMinutes(item?.time1),
        value2: extractMinutes(item?.time2),
        value3: extractMinutes(item?.time3),
        value4: extractMinutes(item?.time4),
      };
    }
    return item;
  });
  return processedData;
};

export const getValue = (item: any) => {
  if (item) {
    const time = item.split(':');
    return convertToDecimalHours(parseInt(time[0], 10), parseInt(time[1], 10));
  } else {
    return 0;
  }
  // return item ? item.replace(':', '.') : 0;
};
export const getTotalHours = (time: string) => {
  if (!time) return '0';
  const fTime = time?.split(':');
  if (fTime[0] === '0') {
    return `${fTime[1]}m`;
  }
  return `${fTime[0]}h ${fTime[1]}m`;
};

export const differenceInMinutes = (timestamp1: number, timestamp2: number) => {
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);

  // Convert milliseconds to minutes
  // const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
  const differenceInMinutes = differenceInMilliseconds / 60000;

  return differenceInMinutes;
};
export const getTimeFromTimestampAmPm = (
  timestamp: number,
  endTime = false,
) => {
  if (endTime && (!timestamp || isNaN(timestamp))) {
    return '0:00';
  }

  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    return '0:00';
  }

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12; // Convert to 12-hour format
  hours = hours ? hours : 12; // If hour is 0, set it to 12

  // Format minutes to always have two digits
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${hours}:${formattedMinutes} ${ampm}`;
};

export const renderOperationText = (item: string): string =>
  item === 'No Implement' ? 'Unselected' : item;

export const timeDiffInSec = (
  timestamp1: number,
  timestamp2: number,
): number => {
  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  return differenceInSeconds;
};

type OperatorData = {
  first_name: string;
  id: number;
  last_name: string;
};

export type ProgressData = {
  category: string;
  drive_action_uuid: string;
  drive_action_uuid_id: string;
  op_type: string;
  from: any;
  to: any;
  color: any;
  duration: number;
  operator: string;
  operatorData: OperatorData;
  opName: string;
};

const formatTime = (date: Date): string =>
  date.toISOString().split('.')[0] + '.000Z';

export const fillGaps = (data: ProgressData[]): ProgressData[] => {
  const filledData: ProgressData[] = [];

  for (let i = 0; i < data?.length; i++) {
    filledData.push(data[i]);

    if (i < data.length - 1) {
      const currentRecord = data[i];
      const nextRecord = data[i + 1];

      const currentEndTime = new Date(currentRecord.to);
      const nextStartTime = new Date(nextRecord.from);

      if (currentEndTime < nextStartTime) {
        const newRecord: ProgressData = {
          category: '',
          drive_action_uuid: 'gap',
          drive_action_uuid_id: `gap_${i}`,
          op_type: 'idle',
          from: currentRecord.to,
          to: nextRecord.from,
          color: am4core.color('#B5B5B6'),
          duration: Math.floor(
            (nextStartTime.getTime() - currentEndTime.getTime()) / (1000 * 60),
          ),
          operator: 'N/A',
          operatorData: {
            first_name: 'N/A',
            id: -1,
            last_name: 'N/A',
          },
          opName: 'N/A',
        };

        filledData.push(newRecord);
      }
    }
  }

  return filledData;
};

export const consolidateDriveActions = (data: any[]) => {
  const map = new Map<string, any>();

  data.forEach((item: any) => {
    const { drive_action_uuid, from, to, duration } = item;

    if (map.has(drive_action_uuid)) {
      const existing = map.get(drive_action_uuid);

      // Update the `from` and `to` times
      existing.from = existing.from < from ? existing.from : from;
      existing.to = existing.to > to ? existing.to : to;
      existing.duration += duration; // Sum the duration if needed
    } else {
      map.set(drive_action_uuid, { ...item }); // Add the new item to the map
    }
  });

  return Array.from(map.values());
};

export const wrapDateTime = (timestamp: number) => {
  return moment(timestamp).format('D/MM, h:mm a');
};
