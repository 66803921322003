import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Jumpandgo from '../jumpandgo/Jumpandgo';
import Guardrails from '../guardrails/Guardrails';
import Shadow from '../shadow/Shadow';
import SafeDrive from '../safeDrive/SafeDrive';
import Copycat from '../Copycat/Copycat';
import RowFollow from '../RowFollow/RowFollow';
import FleetIndicatorHistory from '../fleetIndicatorHistory/FleetIndicatorHistory';
import { SET_JAG_DACTION, SET_LAST_TAB_INDEX } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import Routines from '../routines/Routines';
import HeartBeat from './heartBeat/HeartBeat';
import OtaLogs from '../otaLogs/OtaLogs';
import MapMakerLogs from '../mapMakerLogs/MapMakerLogs';
import BasestationOTALog from '../basestationOTALog/BasestationOTALog';
import { useLocation } from 'react-router-dom';
import DevTools from '../DevTools/DevTools';
import SoftwareUpdate from '../softwareupdates/SoftwareUpdate';
import Implements from '../supportImplements';
import { DriveAction } from '../../constants/types';
import FileUploadTabs from './FileUploadTabs';
import { session, SHOW_ANALYTIC_DASHBOARD } from '../../constants/session';
import './styles.css';

const { TabPane } = Tabs;

const FleetUsageContainer: React.FC = () => {
  const location: any = useLocation();
  const state: any = location && location.state ? location.state : {};
  const [tabid, setTabid] = useState<any>('');
  const { APPReducer } = useContext(ApplicationContext);
  const [tstate, dispatch] = APPReducer;
  const [showLiveMap, setShowLiveMap] = useState<boolean>(false);
  const [driveAction, setDriveAction] = useState<DriveAction | null>(null);
  const { routeRefresh, jAGDAction } = tstate;

  useEffect(() => {
    if (jAGDAction) {
      setTabid('Operations');
      if (jAGDAction) {
        setDriveAction(jAGDAction);
        setShowLiveMap(true);
      }
    }
    return () => {
      window.history.replaceState({}, document.title);
      setDriveAction(null);
      dispatch({
        type: SET_JAG_DACTION,
        payload: null,
      });
    };
  }, [routeRefresh]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'FleetUsage') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('1');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  const navigateBasedonAction = () => {
    location?.state?.tabId ? setTabid(location?.state?.tabId) : setTabid('1');
  };

  useEffect(() => {
    navigateBasedonAction();

    return () => {
      location.state = null;
    };
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if (session(SHOW_ANALYTIC_DASHBOARD)) setTabid('17');
    }, 1000);
  }, []);

  return (
    <div className="rightSideContainer">
      <div style={{ display: showLiveMap ? 'none' : 'block' }}>
        <Tabs
          centered
          activeKey={tabid}
          className="common_tabs tabSpace support_tabs"
          onChange={(key) => {
            setTabid(key);
            dispatch({
              type: SET_LAST_TAB_INDEX,
              payload: {
                catlog: 'FleetUsage',
                tab: key,
              },
            });
          }}
        >
          <TabPane tab="JUMP & GO" key="1" className="attTableHeight mBottom0">
            <Jumpandgo />
          </TabPane>
          <TabPane tab="Guardrails" key="2" className="attTableHeight mBottom0">
            <Guardrails />
          </TabPane>
          <TabPane tab="SHADOW" key="3" className="attTableHeight mBottom0">
            <Shadow />
          </TabPane>
          <TabPane tab="Safe Drive" key="4" className="attTableHeight mBottom0">
            <SafeDrive />
          </TabPane>
          <TabPane tab="Copy Cat" key="5" className="attTableHeight mBottom0">
            <Copycat />
          </TabPane>
          <TabPane tab="Row Follow" key="6" className="attTableHeight mBottom0">
            <RowFollow />
          </TabPane>
          <TabPane
            tab="Fleet Indicators"
            key="7"
            className="attTableHeight mBottom0"
          >
            <FleetIndicatorHistory />
          </TabPane>
          <TabPane tab="Routines" key="8" className="attTableHeight mBottom0">
            <Routines />
          </TabPane>
          <TabPane
            tab="File Upload View"
            key="9"
            className="attTableHeight mBottom0"
          >
            <FileUploadTabs />
          </TabPane>
          <TabPane
            tab="Heart Beat"
            key="10"
            className="attTableHeight mBottom0"
          >
            <HeartBeat />
          </TabPane>
          <TabPane tab="Ota Logs" key="11" className="attTableHeight mBottom0">
            <OtaLogs />
          </TabPane>
          <TabPane
            tab="Map Maker Logs"
            key="12"
            className="attTableHeight mBottom0"
          >
            <MapMakerLogs></MapMakerLogs>
          </TabPane>
          <TabPane
            tab="BS OTA Logs"
            key="13"
            className="attTableHeight mBottom0"
          >
            <BasestationOTALog />
          </TabPane>
          <TabPane tab="Dev Tools" key="14" className="attTableHeight mBottom0">
            <DevTools />
          </TabPane>
          <TabPane tab="Updates" key="15" className="attTableHeight mBottom0">
            <SoftwareUpdate tabKey={tabid} />
          </TabPane>
          {process.env.REACT_APP_ALPHA_FEATURES &&
            process.env.REACT_APP_ALPHA_FEATURES.includes(
              'SupportImplements',
            ) && (
              <TabPane
                tab="Implements"
                key="16"
                className="attTableHeight mBottom0"
              >
                <Implements />
              </TabPane>
            )}
        </Tabs>
      </div>
    </div>
  );
};

export default FleetUsageContainer;
