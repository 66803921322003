/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { Collection, Feature, Overlay } from 'ol';
import OLMap from 'ol/Map';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import { Draw, Modify, Snap } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import translate from '../../locale/en_translate.json';

import ground_zero from '../../assets/images/ground_zero.svg';

import {
  approvePolygon,
  deleteAbBlockFun,
  deleteAbLineFun,
  deleteChargePoint,
  deleteMarkFun,
  deletePolyLineFun,
  deletePolygonFun,
  deleteRouteFun,
  deleteSurveyVineRowFun,
  deleteSurveyVineRowNewFun,
  deleteVineBlockFun,
  deleteVineFun,
  deleteVineLaneFun,
  deleteVineRowFun,
  deleteWaypointFun,
  getAbBlocksData,
  getAbLineData,
  getActualRoute,
  getAllVinePoints,
  getBrwonPolygonsSuggestedPolygonsData,
  getChargePointsData,
  getDesiredRoute,
  getDriveActionSinglePath,
  getFeedLanesData,
  getJumpAndGoVineRowsData,
  getMarksData,
  getPolyLineData,
  getPolygonsData,
  getRoute,
  getRoutePoints,
  getSuggestedPolygonsData,
  getSurveyVineRowData,
  getSurveyVineRowNewData,
  getSurveyVineRowsData,
  getSurveyVineRowsNewData,
  getTractorsListData,
  getTravelledRoute,
  getVineBlocksData,
  getVineLaneData,
  getVineRowsData,
  getWaypointsData,
} from '../../constants/Api';
import {
  PolyLineStyle,
  abBlockHoverLabel,
  abLineStyle,
  diff_minutes,
  displayRecenterAllViewPortSetUp,
  displayRecenterViewPortSetUp,
  drawAbBlockFun,
  drawPoly,
  drawPolyLineFun,
  feedHeadStyle,
  feedLaneStyle,
  getAreaOfPolygon,
  getDateTime,
  getLatitudeAndLongitude,
  getNameFromList,
  getRoadMapPolyStyle,
  getSateLightPolyStyle,
  getSingleElevationValue,
  getXYCoordinates,
  getXYCoordinatesList,
  isVineYardOrDairy,
  mapMakerChargePointStyle,
  mapMakerWayPointStyle,
  mapmakerVineStyle,
  markStyle,
  markStyleForDot,
  nearestLocation,
  nearestLocationNew,
  polyDrawStyle,
  polygonHoverLabel,
  previewVineRowStyle,
  priority,
  randomNumber,
  setPolygonStyle,
  vineBlockStyle,
  vineLaneStyle,
  vineRowDisableStyle,
  vineRowFromJAGStyle,
  vineRowHeadStyle,
  vineRowStyle,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import { Modal, notification } from 'antd';
import { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import { convertRouteXYtoLatLang } from '../../constants/Common';
import {
  AbBlockPayload,
  AbLinePayloadSingle,
  DrawItem,
  GenerateItem,
  LatLang,
  MapFeature,
  MarkPointPayloadTypes,
  MenuItem,
  PolyLinePayload,
  PolyPayload,
  PolygonDropDown,
  TractorsList,
  VineBlockPayloadType,
  VineLanePayloadType,
  VineRowPayload,
  VineRowPayloadSingle,
  VinesPayloadTypes,
  WaypointPayloadTypes,
} from '../../constants/types';
import { ToggleLayer } from './MapMakerBeta';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PubSub } from 'aws-amplify';
import moment from 'moment';
import ContextMenu from 'ol-contextmenu';
import { ScaleLine } from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import { createStringXY } from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import Graticule from 'ol/layer/Graticule';
import { useTranslation } from 'react-i18next';
import {
  AB_BLOCK,
  AB_LINE,
  CHARGE_POINTS,
  FEED_LANE,
  JUMP_AND_GO_MAP,
  MOS_EVENTS_TOPIC,
  POLYGON,
  POLY_LINE,
  POLY_LINE_ID_CONSTANT,
  REMOTE_AV_MAP,
  ROUTE,
  SATILITE_LAYER_URL,
  SURVEY_ID_CONSTANT,
  SURVEY_VINE_ROW,
  SURVEY_VINE_ROW_NEW,
  TRAVELLED_ROUTE,
  VINE,
  VINE_BLOCK,
  VINE_LANE,
  VINE_ROW,
  VINE_ROWS,
  WAY_POINT,
  dairyAutoDrive,
  getStep,
  listVisibility,
  types,
  vineYardAutoDrive,
} from '../../constants/constant';
import { MenuContext } from '../../context/MenuContext';
import { SET_POLYGONS_LIST } from '../../context/actions';
import { FeedLaneType } from '../../lib/types';
import { getJumpandGo } from '../analytics/util';
import AbBlock from './AbBlock';
import AbLine from './AbLine';
import AbLine1 from './AbLine1';
import AddPoly from './AddPoly';
import ChargePoint from './ChargePoint';
import DiscoverModal2 from './DiscoverModal2';
import EditPoly from './EditPoly';
import FeedLine from './FeedLine';
import FileDownload from './FileDownload';
import FileUpload from './FileUpload';
import GroundZeroEditModal from './GroundZeroEditModal';
import ImageUpload from './ImageUpload';
import MapActions from './MapActions';
import MapMakerHeader from './MapMakerHeader';
import Mark from './Mark';
import PolyLine from './PolyLine';
import Vine from './Vine';
import VineBlock from './VineBlock';
import VineBlockFileUpload from './VineBlockFileUpload';
import VineLaneModal1 from './VineLaneModal1';
import VineLaneModal2 from './VineLaneModal2';
import VineRow from './VineRow';
import VineRowJumpAndGoModel from './VineRowJumpAndGoModel';
import VineRowModal2 from './VineRowModal2';
import VineRowModelWithJumpAndGo from './VineRowModelWithJumpAndGo';
import Waypoint from './Waypoint';

// modify declarations
let polygonModify: Modify;
let abBlockModify: Modify;
let vineRowModify: Modify;
let abLineModify: Modify;
let polyLineModify: Modify;
let JumpAndGoModify: Modify;

// draw declarations
let polygonDraw: Draw;
let abBlockDraw: Draw;
let vineRowDraw: Draw;
let abLineDraw: Draw;
let vineDraw: Draw;
let waypointDraw: Draw;
let chargepointDraw: Draw;

// sources for features
let polygonSource: VectorSource<Geometry>;
let abBlockSource: VectorSource<Geometry>;
let wayPointSource: VectorSource<Geometry>;
let ChargePointSource: VectorSource<Geometry>;
let vineSource: VectorSource<Geometry>;
let vineRowSource: VectorSource<Geometry>;
let polyLineSource: VectorSource<Geometry>;
let abLineSource: VectorSource<Geometry>;
let jumpAndGoSource: VectorSource<Geometry>;

let activeDrawActionGlobal: DrawItem['item'] = 'NONE';
let showSaveGlobal = false;
let selectedVineRowsForVineBlock: any[] = [];
let mouseClickEvent = false;
let vineBlockId = 0;
let vineBlockName = '';
let polygonUuid = '';
let monitoringNeeded = false;
let globalVineRowModal: DrawItem['vineRowModal'] = 1;
let globalTriggerCancelOperation = false;

const gridArray = [
  0.000342, 0.000171, 0.0000849, 0.0000439, 0.0000229, 0.00001099, 0.00000545,
  0.00000289, 0.00000139, 0.000000669, 0.000000359, 0.000000189, 0.0000000839,
  0.0000000469,
];
let graticuleToggle = false;
const suggestedPolygon = 'suggested-green';

let globalPolyUnique = randomNumber();
let globalPolyLineUnique = randomNumber();
let globalAbLineUnique = randomNumber();
let globalVineLineUnique = randomNumber();

let brownFeatureItems: Map<number, any> = new Map();

interface Props {
  setRoutesFeature: (data: MapFeature[]) => void;
  setTravelledRoutesDataFeature: (data: MapFeature[]) => void;
  setVineFeature: (data: MapFeature[]) => void;
  setSurveyVineRowFeature: (data: MapFeature[]) => void;
  setSurveyVineRowNewFeature: (data: MapFeature[]) => void;
  setVineRowFeature: (data: MapFeature[]) => void;
  setAbLineFeature: (data: MapFeature[]) => void;
  setPolyLineFeature: (data: MapFeature[]) => void;
  setAbBlockFeature: (data: MapFeature[]) => void;
  setVineBlockFeature: (data: MapFeature[]) => void;
  setPolygonFeature: (data: MapFeature[]) => void;
  setVineLaneFeature: (data: MapFeature[]) => void;
  setWaypointFeature: (data: MapFeature[]) => void;
  setChargePointFeature: (data: MapFeature[]) => void;
  setJumpAndGoFeature: (data: MapFeature[]) => void;
  setFeedLanesFeature: (data: MapFeature[]) => void;
  handleToggleLayer: (e: boolean, type: string, index: number) => void;
  mapFeature: MapFeature;
  trigger: boolean;
  toggleMapFeature: any;
  toggleFullScreen: () => void;
  toggleLayer: ToggleLayer;
  rightSideMenuCollapsed: boolean;
  vineblockId: string;
  selectedDateRange: Date[];
  jumpAndGoTractorId: any;
  setLoader: (loader: boolean) => void;
  setSuggestedPolygonFeature: (data: MapFeature[]) => void;
  setNewSuggestedPolygonFeature: (data: MapFeature[]) => void;
  publishedTimeToReload: number;
  reloadVineBlocks: boolean;
  deleteRouteId: string;
  deleteSurveyVineRowId: string;
  deletePolyLineId: string;
  updateVineRows?: boolean;
  filteredMenuItems: MapFeature[];
  selectedFeatureItem: MenuItem;
  deleteAbBlockId: string;
  deleteFeedLane: string;
}
const MapMakerMap: React.FC<Props> = memo(
  ({
    setRoutesFeature,
    setTravelledRoutesDataFeature,
    setVineFeature,
    setSurveyVineRowFeature,
    setSurveyVineRowNewFeature,
    setVineRowFeature,
    setAbLineFeature,
    setPolyLineFeature,
    setAbBlockFeature,
    setVineBlockFeature,
    setPolygonFeature,
    setVineLaneFeature,
    setWaypointFeature,
    setChargePointFeature,
    setJumpAndGoFeature,
    setFeedLanesFeature,
    handleToggleLayer,
    mapFeature,
    trigger,
    toggleFullScreen,
    toggleLayer: toggleLayer,
    rightSideMenuCollapsed,
    vineblockId,
    selectedDateRange,
    jumpAndGoTractorId,
    setLoader,
    setSuggestedPolygonFeature,
    setNewSuggestedPolygonFeature,
    publishedTimeToReload,
    reloadVineBlocks,
    deleteRouteId,
    deleteSurveyVineRowId,
    deletePolyLineId,
    updateVineRows,
    filteredMenuItems,
    selectedFeatureItem,
    deleteAbBlockId,
    deleteFeedLane,
  }: Props) => {
    const [mapInitialized, setMapInitialized] = useState(false);
    const { userDetails, APPReducer } = useContext(ApplicationContext);
    const [appState, appDispatch] = APPReducer;
    const { groundZero, polygonsList } = appState;
    const { t } = useTranslation();

    const { collapsed } = useContext(MenuContext);
    const [base, setBase] = useState<[number, number]>([0, 0]);
    const [baseLat, setGroundLat] = useState(0);
    const [baseLang, setGroundLang] = useState(0);
    const [updateBaseLat, setUpdateBaseLat] = useState(0);
    const [updateBaseLang, setUpdateBasedLang] = useState(0);
    const [toggleSatellite, setToggleSatellite] = useState(true);
    const [fileUpload, setFileUpload] = useState(false);
    const [fileDownload, setFileDownload] = useState(false);
    const [imgUpload, setImgUpload] = useState(false);
    const [layerVisibleUpdated, setLayerVisibleUpdated] = useState<any>({});
    const [showPoly, setShowPoly] = useState<boolean>(false);
    const [showAbBlock, setShowAbBlock] = useState<boolean>(false);
    const [latLong, setLatLong] = useState<boolean>(false);
    const [showVineRow, setShowVineRow] = useState<boolean>(false);
    const [showAbLine, setShowAbLine] = useState<boolean>(false);
    const [showEditPoly, setShowEditPoly] = useState<boolean>(false);
    const [showWaypoint, setShowWaypoint] = useState<boolean>(false);
    const [showChargePoint, setShowChargePoint] = useState<boolean>(false);
    const [showPolyLine, setShowPolyLine] = useState<boolean>(false);
    const [showMarkPoint, setShowMarkPoint] = useState<boolean>(false);
    const [showVineBlock, setShowVineBlock] = useState<boolean>(false);
    const [showVineLane, setShowVineLane] = useState<boolean>(false);
    const [showVineBlockFileUpload, setShowVineBlockFileUpload] =
      useState(false);
    const [vineBlockPayload, setVineBlockPayload] =
      useState<VineBlockPayloadType>();
    const [vineLanePayload, setVineLanePayload] =
      useState<VineLanePayloadType>();
    const [polyPayload, setPolyPayload] = useState<PolyPayload>();
    const [abBlockPayload, setAbBlockPayload] =
      useState<AbBlockPayload | null>();
    const [vineRowPayload, setVineRowPayload] =
      useState<VineRowPayloadSingle | null>(null);
    const [polyLinePayload, setPolyLinePayload] =
      useState<PolyLinePayload | null>(null);
    const [abLinePayload, setAbLinePayload] =
      useState<AbLinePayloadSingle | null>();
    const [abLinePayload1, setAbLinePayload1] =
      useState<AbLinePayloadSingle | null>();
    const [vineRowModel2FirstPayload, setVineRowModel2FirstPayload] =
      useState<VineRowPayload | null>();
    const [vineRowModel2LastPayload, setVineRowModel2LastPayload] =
      useState<VineRowPayload | null>();
    const [wayPointPayload, setWayPointPayload] =
      useState<WaypointPayloadTypes>();
    const [chargePointPayload, setChargePointPayload] =
      useState<WaypointPayloadTypes>();
    const [markPointPayload, setMarkPointPayload] =
      useState<MarkPointPayloadTypes>();
    const [vinePayload, setVinePayload] = useState<VinesPayloadTypes>();
    const [showVine, setShowVine] = useState<boolean>(false);
    const [polygonsListForPop, setPolygonsListForPop] = useState<any[]>([]);
    const [brownPolygons, setBrownPolygons] = useState<any>([]);
    const [brownPolyCount, setBrownPolyCount] = useState<number>(0);
    const [suggestedNewPolygons, setNewSuggestedPolygons] = useState<any>([]);
    const [showDiscover, setShowDiscover] = useState(false);
    const [waypoints, setWaypoints] = useState<any>([]);
    const [polyLines, setPolyLines] = useState<any>([]);
    const [chargePoints, setChargePoints] = useState<any>([]);
    const [vines, setVines] = useState<any>([]);
    const [polygons, setPolygons] = useState<any>([]);
    const [abBlocks, setAbBlocks] = useState<any>([]);
    const [vineRows, setVineRows] = useState<any>([]);
    const [abLines, setAbLines] = useState<any>([]);
    const [vineBlocks, setVineBlocks] = useState<any>([]);
    const [vineLanes, setVineLanes] = useState<any>([]);
    const [marks, setMarks] = useState<any>([]);
    const [surveyVineRowsState, setSurveyVineRows] = useState<any>([]);
    const [surveyVineRowsNewState, setSurveyVineRowsNew] = useState<any>([]);
    const [vineRowHeads, setVineRowHeads] = useState<any>([]);
    const [brownPoly, setBrownPoly] = useState('');
    const [generateFromRowOrAbLane, setGenerateFromRowOrAbLane] =
      useState<GenerateItem['type']>(VINE_ROW);

    const [activeDrawAction, setActiveDrawAction] =
      useState<DrawItem['item']>('NONE');
    const [showSave, setShowSave] = useState(false);
    const [vineLaneModal, setVineLaneModal] = useState<1 | 2>(1);
    const [vineRowModal, setVineRowModal] =
      useState<DrawItem['vineRowModal']>(1);
    const [reloadSuggestedPolygons, setReloadSuggestedPolygons] =
      useState(false);
    const [selectedJumpAndGoForVineRow, setSelectedJumpAndGoForVineRow] =
      useState<any[]>([]);
    const [
      selectedJumpAndGoListForVineRows,
      setSelectedJumpAndGoListForVineRows,
    ] = useState<any[]>([]);
    const [tractors, setTractorsData] = useState<TractorsList[]>([]);
    const [selectedTractor, setSelectedTractor] = useState<string>('');
    const [selectedVineRowsFromJumpAndGo, setSelectedVineRowsFromJumpAndGo] =
      useState<any[]>([]);
    const [triggerCancelOperation, setTriggerCancelOperation] =
      useState<boolean>(false);
    const [showFeedLine, setShowFeedLine] = useState<boolean>(false);
    const [feedLanes, setFeedLanes] = useState<FeedLaneType[]>([]);
    const { confirm, error } = Modal;

    const mapRef = useRef<OLMap | null>(null);
    const mapElement: any = useRef();
    const initialLayer = useRef<VectorLayer<any>>();
    const vinesLayerRef = useRef<VectorLayer<any>>();
    const routeLayerRef = useRef<VectorLayer<any>>();
    const jumpAndGoLayerRef = useRef<VectorLayer<any>>();
    const travelledRouteLayerRef = useRef<VectorLayer<any>>();
    const desiredRouteLayerRef = useRef<VectorLayer<any>>();
    const vineRowsLayerRef = useRef<VectorLayer<any>>();
    const polyLinesLayerRef = useRef<VectorLayer<any>>();
    const abLinesLayerRef = useRef<VectorLayer<any>>();
    const abBlockLayerRef = useRef<VectorLayer<any>>();
    const vineRowsForJumpAndGoLayerRef = useRef<VectorLayer<any>>();
    const vineBlocksLayerRef = useRef<VectorLayer<any>>();
    const polygonsLayerRef = useRef<VectorLayer<any>>();
    const vineLaneLayerRef = useRef<VectorLayer<any>>();
    const waypointsLayerRef = useRef<VectorLayer<any>>();
    const chargePointsLayerRef = useRef<VectorLayer<any>>();
    const marksLayerRef = useRef<VectorLayer<any>>();
    const drawFeaturesLayer = useRef<VectorLayer<any>>();
    const vineRowHeadLayerRef = useRef<VectorLayer<any>>();
    const markerPointRef = useRef<VectorLayer<any>>();
    const surveyVineRowLayerRef = useRef<VectorLayer<any>>();
    const surveyVineRowNewLayerRef = useRef<VectorLayer<any>>();
    const previewSurveyVineRowLayerRef = useRef<VectorLayer<any>>();
    const feedLaneFeaturesLayerRefs = useRef<VectorLayer<any>>();
    const vectorTileLayerRef = useRef<TileLayer<any>>();
    const graticuleLayerRef = useRef<Graticule>();
    const satelliteTileLayerRef = useRef<TileLayer<any>>();
    const fieldFeatureItem = useRef<any>();
    const baseFeatureItem = useRef<any>();
    const container: any = useRef();
    const content: any = useRef();
    const createdPolyFeatureItem = useRef<any>();

    // default right click menu
    const contextMenuItems = [
      {
        text: 'Recentre',
        classname: 'some-style-class', // add some CSS rules
        callback: () => reCenter(), // `center` is your callback function
      },
    ];
    // default right click menu setup
    const contextmenu = new ContextMenu({
      width: 180,
      items: contextMenuItems,
    });
    let mapMakeToggle = false;
    useEffect(() => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_MAKER_GOOGLE_API_KEY}`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    useEffect(() => {
      if (
        filteredMenuItems &&
        filteredMenuItems.length > 0 &&
        selectedFeatureItem?.type === VINE_ROW
      ) {
        filteredMenuItems.forEach((item) => {
          const feature = vineRowsLayerRef?.current
            ?.getSource()
            .getFeatureById(item.mapId);
          if (feature && item.visible) {
            feature.setStyle(item?.style);
          } else {
            feature.setStyle(new Style({}));
          }
        });
      }
    }, [filteredMenuItems, selectedFeatureItem]);

    useEffect(() => {
      globalVineRowModal = vineRowModal;
    }, [vineRowModal]);

    useEffect(() => {
      if (userDetails && userDetails.organization && mapInitialized) {
        getVineRowsListData();
      }
    }, [updateVineRows]);

    useEffect(() => {
      userDetails &&
        userDetails.organization &&
        mapInitialized &&
        base &&
        getVineBlocksListData();
    }, [reloadVineBlocks]);

    // to reload the ab-line and ab-block
    useEffect(() => {
      publishedTimeToReload && getAbLineListData();
      publishedTimeToReload && getAbBlocksListData();
    }, [publishedTimeToReload]);

    useEffect(() => {
      //   if (!groundZero) getGroundZeroData();
      if (userDetails && userDetails.organization && groundZero) {
        initializeMap();
      }
    }, [userDetails, groundZero]);

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:3857',
    });

    let graticule = new Graticule({
      strokeStyle: new Stroke({
        color: 'rgba(1, 1, 1, 1)',
        width: 1,
      }),
      intervals: [gridArray[0]],
      showLabels: false,
      targetSize: 5,
      visible: false,
    });
    // Map Initialized Start here
    const initializeMap = async () => {
      try {
        // const { organization } = userDetails;
        // const { latitude, longitude } = await getGroundZero(
        //   organization.api_url,
        //   organization.farm.id,
        // );
        const { latitude, longitude } = groundZero;
        setGroundLat(latitude);
        setGroundLang(longitude);
        setUpdateBaseLat(latitude);
        setUpdateBasedLang(longitude);

        setBase([latitude, longitude]);

        const baseLocation = new Feature({
          geometry: new Point(proj.fromLonLat([longitude, latitude])),
          name: 'Ground zero',
        });
        baseFeatureItem.current = baseLocation;
        // base location feature layer
        const initialFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [baseLocation],
          }),
          style: new Style({
            image: new Icon({
              src: ground_zero,
              scale: 0.2,
            }),
          }),
        });
        // draw feature layer
        const drawFeatureslayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.DRAWLAYER,
        });
        // road map layer
        const vectorLayer = new TileLayer({
          source: new OSM(),
          visible: false,
        });
        // satelight map layer
        const satelliteLayer = new TileLayer({
          source: new XYZ({
            url: SATILITE_LAYER_URL,
            cacheSize: 1000,
          }),
          visible: true,
        });
        // vine rows list layer
        const vineRowsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEROW,
          visible: listVisibility.VINEROW,
          style: vineRowStyle,
        });

        const polyLinesFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.POLY_LINE,
          visible: listVisibility.POLY_LINE,
          style: PolyLineStyle,
        });

        const vineRowsFromJumpAndGoFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEROW,
          visible: true,
          style: vineRowFromJAGStyle,
        });

        // polygons list layer
        const polygonsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.POLYGON,
        });
        // vines list layer
        const vinesFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINE,
          visible: listVisibility.VINE,
          style: mapmakerVineStyle,
        });
        // vine lanes list layer
        const vineLaneFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.VINELANE,
        });
        // vine blocks list layer
        const vineBlocksFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEBLOCK,
          visible: listVisibility.VINEBLOCK,
          style: vineBlockStyle,
        });
        // waypoints list layer
        const waypointsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.WAYPOINT,
          zIndex: priority.WAYPOINT,
          style: mapMakerWayPointStyle,
        });

        // charge points list layer
        const chargePointFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.CHARGEPOINTS,
          zIndex: priority.CHARGEPOINTS,
          style: mapMakerChargePointStyle,
        });
        // marks list layer
        const marksFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.MARKS,
          style: markStyle,
        });
        // vine rows heads list layer
        const vinerowHeadFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.VINE_ROW_HEAD,
          style: vineRowHeadStyle,
        });
        // ab lines list layer
        const abLinesFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.ABLANE,
          visible: listVisibility.ABLINE,
          style: abLineStyle,
        });

        // polygons list layer
        const abBlockFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.ABBLOCK,
        });
        const markerLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: 13,
          style: markStyleForDot,
        });
        const surveyVineRowLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: 12,
          style: vineRowFromJAGStyle,
          visible: listVisibility.SURVEYVINROW,
        });
        const surveyVineRowNewLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: 12,
          // style: vineRowNewFromJAGStyle,
          style: vineRowFromJAGStyle,
          visible: listVisibility.SURVEY_VINE_ROW_NEW,
        });
        const previewSurveyVineRowLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: 12,
          style: previewVineRowStyle,
          visible: listVisibility.PREVIEWSURVEYVINROW,
        });

        const feedLaneFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.FEED_LINE,
          style: feedLaneStyle,
          zIndex: priority.ROUTINE,
        });

        // create map
        const initialMap = new OLMap({
          target: mapElement.current,
          layers: [
            satelliteLayer,
            vectorLayer,
            initialFeaturesLayer,
            graticule,
            drawFeatureslayer,
            vineRowsFeaturesLayer,
            polyLinesFeaturesLayer,
            polygonsFeaturesLayer,
            vinesFeaturesLayer,
            vineLaneFeaturesLayer,
            vineBlocksFeaturesLayer,
            waypointsFeaturesLayer,
            chargePointFeaturesLayer,
            marksFeaturesLayer,
            vinerowHeadFeaturesLayer,
            vineRowsFromJumpAndGoFeaturesLayer,
            abLinesFeaturesLayer,
            abBlockFeaturesLayer,
            markerLayer,
            surveyVineRowLayer,
            surveyVineRowNewLayer,
            previewSurveyVineRowLayer,
            feedLaneFeaturesLayer,
          ],
          controls: [scaleControl(), mousePositionControl],
          view: new View({
            projection: 'EPSG:3857',
            center: proj.transform(
              [longitude, latitude],
              'EPSG:4326',
              'EPSG:3857',
            ),
            zoom: 15,
            // minZoom: 15,
            maxZoom: 28,
          }),
        });
        setMapInitialized(true);
        mapRef.current = initialMap;
        initialLayer.current = initialFeaturesLayer;
        satelliteTileLayerRef.current = satelliteLayer;
        vectorTileLayerRef.current = vectorLayer;
        graticuleLayerRef.current = graticule;
        drawFeaturesLayer.current = drawFeatureslayer;
        waypointsLayerRef.current = waypointsFeaturesLayer;
        chargePointsLayerRef.current = chargePointFeaturesLayer;
        vineRowsLayerRef.current = vineRowsFeaturesLayer;
        polyLinesLayerRef.current = polyLinesFeaturesLayer;
        vineRowsForJumpAndGoLayerRef.current =
          vineRowsFromJumpAndGoFeaturesLayer;

        polygonsLayerRef.current = polygonsFeaturesLayer;
        vinesLayerRef.current = vinesFeaturesLayer;
        vineLaneLayerRef.current = vineLaneFeaturesLayer;
        vineBlocksLayerRef.current = vineBlocksFeaturesLayer;
        marksLayerRef.current = marksFeaturesLayer;
        vineRowHeadLayerRef.current = vinerowHeadFeaturesLayer;
        abLinesLayerRef.current = abLinesFeaturesLayer;
        abBlockLayerRef.current = abBlockFeaturesLayer;
        markerPointRef.current = markerLayer;
        surveyVineRowLayerRef.current = surveyVineRowLayer;
        surveyVineRowNewLayerRef.current = surveyVineRowNewLayer;
        feedLaneFeaturesLayerRefs.current = feedLaneFeaturesLayer;

        previewSurveyVineRowLayerRef.current = previewSurveyVineRowLayer;
        let currZoom = mapRef?.current?.getView().getZoom();
        // set the grids based on zoom
        mapRef.current?.on('moveend', function (e) {
          const newZoom = mapRef?.current?.getView().getZoom();
          if (graticuleToggle && currZoom != newZoom) {
            if (newZoom) {
              if (currZoom && newZoom > currZoom) {
                zoomInGrid();
              } else {
                zoomOutGrid();
              }
            }
            currZoom = newZoom;
          }
        });
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    // Map Inititilesed Start here
    const scaleControl = () => {
      const control = new ScaleLine({
        units: 'metric',
        className: 'positionMap',
      });
      return control;
    };
    useEffect(() => {
      if (
        base &&
        mapInitialized &&
        baseFeatureItem &&
        baseFeatureItem.current
      ) {
        const baseDrag = new Modify({
          features: new Collection([baseFeatureItem.current]),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        baseDrag.on('modifyend', function (evt: any) {
          const arr: any = evt.features.getArray();
          if (arr && arr.length && arr[arr.length - 1]) {
            const point = arr[arr.length - 1].getGeometry().getCoordinates();
            const latLong: number[] = proj.transform(
              point,
              'EPSG:3857',
              'EPSG:4326',
            );
            setShowSave(true);
            setActiveDrawAction('BASESTATION');
            setUpdateBaseLat(latLong[1]);
            setUpdateBasedLang(latLong[0]);
          }
        });
        mapRef.current?.addInteraction(baseDrag);
      }
    }, [mapInitialized, base]);

    useEffect(() => {
      if (mapInitialized) {
        // added right click menu
        mapRef.current?.addControl(contextmenu);
        // get the travvled routes
        getTravelledRoutes();
        // get the Routes
        getRoutes();

        // added code on hover namings
        const overlay = new Overlay({
          element: container.current,
          autoPan: false,
          autoPanAnimation: {
            duration: 10,
          },
        });
        mapRef.current?.on('pointermove', function (evt) {
          const hit = mapRef.current?.forEachFeatureAtPixel(
            evt.pixel,
            function (feature: FeatureLike) {
              const coordinate = evt.coordinate;
              let name = feature.get('name');
              const pointType = feature.get('pointType');
              const color = feature.get('color');
              // added below code displaying remarks field
              if (pointType === 'Polygon' && color === 'brown') {
                const remarks = feature.get('remarks');
                if (remarks) {
                  name = feature.get('label');
                  const innerHTML = getBrownPolyRemarks(name, remarks);
                  content.current.innerHTML = innerHTML;
                  if (
                    !overlay
                      .getElement()
                      ?.classList.contains('ol-details-popup')
                  ) {
                    overlay.getElement()?.classList.add('ol-details-popup');
                  }
                  overlay.setPosition(evt.coordinate);
                  mapRef.current?.addOverlay(overlay);
                  return true;
                }
              }
              if (name) {
                content.current.innerHTML =
                  '<p class="nameOverlay">' + name + '</p>';
                overlay.setPosition(coordinate);
                mapRef.current?.addOverlay(overlay);
                return true;
              }

              return false;
            },
          );
          if (hit) {
            evt.map.getTargetElement().style.cursor = 'pointer';
            if (
              (activeDrawActionGlobal === WAY_POINT ||
                activeDrawActionGlobal === VINE) &&
              !showSaveGlobal
            ) {
              evt.map.getTargetElement().style.cursor = 'none';
            }
          } else {
            mapRef.current?.removeOverlay(overlay);
            evt.map.getTargetElement().style.cursor = '';
            if (
              (activeDrawActionGlobal === WAY_POINT ||
                activeDrawActionGlobal === VINE) &&
              !showSaveGlobal
            ) {
              evt.map.getTargetElement().style.cursor = 'none';
            }
            return;
          }
        });
        mapRef.current?.on('click', function (evt) {
          const coords = proj.toLonLat(evt.coordinate);

          mapRef.current?.forEachFeatureAtPixel(
            evt.pixel,
            function (feature: any) {
              evt.preventDefault();
              if (!mouseClickEvent) {
                setShowSave(true);
                mouseClickEvent = true;
                setTimeout(function () {
                  mouseClickEvent = false;
                  if (
                    feature &&
                    (feature.get('pointType') === 'VINEROWFROMJUMPANDGONEW' ||
                      feature.get('pointType') === 'VINEROWFROMJUMPANDGO') &&
                    activeDrawActionGlobal === 'VINEROWFROMJUMPANDGO'
                  ) {
                    const rowData = feature.get('rowData');
                    const type = feature.get('type');
                    const records =
                      feature.get('pointType') === 'VINEROWFROMJUMPANDGONEW'
                        ? rowData.stepRecords
                        : rowData.records;

                    const id =
                      feature.get('pointType') === 'VINEROWFROMJUMPANDGONEW'
                        ? nearestLocationNew(records, coords[1], coords[0])
                        : nearestLocation(records, coords[1], coords[0]);

                    selectedVineRowsForVineBlock.push({
                      name: feature.get('name'),
                      rowUuid: feature.get('rowUuid'),
                      data: feature.get('rowData'),
                      records,
                      id,
                      type,
                    });
                    if (
                      selectedVineRowsForVineBlock &&
                      selectedVineRowsForVineBlock.length === 4
                    ) {
                      let first = [
                        selectedVineRowsForVineBlock[0].id,
                        selectedVineRowsForVineBlock[1].id,
                      ];

                      if (first[0] > first[1]) {
                        first = [first[1], first[0]];
                      }
                      let list: any = [];
                      list = [
                        selectedVineRowsForVineBlock[0].records[first[0]],
                        selectedVineRowsForVineBlock[0].records[first[1]],
                      ];

                      selectedVineRowsForVineBlock[0].data = list;
                      selectedVineRowsForVineBlock[1].data = list;

                      let last = [
                        selectedVineRowsForVineBlock[2].id,
                        selectedVineRowsForVineBlock[3].id,
                      ];
                      if (last[0] > last[1]) {
                        last = [last[1], last[0]];
                      }

                      let list1: any = [];
                      list1 = [
                        selectedVineRowsForVineBlock[2].records[last[0]],
                        selectedVineRowsForVineBlock[2].records[last[1]],
                      ];

                      selectedVineRowsForVineBlock[2].data = list1;
                      selectedVineRowsForVineBlock[3].data = list1;
                      saveVineRowFromJumpandGo();
                    }
                    const f = new Feature({
                      geometry: new Point(proj.fromLonLat(coords)),
                      pointType: 'Mark',
                    });

                    markerPointRef &&
                      markerPointRef.current &&
                      markerPointRef.current?.getSource().addFeature(f);
                  } else if (
                    feature &&
                    feature.get('pointType') ===
                      'VINEROWUSINGVINEROWJUMPANDGO' &&
                    activeDrawActionGlobal === 'VINEROWUSINGVINEROWJUMPANDGO'
                  ) {
                    let exist = false;
                    selectedVineRowsForVineBlock.forEach(
                      (element: { rowUuid: string }) => {
                        if (
                          !exist &&
                          element.rowUuid === feature.get('rowUuid')
                        ) {
                          exist = true;
                        }
                      },
                    );
                    if (!exist) {
                      selectedVineRowsForVineBlock.push({
                        name: feature.get('name'),
                        rowUuid: feature.get('rowUuid'),
                        data: feature.get('rowData'),
                      });
                      feature.setStyle(vineRowDisableStyle);
                      if (
                        activeDrawActionGlobal ===
                          'VINEROWUSINGVINEROWJUMPANDGO' &&
                        selectedVineRowsForVineBlock.length === 2
                      ) {
                        saveVineRowFromJumpandGo();
                      }
                    } else {
                      const li: any[] = [];
                      selectedVineRowsForVineBlock.forEach(
                        (element: { rowUuid: string }) => {
                          if (element.rowUuid === feature.get('rowUuid')) {
                            feature.setStyle(vineRowStyle);
                          } else {
                            li.push(element);
                          }
                        },
                      );
                      selectedVineRowsForVineBlock = li;
                    }
                  } else if (
                    feature &&
                    feature.get('pointType') === 'vinerow' &&
                    (activeDrawActionGlobal === VINE_BLOCK ||
                      activeDrawActionGlobal === 'VINEBLOCKEDIT' ||
                      activeDrawActionGlobal === VINE_LANE)
                  ) {
                    let exist = false;
                    selectedVineRowsForVineBlock.forEach(
                      (element: { rowUuid: string }) => {
                        if (
                          !exist &&
                          element.rowUuid === feature.get('rowUuid')
                        ) {
                          exist = true;
                        }
                      },
                    );
                    if (!exist) {
                      selectedVineRowsForVineBlock.push({
                        name: feature.get('name'),
                        rowUuid: feature.get('rowUuid'),
                      });
                      feature.setStyle(vineRowDisableStyle);
                      if (
                        activeDrawActionGlobal === VINE_LANE &&
                        selectedVineRowsForVineBlock.length === 2
                      ) {
                        saveVineLane();
                      }
                    } else {
                      const li: any[] = [];
                      selectedVineRowsForVineBlock.forEach(
                        (element: { rowUuid: string }) => {
                          if (element.rowUuid === feature.get('rowUuid')) {
                            feature.setStyle(vineRowStyle);
                          } else {
                            li.push(element);
                          }
                        },
                      );
                      selectedVineRowsForVineBlock = li;
                    }
                  }
                }, 1000);
              }
            },
          );
        });
      }
    }, [userDetails, mapInitialized]);
    /**
     * Subscribing to Tractors and getting Information Starts Here
     */

    /**
     * Vines initialization starts Here
     */
    const getVines = async () => {
      vinesLayerRef.current?.getSource().clear();
      try {
        const records = vines && vines.length > 0 ? vines : [];
        const vinesFeatures: Feature<Geometry>[] = [];
        const vineList: MapFeature[] = [];
        records.map((vine: any) => {
          const id = `vine_${vine.id}`;
          let latAndLong: number[] = [];
          if (vine.latitude !== null && vine.longitude !== null) {
            latAndLong = [vine.longitude, vine.latitude];
          } else {
            latAndLong = getLatitudeAndLongitude(
              [vine.y / 100, vine.x / 100],
              base,
              true,
            );
          }
          const vineLocation = new Feature({
            geometry: new Point(proj.fromLonLat(latAndLong)),
            pointType: 'vine',
            name: vine.name,
            pointId: vine.id,
          });
          vineLocation.setId(id);
          vinesFeatures.push(vineLocation);
          vineList.push({
            id: vine.id,
            name: vine.name,
            type: VINE,
            mapId: id,
            visible: true,
            style: mapmakerVineStyle,
          });
        });

        vinesLayerRef.current?.getSource().addFeatures(vinesFeatures);
        vinesLayerRef.current?.setVisible(listVisibility.VINE);
        setVineFeature(vineList);

        const vineDrag = new Modify({
          features: new Collection(vinesFeatures),
          style: new Style({}),
          // pixelTolerance: 20,
        });
        // Add the event to the drag and drop feature
        vineDrag.on('modifyend', function (evt: any) {
          dragVinePoint(evt);
        });
        // Add the vector layer and the refering drag and drop interaction
        mapRef.current?.addInteraction(vineDrag);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    /**
     * Vines initialization Ends Here
     */

    /**
     * Start Travelled Points
     */
    const getTravelledRoutes = async () => {
      const routeList: MapFeature[] = [];
      const travelledRoutes = await getTravelledRoute(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.organization.farm.id,
      );

      const routeFeature: Feature<Geometry>[] = [];
      const desiredFeature: Feature<Geometry>[] = [];
      const style = new Style({
        stroke: new Stroke({
          color: '#64F4FF',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      });
      const desiredStyle = new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      });
      travelledRoutes.map(
        async (route: {
          id: number;
          copycat_name: string;
          copycat_travelled_route_uuid: string;
        }) => {
          const routeLocation = new Feature({
            geometry: new LineString([]),
            name: route.copycat_name,
          });
          routeLocation.setId(route.copycat_travelled_route_uuid);

          const desiredLocation = new Feature({
            geometry: new LineString([]),
            name: route.copycat_name,
          });
          desiredLocation.setId(route.copycat_travelled_route_uuid);

          routeFeature.push(routeLocation);
          desiredFeature.push(desiredLocation);
          routeList.push({
            id: route.id,
            name: route.copycat_name,
            type: TRAVELLED_ROUTE,
            mapId: route.copycat_travelled_route_uuid,
            visible: false,
            style,
          });
        },
      );
      const routeFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: routeFeature,
        }),
        zIndex: Infinity,
        style,
      });
      routeFeaturesLayer.setZIndex(0);
      mapRef.current?.addLayer(routeFeaturesLayer);

      const desiredFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: desiredFeature,
        }),
        zIndex: Infinity,
        style: desiredStyle,
      });
      desiredFeatureLayer.setZIndex(0);
      mapRef.current?.addLayer(desiredFeatureLayer);

      travelledRouteLayerRef.current = routeFeaturesLayer;
      travelledRouteLayerRef.current?.setVisible(listVisibility.TRAVELLEDROUTE);
      desiredRouteLayerRef.current = desiredFeatureLayer;
      setTravelledRoutesDataFeature(routeList);
    };

    const travelledRouteUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = travelledRouteLayerRef.current;
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }

      const { actual_path }: any = await getActualRoute(
        organization.api_url,
        organization.id,
        organization.farm.id,
        route.mapId,
      );

      if (actual_path.length > 0) {
        const array = actual_path.map(
          (item: { latitude: number; longitude: number }) =>
            proj.fromLonLat([item.longitude, item.latitude]),
        );

        tractorLocation.setGeometry(new LineString(array));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', `${route.name}_actual`);
        tractorLocation.setStyle(route.style);

        if (mapRef.current) {
          if (actual_path && actual_path.length > 0) {
            const cordinates: { latitude: number; longitude: number } =
              actual_path[0];

            mapRef.current
              .getView()
              .setCenter(
                proj.fromLonLat([cordinates.longitude, cordinates.latitude]),
              );
          }
        }
      }
    };

    const desiredRouteUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = desiredRouteLayerRef.current;
      defaultLayer?.setVisible(true);
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }

      const desired_path: any = await getDesiredRoute(
        organization.api_url,
        organization.id,
        organization.farm.id,
        route.mapId,
      );

      if (desired_path.length > 0) {
        const array = desired_path.map(
          (item: { latitude: number; longitude: number }) =>
            proj.fromLonLat([item.longitude, item.latitude]),
        );

        tractorLocation.setGeometry(new LineString(array));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', `${route.name}_desired`);
        const desiredStyle = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        tractorLocation.setStyle(desiredStyle);
      }
    };

    /**
     * Stop Travelled Points
     */

    /**
     * Draw route start Here
     */
    useEffect(() => {
      if (deleteRouteId && deleteRouteId !== '') {
        const feature = routeLayerRef.current
          ?.getSource()
          .getFeatureById(deleteRouteId);
        const id = deleteRouteId.replace('route_', '');
        const name = feature ? feature?.get('name') : '';
        confirmDelete(id, ROUTE, name);
      }
    }, [deleteRouteId]);
    const deleteRoute = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteRouteFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = routeLayerRef.current
            ?.getSource()
            .getFeatureById('route_' + pointId);
          feature && routeLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getRoutes();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    const deleteSurveyVineRow = async (
      pointId: number,
      withSurvey?: MapFeature,
    ) => {
      try {
        const { organization } = userDetails;
        const feature = surveyVineRowLayerRef.current
          ?.getSource()
          .getFeatureById(SURVEY_ID_CONSTANT + pointId);
        let rowData;
        if (feature && feature.get('rowData')) {
          rowData = feature.get('rowData');
        }
        if (withSurvey && withSurvey.id) {
          rowData = withSurvey;
        }
        if (rowData && rowData.id) {
          const resp = await deleteSurveyVineRowFun(
            organization.api_url,
            organization.farm.id,
            rowData.id,
          );
          // const resp = { msg: 'success' };
          if (resp && resp.msg) {
            const feature = surveyVineRowLayerRef.current
              ?.getSource()
              .getFeatureById(SURVEY_ID_CONSTANT + pointId);
            feature &&
              surveyVineRowLayerRef?.current
                ?.getSource()
                .removeFeature(feature);
            notification.success({
              message: resp.msg,
            });
            getSurveyVineRowsListData();
          }
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    useEffect(() => {
      if (deletePolyLineId && deletePolyLineId.length > 0) {
        const record = JSON.parse(deletePolyLineId);
        record &&
          record.id &&
          confirmDelete(record.polyline_uuid, POLY_LINE, record.name);
      }
    }, [deletePolyLineId]);

    const deletePolyLine = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deletePolyLineFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        // const resp = { msg: 'success' };
        if (resp && resp.msg) {
          const feature = polyLinesLayerRef.current
            ?.getSource()
            .getFeatureById(POLY_LINE_ID_CONSTANT + pointId);
          feature &&
            polyLinesLayerRef?.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getPolyLineListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    // get routes routes
    const getRoutes = async () => {
      try {
        const routeList: MapFeature[] = [];
        const { organization } = userDetails;
        const records: { id: number; name: string }[] = await getRoute(
          organization.api_url,
          organization.farm.id,
        );
        const routeFeature: Feature<Geometry>[] = [];
        const style = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        records.forEach(
          async (route: { id: number; name: string }, index: number) => {
            const record = [{ x: 0, y: 0 }];
            if (record.length > 0) {
              const id = 'route_' + route.id;
              const convertedRecord = convertRouteXYtoLatLang(
                record,
                baseLat,
                baseLang,
              );
              const array = convertedRecord.map(
                (item: { x: number; y: number }) =>
                  proj.fromLonLat([item.x, item.y]),
              );
              const routeLocation = new Feature({
                geometry: new LineString(array),
                name: route.name,
              });
              routeLocation.setId(id);
              routeFeature.push(routeLocation);
              routeList.push({
                id: route.id,
                name: route.name,
                type: ROUTE,
                mapId: id,
                visible: false,
                style,
              });
            }
            if (index + 1 === records.length) {
              const routeFeaturesLayer = new VectorLayer({
                source: new VectorSource({
                  features: routeFeature,
                }),
                zIndex: Infinity,
                style,
              });
              routeFeaturesLayer.setZIndex(0);
              mapRef.current?.addLayer(routeFeaturesLayer);
              routeLayerRef.current = routeFeaturesLayer;
              setRoutesFeature(routeList);
            }
          },
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const deleteSurveyVineRowNew = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteSurveyVineRowNewFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        const layer = getFeatureLayer(SURVEY_VINE_ROW_NEW);
        const features = layer.getSource().getFeatures();
        const list: any = [];
        features?.map((feature: any) => {
          const id = feature.get('rowUuid');
          id === pointId && list.push(feature);
        });
        if (list && list.length > 0) {
          list.forEach((feature: any) => {
            layer?.getSource().removeFeature(feature);
          });
        }
        notification.success({
          message: resp.msg,
        });
        getSurveyVineRowsNewListData();
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    const routeUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = routeLayerRef.current;
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }
      const records: LatLang[] = await getRoutePoints(
        organization.api_url,
        organization.farm.id,
        route.id,
      );
      if (records.length > 0) {
        let latLanOrXY = false;
        const { latitude, longitude } = records[0];
        if (latitude && longitude) {
          latLanOrXY = true;
        } else {
          latLanOrXY = false;
        }
        const coordinates: any[] = [];
        if (latLanOrXY) {
          records.forEach((element: any) => {
            coordinates.push(
              proj.fromLonLat([element.longitude, element.latitude]),
            );
          });
        } else {
          records.forEach((element) => {
            const latAndLong = getLatitudeAndLongitude(
              [element.y, element.x],
              base,
              true,
            );
            coordinates.push(proj.fromLonLat(latAndLong));
          });
        }
        tractorLocation.setGeometry(new LineString(coordinates));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', route.name);
        tractorLocation.setStyle(route.style);
      }
    };

    // convert points to latitude and longitude

    const getFeatureLayer = (type: string) => {
      let layer: VectorLayer<any> | any;
      if (type === ROUTE) {
        layer = routeLayerRef.current;
      } else if (type === VINE) {
        layer = vinesLayerRef.current;
      } else if (type === VINE_ROW) {
        layer = vineRowsLayerRef.current;
      } else if (type === VINE_BLOCK) {
        layer = vineBlocksLayerRef.current;
      } else if (type === POLYGON) {
        layer = polygonsLayerRef.current;
      } else if (type === WAY_POINT) {
        layer = waypointsLayerRef.current;
      } else if (type === CHARGE_POINTS) {
        layer = chargePointsLayerRef.current;
      } else if (type === TRAVELLED_ROUTE) {
        layer = travelledRouteLayerRef.current;
      } else if (type === 'MARKS') {
        layer = marksLayerRef.current;
      } else if (type === VINE_LANE) {
        layer = vineLaneLayerRef.current;
      } else if (type === JUMP_AND_GO_MAP) {
        layer = jumpAndGoLayerRef.current;
      } else if (type === 'WINEROWHEAD') {
        layer = vineRowHeadLayerRef.current;
      } else if (type === AB_LINE) {
        layer = abLinesLayerRef.current;
      } else if (type === AB_BLOCK) {
        layer = abBlockLayerRef.current;
      } else if (type === SURVEY_VINE_ROW) {
        layer = surveyVineRowLayerRef.current;
      } else if (type === SURVEY_VINE_ROW_NEW) {
        layer = surveyVineRowNewLayerRef.current;
      } else if (type === POLY_LINE) {
        layer = polyLinesLayerRef.current;
      } else if (type === FEED_LANE) {
        layer = feedLaneFeaturesLayerRefs.current;
      }

      return layer;
    };

    const handleVisibilityMenu = (menuItem: any, returnFeature = false) => {
      if (!returnFeature && !menuItem.value) {
        return;
      }
      switch (menuItem.type) {
        case ROUTE:
          listVisibility.ROUTE = menuItem.value;
          break;
        case TRAVELLED_ROUTE:
          listVisibility.ROUTE = menuItem.value;
          break;
        case VINE:
          listVisibility.VINE = menuItem.value;
          break;
        case VINE_LANE:
          listVisibility.VINELANE = menuItem.value;
          break;
        case 'MARKS':
          listVisibility.MARKS = menuItem.value;
          break;
        case 'WINEROWHEAD':
          listVisibility.VINE_ROW_HEAD = menuItem.value;
          break;
        case VINE_ROW:
          listVisibility.VINEROW = menuItem.value;
          break;
        case VINE_BLOCK:
          listVisibility.VINEBLOCK = menuItem.value;
          break;
        case POLYGON:
          listVisibility.POLYGON = menuItem.value;
          break;
        case WAY_POINT:
          listVisibility.WAYPOINT = menuItem.value;
          break;
        case JUMP_AND_GO_MAP:
          listVisibility.ROUTE = menuItem.value;
          break;
        case AB_LINE:
          listVisibility.ABLINE = menuItem.value;
          break;
        case AB_BLOCK:
          listVisibility.ABBLOCK = menuItem.value;
          break;
        case SURVEY_VINE_ROW:
          listVisibility.SURVEYVINROW = menuItem.value;
          break;
        case SURVEY_VINE_ROW_NEW:
          listVisibility.SURVEY_VINE_ROW_NEW = menuItem.value;
          break;
        case CHARGE_POINTS:
          listVisibility.CHARGEPOINTS = menuItem.value;
          break;
        case POLY_LINE:
          listVisibility.POLY_LINE = menuItem.value;
          break;
        case FEED_LANE:
          listVisibility.FEED_LINE = menuItem.value;
          break;
        default:
          break;
      }
    };
    const handleSurveyVineRowNewHandle = (mapFeature: MapFeature) => {
      const layer = getFeatureLayer(mapFeature.type);
      const features = layer.getSource().getFeatures();
      features?.map((feature: any) => {
        const id = feature.get('rowUuid');
        if (id === mapFeature.mapId) {
          if (mapFeature.visible) {
            feature.setStyle(mapFeature.style);
          } else {
            feature.setStyle(new Style({})); // to hide the item
          }
        }
      });
    };

    const hideShowFeature = (mapFeature: MapFeature) => {
      const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
      handleSurveyVineRowNewHandle(mapFeature);
      const feature = layer.getSource().getFeatureById(mapFeature.mapId);

      handleVisibilityMenu({ value: mapFeature.visible, ...mapFeature });
      if (!layer.visible) {
        layer.setVisible(true);
      }
      if (feature) {
        if (mapFeature.visible) {
          if (mapFeature.type === ROUTE) {
            routeUpdate(mapFeature);
            return;
          } else if (mapFeature.type === TRAVELLED_ROUTE) {
            travelledRouteUpdate(mapFeature);
            desiredRouteUpdate(mapFeature);
            return;
          } else if (mapFeature.type === JUMP_AND_GO_MAP) {
            if (mapFeature && mapFeature.actionUuid) {
              drawJAG(mapFeature, mapFeature.mapId, mapFeature.name);
            }
            return;
          }
          feature.setStyle(mapFeature.style);
        } else {
          if (mapFeature.type === TRAVELLED_ROUTE) {
            const desiredLayer = desiredRouteLayerRef.current;
            const feature = desiredLayer
              ?.getSource()
              .getFeatureById(mapFeature.mapId);
            feature?.setStyle(new Style({}));
          }
          feature.setStyle(new Style({})); // to hide the item
        }
        // to show item
      }
      const layersUpdated = layerVisibleUpdated;
      layersUpdated[mapFeature.type] = layer.visible;
      setLayerVisibleUpdated(layersUpdated);
    };
    // on page load getting map items list Api calls
    useEffect(() => {
      if (userDetails && userDetails.organization && mapInitialized && base) {
        getWayPointsListData();
        if (isVineYardOrDairy(userDetails) === vineYardAutoDrive) {
          getVineBlocksListData();
          getVinesListData();
          getAbLineListData();
          getAbBlocksListData();
        }
        // on page load once get from context
        getPolygonsListData(
          polygonsList && polygonsList.length > 0 ? true : false,
        );

        getMarksListData();
        getVineRowHeadsListData();
        getSurveyVineRowsListData();
        getSurveyVineRowsNewListData();
        getChargePointsListData();
        getPolyLineListData();

        isVineYardOrDairy(userDetails) === dairyAutoDrive &&
          getFeedLanesListData();
      }
    }, [userDetails, mapInitialized, base]);

    useEffect(() => {
      drawWaypoints();
    }, [waypoints]);

    useEffect(() => {
      drawChargePoints();
    }, [chargePoints]);

    useEffect(() => {
      drawVineRows();
    }, [vineRows, selectedJumpAndGoListForVineRows]);

    useEffect(() => {
      drawVineBlocks();
    }, [vineBlocks, vineRows]);

    // useEffect(() => {
    //   getVineLanes();
    // }, [vineLanes]);

    useEffect(() => {
      getVines();
    }, [vines]);

    useEffect(() => {
      drawWinerowHead();
    }, [vineRowHeads]);

    useEffect(() => {
      drawMarks();
    }, [marks]);

    // useEffect(() => {
    //   drawAbLines();
    // }, [abLines]);

    useEffect(() => {
      drawAbBlocks();
    }, [abBlocks, abLines]);

    useEffect(() => {
      getFeedLanes();
    }, [feedLanes]);

    // get the waypoints data
    const getWayPointsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getWaypointsData(
          organization.api_url,
          organization.farm.id,
        );

        const { records } = response;
        setWaypoints(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const getChargePointsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getChargePointsData(
          organization.api_url,
          userDetails.organization_id,
        );
        if (response) {
          const { records } = response;
          setChargePoints(records);
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    // get the Poly lines data
    const getPolyLineListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getPolyLineData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        globalPolyLineUnique = randomNumber();
        drawPolyLines(globalPolyLineUnique, records, true);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    // get feed lanes
    const getFeedLanesListData = async () => {
      const { organization } = userDetails;
      try {
        const resp = await getFeedLanesData(
          organization.api_url,
          organization.farm.id,
        );
        const list =
          resp && resp.records && resp.records.length > 0 ? resp.records : [];
        setFeedLanes(list);
      } catch (error) {
        console.log(error);
      }
    };

    // get the vine rows data
    const getVineRowsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getVineRowsData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setVineRows(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the vine block data
    const getVineBlocksListData = async () => {
      getVineRowsListData();
      getVineLanesListData();
      try {
        const { organization } = userDetails;
        const response = await getVineBlocksData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setVineBlocks(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the polygons data
    const getPolygonsListData = async (loadFromContext = false) => {
      polygonsLayerRef.current?.getSource().clear();
      globalPolyUnique = randomNumber();
      try {
        const { organization } = userDetails;
        let list = [];
        if (loadFromContext) {
          list = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          list = records && records.length && records.length > 0 ? records : [];
        }
        const brownList: any[] = [];
        list.map(async (poly: any) => {
          const { color }: { color: string } = poly;
          if (color === 'brown') {
            brownList.push(poly);
          }
        });
        setBrownPolyCount(brownList.length);
        // brownList.length === 0 && brownConfirm();
        // notification.error({
        //   message: 'No farm boundaries present',
        //   duration: 5,
        // });
        brownFeatureItems = new Map();
        getPolygons(globalPolyUnique, list, loadFromContext, loadFromContext);
        getBrownPolygons(globalPolyUnique, brownList, false);
        const resp = await getSuggestedPolygonsData(
          organization.api_url,
          organization.farm.id,
        );
        const { records: sRecords } = resp;
        const suggestedList =
          sRecords && sRecords.length && sRecords.length > 0 ? sRecords : [];
        suggestedList.map(async (poly: any) => {
          poly.suggested = true;
        });
        suggestedList &&
          suggestedList.length > 0 &&
          drawNewSuggestedPolygons(globalPolyUnique, suggestedList, false);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    const drawNewSuggestedPolygons = (
      localPolyUnique: number,
      suggestedList: any,
      isApi = false,
    ) => {
      const suggestedPolyFeatures: MapFeature[] = [];
      if (suggestedList && suggestedList.length > 0) {
        suggestedList.map(async (record: any, index: number) => {
          const { polygonItem } = await drawPoly(
            userDetails,
            base,
            polygonsLayerRef.current,
            globalPolyUnique,
            localPolyUnique,
            record,
            true, // suggested as false
            isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polygonItem && polygonItem.vertices) {
            record.vertices = polygonItem.vertices;
          }
          polygonItem && suggestedPolyFeatures.push(polygonItem);

          if (suggestedList && suggestedList.length - 1 === index) {
            setNewSuggestedPolygonFeature(
              suggestedPolyFeatures as MapFeature[],
            );
            setNewSuggestedPolygons(suggestedList);
          }
        });
      } else {
        setNewSuggestedPolygonFeature([]);
        setNewSuggestedPolygons([]);
      }
    };
    const brownConfirm = async () => {
      confirm({
        title:
          'No farm boundaries present. Please create your farm boundary (Brown) first to be able to create further polygons.',
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          drawPolygon();
        },
        okButtonProps: {
          className: 'okBtn',
        },
        cancelButtonProps: {
          className: 'canelBtn',
        },
      });
    };
    // get the vine lanes data
    // const getVineLanesListData = async () => {
    //   try {
    //     const { organization } = userDetails;
    //     const response = await getVineLaneData(
    //       organization.api_url,
    //       organization.farm.id,
    //     );
    //     const { records } = response;
    //     setVineLanes(records);
    //   } catch (error: any) {
    //     notification.error({
    //       message: error.message,
    //     });
    //   }
    // };
    // get the vines data
    const getVinesListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getAllVinePoints(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setVines(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the marks data
    const getMarksListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getMarksData(
          organization.api_url,
          organization.farm.id,
          'marks',
        );
        const { records } = response;
        setMarks(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the vine row heads data
    const getVineRowHeadsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getMarksData(
          organization.api_url,
          organization.farm.id,
          'vine_row_head',
        );
        const { records } = response;
        setVineRowHeads(records);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the vine rows data
    const getJumpAndGoVineRowsListData = async () => {
      let temp: any[] = [];
      setSelectedJumpAndGoListForVineRows([]);
      const { organization } = userDetails;
      setLoader(true);
      const res = await Promise.all(
        selectedJumpAndGoForVineRow.map((item: any) =>
          getJumpAndGoVineRowsData(
            organization.api_url,
            organization.farm.id,
            item.index,
          ).then((res) => res),
        ),
      ).finally(() => setLoader(false));
      res.map(async (item: any, index: number) => {
        const records =
          item && item.vine_rows_list && item.vine_rows_list.length > 0
            ? item.vine_rows_list
            : [];
        if (records && records.length > 0) temp = [...temp, ...records];
        if (temp && temp.length > 0 && res.length === index + 1) {
          setSelectedJumpAndGoListForVineRows(temp);
        }
      });
    };

    // get the vine rows data
    const getAbLineListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getAbLineData(
          organization.api_url,
          organization.farm.id,
          1,
          100,
        );
        const { _metadata } = response;
        let list: any = [];
        const total_pages = _metadata.total_pages;
        abLinesLayerRef.current?.getSource().clear();
        if (total_pages > 0) {
          for (let i = 1; i <= total_pages; i++) {
            try {
              const resp = await getAbLineData(
                organization.api_url,
                organization.farm.id,
                i,
                100,
              );
              const { records } = resp;
              if (records && records.length > 0) list = [...list, ...records];
              globalAbLineUnique = randomNumber();
              drawAbLines(records, globalAbLineUnique);
            } catch (error: any) {
              notification.error({
                message: error.message,
              });
            }
          }
        }
        const vineRowsList: MapFeature[] = [];
        list.map((vine: any) => {
          const id = `ab_line_${vine.id}`;
          vineRowsList.push({
            id: vine.id,
            name: vine.name,
            type: AB_LINE,
            mapId: id,
            visible: true,
            style: abLineStyle,
          });
        });

        setAbLineFeature(vineRowsList);
        setAbLines(list);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the survey vine rows data
    const getSurveyVineRowsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getSurveyVineRowsData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        drawSurveyVerticesHandler(records, true);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    // get the survey vine rows data
    const getSurveyVineRowsNewListData = async () => {
      try {
        const { organization } = userDetails;
        const records = await getSurveyVineRowsNewData(
          organization.api_url,
          organization.farm.id,
        );
        drawSurveyNewVerticesHandler(records, true);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /** VineRows initialization Starts Here
     *
     */
    // get the vine rows data
    const drawVineRows = async () => {
      vineRowsLayerRef.current?.getSource().clear();
      vineRowsForJumpAndGoLayerRef.current?.getSource().clear();
      try {
        const records = vineRows && vineRows.length > 0 ? vineRows : [];
        const vineRowsFeatures: Feature<Geometry>[] = [];
        const vineRowsList: MapFeature[] = [];
        records.map((vine: any) => {
          const {
            head_a_x,
            head_a_y,
            head_b_x,
            head_b_y,
            head_a_latitude,
            head_a_longitude,
            head_b_latitude,
            head_b_longitude,
            head_a_bearing,
            head_b_bearing,
            head_a_altitude,
            head_b_altitude,
            vine_row_uuid,
            vine_blocks_uuid,
          } = vine;
          const coordinates: any = [];
          const id = `vine_row_${vine.id}`;
          let latAndLongA: number[] = [];
          let latAndLongB: number[] = [];
          if (
            head_a_latitude !== null &&
            head_a_longitude !== null &&
            head_b_latitude !== null &&
            head_b_longitude !== null
          ) {
            latAndLongA = [head_a_longitude, head_a_latitude];
            latAndLongB = [head_b_longitude, head_b_latitude];
          } else {
            const vineA = {
              id: vine.id,
              x: head_a_x / 100,
              y: head_a_y / 100,
            };
            const vineB = {
              id: vine.id,
              x: head_b_x / 100,
              y: head_b_y / 100,
            };
            latAndLongA = getLatitudeAndLongitude(
              [vineA.y, vineA.x],
              base,
              true,
            );
            latAndLongB = getLatitudeAndLongitude(
              [vineB.y, vineB.x],
              base,
              true,
            );
          }

          coordinates.push(proj.fromLonLat(latAndLongA));
          coordinates.push(proj.fromLonLat(latAndLongB));
          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: 'vinerow',
            rowUuid: vine.vine_row_uuid,
            pointName: vine.name,
            pointId: vine.id,
            rowData: vine,
            name: vine.name,
            head_a_bearing,
            head_b_bearing,
            head_a_altitude,
            head_b_altitude,
          });

          vineLocation.setId(id);
          vineLocation.setStyle(vineRowStyle);

          vineRowsFeatures.push(vineLocation);

          vineRowsList.push({
            id: vine.id,
            name: vine.name,
            type: VINE_ROW,
            mapId: id,
            visible: true,
            style: vineRowStyle,
            vine_blocks_uuid,
            vine_row_uuid,
          });
        });
        vineRowsLayerRef.current?.getSource().addFeatures(vineRowsFeatures);
        vineRowsLayerRef.current?.setVisible(listVisibility.VINEROW);

        setVineRowFeature(vineRowsList);

        const records1 =
          selectedJumpAndGoListForVineRows &&
          selectedJumpAndGoListForVineRows.length > 0
            ? selectedJumpAndGoListForVineRows
            : [];
        const vineRowsFeatures1: Feature<Geometry>[] = [];
        records1.map((vine: any) => {
          const coordinates: any = [];
          const id = `vine_row_${vine[0].row_name}`;
          vine.map((vineItem: any) => {
            if (vineItem.latitude !== null && vineItem.longitude !== null) {
              coordinates.push(
                proj.fromLonLat([vineItem.longitude, vineItem.latitude]),
              );
            } else {
              const vineA = {
                x: vine[0].x / 100,
                y: vine[0].y / 100,
              };
              const latAndLong = getLatitudeAndLongitude(
                [vineA.y, vineA.x],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            }
          });
          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: 'VINEROWUSINGVINEROWJUMPANDGO',
            rowUuid: vine[0].row_name,
            pointName: vine[0].row_name,
            pointId: vine[0].row_name,
            rowData: vine,
            name: vine[0].row_name,
          });
          vineLocation.setId(id);
          vineRowsFeatures1.push(vineLocation);
        });
        vineRowsForJumpAndGoLayerRef?.current
          ?.getSource()
          .addFeatures(vineRowsFeatures1);
        vineRowsFeatures1 &&
          vineRowsFeatures1.length > 0 &&
          displayRecenterAllViewPortSetUp(vineRowsFeatures1, mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /**
     * VineRows initialization Ends Here
     */

    const drawPolyLines = async (
      localPolyLineUnique: number,
      polyList: any,
      isApi = false,
    ) => {
      try {
        polyLinesLayerRef.current?.getSource().clear();
        const records = polyList && polyList.length > 0 ? polyList : [];
        const preparedList: MapFeature[] = [];
        records.map(async (record: any, index: number) => {
          const { polyLineItem } = await drawPolyLineFun(
            userDetails,
            base,
            polyLinesLayerRef.current,
            globalPolyLineUnique,
            localPolyLineUnique,
            record,
            !isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polyLineItem && polyLineItem.vertices) {
            record.vertices = polyLineItem.vertices;
          }
          preparedList.push(polyLineItem as MapFeature);
          if (records && records.length - 1 === index) {
            setPolyLineFeature(preparedList);
            setPolyLines(records);
          }
        });
        // set show hide the poly line layer
        polyLinesLayerRef.current?.setVisible(listVisibility.POLY_LINE);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const drawAbLines = async (records: any, localAbLineUnique: number) => {
      try {
        records = records && records.length > 0 ? records : [];
        const vineRowsList: MapFeature[] = [];
        records.map(async (vine: any, index: number) => {
          const coordinates: any = [];
          const id = `ab_line_${vine.id}`;
          // const { organization } = userDetails;
          // const coordinates1 = await getAbLineVerticesData(
          //   organization.api_url,
          //   organization.farm.id,
          //   vine.ab_line_uuid,
          //   // vine.generic_map_uuid,
          // );
          const coordinates1 = [
            {
              latitude: vine.head_a_latitude,
              longitude: vine.head_a_longitude,
              x: vine.head_a_x,
              y: vine.head_a_y,
            },
            {
              latitude: vine.head_b_latitude,
              longitude: vine.head_b_longitude,
              x: vine.head_b_x,
              y: vine.head_b_y,
            },
          ];

          coordinates1 &&
            coordinates1.length > 0 &&
            coordinates1.map((coordinate: any) => {
              if (
                coordinate.latitude !== null &&
                coordinate.longitude !== null
              ) {
                coordinates.push(
                  proj.fromLonLat([coordinate.longitude, coordinate.latitude]),
                );
              } else {
                coordinates.push(
                  proj.fromLonLat(
                    getLatitudeAndLongitude(
                      [coordinate.y / 100, coordinate.x / 100],
                      base,
                      true,
                    ),
                  ),
                );
              }
            });

          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: AB_LINE,
            rowUuid: vine.vine_row_uuid,
            pointName: vine.name,
            pointId: vine.id,
            rowData: vine,
            name: vine.name,
            head_a_bearing: vine.head_a_bearing,
            head_b_bearing: vine.head_b_bearing,
          });

          vineLocation.setId(id);
          vineLocation.setStyle(abLineStyle);
          localAbLineUnique === globalAbLineUnique &&
            abLinesLayerRef.current?.getSource().addFeature(vineLocation);
        });
        abLinesLayerRef.current?.setVisible(listVisibility.ABLINE);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /**
     * VineBlocks initialization Starts Here
     */

    const drawVineBlocks = async () => {
      vineBlocksLayerRef.current?.getSource().clear();
      try {
        if (
          !(
            vineRows &&
            vineRows.length > 0 &&
            vineBlocks &&
            vineBlocks.length > 0
          )
        ) {
          return;
        }
        const records = vineBlocks && vineBlocks.length > 0 ? vineBlocks : [];
        const vineBlocksFeatures: Feature<Geometry>[] = [];
        const vineBlocksList: MapFeature[] = [];
        records.map((block: any) => {
          const id = `vine_block_${block.id}`;

          const coordinates: any = [];

          const vinesHeadA: any = [];
          const vinesHeadB: any = [];
          block.vine_rows_uuidsContent = [];
          block.vine_rows_uuids &&
            block.vine_rows_uuids.length > 0 &&
            block.vine_rows_uuids.map((id: string) => {
              const [item] = vineRows.filter(
                (vineRow: any) => vineRow.vine_row_uuid === id,
              );
              item && block.vine_rows_uuidsContent.push(item);
            });

          let xyOrLatLong = true; // true means x y and false -> lat and long
          const { vine_rows_uuidsContent: vine_rows_uuids } = block;
          const content = vine_rows_uuids?.length ? vine_rows_uuids[0] : null;
          if (
            content &&
            content.head_a_latitude !== null &&
            content.head_a_longitude !== null &&
            content.head_b_latitude !== null &&
            content.head_b_longitude !== null
          ) {
            xyOrLatLong = false;
          } else {
            xyOrLatLong = true;
          }
          !xyOrLatLong &&
            block &&
            vine_rows_uuids &&
            vine_rows_uuids.map((vineBlock: any) => {
              const vineA = [
                vineBlock.head_a_longitude,
                vineBlock.head_a_latitude,
              ];
              const vineB = [
                vineBlock.head_b_longitude,
                vineBlock.head_b_latitude,
              ];
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          xyOrLatLong &&
            vine_rows_uuids &&
            vine_rows_uuids.map((vineBlock: any) => {
              const vineA = { x: vineBlock.head_a_x, y: vineBlock.head_a_y };
              const vineB = { x: vineBlock.head_b_x, y: vineBlock.head_b_y };
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
              xyOrLatLong = true;
            });
          let vineBlockCoordinates = vinesHeadA;
          vinesHeadB.reverse();
          vineBlockCoordinates = vineBlockCoordinates.concat(vinesHeadB);
          if (vinesHeadA[0]) {
            vineBlockCoordinates.push(vinesHeadA[0]);
          }
          if (!xyOrLatLong) {
            vineBlockCoordinates.map((item: any) => {
              coordinates.push(proj.fromLonLat(item));
            });
          } else {
            vineBlockCoordinates.map((item: any) => {
              item.xNew = item.x / 100;
              item.yNew = item.y / 100;
              const latAndLong: [number, number] = getLatitudeAndLongitude(
                [item.yNew, item.xNew],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            });
          }

          if (coordinates && coordinates.length > 0) {
            const vineBlockPolygon = new Feature({
              type: 'Polygon',
              geometry: new Polygon([coordinates]),
              pointType: 'VineBlock',
              vineRows: vine_rows_uuids,
              vineBlockUuid: block.vine_blocks_uuid,
              pointId: block.id,
              name: block.name,
              polygonUuid: block.polygon_uuid,
              monitoringNeeded: block.monitoring_needed,
            });
            vineBlockPolygon.setId(id);

            vineBlocksFeatures.push(vineBlockPolygon);
          }
          vineBlocksList.push({
            id: block.id,
            name: block.name,
            type: VINE_BLOCK,
            mapId: id,
            visible: true,
            style: vineBlockStyle,
            vine_blocks_uuid: block.vine_blocks_uuid,
            is_published: block.is_published ? true : false,
          });
        });
        vineBlocksLayerRef.current?.setVisible(listVisibility.VINEBLOCK);
        vineBlocksLayerRef.current?.getSource().addFeatures(vineBlocksFeatures);
        setVineBlockFeature(vineBlocksList);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /**
     * VineBlocks initialization Ends Here
     */

    /**
     * Polygons initialization Starts Here
     */

    const getPolygons = async (
      localPolyUnique: number,
      polyList: any,
      isApi = false,
      loadFromContext = false,
    ) => {
      try {
        // checking  polygon list from app conect
        const records = loadFromContext
          ? polygonsList
          : polyList && polyList.length > 0
          ? polyList
          : [];
        const preparedList: MapFeature[] = [];
        records.map(async (record: any, index: number) => {
          const { polygonFeature, polygonItem } = await drawPoly(
            userDetails,
            base,
            polygonsLayerRef.current,
            globalPolyUnique,
            localPolyUnique,
            record,
            false, // suggested as false
            isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polygonItem && polygonItem.vertices) {
            record.vertices = polygonItem.vertices;
          }
          // assigne for recenter the white field
          if (record.color === 'white') {
            fieldFeatureItem.current = [polygonFeature];
          }
          if (record.color === 'brown' && polygonItem?.id) {
            brownFeatureItems.set(polygonItem?.id, polygonFeature);
          }
          preparedList.push(polygonItem as MapFeature);
          if (records && records.length - 1 === index) {
            setPolygonFeature(preparedList);
            setPolygons(records);
            // polygons added to maintain app context start
            !isApi &&
              appDispatch({
                type: SET_POLYGONS_LIST,
                payload: records,
              });
            // polygons added to maintain app context end
          }
        });
        // set show hide the polygon layer
        polygonsLayerRef.current?.setVisible(listVisibility.POLYGON);
        const list = records.map(
          (item: { name: string; color: string; polygon_uuid: string }) => {
            return {
              name: item.name,
              color: item.color,
              polygon_uuid: item.polygon_uuid,
            };
          },
        );
        setPolygonsListForPop(list);
        polygonHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    /**
     * Polygons initialization Ends Here
     */

    /** Waypoints initialization Starts Here
     *
     */
    const drawWaypoints = async () => {
      waypointsLayerRef.current?.getSource().clear();
      try {
        const records = waypoints && waypoints.length > 0 ? waypoints : [];
        const waypointsFeatures: Feature<Geometry>[] = [];
        const waypointList: MapFeature[] = [];
        const style = mapMakerWayPointStyle;
        records.map((point: any) => {
          const id = `waypoint_${point.id}`;
          let latAndLong: number[] = [];
          if (point.latitude !== null && point.longitude !== null) {
            latAndLong = [point.longitude, point.latitude];
          } else {
            latAndLong = getLatitudeAndLongitude(
              [point.y / 100, point.x / 100],
              base,
              true,
            );
          }
          if (latAndLong && latAndLong.length === 2) {
            const pointLocation = new Feature({
              geometry: new Point(proj.fromLonLat(latAndLong)),
              name: point.name,
              pointType: 'waypoint',
              pointId: point.id,
              positionX: point.x,
              positionY: point.y,
            });
            pointLocation.setId(id);
            waypointsFeatures.push(pointLocation);
            waypointList.push({
              id: point.id,
              name: point.name,
              type: WAY_POINT,
              mapId: id,
              visible: true,
              style,
            });
          }
        });
        waypointsLayerRef.current?.setVisible(listVisibility.WAYPOINT);
        waypointsLayerRef.current?.getSource().addFeatures(waypointsFeatures);
        // const layer = new VectorLayer({
        //   source: new VectorSource({
        //     features: waypointsFeatures,
        //   }),
        //   visible: true,
        //   zIndex: 10,
        //   // style: mapMakerwWypointStyle,
        // });
        // mapRef.current?.addLayer(layer);
        setWaypointFeature(waypointList);
        const wayDrag = new Modify({
          features: new Collection(waypointsFeatures),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        wayDrag.on('modifyend', function (evt: any) {
          dragWayPoint(evt);
        });

        // Add the vector layer and the refferring drag and drop interaction
        mapRef.current?.addInteraction(wayDrag);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /**
     * Waypoints initialization Ends Here
     */

    /** chargePoints initialization Starts HerewaypointsFeatures
     *
     */
    const drawChargePoints = async () => {
      chargePointsLayerRef.current?.getSource().clear();
      try {
        const records =
          chargePoints && chargePoints.length > 0 ? chargePoints : [];
        const chargePointFeatures: Feature<Geometry>[] = [];
        const chargePointList: MapFeature[] = [];
        const style = mapMakerChargePointStyle;
        records.map((point: any) => {
          const id = `charge_${point.id}`;
          let latAndLong: number[] = [];
          if (
            point.location_latlong.latitide !== null &&
            point.location_latlong.longitude !== null
          ) {
            latAndLong = [
              point.location_latlong.longitude,
              point.location_latlong.latitide,
            ];
          }
          if (latAndLong && latAndLong.length === 2) {
            const pointLocation = new Feature({
              geometry: new Point(proj.fromLonLat(latAndLong)),
              name: point.charge_point_name,
              pointType: 'chargepoint',
              pointId: point.id,
            });
            pointLocation.setId(id);
            chargePointFeatures.push(pointLocation);
            chargePointList.push({
              id: point.id,
              name: point.charge_point_name,
              type: CHARGE_POINTS,
              mapId: id,
              visible: true,
              style,
            });
          }
        });

        chargePointsLayerRef.current?.setVisible(listVisibility.CHARGEPOINTS);
        chargePointsLayerRef.current
          ?.getSource()
          .addFeatures(chargePointFeatures);
        setChargePointFeature(chargePointList);

        const wayDrag = new Modify({
          features: new Collection(chargePointFeatures),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        wayDrag.on('modifyend', function (evt: any) {
          dragChargePoint(evt);
        });

        // Add the vector layer and the refferring drag and drop interaction
        mapRef.current?.addInteraction(wayDrag);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    /** Marks initialization Starts Here
     *
     */

    const drawMarks = async () => {
      marksLayerRef.current?.getSource().clear();
      try {
        const records = marks && marks.length > 0 ? marks : [];
        const marksFeatures: Feature<Geometry>[] = [];
        if (records && records.length && records.length > 0) {
          const { latitude, longitude } = records[0];
          let xyOrLatLong = true;
          if (latitude && longitude) {
            xyOrLatLong = true;
          } else {
            xyOrLatLong = false;
          }
          if (xyOrLatLong) {
            records.map((point: any) => {
              const { latitude, longitude, x, y, id } = point;
              if (latitude && longitude) {
                const pointLocation = new Feature({
                  geometry: new Point(proj.fromLonLat([longitude, latitude])),
                  name: `Mark :- (longitude: ${longitude}, latitude: ${latitude})`,
                  pointType: 'MARK',
                  pointId: id,
                  point,
                });
                marksFeatures.push(pointLocation);
              }
            });
          } else {
            records.map((point: any) => {
              const { x, y, id } = point;
              if (x && y) {
                const latAndLong = getLatitudeAndLongitude([y, x], base, true);
                const pointLocation = new Feature({
                  geometry: new Point(proj.fromLonLat(latAndLong)),
                  name: `Mark :- (x: ${x * 100}, y: ${y * 100})`,
                  pointType: 'MARK',
                  pointId: id,
                  point,
                });
                pointLocation.setId(id);
                marksFeatures.push(pointLocation);
              }
            });
          }
        }

        marksLayerRef.current?.setVisible(listVisibility.MARKS);
        marksLayerRef.current?.getSource().addFeatures(marksFeatures);
        // const layer = new VectorLayer({
        //   source: new VectorSource({
        //     features: marksFeatures,
        //   }),
        //   visible: true,
        //   zIndex: 10,
        //   // style: mapMakerwWypointStyle,
        // });
        // mapRef.current?.addLayer(layer);
        const marksDrag = new Modify({
          features: new Collection(marksFeatures),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        marksDrag.on('modifyend', function (evt: any) {
          dragMarkPoint(evt);
        });
        // Add the vector layer and the refering drag and drop interaction
        mapRef.current?.addInteraction(marksDrag);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const drawWinerowHead = async () => {
      vineRowHeadLayerRef.current?.getSource().clear();
      try {
        const records =
          vineRowHeads && vineRowHeads.length > 0 ? vineRowHeads : [];
        const waypointsFeatures: Feature<Geometry>[] = [];
        if (records && records.length && records.length > 0) {
          const { latitude, longitude } = records[0];
          let xyOrLatLong = true;
          if (latitude && longitude) {
            xyOrLatLong = true;
          } else {
            xyOrLatLong = false;
          }
          if (xyOrLatLong) {
            records.map((point: any) => {
              const { id, latitude, longitude } = point;
              if (latitude && longitude) {
                const pointLocation = new Feature({
                  geometry: new Point(proj.fromLonLat([longitude, latitude])),
                  name: `Vine Row Head :- (latitude: ${latitude}, longitude: ${longitude})`,
                  pointType: 'VINE_ROW_HEAD',
                  pointId: id,
                  point,
                });
                pointLocation.setId(id);
                waypointsFeatures.push(pointLocation);
              }
            });
          } else {
            records.map((point: any) => {
              const { x, y, id } = point;
              if (x && y) {
                const latAndLong = getLatitudeAndLongitude([y, x], base, true);
                const pointLocation = new Feature({
                  geometry: new Point(proj.fromLonLat(latAndLong)),
                  // name: `vine_row_head :- (x: ${point.cm_x}, y: ${point.cm_y}) ${id}`,
                  name: `Vine Row Head :- (x: ${x * 100}, y: ${y * 100})`,
                  pointType: 'VINE_ROW_HEAD',
                  pointId: id,
                  point,
                });
                pointLocation.setId(id);
                waypointsFeatures.push(pointLocation);
              }
            });
          }
        }
        vineRowHeadLayerRef.current?.setVisible(listVisibility.VINE_ROW_HEAD);
        vineRowHeadLayerRef.current?.getSource().addFeatures(waypointsFeatures);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    /**
     * Marks initialization Ends Here
     */
    // updating feature start here
    useEffect(() => {
      if (mapFeature) {
        hideShowFeature(mapFeature);
        mapFeature.visible && recenterTractor();
      }
    }, [mapFeature, trigger]);
    const recenterTractor = () => {
      if (mapRef.current) {
        if (
          mapFeature.type === VINE ||
          mapFeature.type === WAY_POINT ||
          mapFeature.type === CHARGE_POINTS
        ) {
          const layer: VectorLayer<any> | any = getFeatureLayer(
            mapFeature.type,
          );
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          const point = feature.getGeometry().getCoordinates();
          const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
          mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
          mapRef.current?.getView().setZoom(19);
        } else if (
          mapFeature.type === AB_BLOCK ||
          mapFeature.type === POLYGON ||
          mapFeature.type === VINE_LANE ||
          mapFeature.type === VINE_BLOCK
        ) {
          const layer = getFeatureLayer(mapFeature.type);
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          if (feature) {
            const point = feature.getGeometry().getCoordinates();
            point &&
              point.length > 0 &&
              displayRecenterViewPortSetUp(feature, mapRef.current);
          }
        } else if (
          mapFeature.type === AB_LINE ||
          mapFeature.type === VINE_ROW ||
          mapFeature.type === SURVEY_VINE_ROW ||
          mapFeature.type === ROUTE ||
          mapFeature.type === POLY_LINE ||
          mapFeature.type === FEED_LANE
        ) {
          const layer = getFeatureLayer(mapFeature.type);
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          if (feature) {
            const point = feature.getGeometry().getCoordinates();
            point &&
              point.length > 1 &&
              feature &&
              displayRecenterViewPortSetUp(feature, mapRef.current);
          }
        } else if (mapFeature.type === SURVEY_VINE_ROW_NEW) {
          const layer = getFeatureLayer(mapFeature.type);
          const features = layer.getSource().getFeatures();
          const list: any = [];
          features?.map((feature: any) => {
            const id = feature.get('rowUuid');
            id === mapFeature.mapId && list.push(feature);
          });
          if (list) {
            list &&
              list.length > 1 &&
              displayRecenterAllViewPortSetUp(list, mapRef.current, 40);
          }
        }
      }
    };

    /**
     * Zoom In Zoom Out and Recenter
     */

    const handleZoom = (zoomType: string) => {
      if (mapRef.current) {
        const view = mapRef.current.getView();
        const zoom = view.getZoom();
        if (zoom) {
          if (zoomType === 'zoomIn') {
            view.setZoom(zoom + 1);
          } else {
            view.setZoom(zoom - 1);
          }
        }
      }
    };

    const zoomInGrid = () => {
      const layer = graticuleLayerRef.current;
      if (layer) {
        const visible = layer.getVisible();
        mapRef.current?.removeLayer(layer);
        graticule = new Graticule({
          strokeStyle: new Stroke({
            color: 'rgba(1, 1, 1, 1)',
            width: 1,
          }),
          intervals: [gridArray[getGridIndex()]],
          showLabels: false,
          targetSize: 5,
          visible,
        });
        mapRef.current?.addLayer(graticule);
      }
      graticuleLayerRef.current = graticule;
    };

    const zoomOutGrid = () => {
      const layer = graticuleLayerRef.current;
      if (layer) {
        const visible = layer.getVisible();
        mapRef.current?.removeLayer(layer);

        graticule = new Graticule({
          strokeStyle: new Stroke({
            color: 'rgba(1, 1, 1, 1)',
            width: 1,
          }),
          intervals: [gridArray[getGridIndex()]],
          showLabels: false,
          targetSize: 5,
          visible,
        });
        mapRef.current?.addLayer(graticule);
      }
      graticuleLayerRef.current = graticule;
    };

    const getGridIndex = () => {
      const zoom = mapRef?.current?.getView().getZoom();
      if (zoom) {
        const actualZoom = Math.floor(zoom);
        mapRef?.current?.getView().setZoom(actualZoom);
        if (actualZoom === 15 || actualZoom < 16) {
          return 0;
        } else if (actualZoom === 16 || actualZoom < 17) {
          return 1;
        } else if (actualZoom === 17 || actualZoom < 18) {
          return 2;
        } else if (actualZoom === 18 || actualZoom < 19) {
          return 3;
        } else if (actualZoom === 19 || actualZoom < 20) {
          return 4;
        } else if (actualZoom === 20 || actualZoom < 21) {
          return 5;
        } else if (actualZoom === 21 || actualZoom < 22) {
          return 6;
        } else if (actualZoom === 22 || actualZoom < 23) {
          return 7;
        } else if (actualZoom === 23 || actualZoom < 24) {
          return 8;
        } else if (actualZoom === 24 || actualZoom < 25) {
          return 9;
        } else if (actualZoom === 25 || actualZoom < 26) {
          return 10;
        } else if (actualZoom === 26 || actualZoom < 27) {
          return 11;
        } else if (actualZoom === 27 || actualZoom < 28) {
          return 12;
        } else if (actualZoom === 28 || actualZoom < 29) {
          return 13;
        }
      }
      return 0;
    };

    const reCenter = () => {
      if (
        mapRef.current &&
        fieldFeatureItem &&
        fieldFeatureItem.current &&
        fieldFeatureItem.current.length > 0
      ) {
        displayRecenterAllViewPortSetUp(
          fieldFeatureItem.current,
          mapRef.current,
        );
      }
    };

    /**
     * Zoom In Zoom Out and Recenter
     */
    /**
     * Toggle Layer
     */

    useEffect(() => {
      setToggleLayer(toggleLayer);
    }, [toggleLayer]);

    const setToggleLayer = (toggleLayerObject: ToggleLayer) => {
      if (toggleLayerObject) {
        if (toggleLayerObject.type !== REMOTE_AV_MAP) {
          const layer = getFeatureLayer(toggleLayerObject.type);
          handleVisibilityMenu(toggleLayerObject, true);
          layer?.setVisible(toggleLayerObject.value);
        }
      }
    };
    // toggle satelight / road map view
    useEffect(() => {
      vectorTileLayerRef.current?.setVisible(!toggleSatellite);
      satelliteTileLayerRef.current?.setVisible(toggleSatellite);
    }, [toggleSatellite]);

    /**
     * Toogle layer ends
     */

    /**
     * Resize Map on collapsed and exand
     */

    useEffect(() => {
      if (mapInitialized) {
        setTimeout(() => {
          mapRef.current?.updateSize();
        }, 1000);
      }
    }, [collapsed, mapInitialized]);
    /**
     * RemoteAV layer ends
     */
    /**
     * Right Click context menu open event listener
     */
    contextmenu.on('open', (evt: any) => {
      contextmenu.clear();
      let menu: any[] = [];
      // draw menu start here
      if (activeDrawActionGlobal === 'NONE') {
        menu.push({
          text: 'Draw',
          classname: 'remoteav',
        });
        menu.push({
          text: t(translate.map.waypoint),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.WAYPOINT = true;
            drawWayPoint();
          },
        });

        menu.push({
          text: t(translate.map.boundary),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.POLYGON = true;
            drawPolygon();
          },
        });
        menu.push({
          text: t(translate.map.crop),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINE = true;
            drawVine();
          },
        });
        menu.push({
          text: t(translate.map.crop_row),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINEROW = true;
            drawVineRow();
          },
        });
        menu.push({
          text: t(translate.map.crop_block),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINEBLOCK = true;
            drawVineBlockFun();
          },
        });
        menu.push({
          text: t(translate.map.crop_lane),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINELANE = true;
            drawVineLaneFun();
          },
        });
        menu.push({
          text: t(translate.map.charge_point),
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.CHARGEPOINTS = true;
            drawChargePoint();
          },
        });

        // Update Menu start here
        let subMenu: any[] = [];
        const feature = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            return feature;
          },
        );
        if (activeDrawActionGlobal === 'NONE' && feature) {
          const pointType = feature.get('pointType');
          if (pointType === 'Polygon') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.boundary),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updatePolygon(feature);
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                if (feature.get('color') === suggestedPolygon) {
                  confirmDelete(
                    feature.get('id'),
                    POLYGON,
                    feature.get('label'),
                    true,
                  );
                } else {
                  confirmDelete(
                    feature.get('id'),
                    POLYGON,
                    feature.get('label'),
                  );
                }
              },
            });
            if (feature.get('color') === 'brown') {
              subMenu.push({
                text: 'Discover',
                classname: 'rvactionItem',
                callback: () => {
                  // discoverPolygonHandler(feature);
                  const poly = feature.get('poly');
                  setShowDiscover(true);
                  setBrownPoly(poly);
                },
              });
            }
            if (feature.get('color') === suggestedPolygon) {
              subMenu.push({
                text: 'Approve',
                classname: 'rvactionItem',
                callback: () => {
                  approvePolygonHandler(feature);
                },
              });
            }
            subMenu.push({
              text: 'MoveBack',
              classname: 'rvactionItem',
              callback: () => {
                const satelightPolyStyle = getSateLightPolyStyle(
                  feature.get('color'),
                  0,
                  feature.get('color') === suggestedPolygon ? true : false,
                );
                feature.setStyle(satelightPolyStyle);
              },
            });
          } else if (pointType === 'waypoint') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.waypoint),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  WAY_POINT,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'chargepoint') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.charge_point),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  CHARGE_POINTS,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'MARK') {
            subMenu = [];
            subMenu.push({
              text: 'Mark',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'MARK',
                  feature.get('name'),
                );
              },
            });
            subMenu.push({
              text: 'Copy Coordinates ',
              classname: 'rvactionItem',
              callback: () => {
                const point = feature.get('point');
                navigator.clipboard.writeText(
                  `{latitude: ${point.latitude}, longitude: ${point.longitude}}, {x: ${point.x}, y: ${point.y}}`,
                );
                notification.info({ message: 'Copied' });
              },
            });
          } else if (pointType === 'VINE_ROW_HEAD') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.row_head),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINE_ROW_HEAD',
                  feature.get('name'),
                );
              },
            });
            subMenu.push({
              text: 'Copy Coordinates ',
              classname: 'rvactionItem',
              callback: () => {
                const point = feature.get('point');
                navigator.clipboard.writeText(
                  `{latitude: ${point.latitude}, longitude: ${point.longitude}}, {x: ${point.x}, y: ${point.y}}`,
                );
                notification.info({ message: 'Copied' });
              },
            });
          } else if (pointType === 'vine') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.crop),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  VINE,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'vinerow') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.crop_row),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updateVineRow(feature);
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  VINE_ROW,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'VineBlock') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.crop_block),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                handleToggleLayer(true, VINE_ROW, 3);
                vineBlockId = feature.get('pointId');
                vineBlockName = feature.get('name');
                polygonUuid = feature.get('polygonUuid');
                monitoringNeeded = feature.get('monitoringNeeded');
                setActiveDrawAction('VINEBLOCKEDIT');
                disableVineRows(feature.get('vineRows'));
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  VINE_BLOCK,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'VineLane') {
            subMenu = [];
            subMenu.push({
              text: t(translate.map.crop_lane),
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  VINE_LANE,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === JUMP_AND_GO_MAP) {
            subMenu = [];
            subMenu.push({
              text: 'Jump & Go',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updateJumpAndGo(feature);
              },
            });
            // } else if (pointType === AB_LINE) {
            //   subMenu = [];
            //   subMenu.push({
            //     text: 'Ab Line',
            //     classname: 'remoteav',
            //   });
            //   subMenu.push({
            //     text: 'Update',
            //     classname: 'rvactionItem',
            //     callback: () => {
            //       updateAbline(feature);
            //     },
            //   });
            // subMenu.push({
            //   text: 'Delete',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     confirmDelete(
            //       feature.get('pointId'),
            //       AB_LINE,
            //       feature.get('name'),
            //     );
            //   },
            // });
          } else if (pointType === AB_BLOCK) {
            subMenu = [];
            subMenu.push({
              text: 'Ab Block',
              classname: 'remoteav',
            });
            // subMenu.push({
            //   text: 'Update',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     updateAbBlock(feature);
            //   },
            // });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('id'),
                  AB_BLOCK,
                  feature.get('label'),
                );
              },
            });
          } else if (pointType === 'VINEROWFROMJUMPANDGO') {
            subMenu = [];
            subMenu.push({
              text: 'Survey Crop Row',
              classname: 'remoteav',
            });
            // subMenu.push({
            //   text: 'Update',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     updateAbBlock(feature);
            //   },
            // });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('rowUuid'),
                  SURVEY_VINE_ROW,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'VINEROWFROMJUMPANDGONEW') {
            subMenu = [];
            subMenu.push({
              text: 'Survey Crop Row',
              classname: 'remoteav',
            });
            // subMenu.push({
            //   text: 'Update',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     updateAbBlock(feature);
            //   },
            // });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('rowUuid'),
                  SURVEY_VINE_ROW_NEW,
                  feature.get('surveyName'),
                );
              },
            });
          } else if (pointType === POLY_LINE) {
            subMenu = [];
            subMenu.push({
              text: 'Poly Line',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updatePolyLine(feature);
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('polyline_uuid'),
                  POLY_LINE,
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === FEED_LANE) {
            subMenu = [];
            subMenu.push({
              text: 'Feed Line',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('id'),
                  FEED_LANE,
                  feature.get('name'),
                );
              },
            });
          }
        }
        menu = [...menu, ...subMenu];
        // Update Menu start here
      }

      // draw menu ends here

      // Update start here
      if (activeDrawActionGlobal !== 'NONE') {
        if (activeDrawActionGlobal === WAY_POINT) {
          menu.push({
            text: t(translate.map.waypoint),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearWaypoint();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === CHARGE_POINTS) {
          menu.push({
            text: t(translate.map.waypoint),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearWaypoint();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === POLYGON) {
          menu.push({
            text: t(translate.map.boundary),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearPolygonDrawModifyHandler();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'POLYGONEDIT') {
          menu.push({
            text: t(translate.map.boundary),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updatePolygonFun();
              setShowEditPoly(true);
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearPolygonDrawModifyHandler();
              // cancelOperations();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === VINE) {
          menu.push({
            text: t(translate.map.crop),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVine()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === VINE_ROW) {
          menu.push({
            text: t(translate.map.crop_row),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVineRow()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEROWEDIT') {
          menu.push({
            text: t(translate.map.crop_row),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updateVineRowFunc();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearModifiedVineRow()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === VINE_BLOCK) {
          menu.push({
            text: t(translate.map.crop_block),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              saveVineBlock();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // setActiveDrawAction('NONE');
              // selectedVineRowsForVineBlock = [];
              // drawVineRows();
              // drawVineBlocks();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === VINE_LANE) {
          menu.push({
            text: t(translate.map.crop_lane),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              saveVineLane();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVinblockSelection();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEBLOCKEDIT') {
          menu.push({
            text: t(translate.map.crop_block),
            classname: 'remoteav',
          });
          menu.push({
            text: 'Update',
            classname: 'rvactionItem',
            callback: () => {
              editVineBlock();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // handleToggleLayer(true, VINE_BLOCK, 4);
              // handleToggleLayer(true, VINE_ROW, 3);
              // drawVineRows();
              // drawVineBlocks();
              // setActiveDrawAction('NONE');
              // selectedVineRowsForVineBlock = [];
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === JUMP_AND_GO_MAP) {
          menu.push({
            text: 'Jump &Go',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Update',
            classname: 'rvactionItem',
            callback: () => {
              updateJumpAndGoFunc();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearModifiedJumpAndGo();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === AB_LINE) {
          menu.push({
            text: 'Ab Line',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearAbLine()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'ABBLOCKEDIT') {
          menu.push({
            text: 'Ab Block',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updateAbBlockFun();
              setShowAbBlock(true);
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearAbBlock()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        }
      }
      // Update ends here
      contextmenu.extend(menu);
    });

    useEffect(() => {
      globalTriggerCancelOperation = triggerCancelOperation;
      mapInitialized && cancelOperations();
    }, [mapInitialized, triggerCancelOperation]);

    const clearVinblockSelection = () => {
      setActiveDrawAction('NONE');
      selectedVineRowsForVineBlock = [];
      drawVineRows();
      globalVineLineUnique = randomNumber();

      getVineLanes(vineLanes, globalVineLineUnique);
    };

    const cancelOperations = () => {
      clearModifiedPolyLine();

      // clear new waypoint
      clearWaypoint();

      // clear new Chargepoint
      clearChargepoint();
      // clear  new vine
      clearVine();
      // to clear new vinerow
      clearVineRow();
      // clear update vinerow
      clearModifiedVineRow();

      clearPolygonDrawModifyHandler();

      clearModifiedJumpAndGo();

      // vine lane create cancel
      selectedVineRowsForVineBlock = [];

      // clear vine block selection
      clearVinblockSelection();

      // clear the abline
      clearAbLine();

      // clear ablock
      clearAbBlock();

      setShowSave(false);

      vineRowsForJumpAndGoLayerRef.current?.getSource().clear();

      // to clear drawn features on map
      drawFeaturesLayer.current?.getSource().clear();
      setActiveDrawAction('NONE');
      // baseFeatureItem.current.setGe();
      baseFeatureItem &&
        baseFeatureItem.current &&
        baseFeatureItem?.current.setGeometry(
          new Point(proj.fromLonLat([baseLang, baseLat])),
        );
      markerPointRef &&
        markerPointRef.current &&
        markerPointRef.current.getSource().clear();
      // added this layer for preview related vinerows
      previewSurveyVineRowLayerRef &&
        previewSurveyVineRowLayerRef.current &&
        previewSurveyVineRowLayerRef.current.getSource().clear();

      setSelectedJumpAndGoListForVineRows([]);
      setSelectedVineRowsFromJumpAndGo([]);
      setSelectedJumpAndGoForVineRow([]);
      setVineRowPayload(null);
      setAbLinePayload(null);
      setAbLinePayload1(null);
      setVineRowModel2LastPayload(null);
      setVineRowModel2FirstPayload(null);

      drawWaypoints();
      drawChargePoints();
      drawVineRows();
      drawVineBlocks();
      polygons &&
        polygons.length > 0 &&
        polygonsLayerRef.current?.getSource().clear();
      globalPolyUnique = randomNumber();
      polygons &&
        polygons.length > 0 &&
        getPolygons(globalPolyUnique, polygons, true);
      getBrownPolygons(globalPolyUnique, brownPolygons, true);
      drawNewSuggestedPolygons(globalPolyUnique, suggestedNewPolygons, true);
      globalVineLineUnique = randomNumber();
      getVineLanes(vineLanes, globalVineLineUnique);
      getVines();
      drawWinerowHead();
      drawMarks();
      globalAbLineUnique = randomNumber();
      abLines &&
        abLines.length > 0 &&
        abLinesLayerRef.current?.getSource().clear();
      abLines && abLines.length > 0 && drawAbLines(abLines, globalAbLineUnique);
      drawAbBlocks();
      drawSurveyVerticesHandler(surveyVineRowsState, false);
      drawSurveyNewVerticesHandler(surveyVineRowsNewState, false);
      globalPolyLineUnique = randomNumber();
      polyLines &&
        polyLines.length > 0 &&
        polyLinesLayerRef.current?.getSource().clear();
      polyLines &&
        polyLines.length > 0 &&
        drawPolyLines(globalPolyLineUnique, polyLines, false);
      // getAbBlocks(globalPolyUnique, abBlocks, true);
    };

    const drawVineBlockFun = () => {
      selectedVineRowsForVineBlock = [];
      setActiveDrawAction(VINE_BLOCK);
    };
    const drawVineLaneFun = () => {
      selectedVineRowsForVineBlock = [];
      setActiveDrawAction(VINE_LANE);
    };

    const disableVineRows = (rows: any) => {
      const features = vineRowsLayerRef?.current?.getSource().getFeatures();
      if (features && features.length > 0) {
        selectedVineRowsForVineBlock = [];
        const namesArray = rows.map((item: any) => item.name);
        const filteredData = features.filter((item: any) =>
          namesArray.includes(item.get('name')),
        );
        filteredData.map((fe: any) => {
          setShowSave(true);
          editVineBlock();
          fe.setStyle(vineRowDisableStyle);
          selectedVineRowsForVineBlock.push({
            name: fe.get('name'),
            rowUuid: fe.get('rowUuid'),
          });
        });
      }
    };
    const toggleMapMaker = () => {
      toggleSatellite &&
        satelliteTileLayerRef.current?.setVisible(mapMakeToggle);
      !toggleSatellite && vectorTileLayerRef.current?.setVisible(mapMakeToggle);
      mapMakeToggle = !mapMakeToggle;
    };
    const toggleGraticule = () => {
      graticuleToggle = !graticuleToggle;
      // added for getting grids zoom level on toggle
      if (graticuleToggle) {
        mapRef.current?.getView().setMinZoom(15);
      } else {
        mapRef.current?.getView().setMinZoom(1);
      }
      graticuleLayerRef.current?.setVisible(graticuleToggle);
    };
    useEffect(() => {
      setPolygonStyle(polygonsLayerRef, toggleSatellite);
    }, [toggleSatellite]);

    const clearPolygonDrawModifyHandler = () => {
      detatchPolygonModifyHandler();
      detatchPolygonDrawHandler();
    };

    // DRAW VINES START **************************************************
    const clearVine = () => {
      if (activeDrawActionGlobal === VINE) {
        mapRef.current?.removeInteraction(vineDraw);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawVine = () => {
      clearVine();
      setActiveDrawAction(VINE);
      vineSource = new VectorSource();
      vineDraw = new Draw({
        source: vineSource,
        type: GeometryType.POINT,
        style: mapmakerVineStyle,
      });
      mapRef.current?.addInteraction(vineDraw);
      vineDraw.on('drawend', vineDrawEnd);
    };
    const vineDrawEnd = (e: any) => {
      e.feature.setStyle(mapmakerVineStyle);
      vineDraw?.un('drawend', vineDrawEnd);
      vineDraw.abortDrawing();
      mapRef.current?.removeInteraction(vineDraw);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      const points = e.feature.getGeometry().getCoordinates();
      const latLong: number[] = proj.transform(
        points,
        'EPSG:3857',
        'EPSG:4326',
      );
      saveVine(latLong);

      const wayDrag = new Modify({
        features: new Collection([e.feature]),
        style: new Style({}),
      });
      // Add the event to the drag and drop feature
      wayDrag.on('modifyend', function (evt: any) {
        const arr: any = evt.features.getArray();
        if (arr && arr.length && arr[arr.length - 1]) {
          const point = arr[arr.length - 1].getGeometry().getCoordinates();
          const latLong: number[] = proj.transform(
            point,
            'EPSG:3857',
            'EPSG:4326',
          );
          saveVine(latLong);
        }
      });
      // Add the vector layer and the refferring drag and drop interaction
      mapRef.current?.addInteraction(wayDrag);
    };
    const saveVine = async (latLong: number[]) => {
      const altitude = await getSingleElevationValue(latLong);
      const payload: VinesPayloadTypes = {
        name: '',
        latitude: latLong[1],
        longitude: latLong[0],
        altitude,
        vine_row1_uuid: '',
        vine_row2_uuid: '',
        action: 'ADD',
      };
      setVinePayload(payload);
      setShowSave(true);
    };
    const deleteVine = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const mapId = `vine_${pointId}`;
          const feature = vinesLayerRef?.current
            ?.getSource()
            .getFeatureById(mapId);
          feature && vinesLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVinesListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    const dragVinePoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const vineId = feature.get('pointId');
        const name = feature.get('name');
        const latLong: number[] = proj.transform(
          point,
          'EPSG:3857',
          'EPSG:4326',
        );
        const altitude = await getSingleElevationValue(latLong);

        const payload: VinesPayloadTypes = {
          name,
          latitude: latLong[1],
          longitude: latLong[0],
          altitude,
          vine_row1_uuid: '',
          vine_row2_uuid: '',
          action: 'EDIT',
          vineId,
        };
        setVinePayload(payload);
        setShowSave(true);
        setActiveDrawAction(VINE);
      }
    };
    // DRAW VINES END *******************************************************

    // DRAW VINE ROWS START *************************************************
    const clearVineRow = () => {
      if (vineRowDraw) {
        vineRowDraw?.un('drawend', vineRowDrawEnd);
        vineRowDraw.abortDrawing();
        mapRef.current?.removeInteraction(vineRowDraw);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawVineRow = () => {
      clearVineRow();
      setActiveDrawAction(VINE_ROW);
      vineRowSource = new VectorSource();
      vineRowDraw = new Draw({
        source: vineRowSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(vineRowDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      vineRowDraw.on('drawend', vineRowDrawEnd);
    };
    const vineRowDrawEnd = (e: any) => {
      e.feature.setStyle(vineRowStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(vineRowDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({ longitude: latLong[0], latitude: latLong[1] });
      });
      const payload: VineRowPayload = {
        name: '',
        vineRowXY: getXYCoordinatesList(xy, base),
        action: 'ADD',
        vineRowId: 0,
        head_a_altitude: 0,
        head_b_altitude: 0,
        polygon_uuid: '',
      };
      const payload1: VineRowPayloadSingle = {
        name: '',
        vineRowXY: vertices,
        action: 'ADD',
        vineRowId: 0,
        head_a_bearing: 0,
        head_b_bearing: 0,
        polygon_uuid: '',
      };
      setVineRowPayload(payload1);
      setVineRowModel2FirstPayload(payload);
      if (globalVineRowModal === 1) {
        setShowSave(true);
      }
      if (globalVineRowModal === 2) {
        vineRowSource = new VectorSource();
        vineRowDraw = new Draw({
          source: vineRowSource,
          type: GeometryType.LINE_STRING,
          maxPoints: 2,
        });
        mapRef.current?.addInteraction(vineRowDraw);
        const snap = new Snap({
          source: vineRowHeadLayerRef?.current?.getSource(),
        });
        mapRef?.current?.addInteraction(snap);
        vineRowDraw.on('drawend', vineRowDrawEndSecond);
      }
    };
    const vineRowDrawEndSecond = (e: any) => {
      e.feature.setStyle(vineRowStyle);
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(vineRowDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
      });
      const payload: VineRowPayload = {
        name: '',
        vineRowXY: getXYCoordinatesList(xy, base),
        action: 'ADD',
        vineRowId: 0,
        head_a_bearing: 0,
        head_b_bearing: 0,
        polygon_uuid: '',
      };
      setShowSave(true);
      setVineRowModel2LastPayload(payload);
    };
    const clearModifiedVineRow = () => {
      if (activeDrawActionGlobal === 'VINEROWEDIT') {
        vineRowModify?.un('modifyend', vineRowDrawEnd);
        mapRef.current?.removeInteraction(vineRowModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateVineRow = (feature: any) => {
      clearModifiedVineRow();
      setShowSave(true);
      setActiveDrawAction('VINEROWEDIT');
      vineRowSource = new VectorSource();
      vineRowSource.addFeature(feature);
      vineRowModify = new Modify({ source: vineRowSource });
      mapRef.current?.addInteraction(vineRowModify);
      updateVineRowFunc();
      vineRowModify.on('modifyend', updateVineRowFunc);
    };
    const updateVineRowFunc = () => {
      const feature: any = vineRowSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_altitude = feature[0].get('head_a_altitude');
        const head_b_altitude = feature[0].get('head_b_altitude');
        const polygon_uuid = feature[0].get('polygon_uuid');
        const vineRowXY: any[] = [];
        const vertices: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
          vertices.push({ longitude: latLong[0], latitude: latLong[1] });
        });
        const payload: VineRowPayloadSingle = {
          name: pointName,
          vineRowXY: vertices,
          action: 'EDIT',
          vineRowId,
          head_a_altitude,
          head_b_altitude,
          polygon_uuid,
        };
        setVineRowPayload(payload);
      }
    };

    const deleteVineRow = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineRowFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineRowsLayerRef.current
            ?.getSource()
            .getFeatureById('vine_row_' + pointId);
          feature &&
            vineRowsLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineRowsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    // DRAW VINE ROWS END *****************************************************

    // draw polygon start here
    const drawPolygon = () => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction(POLYGON);
      listVisibility.POLY_LINE = true;
      handleToggleLayer(true, POLY_LINE, 16);
      polygonSource = new VectorSource({ wrapX: false });
      polygonDraw = new Draw({
        source: polygonSource,
        type: GeometryType.POLYGON,
      });
      mapRef.current?.addInteraction(polygonDraw);
      const snap = new Snap({
        source: polyLinesLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      polygonDraw.on('drawend', polyDrawEnd);
    };
    const polyDrawEnd = async (e: any) => {
      createPolygonModifyHandler(e.feature);
    };
    const reditThePoly = (feature: any) => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction(POLYGON);
      listVisibility.POLY_LINE = true;
      handleToggleLayer(true, POLY_LINE, 16);
      createPolygonModifyHandler(feature);
    };

    const createPolygonModifyHandler = (feature: any) => {
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(feature);
      feature.setStyle(polyDrawStyle);
      let points = feature.getGeometry().getCoordinates();
      points = points && points.length > 0 ? points[0] : [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        vertices.push({ longitude: xy[0], latitude: xy[1] });
      });
      const area = getAreaOfPolygon(feature);
      const payload = {
        name: '',
        color: '',
        lat_long_vertices: vertices,
        polygonId: 0,
        area: area ? area : 0,
      };
      createdPolyFeatureItem.current = feature;
      setPolyPayload(payload);

      // show polygon save button
      setShowSave(true);
      // detach polygon draw
      polygonDraw.abortDrawing();
      polygonDraw?.un('drawend', polyDrawEnd);
      mapRef.current?.removeInteraction(polygonDraw);
      const featuresList = drawFeaturesLayer.current?.getSource().getFeatures();
      if (featuresList && featuresList.length > 0) {
        const feature = featuresList[0];
        polygonSource = new VectorSource();
        polygonSource.addFeature(feature);
        polygonModify = new Modify({
          source: polygonSource,
        });
        mapRef.current?.addInteraction(polygonModify);
        polygonModify.on('modifyend', createdPolyUpdatePolygonFun);
      }
    };
    const createdPolyUpdatePolygonFun = async () => {
      if (polygonSource) {
        const feature: any = polygonSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const area = getAreaOfPolygon(feature[0]);
          const payload = {
            name: '',
            color: '',
            lat_long_vertices: vertices,
            polygonId: 0,
            area: area ? area : 0,
          };
          setPolyPayload(payload);
          createdPolyFeatureItem.current = feature[0];
        }
      }
    };
    const editDrawPolygon = (e: any) => {
      setShowSave(true);
    };
    // clear the drawn polygon
    const detatchPolygonDrawHandler = () => {
      if (polygonDraw) {
        polygonDraw.abortDrawing();
        polygonDraw?.un('drawend', polyDrawEnd);
        mapRef.current?.removeInteraction(polygonDraw);
        setActiveDrawAction('NONE');
      }
    };
    // clear the modified polygon
    const detatchPolygonModifyHandler = () => {
      if (polygonModify) {
        polygonModify?.un('modifyend', editDrawPolygon);
        mapRef.current?.removeInteraction(polygonModify);
        setActiveDrawAction('NONE');
      }
    };

    // update polygon start here
    const updatePolygon = (feat: any) => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction('POLYGONEDIT');
      feat.setStyle(null);
      polygonSource = new VectorSource();
      feat.set('type', 'none');
      polygonSource.addFeature(feat);
      feat.setStyle(polyDrawStyle);
      polygonModify = new Modify({
        source: polygonSource,
      });
      mapRef.current?.addInteraction(polygonModify);
      const snap = new Snap({
        source: polyLinesLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      polygonModify.on('modifyend', editDrawPolygon);
    };
    const updatePolygonFun = async () => {
      if (polygonSource) {
        const feature: any = polygonSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const polyName = feature[0].get('polyName');
          const color = feature[0].get('color');
          const polygonId = feature[0].get('id');
          const area = getAreaOfPolygon(feature[0]);
          const payload = {
            name: polyName,
            color,
            lat_long_vertices: vertices,
            polygonId,
            area: area ? area : 0,
          };
          setPolyPayload(payload);
          createdPolyFeatureItem.current = feature[0];
          polygonModify?.un('modifyend', editDrawPolygon);
          mapRef.current?.removeInteraction(polygonModify);
        }
      }
    };
    // update polygon ends here

    // delete Polygon starts here
    const deletePolygon = async (polygonId: number, isSuggested = false) => {
      try {
        const { organization } = userDetails;
        let url = `${organization.api_url}farm/${organization.farm.id}/cartography/polygons/${polygonId}`;
        if (isSuggested) {
          url = `${organization.api_url}farm/${organization.farm.id}/cartography/suggested-polygon/${polygonId}`;
        }
        const resp = await deletePolygonFun(url);
        if (resp && resp.msg) {
          const mapId = `polygon_${polygonId}`;
          const feature = polygonsLayerRef?.current
            ?.getSource()
            .getFeatureById(mapId);
          feature &&
            polygonsLayerRef.current?.getSource().removeFeature(feature);
          isSuggested && setReloadSuggestedPolygons(!reloadSuggestedPolygons);
          notification.success({
            message: resp.msg,
          });
          getPolygonsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    // delete polygon end here

    // DRAW WAYPOINT START **************************************************
    const clearWaypoint = () => {
      if (activeDrawActionGlobal === WAY_POINT) {
        mapRef.current?.removeInteraction(waypointDraw);
        // setWaypointDrawOn(false);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawWayPoint = () => {
      clearWaypoint();
      setActiveDrawAction(WAY_POINT);
      wayPointSource = new VectorSource();
      waypointDraw = new Draw({
        source: wayPointSource,
        type: GeometryType.POINT,
        style: mapMakerWayPointStyle,
      });
      mapRef.current?.addInteraction(waypointDraw);
      waypointDraw.on('drawend', wayPointDrawEnd);
    };
    const wayPointDrawEnd = (e: any) => {
      e.feature.setStyle(mapMakerWayPointStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);

      const points = e.feature.getGeometry().getCoordinates();
      const latLong: number[] = proj.transform(
        points,
        'EPSG:3857',
        'EPSG:4326',
      );
      saveWaypoint(latLong);
      setShowSave(true);
      // stop waypoint drawing
      waypointDraw?.un('drawend', wayPointDrawEnd);
      waypointDraw.abortDrawing();
      mapRef.current?.removeInteraction(waypointDraw);

      const wayDrag = new Modify({
        features: new Collection([e.feature]),
        style: new Style({}),
      });
      // Add the event to the drag and drop feature
      wayDrag.on('modifyend', function (evt: any) {
        const arr: any = evt.features.getArray();
        if (arr && arr.length && arr[arr.length - 1]) {
          const point = arr[arr.length - 1].getGeometry().getCoordinates();
          const latLong: number[] = proj.transform(
            point,
            'EPSG:3857',
            'EPSG:4326',
          );
          saveWaypoint(latLong);
        }
      });
      // Add the vector layer and the refferring drag and drop interaction
      mapRef.current?.addInteraction(wayDrag);
    };
    const saveWaypoint = async (latLong: number[]) => {
      const altitude = await getSingleElevationValue(latLong);
      const payload: WaypointPayloadTypes = {
        name: '',
        latitude: latLong[1],
        longitude: latLong[0],
        altitude,
        waypointId: 0,
        action: 'ADD',
      };
      // setShowWaypoint(true);
      setWayPointPayload(payload);
    };
    const confirmDelete = (
      pointeId: any,
      type:
        | 'WAYPOINT'
        | 'POLYGON'
        | 'VINE'
        | 'VINEROW'
        | 'VINEBLOCK'
        | 'VINELANE'
        | 'MARK'
        | 'VINE_ROW_HEAD'
        | 'ABLINE'
        | 'ABBLOCK'
        | 'ROUTE'
        | 'SURVEYVINEROW'
        | 'SURVEY_VINE_ROW_NEW'
        | 'CHARGEPOINTS'
        | 'POLY_LINE'
        | 'FEED_LANE',
      name = '',
      isSuggested = false,
    ) => {
      let title = '';
      if (type === WAY_POINT) {
        title = t(translate.map.waypoint);
      } else if (type === CHARGE_POINTS) {
        title = t(translate.map.charge_point);
      } else if (type === POLYGON) {
        title = t(translate.map.boundary);
      } else if (type === VINE) {
        title = t(translate.map.crop);
      } else if (type === VINE_ROW) {
        title = t(translate.map.crop_row);
      } else if (type === VINE_BLOCK) {
        title = t(translate.map.crop_block);
      } else if (type === VINE_LANE) {
        title = t(translate.map.crop_lane);
      } else if (type === 'MARK') {
        title = 'Mark';
      } else if (type === 'VINE_ROW_HEAD') {
        title = t(translate.map.row_heads);
      } else if (type === AB_LINE) {
        title = 'Ab Line';
      } else if (type === AB_BLOCK) {
        title = 'Ab Block';
      } else if (type === ROUTE) {
        title = 'Route';
      } else if (type === SURVEY_VINE_ROW || type === SURVEY_VINE_ROW_NEW) {
        title = 'Survey Crop Row';
      } else if (type === POLY_LINE) {
        title = 'Poly Line';
      } else if (type === FEED_LANE) {
        title = 'Feed Line';
      }

      confirm({
        title: `Deleting ${title} ${name} ?`,
        icon: <ExclamationCircleOutlined />,
        content: '',
        okButtonProps: {
          className: 'okBtn',
        },
        cancelButtonProps: {
          className: 'canelBtn',
        },
        onOk() {
          if (type === WAY_POINT) {
            deleteWaypoint(pointeId);
          } else if (type === CHARGE_POINTS) {
            deleteChargepoints(pointeId);
          } else if (type === POLYGON) {
            deletePolygon(pointeId, isSuggested);
          } else if (type === VINE) {
            deleteVine(pointeId);
          } else if (type === VINE_ROW) {
            deleteVineRow(pointeId);
          } else if (type === VINE_BLOCK) {
            deleteVineBlock(pointeId);
          } else if (type === VINE_LANE) {
            deleteVineLane(pointeId);
          } else if (type === 'MARK') {
            deleteMark(pointeId, type);
          } else if (type === 'VINE_ROW_HEAD') {
            deleteMark(pointeId, type);
          } else if (type === AB_LINE) {
            deleteAbLine(pointeId);
          } else if (type === AB_BLOCK) {
            deleteAbBlock(pointeId);
          } else if (type === ROUTE) {
            deleteRoute(pointeId);
          } else if (type === SURVEY_VINE_ROW) {
            deleteSurveyVineRow(pointeId);
          } else if (type === SURVEY_VINE_ROW_NEW) {
            deleteSurveyVineRowNew(pointeId);
          } else if (type === POLY_LINE) {
            deletePolyLine(pointeId);
          } else if (type === FEED_LANE) {
            deleteFeedLanehandler(pointeId);
          }
        },
      });
    };
    useEffect(() => {
      if (deleteSurveyVineRowId) {
        const item = JSON.parse(deleteSurveyVineRowId);
        if (item.type === SURVEY_VINE_ROW) {
          deleteSurveyVineRowId &&
            confirmDeleteSurveyVineRow(JSON.parse(deleteSurveyVineRowId));
        }
        if (item.type === SURVEY_VINE_ROW_NEW) {
          // deleteSurveyVineRowId &&
          // confirmDeleteSurveyVineRow(JSON.parse(deleteSurveyVineRowId));
          // deleteSurveyVineRowNew()
          confirmDelete(item.mapId, SURVEY_VINE_ROW_NEW, item.name);
        }
      }
    }, [deleteSurveyVineRowId]);
    const confirmDeleteSurveyVineRow = (item: MapFeature) => {
      const title = 'Survey Crop Row';
      confirm({
        title: `Deleting ${title} ${item?.name} ?`,
        icon: <ExclamationCircleOutlined />,
        content: '',
        okButtonProps: {
          className: 'okBtn',
        },
        cancelButtonProps: {
          className: 'canelBtn',
        },
        onOk() {
          const pointId: any = item.mapId.replace(SURVEY_ID_CONSTANT, '');
          deleteSurveyVineRow(pointId, item);
        },
      });
    };
    const deleteWaypoint = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteWaypointFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feat = waypointsLayerRef.current
            ?.getSource()
            .getFeatureById('waypoint_' + pointId);
          feat && waypointsLayerRef.current?.getSource().removeFeature(feat);
          notification.success({
            message: resp.msg,
          });
          getWayPointsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    const dragWayPoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const waypointId = feature.get('pointId');
        const name = feature.get('name');
        const latLong: number[] = proj.transform(
          point,
          'EPSG:3857',
          'EPSG:4326',
        );
        const altitude = await getSingleElevationValue(latLong);

        const payload: WaypointPayloadTypes = {
          name,
          latitude: latLong[1],
          altitude,
          longitude: latLong[0],
          waypointId,
          action: 'EDIT',
        };
        setShowSave(true);
        setActiveDrawAction(WAY_POINT);
        setWayPointPayload(payload);
      }
    };
    // DRAW WAYPOINT END *******************************************************

    // DRAW CHARGEPOINT START *******************************************
    const clearChargepoint = () => {
      if (activeDrawActionGlobal === CHARGE_POINTS) {
        mapRef.current?.removeInteraction(chargepointDraw);
        // setWaypointDrawOn(false);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawChargePoint = () => {
      clearChargepoint();
      setActiveDrawAction(CHARGE_POINTS);
      ChargePointSource = new VectorSource();
      chargepointDraw = new Draw({
        source: ChargePointSource,
        type: GeometryType.POINT,
        style: mapMakerChargePointStyle,
      });
      mapRef.current?.addInteraction(chargepointDraw);
      chargepointDraw.on('drawend', chargePointDrawEnd);
    };

    const chargePointDrawEnd = (e: any) => {
      e.feature.setStyle(mapMakerChargePointStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);

      const points = e.feature.getGeometry().getCoordinates();
      const latLong: number[] = proj.transform(
        points,
        'EPSG:3857',
        'EPSG:4326',
      );
      saveChargepoint(latLong);
      setShowSave(true);
      // stop waypoint drawing
      chargepointDraw?.un('drawend', chargePointDrawEnd);
      chargepointDraw.abortDrawing();
      mapRef.current?.removeInteraction(chargepointDraw);

      const wayDrag = new Modify({
        features: new Collection([e.feature]),
        style: new Style({}),
      });
      // Add the event to the drag and drop feature
      wayDrag.on('modifyend', function (evt: any) {
        const arr: any = evt.features.getArray();
        if (arr && arr.length && arr[arr.length - 1]) {
          const point = arr[arr.length - 1].getGeometry().getCoordinates();
          const latLong: number[] = proj.transform(
            point,
            'EPSG:3857',
            'EPSG:4326',
          );
          saveChargepoint(latLong);
        }
      });
      // Add the vector layer and the refferring drag and drop interaction
      mapRef.current?.addInteraction(wayDrag);
    };
    const saveChargepoint = async (latLong: number[]) => {
      const altitude = await getSingleElevationValue(latLong);
      const payload: WaypointPayloadTypes = {
        name: '',
        latitude: latLong[1],
        longitude: latLong[0],
        altitude,
        waypointId: 0,
        action: 'ADD',
      };
      setChargePointPayload(payload);
    };
    const deleteChargepoints = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp: any = await deleteChargePoint(
          organization.api_url,
          userDetails.organization.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feat = chargePointsLayerRef.current
            ?.getSource()
            .getFeatureById('waypoint_' + pointId);
          feat && chargePointsLayerRef.current?.getSource().removeFeature(feat);
          notification.success({
            message: resp.msg,
          });
          getChargePointsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    const dragChargePoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const waypointId = feature.get('pointId');
        const name = feature.get('name');
        const latLong: number[] = proj.transform(
          point,
          'EPSG:3857',
          'EPSG:4326',
        );
        const altitude = await getSingleElevationValue(latLong);

        const payload: WaypointPayloadTypes = {
          name,
          latitude: latLong[1],
          altitude,
          longitude: latLong[0],
          waypointId,
          action: 'EDIT',
        };
        setShowSave(true);
        setActiveDrawAction(CHARGE_POINTS);
        setChargePointPayload(payload);
      }
    };

    // DRAW ChargePOINT END *******************************************************
    const saveVineBlock = () => {
      const payload: VineBlockPayloadType = {
        name: '',
        vineBlockId: 0,
        action: 'ADD',
        vineRows: selectedVineRowsForVineBlock,
        polygonUuid: '',
      };
      setVineBlockPayload(payload);
    };
    const editVineBlock = () => {
      const payload: VineBlockPayloadType = {
        name: vineBlockName,
        vineBlockId,
        action: 'EDIT',
        vineRows: selectedVineRowsForVineBlock,
        polygonUuid,
        monitoringNeeded,
      };
      // setShowVineBlock(true);
      setVineBlockPayload(payload);
    };
    const deleteVineBlock = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineBlockFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineBlocksLayerRef.current
            ?.getSource()
            .getFeatureById('vine_block_' + pointId);
          feature &&
            vineBlocksLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineBlocksListData();
          // getVineRowsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };

    const closefileModel = () => {
      setFileUpload(false);
    };

    const closeImageModel = () => {
      setImgUpload(false);
    };

    /*
    start vine lane here 
    */
    const saveVineLane = () => {
      const payload: VineLanePayloadType = {
        name: '',
        vineLaneId: 0,
        action: 'ADD',
        vineRows: selectedVineRowsForVineBlock,
      };
      setShowVineLane(true);
      setVineLanePayload(payload);
    };
    const saveVineRowFromJumpandGo = () => {
      setActiveDrawAction('NONE');
      setVineRowModal(4);
      setShowVineRow(true);
      setSelectedVineRowsFromJumpAndGo(selectedVineRowsForVineBlock);
    };
    const deleteVineLane = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineLaneFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineLaneLayerRef.current
            ?.getSource()
            .getFeatureById('vineLane_' + pointId);
          feature &&
            vineLaneLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineLanesListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };

    /**
     * Vine lanes initialization Starts Here
     */

    const getVineLanes = async (records: any, localVineLineUnique: number) => {
      try {
        records = records && records.length > 0 ? records : [];
        const style = vineLaneStyle;
        const vineLaneFeatures: Feature<Geometry>[] = [];

        records.map((poly: any, index: number) => {
          const id = `vineLane_${poly.id}`;
          const coordinates: any[] = [];
          if (
            poly.vertices &&
            poly.vertices.length &&
            poly.vertices.length > 0
          ) {
            const { latitude, longitude } = poly.vertices[0];
            let xyOrLatLong = true;
            if (latitude && longitude) {
              xyOrLatLong = true;
            } else {
              xyOrLatLong = false;
            }
            if (xyOrLatLong) {
              poly.vertices.map((item: any) => {
                const { latitude, longitude } = item;
                coordinates.push(proj.fromLonLat([longitude, latitude]));
              });
            } else {
              poly.vertices.map((point: any) => {
                point.xNew = point.x / 100;
                point.yNew = point.y / 100;
                const latAndLong: [number, number] = getLatitudeAndLongitude(
                  [point.yNew, point.xNew],
                  base,
                  true,
                );
                coordinates.push(proj.fromLonLat(latAndLong));
              });
            }
          }
          if (coordinates && coordinates.length > 0) {
            const vineLane = new Feature({
              type: 'Polygon',
              pointType: 'VineLane',
              geometry: new Polygon([coordinates]),
              pointId: poly.id,
              name: poly.name,
              style,
            });

            vineLane.setId(id);
            vineLane.set('id', poly.id);
            vineLane.setStyle(style);
            vineLane.setId(id);
            vineLane.setStyle(style);
            if (localVineLineUnique === globalVineLineUnique) {
              vineLaneLayerRef.current?.getSource().addFeature(vineLane);
            }
          }
        });
        vineLaneLayerRef.current?.setVisible(listVisibility.VINELANE);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const getVineLanesListData = async () => {
      vineLaneLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        const response = await getVineLaneData(
          organization.api_url,
          organization.farm.id,
          1,
          500,
        );
        const { _metadata } = response;
        let list: any = [];
        const total_pages = _metadata.total_pages;
        const vineLanesList: MapFeature[] = [];
        if (total_pages > 0) {
          for (let i = 1; i <= total_pages; i++) {
            try {
              const resp = await getVineLaneData(
                organization.api_url,
                organization.farm.id,
                i,
                500,
              );
              const { records } = resp;
              if (records && records.length > 0) list = [...list, ...records];
              globalVineLineUnique = randomNumber();
              getVineLanes(records, globalVineLineUnique);
            } catch (error: any) {
              notification.error({
                message: error.message,
              });
            }
          }
        }
        list.map((vine: any) => {
          const id = `vineLane_${vine.id}`;
          vineLanesList.push({
            id: vine.id,
            name: vine.name,
            type: VINE_LANE,
            mapId: id,
            visible: true,
            style: vineLaneStyle,
          });
        });
        setVineLaneFeature(vineLanesList);
        setVineLanes(list);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    /**
     * VineLanes initialization Ends Here
     */
    /**
     * Draw route start Here
     */
    useEffect(() => {
      if (mapInitialized && selectedDateRange) {
        /** Lets Do it After Optimization */
        getJumpandGos();
      }
    }, [userDetails, mapInitialized, selectedDateRange, jumpAndGoTractorId]);
    // get routes routes
    const getJumpandGos = async () => {
      if (jumpAndGoLayerRef.current) {
        mapRef.current?.removeLayer(jumpAndGoLayerRef.current);
      }
      setJumpAndGoFeature([]);
      setLoader(true);
      try {
        const routeList: MapFeature[] = [];
        const [date1, date2] = selectedDateRange;
        const { records } = await getJumpandGo(
          userDetails,
          // moment(date1).startOf('day').toDate().getTime(),
          // moment(date2).endOf('day').toDate().getTime(),
          moment(date1).toDate().getTime(),
          moment(date2).toDate().getTime(),
          jumpAndGoTractorId,
        );
        const jumpAndGoFeature: Feature<Geometry>[] = [];
        const style = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        records.map(async (route: any, index: number) => {
          route.range = 0;
          route.times = [];
          route.showTimes = [];
          if (
            route &&
            route.drive_action_start_date_time &&
            route.drive_action_finish_date_time
          ) {
            route.times = [
              moment(route?.drive_action_start_date_time),
              moment(route?.drive_action_finish_date_time),
            ];
            route.showTimes = route.times;
            route.range = diff_minutes(
              route.drive_action_finish_date_time,
              route.drive_action_start_date_time,
            );
          }
          const record = [{ x: 0, y: 0 }];
          if (record.length > 0) {
            const id = 'jump_and_go_' + route.id;
            const convertedRecord = convertRouteXYtoLatLang(
              record,
              baseLat,
              baseLang,
            );
            const array = convertedRecord.map(
              (item: { x: number; y: number }) =>
                proj.fromLonLat([item.x, item.y]),
            );
            const name = `${route?.operator?.first_name}  ${route?.operator?.last_name}`;
            const jumpAndGo = new Feature({
              geometry: new LineString(array),
              name,
            });
            jumpAndGo.setId(id);
            jumpAndGoFeature.push(jumpAndGo);
            routeList.push({
              id: route.id,
              name,
              type: JUMP_AND_GO_MAP,
              mapId: id,
              visible: false,
              style,
              actionUuid: route.drive_action_uuid,
              tractorName: route?.tractor?.name,
              drive_action_finish_date_time:
                route?.drive_action_finish_date_time,
              drive_action_start_date_time: route?.drive_action_start_date_time,
              first_name: route?.operator?.first_name,
              last_name: route?.operator?.last_name,
              created_date_time: route.created_date_time,
              times: route.times,
              showTimes: route.showTimes,
              range: route.range,
            });
          }
          if (index + 1 === records.length) {
            const jumpAndGo = new VectorLayer({
              source: new VectorSource({
                features: jumpAndGoFeature,
              }),
              zIndex: Infinity,
              style,
            });
            // jumpAndGo.setZIndex(0);
            mapRef.current?.addLayer(jumpAndGo);
            jumpAndGoLayerRef.current = jumpAndGo;
            setJumpAndGoFeature(routeList);
          }
        });
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      } finally {
        setLoader(false);
      }
    };
    const drawJAG = async (mapFeature: any, id: string, name?: string) => {
      const { organization } = userDetails;
      const { tractor_id, records } = await getDriveActionSinglePath(
        organization.api_url,
        mapFeature.actionUuid,
      );
      const defaultLayer = jumpAndGoLayerRef.current;
      const arr: Coordinate[] = [];
      if (records && records.length > 0) {
        records.map(
          (path: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
          }) => {
            if (
              mapFeature.showTimes &&
              mapFeature.showTimes.length > 0 &&
              mapFeature.showTimes[0] < path.created_date_time &&
              mapFeature.showTimes[1] > path.created_date_time
            ) {
              path.dTime = getDateTime(path.created_date_time);
              arr?.push(proj.fromLonLat([path.longitude, path.latitude]));
            }
          },
        );
        let tractorLocation: Feature<Geometry> = new Feature();
        if (defaultLayer) {
          tractorLocation = defaultLayer?.getSource().getFeatureById(id);
        }
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new LineString(arr));
        const color = 'rgba(0,183,255,255)';
        const borderColor = '#FFFFFF';
        const style = [
          new Style({
            stroke: new Stroke({
              color: borderColor,
              width: 8,
            }),
            zIndex: 0,
          }),
          new Style({
            stroke: new Stroke({
              color: color,
              width: 6,
            }),
            zIndex: 1,
          }),
        ];
        const pathHover = [
          new Style({
            stroke: new Stroke({
              color: borderColor,
              width: 10,
            }),
            zIndex: 0,
          }),
          new Style({
            stroke: new Stroke({
              color,
              width: 8,
            }),
            zIndex: 1,
          }),
        ];
        tractorLocation.setStyle(style);
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', pathHover);
        tractorLocation.set('name', name);
        tractorLocation.set('pointType', JUMP_AND_GO_MAP);
        tractorLocation.set('drawPointsText', records);
        tractorLocation.set('tractorId', tractor_id);
        defaultLayer?.getSource().addFeature(tractorLocation);
        const list = defaultLayer?.getSource().getFeatures();
        list &&
          list.length > 0 &&
          displayRecenterAllViewPortSetUp(list, mapRef.current);

        const view = mapRef.current?.getView();
        if (view && arr && arr.length > 0) {
          const zoom = view.getZoom();
          if (zoom) {
            mapRef.current?.getView().setCenter(arr[0]);
            mapRef.current?.getView().setZoom(17);
          }
        }
      }
    };
    const getBrownPolyRemarks = (name: string, remarks: string) => {
      const dataStr = `<p> Name: <span>  ${name} </span></p>
                       <p> Remarks: <span> ${remarks}</span></p>`;
      return dataStr;
    };
    const clearModifiedJumpAndGo = () => {
      if (activeDrawActionGlobal === JUMP_AND_GO_MAP) {
        JumpAndGoModify?.un('modifyend', updateJumpAndGoFunc);
        mapRef.current?.removeInteraction(JumpAndGoModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateJumpAndGo = (feature: any) => {
      clearModifiedJumpAndGo();
      setActiveDrawAction(JUMP_AND_GO_MAP);
      jumpAndGoSource = new VectorSource();
      jumpAndGoSource.addFeature(feature);
      JumpAndGoModify = new Modify({ source: jumpAndGoSource });
      mapRef.current?.addInteraction(JumpAndGoModify);
      // JumpAndGoModify.on('modifyend', updateJumpAndGoFunc);
      JumpAndGoModify.on('modifyend', (e) => console.log(e));
    };
    const updateJumpAndGoFunc = () => {
      const feature: any = jumpAndGoSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_bearing = feature[0].get('head_a_bearing');
        const head_b_bearing = feature[0].get('head_b_bearing');
        const vineRowXY: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
        });
        const payload: VineRowPayload = {
          name: pointName,
          vineRowXY: getXYCoordinatesList(vineRowXY, base),
          action: 'EDIT',
          vineRowId,
          head_a_bearing,
          head_b_bearing,
        };
        localStorage.setItem('updatedJumpAndGo', JSON.stringify(vineRowXY));
        clearModifiedJumpAndGo();
      }
    };
    const approvePolygonHandler = async (feature: any) => {
      try {
        const polygonUuid = feature.get('polygon_uuid');
        const payload = {
          polygon_uuids: [polygonUuid],
        };
        const { organization } = userDetails;
        const resp = await approvePolygon(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          getPolygonsListData();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };

    // draw suggested green polygons starts here
    const getBrownPolygons = (
      localPolyUnique: number,
      brownPolygonsList: any,
      isApi = false,
    ) => {
      if (brownPolygonsList && brownPolygonsList.length > 0) {
        brownPolygonsList.map(async (brownPolygon: any, index: number) => {
          try {
            const { organization } = userDetails;
            let records = [];
            if (!isApi) {
              const res1 = await getBrwonPolygonsSuggestedPolygonsData(
                organization.api_url,
                organization.farm.id,
                brownPolygon.polygon_uuid,
              );
              const res2 = await getBrwonPolygonsSuggestedPolygonsData(
                organization.api_url,
                organization.farm.id,
                brownPolygon.polygon_uuid,
                'blue',
              );
              records = [...res1.records, ...res2.records];
            } else {
              records = brownPolygon.records;
            }

            brownPolygon.records = records;
            if (index === brownPolygonsList.length - 1) {
              drawBrownPolyons(localPolyUnique, brownPolygonsList, isApi);
            }
          } catch (error: any) {
            notification.error({
              message: error.message,
            });
          }
        });
      }
    };

    // draw suggested green polygons starts here
    const drawBrownPolyons = (
      localPolyUnique: number,
      brownPolygonsList: any,
      isApi = false,
    ) => {
      const suggestedPolys: MapFeature[] = [];
      if (brownPolygonsList && brownPolygonsList.length > 0) {
        brownPolygonsList.map(async (brownPolygon: any, index: number) => {
          brownPolygon.records =
            brownPolygon.records && brownPolygon.records.length > 0
              ? brownPolygon.records
              : [];
          brownPolygon.records.map(async (record: any) => {
            const { polygonItem } = await drawPoly(
              userDetails,
              base,
              polygonsLayerRef.current,
              globalPolyUnique,
              localPolyUnique,
              record,
              true, // suggested as false
              isApi, // call api to get vertices -> false call api , -> true dont call api
            );
            polygonItem && suggestedPolys.push(polygonItem);
            if (polygonItem && polygonItem.vertices) {
              record.vertices = polygonItem.vertices;
            }
          });
          if (brownPolygonsList && brownPolygonsList.length - 1 === index) {
            setSuggestedPolygonFeature(suggestedPolys as MapFeature[]);
            setBrownPolygons(brownPolygonsList);
          }
        });
      } else {
        setSuggestedPolygonFeature([]);
        setBrownPolygons([]);
      }
    };

    // draw brown polygons ends here

    // mark delete starts here
    const deleteMark = async (pointId: number, type: string) => {
      try {
        const { organization } = userDetails;
        const payload = {
          mark_ids: [pointId],
        };
        const resp = await deleteMarkFun(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          if (type === 'MARK') {
            const feature = marksLayerRef.current
              ?.getSource()
              .getFeatureById(pointId);
            feature &&
              marksLayerRef.current?.getSource().removeFeature(feature);
            getMarksListData();
          }
          if (type === 'VINE_ROW_HEAD') {
            const feature = vineRowHeadLayerRef.current
              ?.getSource()
              .getFeatureById(pointId);
            feature &&
              vineRowHeadLayerRef.current?.getSource().removeFeature(feature);
            getVineRowHeadsListData();
          }
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
        });
      }
    };
    // mark delete ends here

    // mark drag  starts here
    const dragMarkPoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const markId = feature.get('pointId');
        let latLong: number[] = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        latLong = getXYCoordinates(latLong, base);
        const pointData = feature.get('point');
        const { z }: { z: number } = pointData;
        const payload: MarkPointPayloadTypes = {
          y: latLong[1],
          x: latLong[0],
          markId,
          action: 'EDIT',
          z,
        };
        setActiveDrawAction('MARK');
        setShowSave(true);
        setMarkPointPayload(payload);
      }
    };
    // mark drag  ends here

    // on save button click differnt operations
    const onSaveClick = () => {
      // open Mark save popup
      if (activeDrawAction === 'MARK') {
        setShowMarkPoint(true);
      }
      // open waypoint save popup
      if (activeDrawAction === WAY_POINT) {
        setShowWaypoint(true);
      }
      // open Vine save popup
      if (activeDrawAction === VINE) {
        setShowVine(true);
      }
      // open polygon save popup

      if (activeDrawAction === POLYGON) {
        setShowPoly(true);
      }
      // open waypoint edit popup
      if (activeDrawAction === 'POLYGONEDIT') {
        updatePolygonFun();
        setShowEditPoly(true);
      }
      // open waypoint save / update popup
      if (activeDrawAction === VINE_ROW || activeDrawAction === 'VINEROWEDIT') {
        setShowVineRow(true);
      }
      // open vineblock save popup
      if (activeDrawAction === VINE_BLOCK) {
        saveVineBlock();
        setShowVineBlock(true);
      }
      if (activeDrawAction === 'VINEBLOCKEDIT') {
        editVineBlock();
        setShowVineBlock(true);
      }
      if (activeDrawAction === 'BASESTATION') {
        setLatLong(true);
      }
      if (activeDrawAction === AB_LINE || activeDrawAction === 'ABLINEEDIT') {
        setShowAbLine(true);
      }
      if (activeDrawAction === AB_BLOCK || activeDrawAction === 'ABBLOCKEDIT') {
        if (activeDrawAction === 'ABBLOCKEDIT') {
          updateAbBlockFun();
        }
        setShowAbBlock(true);
      }
      if (activeDrawAction === CHARGE_POINTS) {
        setShowChargePoint(true);
      }
      if (activeDrawAction === 'POLYLINEEDIT') {
        setShowPolyLine(true);
      }
    };
    // stting global vairible for drawitem
    useEffect(() => {
      activeDrawActionGlobal = activeDrawAction;
    }, [activeDrawAction]);

    useEffect(() => {
      showSaveGlobal = showSave;
    }, [showSave]);

    const closeLatLongModal = () => {
      setLatLong(false);
    };

    // deleting vine blocks from right slider
    useEffect(() => {
      if (vineblockId && vineblockId !== '') {
        const id = vineblockId.replace('vine_block_', '');
        const name = getNameFromList(vineBlocks, Number(id));
        confirmDelete(id, VINE_BLOCK, name);
      }
    }, [vineblockId]);
    useEffect(() => {
      userDetails && userDetails.organization && getTractorsList();
    }, [userDetails]);
    const getTractorsList = async () => {
      setSelectedJumpAndGoForVineRow([]);
      try {
        const tractor = await getTractorsListData(
          userDetails.organization.api_url,
          userDetails.organization_id,
          1,
        );
        const data =
          tractor && tractor.records && tractor.records.length > 0
            ? tractor.records
            : [];
        const tractorData = data.map((d: TractorsList, index: number) => {
          index === 0 && setSelectedTractor(`${d.id}`);
          return {
            label: d.name,
            value: `${d.id}`,
            disabled: false,
            index: `${d.id}`,
          };
        });

        setTractorsData(tractorData);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const clearAbLine = () => {
      if (abLineDraw) {
        abLineDraw?.un('drawend', abLineDrawEnd);
        abLineDraw.abortDrawing();
        mapRef.current?.removeInteraction(abLineDraw);
        setActiveDrawAction('NONE');
        return;
      }
      clearModifiedAbLine();
    };
    const drawAbLine = () => {
      clearAbLine();
      setActiveDrawAction(AB_LINE);
      abLineSource = new VectorSource();
      abLineDraw = new Draw({
        source: abLineSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(abLineDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      abLineDraw.on('drawend', abLineDrawEnd);
    };
    const abLineDrawEnd = (e: any) => {
      e.feature.setStyle(abLineStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(abLineDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({
          longitude: latLong[0],
          latitude: latLong[1],
          altitude: 0,
        });
      });
      const payload1: AbLinePayloadSingle = {
        name: '',
        abLineXY: vertices,
        head_a_bearing: 0,
        head_b_bearing: 0,
        action: 'Add',
        vineRowId: 0,
        polygon_uuid: '',
      };
      if (vertices && vertices.length > 1) {
        setAbLinePayload(payload1);
        setShowSave(true);
      }
    };

    const drawAbLine1 = () => {
      clearAbLine();
      setActiveDrawAction(AB_LINE);
      abLineSource = new VectorSource();
      abLineDraw = new Draw({
        source: abLineSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(abLineDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      abLineDraw.on('drawend', abLineDrawEnd1);
    };
    const abLineDrawEnd1 = (e: any) => {
      e.feature.setStyle(abLineStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(abLineDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({
          longitude: latLong[0],
          latitude: latLong[1],
          altitude: 0,
        });
      });
      const payload1: AbLinePayloadSingle = {
        name: '',
        abLineXY: vertices,
        head_a_bearing: 0,
        head_b_bearing: 0,
        action: 'Add',
        vineRowId: 0,
        polygon_uuid: '',
      };
      if (vertices && vertices.length > 1) {
        setAbLinePayload1(payload1);
        setShowSave(true);
      }
    };

    const deleteAbLine = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteAbLineFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = abLinesLayerRef.current
            ?.getSource()
            .getFeatureById('ab_line_' + pointId);
          feature &&
            abLinesLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getAbLineListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    const clearModifiedAbLine = () => {
      if (abLineModify) {
        abLineModify?.un('modifyend', updateAbLineFunc);
        mapRef.current?.removeInteraction(abLineModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateAbline = (feature: any) => {
      clearModifiedAbLine();
      setShowSave(true);
      setActiveDrawAction('ABLINEEDIT');
      abLineSource = new VectorSource();
      abLineSource.addFeature(feature);
      abLineModify = new Modify({ source: abLineSource });
      mapRef.current?.addInteraction(abLineModify);
      updateAbLineFunc();
      abLineModify.on('modifyend', updateAbLineFunc);
    };
    const updateAbLineFunc = () => {
      const feature: any = abLineSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_bearing = feature[0].get('head_a_bearing');
        const head_b_bearing = feature[0].get('head_b_bearing');
        const polygon_uuid = feature[0].get('polygon_uuid');
        const vineRowXY: any[] = [];
        const vertices: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
          vertices.push({ longitude: latLong[0], latitude: latLong[1] });
        });
        const payload: AbLinePayloadSingle = {
          name: pointName,
          abLineXY: vertices,
          action: 'Edit',
          vineRowId,
          head_a_bearing,
          head_b_bearing,
          polygon_uuid,
        };
        setAbLinePayload(payload);
        // clearModifiedAbLine();
      }
    };

    const getAbBlocksListData = async () => {
      abBlockLayerRef.current?.getSource().clear();
      globalPolyUnique = randomNumber();
      try {
        const { organization } = userDetails;
        const response = await getAbBlocksData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        const list =
          records && records.length && records.length > 0 ? records : [];
        // getAbBlocks(globalPolyUnique, list, false);
        setAbBlocks(list);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const getAbBlocks = async (
      localPolyUnique: number,
      polyList: any,
      isApi = false,
    ) => {
      try {
        abBlockLayerRef.current?.getSource().clear();
        const records = polyList && polyList.length > 0 ? polyList : [];
        const preparedList: MapFeature[] = [];
        records.map(async (record: any, index: number) => {
          const { polygonItem } = await drawAbBlockFun(
            userDetails,
            base,
            abBlockLayerRef.current,
            globalPolyUnique,
            localPolyUnique,
            record,
            isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polygonItem && polygonItem.vertices) {
            record.vertices = polygonItem.vertices;
          }
          preparedList.push(polygonItem as MapFeature);
          if (records && records.length - 1 === index) {
            setAbBlockFeature(preparedList);
            setAbBlocks(records);
          }
        });
        // set show hide the polygon layer
        abBlockLayerRef.current?.setVisible(listVisibility.ABBLOCK);
        abBlockHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    const detatchAbBlockDrawHandler = () => {
      if (abBlockDraw) {
        abBlockDraw.abortDrawing();
        abBlockDraw?.un('drawend', drawAbBlock);
        mapRef.current?.removeInteraction(abBlockDraw);
        setActiveDrawAction('NONE');
      }
    };
    const detatchAbBlockModifyHandler = () => {
      if (abBlockModify) {
        abBlockModify?.un('modifyend', editDrawAbBlock);
        mapRef.current?.removeInteraction(abBlockModify);
        setActiveDrawAction('NONE');
      }
    };
    const editDrawAbBlock = (e: any) => {
      setShowSave(true);
    };
    const clearAbBlock = () => {
      detatchAbBlockDrawHandler();
      detatchAbBlockModifyHandler();
    };
    const drawAbBlock = () => {
      clearAbBlock();
      setActiveDrawAction(AB_BLOCK);
      abBlockSource = new VectorSource({ wrapX: false });
      abBlockDraw = new Draw({
        source: abBlockSource,
        type: GeometryType.POLYGON,
      });
      mapRef.current?.addInteraction(abBlockDraw);
      // abBlockDrawEnd();
      abBlockDraw.on('drawend', abBlockDrawEnd);
    };
    const abBlockDrawEnd = async (e: any) => {
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      e.feature.setStyle(polyDrawStyle);
      let points = e.feature.getGeometry().getCoordinates();
      points = points && points.length > 0 ? points[0] : [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        vertices.push({ longitude: xy[0], latitude: xy[1] });
      });
      const payload: AbBlockPayload = {
        name: '',
        color: '',
        type: 'Add',
        lat_long_vertices: vertices,
        polygonId: 0,
      };
      setAbBlockPayload(payload);

      // show polygon save button
      setShowSave(true);
      // detach polygon draw
      abBlockDraw.abortDrawing();
      abBlockDraw?.un('drawend', abBlockDrawEnd);
      mapRef.current?.removeInteraction(abBlockDraw);
      const featuresList = drawFeaturesLayer.current?.getSource().getFeatures();
      if (featuresList && featuresList.length > 0) {
        const feature = featuresList[0];
        abBlockSource = new VectorSource();
        abBlockSource.addFeature(feature);
        abBlockModify = new Modify({
          source: abBlockSource,
        });
        mapRef.current?.addInteraction(abBlockModify);
        abBlockModify.on('modifyend', createdAbBlockUpdateAbBlockFun);
      }
    };
    const createdAbBlockUpdateAbBlockFun = async () => {
      if (abBlockSource) {
        const feature: any = abBlockSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const payload: AbBlockPayload = {
            name: '',
            color: '',
            type: 'Add',
            lat_long_vertices: vertices,
            polygonId: 0,
          };
          setAbBlockPayload(payload);
        }
      }
    };
    useEffect(() => {
      if (deleteAbBlockId && deleteAbBlockId.length > 0) {
        const record = JSON.parse(deleteAbBlockId);
        record && record.id && confirmDelete(record.id, AB_BLOCK, record.name);
      }
    }, [deleteAbBlockId]);
    const deleteAbBlock = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteAbBlockFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = abBlockLayerRef.current
            ?.getSource()
            .getFeatureById('ab_block_' + pointId);
          feature &&
            abBlockLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getAbBlocksListData();
          getAbLineListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    // update polygon start here
    const updateAbBlock = (feat: any) => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction('ABBLOCKEDIT');
      feat.setStyle(null);
      abBlockSource = new VectorSource();
      feat.set('type', 'none');
      abBlockSource.addFeature(feat);
      feat.setStyle(polyDrawStyle);
      abBlockModify = new Modify({
        source: abBlockSource,
      });
      mapRef.current?.addInteraction(abBlockModify);
      abBlockModify.on('modifyend', editDrawAbBlock);
    };
    const updateAbBlockFun = async () => {
      if (abBlockSource) {
        const feature: any = abBlockSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const label = feature[0].get('label');
          const color = feature[0].get('color');
          const polygonId = feature[0].get('id');
          const payload: AbBlockPayload = {
            name: label,
            type: 'Edit',
            color,
            lat_long_vertices: vertices,
            polygonId,
          };
          setAbBlockPayload(payload);
          abBlockModify?.un('modifyend', editDrawAbBlock);
          mapRef.current?.removeInteraction(abBlockModify);
        }
      }
    };
    const drawAbBlocks = async () => {
      abBlockLayerRef.current?.getSource().clear();
      try {
        if (
          !(abLines && abLines.length > 0 && abBlocks && abBlocks.length > 0)
        ) {
          return;
        }
        const records = abBlocks && abBlocks.length > 0 ? abBlocks : [];
        const abBlocksFeatures: Feature<Geometry>[] = [];
        const abBlocksList: MapFeature[] = [];
        const color = 'abBlock';
        records.map((abBlock1: any) => {
          const id = `ab_block_${abBlock1.id}`;
          const vertices: any = [];

          const vinesHeadA: any[] = [];
          const vinesHeadB: any[] = [];
          let xyOrLatLong = false;

          abBlock1.ab_line_uuidsContent = [];
          abBlock1.ab_line_uuids &&
            abBlock1.ab_line_uuids.length > 0 &&
            abBlock1.ab_line_uuids.map((id: string) => {
              const [item] = abLines.filter(
                (vineRow: any) => vineRow.ab_line_uuid === id,
              );
              item && abBlock1.ab_line_uuidsContent.push(item);
            });
          const { ab_line_uuidsContent: ab_line_uuids } = abBlock1;
          const content = ab_line_uuids?.length ? ab_line_uuids[0] : null;

          if (
            content &&
            content.head_a_latitude !== null &&
            content.head_a_longitude !== null &&
            content.head_b_latitude !== null &&
            content.head_b_longitude !== null
          ) {
            xyOrLatLong = false;
          } else {
            xyOrLatLong = true;
          }
          !xyOrLatLong &&
            ab_line_uuids.map((abLine: any) => {
              const vineA = [abLine.head_a_longitude, abLine.head_a_latitude];
              const vineB = [abLine.head_b_longitude, abLine.head_b_latitude];
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          xyOrLatLong &&
            ab_line_uuids.map((abLine: any) => {
              const vineA = { x: abLine.head_a_x, y: abLine.head_a_y };
              const vineB = { x: abLine.head_b_x, y: abLine.head_b_y };
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          let vineBlockCoordinates = vinesHeadA;
          vinesHeadB.reverse();
          vineBlockCoordinates = vineBlockCoordinates.concat(vinesHeadB);
          if (vinesHeadA[0]) {
            vineBlockCoordinates.push(vinesHeadA[0]);
          }

          if (!xyOrLatLong) {
            vineBlockCoordinates.forEach((item: any) => {
              vertices.push(proj.fromLonLat(item));
            });
          } else {
            vineBlockCoordinates.forEach((item: any) => {
              item.xNew = item.x / 100;
              item.yNew = item.y / 100;
              const latAndLong: [number, number] = getLatitudeAndLongitude(
                [item.yNew, item.xNew],
                base,
                true,
              );
              vertices.push(proj.fromLonLat(latAndLong));
            });
          }

          let satelightPolyStyle = null;
          if (vertices && vertices.length > 0) {
            const zIndex = priority.ABBLOCK;
            const abBlockFeature = new Feature({
              type: AB_BLOCK,
              pointType: AB_BLOCK,
              geometry: new Polygon([vertices]),
              zIndex,
              color,
              id: abBlock1.id,
              remarks: abBlock1.remarks,
              poly: abBlock1,
              label: abBlock1.name,
            });
            abBlockFeature.setId(id);
            satelightPolyStyle = getSateLightPolyStyle(color, zIndex);
            const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex);
            abBlockFeature.setStyle(satelightPolyStyle);
            abBlockFeature.set('satelightPolyStyle', satelightPolyStyle);
            abBlockFeature.set('roadMapPolyStyle', roadMapPolyStyle);
            abBlocksFeatures.push(abBlockFeature);
          }
          abBlocksList.push({
            id: abBlock1.id,
            name: abBlock1.name,
            type: AB_BLOCK,
            mapId: id,
            visible: true,
            style: satelightPolyStyle ? satelightPolyStyle : null,
            color,
            vertices,
            isPublished: abBlock1.is_published ? true : false,
          });
        });
        abBlockLayerRef.current?.setVisible(listVisibility.ABBLOCK);
        abBlockLayerRef.current?.getSource().addFeatures(abBlocksFeatures);
        setAbBlockFeature(abBlocksList);
        abBlockHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    const drawSurveyVerticesHandler = (records: any, isApi = false) => {
      surveyVineRowLayerRef &&
        surveyVineRowLayerRef.current &&
        surveyVineRowLayerRef.current.getSource().clear();
      const list =
        records && records.length && records.length > 0 ? records : [];

      const surveyVineRows: MapFeature[] = [];
      // const vineRowsFeatures1: Feature<Geometry>[] = [];
      list.map(async (surveyVineRow: any, index: number) => {
        const coordinates: any = [];
        const id = `${SURVEY_ID_CONSTANT}${surveyVineRow.survey_vine_row_uuid}`;
        const { organization } = userDetails;
        try {
          surveyVineRow.records = isApi
            ? await getSurveyVineRowData(
                organization.api_url,
                organization.farm.id,
                surveyVineRow.survey_vine_row_uuid,
              )
            : surveyVineRow.records;
          surveyVineRow?.records?.map((vineItem: any) => {
            vineItem.row_name = surveyVineRow.name;
            if (vineItem.latitude !== null && vineItem.longitude !== null) {
              coordinates.push(
                proj.fromLonLat([vineItem.longitude, vineItem.latitude]),
              );
            } else {
              const vineA = {
                x: surveyVineRow[0].x / 100,
                y: surveyVineRow[0].y / 100,
              };
              const latAndLong = getLatitudeAndLongitude(
                [vineA.y, vineA.x],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            }
          });
          if (coordinates && coordinates.length > 0) {
            const surveyVineRowLocation = new Feature({
              geometry: new LineString(coordinates),
              pointType: 'VINEROWFROMJUMPANDGO',
              type: 1,
              rowData: { ...surveyVineRow, records: surveyVineRow.records },
              rowUuid: surveyVineRow.survey_vine_row_uuid,
              name: surveyVineRow.name,
            });
            surveyVineRowLocation.setId(id);
            // vineRowsFeatures1.push(surveyVineRowLocation);
            surveyVineRowLayerRef?.current
              ?.getSource()
              .addFeature(surveyVineRowLocation);
          }
          surveyVineRows.push({
            id: surveyVineRow.id,
            name: surveyVineRow.name,
            type: SURVEY_VINE_ROW,
            mapId: id,
            visible: true,
          });
          isApi &&
            list &&
            list.length === index + 1 &&
            setSurveyVineRowFeature(surveyVineRows);
          list && list.length === index + 1 && setSurveyVineRows(list);
          surveyVineRowLayerRef.current?.setVisible(
            listVisibility.SURVEYVINROW,
          );
        } catch (error: any) {
          notification.error({
            message: error.message,
          });
        }
      });
    };
    const drawSurveyVertices = async () => {
      setActiveDrawAction('VINEROWFROMJUMPANDGO');
      markerPointRef.current?.getSource().clear();
      previewSurveyVineRowLayerRef.current?.getSource().clear();
      vineRowsForJumpAndGoLayerRef.current?.getSource().clear();
      // const vineRowsFeatures1 = surveyVineRowLayerRef.current
      //   ?.getSource()
      //   .getFeatures();
      // const surveyVineRows = surveyVineRowNewLayerRef.current
      //   ?.getSource()
      //   .getFeatures();
      // const list = [...surveyVineRows, ...vineRowsFeatures1];
      // list &&
      //   list.length > 0 &&
      //   displayRecenterAllViewPortSetUp(list, mapRef.current);
    };
    const vinerowPreviewHandlerFun = (data: any) => {
      if (data && (data.is_vine_row_generated || data.is_ab_line_generated)) {
        vinerowPreviewHandler(data);
      } else {
        error({
          title: 'Crop Row preview failed',
          content: data.error_msg,
        });
      }
    };
    const vinerowPreviewHandler = (data: any) => {
      previewSurveyVineRowLayerRef?.current?.getSource().clear();

      try {
        const records =
          data && data.vine_row_list && data.vine_row_list.length > 0
            ? data.vine_row_list
            : [];
        const vineRowsFeatures: Feature<Geometry>[] = [];
        records.map((vine: any, index: number) => {
          const {
            head_a_x,
            head_a_y,
            head_b_x,
            head_b_y,
            head_a_latitude,
            head_a_longitude,
            head_b_latitude,
            head_b_longitude,
          } = vine;
          const coordinates: any = [];
          const id = `survey_vine_row_${vine.vine_row_uuid}`;
          let latAndLongA: number[] = [];
          let latAndLongB: number[] = [];
          if (
            head_a_latitude !== null &&
            head_a_longitude !== null &&
            head_b_latitude !== null &&
            head_b_longitude !== null
          ) {
            latAndLongA = [head_a_longitude, head_a_latitude];
            latAndLongB = [head_b_longitude, head_b_latitude];
          } else {
            const vineA = {
              id: vine.id,
              x: head_a_x / 100,
              y: head_a_y / 100,
            };
            const vineB = {
              id: vine.id,
              x: head_b_x / 100,
              y: head_b_y / 100,
            };
            latAndLongA = getLatitudeAndLongitude(
              [vineA.y, vineA.x],
              base,
              true,
            );
            latAndLongB = getLatitudeAndLongitude(
              [vineB.y, vineB.x],
              base,
              true,
            );
          }

          coordinates.push(proj.fromLonLat(latAndLongA));
          coordinates.push(proj.fromLonLat(latAndLongB));
          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            name: vine.name,
          });

          vineLocation.setId(id);
          vineRowsFeatures.push(vineLocation);
          previewSurveyVineRowLayerRef.current
            ?.getSource()
            .addFeature(vineLocation);

          if (index === records.length - 1) {
            displayRecenterAllViewPortSetUp(vineRowsFeatures, mapRef.current);
          }
        });
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };

    const drawSurveyNewVerticesHandler = (records: any, isApi = false) => {
      surveyVineRowNewLayerRef &&
        surveyVineRowNewLayerRef.current &&
        surveyVineRowNewLayerRef.current.getSource().clear();
      const list =
        records && records.length && records.length > 0 ? records : [];

      const surveyVineRows: MapFeature[] = [];
      list.map(async (surveyVineRow: any, index: number) => {
        // const id = `${SURVEY_NEW_ID_CONSTANT}${surveyVineRow.survey_uuid}`;
        const id = surveyVineRow.survey_uuid;
        const { organization } = userDetails;
        try {
          surveyVineRow.records = isApi
            ? await getSurveyVineRowNewData(
                organization.api_url,
                organization.farm.id,
                surveyVineRow.survey_uuid,
              )
            : surveyVineRow.records;
          const showSteps =
            Object.keys(surveyVineRow.records) &&
            Object.keys(surveyVineRow.records).length &&
            Object.keys(surveyVineRow.records).length > 1
              ? true
              : false;
          for (const vineItem in surveyVineRow.records) {
            const l: any = [];
            surveyVineRow.records[vineItem].map(
              (item: {
                lat: number;
                long: number;
                survey_uuid?: string;
                name?: string;
              }) => {
                item.survey_uuid = surveyVineRow.survey_uuid;
                item.name = `${surveyVineRow.name} ${
                  showSteps ? getStep(vineItem) : ''
                }`;
                l.push(proj.fromLonLat([item.long, item.lat]));
              },
            );

            if (l && l.length > 0) {
              const surveyVineRowLocation = new Feature({
                geometry: new LineString(l),
                pointType: 'VINEROWFROMJUMPANDGONEW',
                type: 1,
                rowData: {
                  ...surveyVineRow,
                  stepRecords: surveyVineRow.records[vineItem],
                },
                rowUuid: surveyVineRow.survey_uuid,
                name: `${surveyVineRow.name} ${
                  showSteps ? getStep(vineItem) : ''
                }`,
                surveyName: surveyVineRow.name,
              });
              surveyVineRowLocation.setId(id + vineItem);
              surveyVineRowNewLayerRef?.current
                ?.getSource()
                .addFeature(surveyVineRowLocation);
            }
          }
          surveyVineRows.push({
            id: surveyVineRow.id,
            name: surveyVineRow.name,
            type: SURVEY_VINE_ROW_NEW,
            mapId: id,
            visible: true,
          });
          isApi &&
            list &&
            list.length === index + 1 &&
            setSurveyVineRowNewFeature(surveyVineRows);
          list && list.length === index + 1 && setSurveyVineRowsNew(list);
          surveyVineRowNewLayerRef.current?.setVisible(
            listVisibility.SURVEY_VINE_ROW_NEW,
          );
        } catch (error: any) {
          notification.error({
            message: error.message,
          });
        }
      });
    };
    // getting iot event to draw the preview vinerows for survey vinerow generation
    useEffect(() => {
      if (userDetails && !userDetails?.organization) return;
      try {
        const sub1 = PubSub.subscribe(
          `${VINE_ROWS}${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            vinerowPreviewHandlerFun(data.value);
          },
          error: (error: any) => console.error(error),
        });
        const sub2 = PubSub.subscribe(
          `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            // vinerowPreviewHandlerFun(data.value);
            if (
              data.value &&
              data.value.category &&
              data.value.category === 'reload_abline' &&
              data.value.message &&
              data.value.message == 'true'
            ) {
              listVisibility.ABLINE = true;
              listVisibility.ABBLOCK = true;

              getAbLineListData();
              getAbBlocksListData();
            }
            if (
              data.value &&
              data.value.category &&
              data.value.category === 'reload_vinerow' &&
              data.value.message &&
              data.value.message == 'true'
            ) {
              listVisibility.VINEROW = true;
              listVisibility.VINEBLOCK = true;
              // getVineRowsListData();
              getVineBlocksListData();
            }
          },
          error: (error: any) => console.error(error),
        });
        return () => {
          sub1.unsubscribe();
          sub2.unsubscribe();
        };
      } catch (error) {
        console.log(error);
      }
    }, [userDetails]);

    const clearModifiedPolyLine = () => {
      if (activeDrawActionGlobal === 'POLYLINEEDIT') {
        polyLineModify?.un('modifyend', updatePolyLineFunc);
        mapRef.current?.removeInteraction(polyLineModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updatePolyLine = (feature: any) => {
      clearModifiedPolyLine();
      setShowSave(true);
      setActiveDrawAction('POLYLINEEDIT');
      polyLineSource = new VectorSource();
      polyLineSource.addFeature(feature);
      polyLineModify = new Modify({ source: polyLineSource });
      mapRef.current?.addInteraction(polyLineModify);
      updatePolyLineFunc();
      polyLineModify.on('modifyend', updatePolyLineFunc);
    };
    const updatePolyLineFunc = () => {
      const feature: any = polyLineSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('name');
        const polyline_uuid = feature[0].get('polyline_uuid');
        const vineRowXY: any[] = [];
        const vertices: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
          vertices.push({ longitude: latLong[0], latitude: latLong[1] });
        });
        const payload: PolyLinePayload = {
          name: pointName,
          lat_long_vertices: vertices,
          action: 'EDIT',
          polyline_uuid: polyline_uuid,
          status: 'inprogress',
        };
        setPolyLinePayload(payload);
      }
    };

    useEffect(() => {
      if (deleteFeedLane && deleteFeedLane.length > 0) {
        const record = JSON.parse(deleteFeedLane);
        record && record.id && confirmDelete(record.id, FEED_LANE, record.name);
      }
    }, [deleteFeedLane]);
    const deleteFeedLanehandler = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineRowFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = feedLaneFeaturesLayerRefs.current
            ?.getSource()
            .getFeatureById('feed_Lane_' + pointId);
          feature &&
            feedLaneFeaturesLayerRefs.current
              ?.getSource()
              .removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getFeedLanesListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    const getFeedLanes = async () => {
      try {
        feedLaneFeaturesLayerRefs?.current?.getSource().clear();
        feedLaneFeaturesLayerRefs.current?.setVisible(listVisibility.FEED_LINE);
        const features: MapFeature[] = [];
        const feedLanesList =
          feedLanes && feedLanes.length > 0 ? feedLanes : [];
        feedLanesList.forEach((feedLane: FeedLaneType, index: number) => {
          const feedRow = [];
          const {
            head_a_x,
            head_a_y,
            head_b_x,
            head_b_y,
            head_a_latitude,
            head_a_longitude,
            head_b_latitude,
            head_b_longitude,
            head_a_altitude,
            head_a_bearing,
          } = feedLane;

          const latAndLong1: [number, number] =
            head_a_latitude && head_a_longitude
              ? [head_a_longitude, head_a_latitude]
              : getLatitudeAndLongitude(
                  [head_a_y / 100, head_a_x / 100],
                  base,
                  true,
                );
          const latAndLong2: [number, number] =
            head_b_latitude && head_b_longitude
              ? [head_b_longitude, head_b_latitude]
              : getLatitudeAndLongitude(
                  [head_b_y / 100, head_b_x / 100],
                  base,
                  true,
                );
          const id = `feed_Lane_${feedLane.id}`;
          const feedHead = new Feature({
            geometry: new Point(proj.fromLonLat(latAndLong1)),
            name: feedLane.name,
          });
          // pointLocation1.setId(id1);
          feedHead.setId(id + '_1');
          feedHead.setStyle(feedHeadStyle(head_a_bearing));
          feedRow.push(proj.fromLonLat(latAndLong1));
          feedRow.push(proj.fromLonLat(latAndLong2));
          const feedLaneLocation = new Feature({
            geometry: new LineString(feedRow),
            data: feedLane,
            name: feedLane.name,
            pointType: types.FEED_LANE,
            id: feedLane.id,
            style: feedLaneStyle,
          });
          feedLaneLocation.setStyle(feedLaneStyle);
          feedLaneLocation.setId(id);
          feedLaneFeaturesLayerRefs.current
            ?.getSource()
            .addFeature(feedLaneLocation);
          feedLaneFeaturesLayerRefs.current?.getSource().addFeature(feedHead);

          features.push({
            id: feedLane.id,
            name: feedLane.name,
            type: FEED_LANE,
            mapId: id,
            visible: true,
            style: feedLaneStyle,
          });
          feedLanesList &&
            feedLanesList.length === index + 1 &&
            setFeedLanesFeature(features);
        });
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        {/* map actions on right side  */}
        <MapActions
          rightSideMenuCollapsed={rightSideMenuCollapsed}
          toggleSatellite={toggleSatellite}
          handleZoom={handleZoom}
          reCenter={reCenter}
          toggleMapMaker={toggleMapMaker}
          setToggleSatellite={setToggleSatellite}
          toggleGraticule={toggleGraticule}
          setImgUpload={setImgUpload}
          setFileUpload={setFileUpload}
          setFileDownload={setFileDownload}
          setLatLong={setLatLong}
        />
        {/* map items actions on header */}
        <MapMakerHeader
          activeDrawAction={activeDrawAction}
          setActiveDrawAction={setActiveDrawAction}
          vineRowPayload={vineRowPayload}
          vineRowModel2LastPayload={
            vineRowModel2LastPayload as VineRowPayload | null
          }
          vineRowModal={vineRowModal}
          showSave={showSave}
          cancelOperations={cancelOperations}
          onSaveClick={onSaveClick}
          handleToggleLayer={handleToggleLayer}
          drawWayPoint={drawWayPoint}
          drawChargePoint={drawChargePoint}
          drawVineBlockFun={drawVineBlockFun}
          drawVine={drawVine}
          drawPolygon={drawPolygon}
          drawAbLine1={drawAbLine1}
          drawVineLaneFun={drawVineLaneFun}
          drawVineRow={drawVineRow}
          setShowVineRow={setShowVineRow}
          globalVineRowModal={globalVineRowModal}
          setVineRowModal={setVineRowModal}
          setVineLaneModal={setVineLaneModal}
          drawSurveyVertices={drawSurveyVertices}
          setShowVineBlockFileUpload={setShowVineBlockFileUpload}
          brownPolygonsLength={brownPolyCount}
          brownConfirm={brownConfirm}
          setGenerateFromRowOrAbLane={setGenerateFromRowOrAbLane}
          setShowFeedLine={setShowFeedLine}
        />
        {/* map element ref */}
        <div
          ref={mapElement}
          style={{ height: '100vh', backgroundColor: '#1c1c1c' }}
        ></div>
        {/* map  tooltip element ref */}
        <div ref={container} className="ol-popup">
          <div ref={content}></div>
        </div>
        {showVineRow &&
          vineRowModal == 1 &&
          vineRowPayload &&
          vineRowPayload.vineRowXY && (
            <VineRow
              showModal={showVineRow}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowVineRow(false);
                reload && getVineRowsListData();
              }}
              vineRowPayload={vineRowPayload as VineRowPayloadSingle}
            />
          )}
        {showVineRow &&
          vineRowModal == 2 &&
          vineRowModel2FirstPayload &&
          vineRowModel2LastPayload && (
            <VineRowModal2
              showModal={showVineRow}
              handleCancel={(reload?: boolean) => {
                // if (reload) {
                //   setTimeout(() => {
                //     // location.reload();
                //   }, 2000);
                //   return;
                // }
                setVineRowModal(1);
                cancelOperations();
                setShowVineRow(false);
                reload && getVineRowsListData();
              }}
              vineRowModel2FirstPayload={
                vineRowModel2FirstPayload as VineRowPayload
              }
              vineRowModel2LastPayload={
                vineRowModel2LastPayload as VineRowPayload
              }
              polygonsListForPop={polygonsListForPop}
            />
          )}
        {showVineRow && (vineRowModal == 3 || vineRowModal == 6) && (
          <VineRowJumpAndGoModel
            showModal={showVineRow}
            setSelectedJumpAndGoForVineRow={setSelectedJumpAndGoForVineRow}
            selectedJumpAndGoForVineRow={selectedJumpAndGoForVineRow}
            handleCancel={(showVineRows?: boolean) => {
              setVineRowModal(2);
              // globalVineRowModal = 2;

              setShowVineRow(false);

              if (showVineRows) {
                listVisibility.VINEROW = false;
                handleToggleLayer(false, VINE_ROW, 3);
                setActiveDrawAction('VINEROWUSINGVINEROWJUMPANDGO');
                // vineRowModal === 3
                //   ? getJumpAndGoVineRowsListData()
                //   : drawJumpAndGoVertices();
                vineRowModal === 3 && getJumpAndGoVineRowsListData();
              }
            }}
            selectedTractor={selectedTractor}
            setSelectedTractor={setSelectedTractor}
            tractors={tractors}
            vineRowModal={vineRowModal}
          />
        )}
        {showPoly && (
          <AddPoly
            showModal={showPoly}
            polyPayload={polyPayload as PolyPayload}
            brownFeatureItems={brownFeatureItems}
            createdPolyFeatureItem={createdPolyFeatureItem.current}
            brownPolygonsLength={brownPolyCount}
            handleCancel={(reload?: boolean, brownPoly?: any) => {
              cancelOperations();
              setShowPoly(false);
              reload && getPolygonsListData();
              if (brownPoly && brownPoly.id) {
                setShowDiscover(true);
                setBrownPoly(brownPoly);
              }
              !reload && reditThePoly(createdPolyFeatureItem.current);
            }}
          />
        )}
        {fileUpload && (
          <FileUpload
            showModal={fileUpload}
            handleCancel={() => {
              closefileModel();
            }}
          />
        )}
        {fileDownload && (
          <FileDownload
            showModal={fileDownload}
            handleCancel={() => setFileDownload(false)}
          />
        )}
        {imgUpload && (
          <ImageUpload
            showModal={imgUpload}
            handleCancel={() => {
              closeImageModel();
            }}
          />
        )}
        {showEditPoly && (
          <EditPoly
            showModal={showEditPoly}
            polyPayload={polyPayload as PolyPayload}
            brownFeatureItems={brownFeatureItems}
            updatedPolyFeatureItem={createdPolyFeatureItem.current}
            polyList={polygonsLayerRef.current?.getSource()?.getFeatures()}
            handleCancel={(reload?: boolean) => {
              setShowEditPoly(false);
              reload && getPolygonsListData();
              reload && setActiveDrawAction('NONE');
              !reload &&
                setTimeout(() => {
                  updatePolygon(createdPolyFeatureItem.current);
                }, 500);
            }}
          />
        )}
        {showWaypoint && (
          <Waypoint
            showModal={showWaypoint}
            wayPointPayload={wayPointPayload as WaypointPayloadTypes}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowWaypoint(false);
              // get the update data
              reload && getWayPointsListData();
            }}
          />
        )}
        {showChargePoint && (
          <ChargePoint
            showModal={showChargePoint}
            wayPointPayload={chargePointPayload as WaypointPayloadTypes}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowChargePoint(false);
              // get the update data
              reload && getChargePointsListData();
            }}
          />
        )}
        {showVine && (
          <Vine
            showModal={showVine}
            vinePayload={vinePayload as VinesPayloadTypes}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowVine(false);
              reload && getVinesListData();
            }}
          />
        )}
        {showVineBlock && (
          <VineBlock
            showModal={showVineBlock}
            vineBlockPayload={vineBlockPayload as VineBlockPayloadType}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowVineBlock(false);
              reload && getVineBlocksListData();
            }}
            polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
          />
        )}
        {showVineLane && vineLaneModal === 1 && (
          <VineLaneModal1
            showModal={showVineLane}
            vineLanePayload={vineLanePayload as VineLanePayloadType}
            polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowVineLane(false);
              reload && getVineLanesListData();
            }}
          />
        )}
        {showVineLane && vineLaneModal === 2 && (
          <VineLaneModal2
            showModal={showVineLane}
            vineLanePayload={vineLanePayload as VineLanePayloadType}
            polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowVineLane(false);
              reload && getVineLanesListData();
            }}
          />
        )}
        {showMarkPoint && (
          <Mark
            showModal={showMarkPoint}
            markPointPayload={markPointPayload as MarkPointPayloadTypes}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowMarkPoint(false);
              reload && getMarksListData();
            }}
          />
        )}
        {showDiscover && (
          <DiscoverModal2
            showDiscover={showDiscover}
            handleCancel={(reloadPoly?: boolean) => {
              setShowDiscover(false);
              reloadPoly && getPolygonsListData();
            }}
            brownPoly={brownPoly}
          />
        )}
        {latLong && (
          <GroundZeroEditModal
            showModal={latLong}
            closeModal={() => {
              closeLatLongModal();
              setUpdateBaseLat(baseLat);
              setUpdateBasedLang(baseLang);
              cancelOperations();
            }}
            groundLat={updateBaseLat}
            groundLang={updateBaseLang}
          />
        )}
        {showAbLine && abLinePayload && abLinePayload.abLineXY && (
          <AbLine
            showModal={showAbLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbLine(false);
              reload && getAbLineListData();
            }}
            abLinePayload={abLinePayload as AbLinePayloadSingle}
          />
        )}
        {showAbLine && abLinePayload1 && abLinePayload1.abLineXY && (
          <AbLine1
            showModal={showAbLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbLine(false);
              reload && getAbBlocksListData();
              reload && getAbLineListData();
            }}
            abLinePayload={abLinePayload1 as AbLinePayloadSingle}
            polygonsListForPop={polygonsListForPop}
          />
        )}
        {showAbBlock && (
          <AbBlock
            showModal={showAbBlock}
            polyPayload={abBlockPayload as AbBlockPayload}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbBlock(false);
              reload && getAbBlocksListData();
            }}
          />
        )}
        {showVineBlockFileUpload && (
          <VineBlockFileUpload
            showModal={showVineBlockFileUpload}
            handleCancel={(reload?: boolean) => {
              setShowVineBlockFileUpload(false);
              reload && getVineBlocksListData();
            }}
            polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
          />
        )}
        {/* {siderPop && (
          <>
            <div
              className={
                'rightSideWidegtMapMaker ' +
                (siderPop ? 'expandMapMaker' : 'hideMapMaker')
              }
            >
              <div className="closeButton profileClose">
                <img src={closeGreen} height="12px" alt="close icon" />
                <span className="mapMakerTitle">Testing</span>
              </div>
              <div className="mapMakerContainer">sadf</div>
            </div>
          </>
        )} */}
        {showVineRow && vineRowModal == 4 && (
          <VineRowModelWithJumpAndGo
            // key={randomNumber()}
            showModal={showVineRow}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowVineRow(false);
              reload && getVineRowsListData();
            }}
            selectedVineRowsFromJumpAndGo={selectedVineRowsFromJumpAndGo}
            polygonsListForPop={polygonsListForPop}
            generateFromRowOrAbLane={generateFromRowOrAbLane}
          />
        )}
        {showPolyLine && polyLinePayload && polyLinePayload.lat_long_vertices && (
          <PolyLine
            showModal={showPolyLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowPolyLine(false);
              reload && getPolyLineListData();
            }}
            polyLinePayload={polyLinePayload as PolyLinePayload}
          />
        )}
        {showFeedLine && (
          <FeedLine
            showModal={showFeedLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowFeedLine(false);
              reload && getFeedLanesListData();
            }}
          />
        )}
      </>
    );
  },
);

export default MapMakerMap;
MapMakerMap.displayName = 'LiveMap';
