/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Layout, Modal, notification, Row, Space, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Chapter } from '../../../../constants/types';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import RoutesConstants from '../../../../routes/RoutesConstant';
import edit_icon from '../../../../assets/images/nEdit.svg';
import deleteIcon from '../../../../assets/images/del_icon.svg';
import duplicate from '../../../../assets/images/duplicate.svg';
import { initScroller } from '../../../../constants/Common';
import constants from '../../../../constants/constant';
import { UserManualAppContext } from './UserManualContext';
import usePaginate from '../../../../hooks/usePaginate';
import { RESET } from './UserManualReducer';
import AddButton from '../../../common/AddButton';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { deleteChapter, getChaptersData } from '../../API';
import EditChapter from './EditChapter';
import DuplicateChapter from './DuplicateChapter';
import SwPublishModal from './SwPublishModal';
import Manual from './Manual';
import pub_icon from '../../../../assets/images/pub_icon.svg';
import AddChapter from './AddChapter';

const { DOWNLOAD_SIZE } = constants;
let columns: any[] = [];

const UserManual: React.FC = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { userManualReducer } = useContext(UserManualAppContext);
  const [, dispatch] = userManualReducer;
  const [selectedChapter, setSelectedChapter] = useState<any>();
  const [chapterPublish, setChapterPublish] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { push } = useHistory();
  const [showAddChapter, setShowAddChapter] = useState(false);
  const [showEditChapter, setShowEditChapter] = useState(false);
  const [showDuplicateChapter, setShowDuplicateChapter] = useState(false);
  const [selectDuplicateChapter, setSelectDuplicateChapter] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [headers, setHeaders] = useState<any>([]);
  const tableRef = useRef<any>(null);
  const [sortInfo, setSortInfo] = useState<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const [showManual, setShowManual] = useState<boolean>(false);
  const [manualrecord, setManualrecord] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization) loadUserManual();

    return () => {
      dispatch({ type: RESET });
    };
  }, [userDetails, pageNumber, pageSize, sortInfo]);

  const loadUserManual = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      // eslint-disable-next-line no-unused-expressions
      pageNumber !== 1 ? setPageNumber(1) : getChapters();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getChapters();
    }
  };

  const wrapResult = async (pageNumber: number, pageSize: number) => {
    const result = await getChaptersData(
      pageNumber,
      pageSize,
      false,
      false,
      sortInfo,
    );
    return {
      result,
    };
  };

  const getChapters = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(pageNumber, pageSize);

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const deleteChapterById = async (record: any) => {
    try {
      setLoader(true);
      await deleteChapter(record.id);
      notification.success({
        message: `${record.title} deleted successfully`,
      });
      handleRefresh();
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleOnClick = (record: any) => {
    setManualrecord(record);
    setShowManual(true);
  };

  const confirm = (record: Chapter) => {
    Modal.confirm({
      title: `${t(translate.Oem.youWantToDelete)} ${record?.title}?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteChapterById(record),
    });
  };

  const handleChapterPublish = (record: any) => {
    setSelectedChapter(record);
    setChapterPublish(true);
  };

  const handleChapterEdit = (record: any) => {
    setSelectedChapter(record);
    setShowEditChapter(true);
  };

  const handleDuplicateChapter = (record: any) => {
    setSelectDuplicateChapter(record);
    setShowDuplicateChapter(true);
  };

  useEffect(() => {
    columns = [
      {
        title: `${t(translate.battery.name)}`,
        dataIndex: 'title',
        width: '150px',
        ellipsis: true,
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
        render: (name: string) => <span>{name}</span>,
      },
      {
        title: `${t(translate.myTask.description)}`,
        dataIndex: 'description',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
        render: (description: string) => <span>{description}</span>,
      },
      {
        title: `${t(translate.training.language)}`,
        dataIndex: 'language',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.Oem.oem)}`,
        dataIndex: 'oem_code',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.releaseNotes.assignedSoftwares)}`,
        dataIndex: 'software_version_numbers',
        render: (software_version_numbers: number) => (
          <>{software_version_numbers || '-'}</>
        ),
        width: '180px',
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.baseStation.status)}`,
        dataIndex: 'published_status',
        width: '150px',
        // render: (text: any) => getDateTime(text),
        render: (published_status: any) => (
          <>{published_status ? 'Published' : '-'}</>
        ),
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
      {
        title: `${t(translate.updateManager.action)}`,
        dataIndex: 'published_status',
        width: '150px',
        render: (published_status: any, record: any) => (
          <>
            <Space>
              {!published_status ? (
                <Tooltip title="Publish">
                  {/* <CheckCircleOutlined
                    style={{
                      fontSize: '20px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChapterPublish(record);
                    }}
                  /> */}
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChapterPublish(record);
                    }}
                    src={pub_icon}
                    style={{
                      fontSize: '20px',
                    }}
                    height="24"
                    width="24"
                  />
                </Tooltip>
              ) : (
                <>
                  <img
                    src={pub_icon}
                    height="24"
                    width="24"
                    style={{
                      visibility: 'hidden',
                    }}
                  />
                  {/* <CheckCircleOutlined
                    style={{
                      fontSize: '20px',
                      visibility: 'hidden',
                    }}
                  /> */}
                </>
              )}
              <Tooltip title="Edit">
                <img
                  data-testid={`${record.title}-editIcon-UserManual`}
                  src={edit_icon}
                  alt="Edit"
                  height="24"
                  width="24"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChapterEdit(record);
                  }}
                  className="imgStyle"
                />
              </Tooltip>
              <Tooltip title="Duplicate">
                <img
                  data-testid={`${record.title}-duplicateIcon-UserManual`}
                  src={duplicate}
                  alt="Edit"
                  height="24"
                  width="24"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDuplicateChapter(record);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <img
                  data-testid={`${record.title}-deleteIcon-UserManual`}
                  src={deleteIcon}
                  alt="Delete"
                  height="24"
                  width="24"
                  className=""
                  onClick={(e) => {
                    e.stopPropagation();
                    confirm(record);
                  }}
                />
              </Tooltip>
            </Space>
          </>
        ),
        onCell: (record: any) => ({
          onClick: () => handleOnClick(record),
        }),
      },
    ];

    if (columns) {
      const header = columns
        .map((column: any) => ({
          label: column.title,
          key: column.dataIndex,
        }))
        .filter(({ key }) => key !== 'published_status');
      setHeaders(header);
    }
  }, []);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadUserManual();
  };
  const handleAdd = () => setShowAddChapter(true);

  const closeAddChapter = (state = false) => {
    setShowAddChapter(false);
    if (state) handleRefresh();
  };

  const closeEditChapter = (state = false) => {
    setShowEditChapter(false);
    if (state) handleRefresh();
  };

  const closeManual = (state = false) => {
    setShowManual(false);
    if (state) handleRefresh();
  };

  const closeDuplicateChapter = (state = false) => {
    setShowDuplicateChapter(false);
    if (state) handleRefresh();
  };

  const closeChapterPublish = (state = false) => {
    setChapterPublish(false);
    if (state) handleRefresh();
  };

  const handleSort = (value: any) => {
    activity.current = 'sort';
    setSortInfo(value);
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result } = await wrapResult(page, DOWNLOAD_SIZE);
        const { records } = result;
        const tdata = Array.isArray(records) ? records : [];
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  return (
    <>
      <>
        {!showManual && (
          <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
            <div className="mainContent">
              <div className="tblContainer viewportContainer">
                <Row>
                  <div className="common_wrapper tabFilterWidget_wrapper customer_wrapper">
                    <div className="filters_card personnelCard">
                      <div
                        onClick={() => goBack()}
                        className="back-button-bdr backtoCardsBtn"
                      />
                      <AddButton
                        label="Add"
                        onClick={() => {
                          setShowAddChapter(true);
                        }}
                      />

                      <TableExt
                        handleRefresh={handleRefresh}
                        handleDownload={handleDownload}
                        downloadData={downloadData}
                        placeholder="Search Customers"
                        csvHeaders={headers}
                        csvData={csvData}
                        csvFilename="UserManual"
                        sortColumns={['created_date_time', 'name']}
                        tableRef={tableRef}
                        tableName="UserManual"
                        toggleColumnsStatus={toggleColumnsStatus}
                      />
                    </div>

                    <div className="common_table">
                      <InfiniteScrollTable
                        filterData={filterData}
                        columns={columns}
                        handleLoadMore={handleLoadMore}
                        hasMore={hasMore}
                        loading={loader}
                        totalcount={total}
                        filename="User Manual"
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            {showAddChapter && (
              <AddChapter close={closeAddChapter} showModel={showAddChapter} />
            )}
            {showEditChapter && (
              <EditChapter
                close={closeEditChapter}
                showModel={showEditChapter}
                chapter={selectedChapter}
              />
            )}
            {showDuplicateChapter && (
              <DuplicateChapter
                close={closeDuplicateChapter}
                showModel={showDuplicateChapter}
                chapter={selectDuplicateChapter}
              />
            )}
            {chapterPublish && (
              <SwPublishModal
                showModal={chapterPublish}
                close={closeChapterPublish}
                selectedChapter={selectedChapter}
              />
            )}
          </Layout>
        )}
      </>
      <>
        {showManual && manualrecord && (
          <Manual
            close={closeManual}
            showModel={showManual}
            manualrecord={manualrecord}
          />
        )}
      </>
    </>
  );
};

export default UserManual;
