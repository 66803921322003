import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, Row, Table, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../locale/en_translate.json';
import { ApplicationContext } from '../../../context/AppContext';
import { getDateTime } from '../../../constants/Common';
import { getDriveActionInteruptData } from '../api';
import loading_logo from '../../../assets/images/loading_logo.gif';
import {
  getDescriptionForInterrupt,
  getInterruptTitle,
} from '../../../constants/constant';

interface Props {
  driveAction: any;
  handleCancel: () => void;
}
const DriveActionDetailsModal: React.FC<Props> = ({
  driveAction,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  const columns = [
    {
      title: `${t(translate.JNGDetails.time)}`,
      dataIndex: 'time',
      width: 150,
    },
    {
      title: `${t(translate.JNGDetails.action)}`,
      dataIndex: 'action',
      width: 70,
    },
    {
      title: `${t(translate.JNGDetails.createdBy)}`,
      dataIndex: 'category',
      width: 120,
      render: (category: string) => {
        let value = '';
        if (category) value = category?.replaceAll('_', ' ');
        return <div className="capitalize">{value}</div>;
      },
    },
    {
      title: `${t(translate.JNGDetails.details)}`,
      dataIndex: 'details',
      width: 200,
      render: (details: string) => {
        let value = '';
        if (details) value = details?.replaceAll('_', ' ');
        return <div className="capitalize">{value}</div>;
      },
    },
    {
      title: `${t(translate.JNGDetails.description)}`,
      dataIndex: 'details',
      render: (details: string) => {
        let value = '';
        if (getInterruptTitle(details) !== '')
          value = getDescriptionForInterrupt(details);
        return <div className="capitalize">{value}</div>;
      },
    },
    // {
    //   title: `${t(translate.JNGDetails.type)}`,
    //   dataIndex: 'type',
    // },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getDriveActions();
    }
  }, [userDetails, driveAction]);

  const getDriveActions = async () => {
    try {
      setLoader(true);
      const records = await getDriveActionInteruptData(
        userDetails.organization.api_url,
        driveAction?.customer_id,
        driveAction.drive_action_uuid,
        // pageNumber,
        // pageSize,
      );
      let data = Array.isArray(records) ? records : [];
      setTotal(data.length);
      data = data.map((record: any) => {
        return {
          time: record.created_date_time
            ? getDateTime(record.created_date_time)
            : '-',
          action: record.action,
          details: record.details,
          category: record.category || '-',
          type: record.type || '-',
        };
      });
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      title="JNG Details"
      visible
      onCancel={handleCancel}
      width={900}
      footer={false}
      className="jngDetails"
    >
      <Row>
        <Col span={12}>
          <Item title="Customer" value={driveAction?.customer_name} />
          <Item title="Tractor" value={driveAction?.tractor_name} />
          <Item title="Operator" value={driveAction?.operator_name} />
        </Col>
        <Col span={12}>
          <Item title="Start Time" value={driveAction?.start_time} />
          <Item title="Finish Time" value={driveAction?.end_time} />
        </Col>
      </Row>
      <br />
      <div className="common_table jngTBl">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="driveActionDetailsTable"
          loading={{
            indicator: (
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            ),
            spinning: loader,
          }}
          bordered
          size="middle"
          scroll={{
            x: 600,
            y: 425,
          }}
        />
      </div>
      <div className="reportTotal">
        <b>Total:</b> {total} JNG details
      </div>
    </Modal>
  );
};

interface Props1 {
  title: any;
  value: any;
}
const Item: React.FC<Props1> = ({ title, value }) => (
  <div className="labelBlock">
    <div className="labelItem flex1">
      <b>{title}</b>
    </div>
    <div className="labelItem flex2">{value}</div>
  </div>
);

export default DriveActionDetailsModal;
