import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, DatePicker, Modal, Row, message, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import translate from '../../../../locale/en_translate.json';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import usePaginate from '../../../../hooks/usePaginate';
import { ApplicationContext } from '../../../../context/AppContext';
import { customTimeSorter, initScroller } from '../../../../constants/Common';
import { getOrgTractorsListData, getTractorUsageReport } from '../../API';
import { useTranslation } from 'react-i18next';
import { dateRangeFilters } from '../../../../constants/AppData';
import CropOrgFilter from '../../../common/CropOrgFilter';
import CustomSelect from '../../../common/CustomSelect';
import moment from 'moment';
import { mapTractorUsageData1 } from '../../../../lib/dataFormat';
import useColumns from '../../../../hooks/useColumns';
import copyIcon from '../../../../assets/images/copy_icon.png';
import TableExt from '../../../widget/TableExt';
import './style.css';
import CustomMultiSelectTractor from '../../../common/CustomMultiSelectTractor';
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;

const PAGE_SIZE = 25;
let columnsData: any;
const title = 'TractorUsageReport';

const TractorUsageReport: React.FC = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [summeryData, setSummaryData] = useState<any>(null);
  const [totalCol, setTotalCol] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [visibleData, setVisibleData] = useState<any[]>([]);
  const [filterColumnsData, setFilterColumnsData] = useState<any[]>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [tractorIds, setTractorIds] = useState<string[]>([]);
  const [tractors, setTractors] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const { filterData, setData, activity, checkActivity } = usePaginate();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<string>('Monthly');
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment().endOf('month').format('YYYY-MM-DD'),
    type: filter,
  });
  const [downloadData, setDownloadData] = useState({
    percent: 0,
    status: '',
  });
  const init = useRef(true);

  const { headers, columns, setColumns, setColumnsData } =
    useColumns('TractorUsage1');

  const wrapResult = async (
    orgId: number,
    fromDate: any,
    toDate: any,
    viewReport: string,
    selectedTractorId: any,
    selectedOrgId: any,
  ) => {
    const result = await getTractorUsageReport(
      fromDate,
      toDate,
      viewReport,
      selectedTractorId,
      selectedOrgId,
    );
    return {
      result,
    };
  };

  const handleFilter = (value: string) => {
    if (value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      const from_date = moment(`${filterObj.year}-${filterObj.month}`)
        .startOf('month')
        .format('YYYY-MM-DD');
      const to_date = moment(`${filterObj.year}-${filterObj.month}`)
        .endOf('month')
        .format('YYYY-MM-DD');
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2022'))
    );
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const loadData = async () => {
    const { from_date, to_date, type } = filterObj;
    try {
      if (checkActivity()) {
        setHasMore(true);
        initScroller();
        setPage(0);
      }
      setLoader(true);
      const { result } = await wrapResult(
        userDetails?.organization.id,
        from_date,
        to_date,
        type,
        tractorIds,
        selectedOrgId,
      );

      setTotal(result?.tractor_data?.length);
      const { tractor_data, summary } = result;
      let data = Array.isArray(tractor_data) ? tractor_data : [];

      data = mapTractorUsageData1(data, filter);
      // setData(data);
      setVisibleData(data?.slice(0, PAGE_SIZE));
      setSummaryData(summary);
    } catch (error: any) {
      if (error?.error?.statusCode === 413) {
        info();
      } else {
        notification.error({
          message: error.message,
        });
      }
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadTractors = async () => {
    try {
      const response: { records: any[] } = await getOrgTractorsListData(
        selectedOrgId,
      );
      let data = Array.isArray(response.records) ? response.records : [];
      data = data.map(({ id, name }) =>
        Object.assign({ value: id, title: name }),
      );
      setTractors(data);
      const tractorIds = data.map(({ value }) => String(value));
      setTractorIds([...tractorIds]);
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  useEffect(() => {
    if (selectedOrgId.length) loadTractors();
    else setTractors([]);
  }, [selectedOrgId]);

  const info = () => {
    Modal.info({
      centered: true,
      content: (
        <div>
          <p>Response is too large, Report is being sent to your Email.</p>
        </div>
      ),
    });
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.tractorusagereport.tractor)}`,
        dataIndex: 'tractorname',
        key: 'tractorname',
        sorter: (a: { tractorname: string }, b: { tractorname: string }) =>
          a.tractorname.localeCompare(b.tractorname),
        width: '195px',
        render: (tractorname: string) => (
          <span data-testid={`${tractorname}-TractorUsageReport`}>
            {tractorname}
          </span>
        ),
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend', 'descend'],
      },
      {
        title: `${t(translate.tractorusagereport.tractorPin)}`,
        dataIndex: 'tractor_pin',
        key: 'tractor_pin',
        width: '200px',
        render: (tractor_pin: string) => (
          <span className="emailAlign hoverWrapper">
            <span
              className="tractorPinStyle"
              data-testid={`${tractor_pin}-TractorUsageReport`}
            >
              {tractor_pin}
            </span>
            {tractor_pin !== '-' && (
              <img
                src={copyIcon}
                alt="Edit"
                height="32"
                width="32"
                className="marginLeftIcon hoverShow1 on-hover-hand-change"
                onClick={() => {
                  navigator.clipboard.writeText(tractor_pin);
                  notification.info({ message: 'Copied' });
                }}
              />
            )}
          </span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.customer_name)}`,
        dataIndex: 'customer_name',
        key: 'customer_name',
        ellipsis: true,
        sorter: (a: { customer_name: string }, b: { customer_name: string }) =>
          a.customer_name.localeCompare(b.customer_name),
        width: '190px',
        render: (customer_name: string) => (
          <span data-testid={`${customer_name}-TractorUsageReport`}>
            {customer_name}
          </span>
        ),
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend', 'descend'],
      },
      {
        title: `${t(translate.tractorusagereport.clientNodeName)}`,
        dataIndex: 'clientNodeName',
        key: 'clientNodeName',
        sorter: (
          a: { clientNodeName: string },
          b: { clientNodeName: string },
        ) => a.clientNodeName.localeCompare(b.clientNodeName),
        width: '190px',
        render: (clientNodeName: string) => (
          <span data-testid={`${clientNodeName}-TractorUsageReport`}>
            {clientNodeName}
          </span>
        ),
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend', 'descend'],
      },
      {
        title:
          filter === 'Daily'
            ? `${t(translate.tractorusagereport.date)}`
            : `${t(translate.tractorusagereport.month)}`,
        dataIndex: 'date',
        key: 'date',
        width: '150px',
        render: (Daily: string) => (
          <span data-testid={`${Daily}-TractorUsageReport`}>{Daily}</span>
        ),
      },
      {
        title: `${t(translate.tractorusagereport.kmsdriven)}`,
        dataIndex: 'distance',
        key: 'distance',
        width: '155px',
        render: (distance: string) => (
          <span data-testid={`${distance}-TractorUsageReport`}>{distance}</span>
        ),
        sorter: (a: { distance: number }, b: { distance: number }) =>
          a.distance - b.distance,
      },
      {
        title: `${t(translate.tractorusagereport.energyused)}`,
        dataIndex: 'energy_used',
        key: 'energy_used',
        width: '175px',
        render: (energy_used: string) => (
          <span data-testid={`${energy_used}-TractorUsageReport`}>
            {energy_used}
          </span>
        ),
        sorter: (a: { energy_used: number }, b: { energy_used: number }) =>
          a.energy_used - b.energy_used,
      },
      {
        title: `${t(translate.tractorusagereport.co2saved)}`,
        dataIndex: 'co2_saved',
        key: 'co2_saved',
        width: '155px',
        render: (co2_saved: string) => (
          <span data-testid={`${co2_saved}-TractorUsageReport`}>
            {co2_saved}
          </span>
        ),
        sorter: (a: { co2_saved: number }, b: { co2_saved: number }) =>
          a.co2_saved - b.co2_saved,
      },
      {
        title: `${t(translate.tractorusagereport.humandetections)}`,
        dataIndex: 'human_detections',
        key: 'human_detections',
        width: '175px',
        render: (human_detections: string) => (
          <span data-testid={`${human_detections}-TractorUsageReport`}>
            {human_detections}
          </span>
        ),
        sorter: (
          a: { human_detections: number },
          b: { human_detections: number },
        ) => a.human_detections - b.human_detections,
      },
      {
        title: `${t(translate.tractorusagereport.manualhrs)}`,
        dataIndex: 'manual_hours',
        key: 'manual_hours',
        width: '155px',
        render: (manual_hours: string) => (
          <span data-testid={`${manual_hours}-TractorUsageReport`}>
            {manual_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.manual_hours, b.manual_hours),
      },
      {
        title: `${t(translate.tractorusagereport.shadowHrs)}`,
        dataIndex: 'pm_follow_me_hours',
        key: 'pm_follow_me_hours',
        width: '155px',
        render: (pm_follow_me_hours: string) => (
          <span data-testid={`${pm_follow_me_hours}-TractorUsageReport`}>
            {pm_follow_me_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_follow_me_hours, b.pm_follow_me_hours),
      },
      {
        title: `${t(translate.tractorusagereport.rowFollowHrs)}`,
        dataIndex: 'pm_vinerow_hours',
        key: 'pm_vinerow_hours',
        width: '155px',
        render: (pm_vinerow_hours: string) => (
          <span data-testid={`${pm_vinerow_hours}-TractorUsageReport`}>
            {pm_vinerow_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_vinerow_hours, b.pm_vinerow_hours),
      },
      {
        title: `${t(translate.tractorusagereport.copyCatHrs)}`,
        dataIndex: 'pm_copycat_hours',
        key: 'pm_copycat_hours',
        width: '195px',
        render: (pm_copycat_hours: string) => (
          <span data-testid={`${pm_copycat_hours}-TractorUsageReport`}>
            {pm_copycat_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_copycat_hours, b.pm_copycat_hours),
      },
      {
        title: `${t(translate.tractorusagereport.autoDriveHrs)}`,
        dataIndex: 'pm_remote_av_hours',
        key: 'pm_remote_av_hours',
        render: (pm_remote_av_hours: string) => (
          <span>{pm_remote_av_hours}</span>
        ),
        width: '155px',
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_remote_av_hours, b.pm_remote_av_hours),
      },
      {
        title: `${t(translate.tractorusagereport.autonomyhrs)}`,
        dataIndex: 'autonomy',
        key: 'autonomy',
        width: '155px',
        render: (autonomy: string) => (
          <span data-testid={`${autonomy}-TractorUsageReport`}>{autonomy}</span>
        ),
        sorter: (a: any, b: any) => customTimeSorter(a.autonomy, b.autonomy),
      },
      {
        title: `${t(translate.tractorusagereport.drivingHrs)}`,
        dataIndex: 'pm_driving_hours',
        key: 'pm_driving_hours',
        width: '155px',
        render: (pm_driving_hours: string) => (
          <span data-testid={`${pm_driving_hours}-TractorUsageReport`}>
            {pm_driving_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_driving_hours, b.pm_driving_hours),
      },
      {
        title: `${t(translate.tractorusagereport.idlehrs)}`,
        dataIndex: 'pm_standby_hours',
        key: 'pm_standby_hours',
        width: '155px',
        render: (pm_standby_hours: string) => (
          <span data-testid={`${pm_standby_hours}-TractorUsageReport`}>
            {pm_standby_hours}
          </span>
        ),
        sorter: (a: any, b: any) =>
          customTimeSorter(a.pm_standby_hours, b.pm_standby_hours),
      },
    ];
    const data1 = columnsData.slice(4);
    setFilterColumnsData(data1);
    setColumnsData(columnsData);

    const tcols = columnsData.map((item: any) => item.key);
    setTotalCol(tcols);

    return () => {
      setData([]);
      setVisibleData([]);
      setTotal(0);
      setSummaryData(null);
      setColumns([]);
    };
  }, []);

  const loadSummary = useCallback(
    () => (
      <>
        {!!filterData.length && totalCol && (
          <tr>
            {totalCol.length > 0 && (
              <td colSpan={4} style={{ textAlign: 'center' }}>
                <strong>{t(translate.tractorusagereport.grandTotal)}</strong>
              </td>
            )}
            {totalCol.includes('distance') && (
              <td>{summeryData.total_distance}</td>
            )}
            {totalCol.includes('energy_used') && (
              <td>{summeryData.total_energy_used}</td>
            )}
            {totalCol.includes('co2_saved') && (
              <td>{summeryData.total_co2_saved}</td>
            )}
            {totalCol.includes('human_detections') && (
              <td>{summeryData.total_human_detections}</td>
            )}
            {totalCol.includes('manual_hours') && (
              <td>{summeryData.total_pm_manual_hours}</td>
            )}
            {totalCol.includes('pm_follow_me_hours') && (
              <td>{summeryData.total_pm_follow_me_hours}</td>
            )}
            {totalCol.includes('pm_vinerow_hours') && (
              <td>{summeryData.total_pm_vinerow_hours}</td>
            )}
            {totalCol.includes('pm_copycat_hours') && (
              <td>{summeryData.total_pm_copycat_hours}</td>
            )}
            {totalCol.includes('pm_remote_av_hours') && (
              <td>{summeryData.total_pm_remote_av_hours}</td>
            )}
            {totalCol.includes('autonomy') && (
              <td>{summeryData.total_pm_autonomy_hours}</td>
            )}
            {totalCol.includes('pm_driving_hours') && (
              <td>{summeryData.total_pm_driving_hours}</td>
            )}
            {totalCol.includes('pm_standby_hours') && (
              <td>{summeryData.total_pm_standby_hours}</td>
            )}
          </tr>
        )}
      </>
    ),
    [totalCol, summeryData],
  );

  const handleDownload = async () => {
    try {
      const { from_date, to_date } = filterObj;
      if (downloadData.status === 'start') return;
      const fromDate1 = moment(from_date).format('YYYY-MM-DD');
      const toDate1 = moment(to_date).format('YYYY-MM-DD');

      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const { result } = await wrapResult(
        userDetails?.organization.id,
        fromDate1,
        toDate1,
        filter,
        tractorIds,
        selectedOrgId,
      );
      setTotal(result?.tractor_data?.length);
      const { tractor_data } = result;
      let tdata = Array.isArray(tractor_data) ? tractor_data : [];
      tdata = mapTractorUsageData1(tdata, filter);

      data = [...data, ...tdata];
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: Math.round((data.length / total) * 100),
      });
      // }

      const summary: any = {
        // month: 'Grand Total',
        distance: summeryData?.total_distance || 0,
        energy_used: summeryData?.total_energy_used || 0,
        co2_saved: summeryData?.total_co2_saved || 0,
        human_detections: summeryData?.total_human_detections || 0,
        manual_hours: summeryData?.total_pm_manual_hours || '-',
        pm_follow_me_hours: summeryData?.total_pm_follow_me_hours || 0,
        pm_vinerow_hours: summeryData?.total_pm_vinerow_hours || 0,
        pm_copycat_hours: summeryData?.total_pm_copycat_hours || 0,
        pm_remote_av_hours: summeryData?.total_pm_remote_av_hours || 0,
        autonomy: summeryData?.total_pm_autonomy_hours || '-',
        pm_driving_hours: summeryData?.total_pm_driving_hours || '-',
        pm_standby_hours: summeryData?.total_pm_standby_hours || 0,
      };

      if (filter === 'Daily') summary.date = 'Grand Total';
      else summary.month = 'Grand Total';

      setCSVData([...data, summary]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadData();
  };

  const handleOrg = (ids: any) => {
    setSelectedOrgId(ids);
    setTractors([]);
    setTractorIds([]);
  };

  const loadMoreData = () => {
    const newPage = page + 1;
    const newVisibleData = filterData.slice(0, (newPage + 1) * PAGE_SIZE);
    setVisibleData(newVisibleData);
    setPage(newPage);

    if (newVisibleData.length >= filterData.length) {
      setHasMore(false);
    }
  };

  const handleSubmit = () => {
    activity.current = 'filter';
    if (userDetails?.organization && selectedOrgId.length) {
      loadData();
    } else {
      message.warning('Please select Crop');
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      tractorIds.length > 0 &&
      init.current
    ) {
      loadData();
      init.current = false;
    }
  }, [userDetails, tractorIds]);

  return (
    <Layout className="posRel">
      <Content>
        <div
          className="tblContainer viewportContainer"
          onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}
        >
          <Row>
            <div className="common_wrapper tab2ColumnFilterWidget_wrapper">
              <div className="filters_card ad_filters_card filtersHealthScreen">
                <div
                  onClick={() => goBack()}
                  className="back-button-bdr backtoCardsBtn"
                />
                <CropOrgFilter handleOrgs={handleOrg} isDealer />
                <CustomMultiSelectTractor
                  handleTreeSelect={setTractorIds}
                  selectedId={tractorIds}
                  list={tractors}
                  label="Tractors"
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loader}
                >
                  {t(translate.buttons.submit)}
                </Button>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableName="TractorUsage1"
                  handleCallback={(args: any[]) => {
                    const data = args.map((item) => item.key);
                    setTotalCol(data);
                    setColumns([
                      columnsData[0],
                      columnsData[1],
                      columnsData[2],
                      columnsData[3],
                      columnsData[4],
                      ...args,
                    ]);
                  }}
                  columnsData={filterColumnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>
              <div className="filters_card">
                {' '}
                <CustomSelect
                  label="Filters"
                  cssClass="ad_min_width"
                  value={filter}
                  setValue={handleFilter}
                  options={dateRangeFilters}
                  optionKey="value"
                  optionDisplay="name"
                />
                {filter === 'Monthly' && (
                  <DatePicker
                    allowClear={false}
                    format="MMM-YYYY"
                    value={moment(`${filterObj.year}-${filterObj.month}`)}
                    onChange={onMonthly}
                    disabledDate={disabledDates}
                    picker="month"
                  />
                )}
                {filter === 'Daily' && (
                  <RangePicker
                    allowClear={false}
                    value={[
                      moment(filterObj.from_date),
                      moment(filterObj.to_date),
                    ]}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDates}
                  />
                )}
              </div>
              <div className="common_table fullHeight">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  handleLoadMore={loadMoreData}
                  hasMore={hasMore}
                  filterData={visibleData}
                  totalcount={total}
                  filename={
                    total > 0 ? 'Fleet Usage Reports' : 'Fleet Usage Report'
                  }
                  // onChange={handleChange}
                  summary={loadSummary}
                />
              </div>
            </div>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default TractorUsageReport;
