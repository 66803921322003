import { create } from 'zustand';

interface OperationalHours {
  hours: number;
  minutes: number;
}

interface AnalyticDetails {
  tractorId: string;
  implementName: string;
  operators: any[];
  operationHours: {
    manual: OperationalHours;
    idle: OperationalHours;
    opAssist: OperationalHours;
    autoDrive: OperationalHours;
  };
  totalOperationalHours: OperationalHours;
  efficiency: string;
  averageSpeed: string;
  coverage: string;
  distance: string;
  operationType: string;
}

// define types for state values and actions separately
type State = {
  ticketsData: any[];
  analyticDetails: AnalyticDetails;
  operationalHours: any[];
  selectedTractor: any;
  operations: any[];
  driveActions: any[];
  snapshots: any[];
  guradrails: any[];
  errorCodes: any[];
};

type Actions = {
  addTicketsData: (data: any[]) => void;
  addAnalyticsDetails: (data: any) => void;
  addOperationalHoursData: (data: any[]) => void;
  selectTractor: (tractor: any) => void;
  selectOperations: (operations: any) => void;
  addDriveActions: (driveActions: any[]) => void;
  addSnapshots: (snapshots: any[]) => void;
  addGuardrails: (guradrails: any[]) => void;
  addErrorCodes: (errorCodes: any[]) => void;
  reset: () => void;
};

const opHrs = {
  hours: 0,
  minutes: 0,
};

// define the initial state
const initialState: State = {
  ticketsData: [],
  analyticDetails: {
    tractorId: '',
    implementName: '',
    operators: [],
    operationHours: {
      manual: opHrs,
      idle: opHrs,
      opAssist: opHrs,
      autoDrive: opHrs,
    },
    totalOperationalHours: opHrs,
    efficiency: '',
    averageSpeed: '',
    coverage: '',
    distance: '',
    operationType: '',
  },
  operationalHours: [],
  selectedTractor: null,
  operations: [],
  driveActions: [],
  snapshots: [],
  guradrails: [],
  errorCodes: [],
};

const tractorAnalyticDetailsStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  addTicketsData: (ticketsData) =>
    set((state) => ({
      ticketsData,
    })),
  addAnalyticsDetails: (analyticDetails) =>
    set((state) => ({
      analyticDetails,
    })),
  addOperationalHoursData: (operationalHours) =>
    set((state) => ({
      operationalHours,
    })),
  selectTractor: (selectedTractor) =>
    set((state) => ({
      selectedTractor,
    })),
  selectOperations: (operations) =>
    set((state) => ({
      operations,
    })),
  addDriveActions: (driveActions) =>
    set((state) => ({
      driveActions,
    })),
  addSnapshots: (snapshots) =>
    set((state) => ({
      snapshots,
    })),
  addGuardrails: (guradrails) =>
    set((state) => ({
      guradrails,
    })),
  addErrorCodes: (errorCodes) =>
    set((state) => ({
      errorCodes,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default tractorAnalyticDetailsStore;
