import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
  Tag,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload/interface';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../util/logger';
import closeGreen from '../../assets/images/right_widget_close_icon.svg';
import {
  createdAddticket,
  getOrgEquipments,
  getOrgTags,
  getTasksearch,
  ticketaddattachment,
} from '../../constants/Api';
import { TICKET_TYPES, speCharRegX } from '../../constants/constant';
import { Taskview, Taskviewdetails } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import './TicketsStyle.css';
import CustomSeverityLevel from '../common/CustomSeverityLevel';
import CustomSelect from '../common/CustomSelect';
import CustomTextArea from '../common/CustomTextArea';
import CustomDate from '../common/CustomDate';
import moment from 'moment';
import DiscardChanges from '../Implements/DiscardChanges';

const { CheckableTag } = Tag;
const { Option } = Select;

interface Props {
  tractorId?: any;
  ticketObj?: any;
  snapShotId?: number;
  Close: (action?: string) => void;
  toggleWidget: boolean;
  tractorVisable?: boolean;
  ticketCreationType?: string;
}
const TicketsCreateEdit: React.FC<Props> = ({
  tractorId,
  ticketObj,
  snapShotId,
  Close,
  toggleWidget,
  ticketCreationType = TICKET_TYPES.MAINTENANCE,
}: Props) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState(ticketObj?.description);
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [equipment, setEquipment] = useState<any>();
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [stateVarible, setStateVarible] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [assignid, setAssignid] = useState<any>('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [uuidnum, setuuid] = useState<any>();
  const [tractorName, setTractorName] = useState<any>();
  const [search, setSearch] = useState<string>('');
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(true);
  const [incidentTime, setIncidentTime] = useState<any>(moment());
  const [discard, setDiscard] = useState<boolean>(false);
  const searchString = useRef<string>('');
  const { APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractors } = appState;

  const [validations, setValidations] = useState<any>({
    assignid: true,
    equipment: true,
    incidentTime: true,
    enabled: false,
  });

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) saveTicket();
  }, [validations]);

  const saveTicketData = () => {
    setValidations({
      ...validations,
      assignid: assignid ? true : false,
      equipment: equipment ? true : false,
      incidentTime: incidentTime ? true : false,
      enabled: true,
    });
  };

  const CloseIcon = () => {
    if (
      stateVarible ||
      equipment ||
      fileList.length > 0 ||
      selectedTags.length > 0 ||
      descriptionCount > 0 ||
      !toggleSwitch
    ) {
      setDiscard(true);
    } else {
      setDiscard(false);
      Close();
    }
  };

  const closeModel = () => {
    setDiscard(false);
  };
  const closeDiscard = () => {
    Close();
    setDiscard(false);
  };

  const onChange = (info: any) => {
    const { file, fileList } = info;
    file.status = 'done';
    fileList.map((ele: any, i: any) => {
      ele.index = i;
    });
    setFileList(fileList);
    track('TicketsCreateEdit', {
      event: 'Added Ticket Attachment',
    });
  };

  const loadTags = async () => {
    const resultTags: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    resultTags.map((tag: any) => {
      tag.selected = false;
    });

    setTagsList(resultTags);
  };

  const ticketattachment = async (response: any) => {
    if (fileList.length > 0) {
      setLoader(true);
      const promiseall = fileList.map(async (ele: any, index: any) => {
        const formData = new FormData();
        formData.append('image', ele.originFileObj);

        const data = await ticketaddattachment(
          userDetails.organization.api_url,
          response?.fleet_id,
          response?.id,
          formData,
        );
        return data;
      });
      try {
        await Promise.all(promiseall);
        if (response.msg) {
          Close('create');
        }
        track('TicketsCreateEdit', {
          event: 'Ticket Attachment Added Sucessfully',
        });
        notification.success({
          message: t(translate.label.ticketCreated),
          duration: 2,
        });
      } catch (e: any) {
        track('TicketsCreateEdit', {
          event: 'Ticket Attachment Failed',
        });
        notification.success({
          message: t(translate.label.ticketAttachementFailed),
          duration: 2,
        });
      } finally {
        setLoader(false);
        Close('create');
      }
    } else if (response.msg) {
      Close('create');
    }
  };

  const loadEquipments = async () => {
    setEquipmentsList([]);
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    result = result.map((equip: any, i: any) => {
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });
    setEquipmentsList(result);
  };

  const wrapAssinged = async (search: string) => {
    const data: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      search.length > 2 ? search : '',
    );
    return {
      searchKey: search,
      data,
    };
  };

  const getassigned = async () => {
    const { data, searchKey }: any = await wrapAssinged(search);
    data.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    const { records } = data;
    if (searchKey === searchString.current) {
      setResult(records);
    }
  };

  const saveTicket = async () => {
    const selTags: any[] = [];
    let equipmentType = '';
    let equipment_id: any = 0;

    try {
      setLoader(true);
      setIsDisabled(true);
      tagsList.map((tag: any) => {
        if (tag.selected) {
          selTags.push(tag.name);
        }
      });
      equipmentsList.map((equip: any) => {
        if (equip.uuid == uuidnum) {
          equipmentType = equip.equipment_type;
          equipment_id = equip.equipment_id;
        }
      });
      const obj: any = {
        ticket_description: description ? description.trim() : '',
        ticket_level: stateVarible,
        assignee_id: assignid,
        tags: selTags,
        equipment_id: equipment_id,
        equipment_type: equipmentType.trim(),
        issue_occurred_date_time: incidentTime.valueOf(),
        tractor_operational: toggleSwitch,
      };
      if (equipment_id !== 'NULL' && equipmentType.trim() !== 'BASESTATION') {
        obj.tractor_id = equipment_id;
      }
      if (snapShotId) {
        obj.snapshot_id = snapShotId;
      }
      if (ticketCreationType) {
        obj.ticket_creation_type = ticketCreationType;
      }
      const response: { msg: string } = await createdAddticket(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        obj,
      );
      if (fileList.length > 0) {
        ticketattachment(response);
      } else {
        track('AddedTicket', {
          event: 'Ticket Added Sucessfully',
        });
        notification.success({
          message: t(translate.label.ticketCreated),
          duration: 2,
        });
        Close('create');
      }
      // setResponse(response);
    } catch (err: any) {
      track('AddedTicketFailed', {
        event: 'Ticket Added Failed',
      });
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
      setIsDisabled(false);
    }
  };

  const equipmentSelect = (e: any) => {
    const raw = JSON.parse(e);
    equipmentsList.map((ele: any) => {
      if (ele.uuid === raw.uuid) {
        setuuid(ele.uuid);
        setEquipment(ele.name);
      }
    });

    track('TicketsCreateEdit', {
      event: `Select Equipment ${e}`,
    });
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
    if (checked) {
      track('TicketsCreateEdit', {
        event: 'Select Tags',
      });
    } else {
      track('TicketsCreateEdit', {
        event: 'Remove Tags',
      });
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };
  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    const fileTypeAccepted =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!fileTypeAccepted) {
      notification.error({
        message: `${file.type} is not accepted`,
      });
      return Upload.LIST_IGNORE;
    }
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE || fileTypeAccepted;
  };

  const onSelect = (e: any) => {
    setAssignid(e);
  };

  const selectSeverity = (e: any) => {
    setStateVarible(e);
  };

  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      fileList[leftindex].name ||
        fileList[leftindex].url!.substring(
          fileList[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[leftindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[leftindex].url || (preview as string));
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      fileList[rightindex].name ||
        fileList[rightindex].url!.substring(
          fileList[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[rightindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[rightindex].url || (preview as string));
  };
  const handleCancel = () => setPreviewOpen(false);

  const onSearch = (e: any) => {
    const value = e.trim();
    setSearch(value);
    searchString.current = value;
  };

  useEffect(() => {
    if (search) {
      getassigned();
    }
  }, [search]);

  // useEffect(() => {
  //   if (response) {
  //     ticketattachment();
  //   }
  // }, [response]);

  useEffect(() => {
    if (tractors && tractors.length > 0) {
      const [tractorinfo] = tractors.filter(
        (tractor: any) => tractor.id === tractorId,
      );

      setTractorName(tractorinfo?.name);
    }
  }, [tractors]);

  useEffect(() => {
    if (ticketObj?.id) {
      let tdesc = '';
      if (ticketObj?.error_code) tdesc += `${ticketObj?.error_code} `;
      if (ticketObj?.subsystem) tdesc += `# ${ticketObj?.subsystem} `;
      if (ticketObj?.description) tdesc += `, ${ticketObj?.description}`;
      setDescription(tdesc);
      setStateVarible(ticketObj?.severity);
    }
  }, [ticketObj]);

  useEffect(() => {
    if (
      stateVarible > 0 &&
      description &&
      description.trim().length > 2 &&
      equipment &&
      incidentTime
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [stateVarible, description, equipment, incidentTime]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTags();
      loadEquipments();
      getassigned();
    }
  }, [userDetails]);

  useEffect(() => {
    if (equipmentsList && equipmentsList.length > 0) {
      equipmentsList.map((equip: any, i: any) => {
        if (tractorName === equip.name) {
          setEquipment(equip.name);
          setuuid(i + 1);
        }
      });
    }
  }, [equipmentsList]);

  useEffect(() => {
    if (result && result.length > 0 && ticketObj?.assignee_id) {
      result.map((ele: any, i: any) => {
        if (ele?.id === ticketObj?.assignee_id) {
          setAssignid(ele?.id);
        }
      });
    }
  }, [result, ticketObj?.assignee_id]);

  useEffect(() => {
    if (result.length && userDetails && assignid === '') {
      const [tresult]: any = result.filter(
        (item: any) => item.id === userDetails?.id,
      );
      if (!tresult) {
        if (!ticketObj?.id) setAssignid(userDetails?.id);
        const temp: any[] = [
          ...result,
          {
            id: userDetails.id,
            fullname: userDetails?.first_name + ' ' + userDetails?.last_name,
          },
        ];
        setResult(temp);
      } else {
        setAssignid(tresult.id);
      }
    }
  }, [userDetails, result]);

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
  }, [description]);

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            data-testid="createTicketModelCloseIcon-TicketsCreateEdit"
            id={applicationIds.ticketScreen.ticketCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={CloseIcon}
          />
        </div>

        <div className="profileEdit widget_card">
          <h3
            className="createTcktTitle headline3 rowGapBtm3"
            style={{ textAlign: 'center' }}
            data-testid="createTicketHeader-TicketsCreateEdit"
          >
            {t(translate.tickets.createTicketTitle)}
          </h3>
          <div className="filters_card selectLabel">
            <div className="proRowType">
              <CustomSeverityLevel
                label="Severity Level"
                cssClass="min_width"
                value={stateVarible}
                onSelect={selectSeverity}
                required={true}
                testId="severityLevelDropdown-TicketsCreateEdit"
              />
            </div>
            <div className="proRowType rowGapBtm3">
              <CustomSelect
                label="Assigned To"
                cssClass="min_width"
                value={assignid}
                setValue={onSelect}
                options={result}
                optionKey="id"
                optionDisplay="fullname"
                required={true}
                validations={validations.assignid}
                testId="assignedToDropdown-TicketsCreateEdit"
              />
            </div>
            <div className="proRowType rowGapBtm3">
              <CustomSelect
                label="Equipment"
                cssClass="min_width"
                value={equipment}
                setValue={equipmentSelect}
                options={equipmentsList}
                jsonFormat={true}
                optionDisplay="name"
                required={true}
                validations={validations.equipment}
                testId="equipmentDropdown-TicketsCreateEdit"
              />
            </div>
            <div className="proRowType">
              <CustomDate
                label="Incident Time"
                cssClass="min_width"
                value={incidentTime}
                onChange={(dates: any) => {
                  setIncidentTime(dates);
                }}
                dateTime={true}
                required={true}
                validations={validations.incidentTime}
                showTime={true}
                allowClear={false}
              ></CustomDate>
            </div>

            <div className="rowGapBtm3 mb20 desBorder">
              <CustomTextArea
                label="Description"
                dataTestid="createTicketDescription-TicketsCreateEdit"
                value={description}
                setValue={setDescription}
                required={true}
                description={description}
                descriptionCount={descriptionCount}
                count={1500}
              />
            </div>
          </div>
          <div className="proRowType tagsSec rowGapBtm3">
            <h4 className="headline4 mb15">
              {t(translate.fleetticketsHistory.tags)}
            </h4>
            <div className="tagsBlk" data-testid="selectTag-TicketCreateEdit">
              {tagsList?.map((data: any) => {
                return (
                  <CheckableTag
                    className="checktag body3 fMed"
                    key={data.id}
                    checked={selectedTags.indexOf(data) > -1}
                    onChange={(checked: any) => handleChange(data, checked)}
                    data-testid={`${data.name}-Tag-TicketCreateEdit`}
                  >
                    {data.name}
                  </CheckableTag>
                );
              })}
            </div>
          </div>

          <div className="proRowType tagsSec rowGapBtm3">
            <h4 className="headline4 mb15">
              {t(translate.tickets.tractorOperational)}
            </h4>
            <div className="tagsBlk radioBtn">
              <Radio.Group
                onChange={(e) => setToggleSwitch(e.target.value)}
                defaultValue={toggleSwitch}
                value={toggleSwitch}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="proRowType attachemtBlk rowGapBtm3 ticketsAttachementBlk attachIcons">
            <h4 className="headline4">
              {t(translate.tickets.attachements)} ({fileList.length})
            </h4>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={beforeUpload}
              maxCount={5}
              accept=".jpg,.png"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              openFileDialogOnClick={fileList.length != 5 ? true : false}
            >
              <Button className="uploadBtn" disabled={fileList.length >= 5}>
                <PlusOutlined />
              </Button>
            </Upload>
          </div>
          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
          <div className="create_ticket_btn_sec">
            <Button
              id={applicationIds.ticketScreen.saveTicket}
              onClick={saveTicketData}
              disabled={isDisabled}
              data-testid="createTicketButton-TicketCreateEdit"
            >
              {t(translate.buttons.createTicket)}
            </Button>
          </div>
        </div>

        <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
          className="previewModal"
        >
          <div className="previewmodalBody">
            {fileList.length - 1 !== currentIndex && (
              <ArrowLeftOutlined onClick={leftarrowClick} />
            )}
            {previewImage && (
              <img
                alt="example"
                className="img-responsive"
                src={previewImage}
                width="100%"
              />
            )}
            {currentIndex !== 0 && (
              <ArrowRightOutlined onClick={rightarrowClick} />
            )}
          </div>
        </Modal>
      </div>

      {discard && (
        <DiscardChanges
          showModal={discard}
          closeModel={closeModel}
          closeDiscard={closeDiscard}
        ></DiscardChanges>
      )}
    </Layout>
  );
};

export default TicketsCreateEdit;
