import React, { useContext, useEffect, useRef, useState } from 'react';
import { getTractors } from '../API';
import { ApplicationContext } from '../../../context/AppContext';
import { Input, notification, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';
import useColumns from '../../../hooks/useColumns';
import { initScroller } from '../../../constants/Common';
import constants from '../../../constants/constant';
import { mapNexusTractorsData } from '../../../lib/dataFormat';
import copyIcon from '../../../assets/images/copy_icon.png';
import { SearchOutlined } from '@ant-design/icons';
import TableExt from '../../widget/TableExt';
import EditTractor from './EditTractor';

let columnsData: any[] = [];
const title = 'Tractors';
const { DOWNLOAD_SIZE } = constants;

const DealerTractors: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [loader, setLoader] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [search, setSearch] = useState('');
  const searchString = useRef('');
  const [total, setTotal] = useState(0);
  const init = useRef(false);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('Tractor');
  const [selectedTractor, setSelectedTractor] = useState<any>(null);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
  ) => {
    const result = await getTractors(pageNumber, pageSize, search);
    return {
      result,
      searchKey: search,
    };
  };

  const getTractorsList = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
      );
      if (searchString.current !== searchKey) return;
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      const data = mapNexusTractorsData(records);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = true;
      setLoader(false);
    }
  };

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTractorsList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorsList();
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.tractors.name)}`,
        dataIndex: 'name',
        key: 'name',
        width: '170px',
        render: (tractorName: string) => (
          <span data-testid={`${tractorName}-Tractors`}>{tractorName}</span>
        ),
      },
      // {
      //   title: `${t(translate.tractors.serialNumber)}`,
      //   dataIndex: 'serial_number',
      //   key: 'serial_number',
      //   width: '150px',
      //   render: (serialNumber: string) => (
      //     <span data-testid={`${serialNumber}-Tractors`}>{serialNumber}</span>
      //   ),
      // },
      {
        title: `${t(translate.tractors.tractorPin)}`,
        dataIndex: 'tractor_pin',
        key: 'tractor_pin',
        width: '200px',
        render: (tractor_pin: any) => (
          <span className="emailAlign hoverWrapper">
            <span className="tractorPinStyle">{tractor_pin}</span>
            {tractor_pin !== '-' && (
              <img
                src={copyIcon}
                alt="Edit"
                height="32"
                width="32"
                className="marginLeftIcon hoverShow1 on-hover-hand-change"
                onClick={() => {
                  navigator.clipboard.writeText(tractor_pin);
                  notification.info({ message: 'Copied' });
                }}
              />
            )}
          </span>
        ),
      },
      // {
      //   title: `${t(translate.tractors.radio_id)}`,
      //   dataIndex: 'radio_id',
      //   key: 'radio_id',
      //   width: '120px',
      // },
      // {
      //   title: `${t(translate.tractors.radio_channel_number)}`,
      //   dataIndex: 'radio_channel_number',
      //   key: 'radio_channel_number',
      //   width: '200px',
      // },
      // {
      //   title: `${t(translate.tractors.installation_key)}`,
      //   dataIndex: 'installation_key',
      //   key: 'installation_key',
      //   ellipsis: true,
      //   width: '210px',
      //   render: (installation_key: string) => (
      //     <span className="hoverWrapper installationkey">
      //       <span data-testid={`${installation_key}-InstallationKey`}>
      //         {installation_key}
      //       </span>
      //       <img
      //         src={copyIcon}
      //         alt="Edit"
      //         height="32"
      //         width="32"
      //         className="marginLeftIcon hoverShow1 on-hover-hand-change"
      //         onClick={() => {
      //           navigator.clipboard.writeText(installation_key);
      //           notification.info({ message: 'Copied' });
      //         }}
      //       />
      //     </span>
      //   ),
      // },

      // {
      //   title: `${t(translate.tractors.model)}`,
      //   dataIndex: 'model_name',
      //   key: 'model_name',
      //   width: '90px',
      //   render: (modelName: string) => (
      //     <span data-testid={`${modelName}-Tractors`}>{modelName}</span>
      //   ),
      // },
      {
        title: `${t(translate.tractors.organization)}`,
        dataIndex: 'customer_organization_name',
        key: 'customer_organization_name',
        width: '150px',
        ellipsis: true,
        render: (orgName: string) => (
          <span data-testid={`${orgName}-Tractors`}>{orgName}</span>
        ),
      },

      // {
      //   title: `${t(translate.tractors.dateAdded)}`,
      //   dataIndex: 'startTime',
      //   key: 'startTime',
      //   width: '180px',
      // },
    ];
    setColumnsData(columnsData);
  }, []);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTractors();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(page, DOWNLOAD_SIZE, search);
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusTractorsData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    loadTractors();
  }, [userDetails, pageNumber, pageSize, search]);

  return (
    <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper tabFilterWidget_wrapper">
              <div className="filters_card personnelCard">
                <div>
                  <Input
                    data-testid="searchDirectoryInputField-Employees"
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />
                </div>
                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  placeholder="Search Tractors"
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableRef={tableRef}
                  tableName="Tractor"
                  handleCallback={(args: any[]) => {
                    setColumns(args);
                  }}
                  columnsData={columnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>

              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tractors"
                  // onRowClick={(record) => setSelectedTractor(record)}
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {selectedTractor && (
        <EditTractor
          showModel={!!selectedTractor}
          close={(state) => {
            setSelectedTractor(null);
            if (state) handleRefresh();
          }}
          selectedTractor={selectedTractor}
        />
      )}
    </Layout>
  );
};

export default DealerTractors;
