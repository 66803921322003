import React from 'react';
import { Button, Card, Tag } from 'antd';
import winterImage from './images/winterage.gif';
import xiconClose from '../../../assets/images/X_icon-01.svg';
import './styles.scss';
import { ComplainCode } from '../../../constants/types';

const { Meta } = Card;

interface Props {
  data: ComplainCode | null;
  onClose: (showModal: boolean) => void;
}

const WinterPopUp: React.FC<Props> = ({ data, onClose }) => {
  const viewPdf = () => {
    onClose(true);
  };

  const handleClose = async () => {
    onClose(false);
  };

  return (
    <>
      <Card
        hoverable
        className="ADCCard"
        cover={
          <>
            <img
              src={xiconClose}
              alt=""
              className="ADCCloseIcon"
              onClick={() => handleClose()}
            />
            <img alt="example" src={winterImage} height="127" />
          </>
        }
      >
        <div className="ADCCardContent">
          <div className="an_n_heading">
            <div className="adcContentWrapper">
              <div className="newTagSec">
                <Tag className="newWinterTag">SERVICE TIP</Tag>
              </div>
              <Meta
                className="WinterCardMetaData"
                title="Winter Is Coming"
                description="Are you planning to shut down your fleet for the winter? Follow these instructions to safely store your MK-V, avoid potential service fees, and start the next season on the right footing."
              />
            </div>
            <div className="WinterCardFooter">
              <Button className="WinterFooterBtn" onClick={viewPdf}>
                View Instructions
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default WinterPopUp;
