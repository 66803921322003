import { Avatar } from 'antd';
import React from 'react';
import { wrapDateTime } from '../common';
import error_mlogo from '../images/error_autodrive.svg';
import low from '../images/low.svg';
import medium from '../images/medium.svg';
import { getSeverity } from '../../fleethealth/Diagnostics';

interface Props {
  data: any;
  handleOperationIndClick: (ind: any) => void;
}

const ErrorCode: React.FC<Props> = ({ data, handleOperationIndClick }) => {
  // const getSeverity = (level: number) => {
  //   return (
  //     <>
  //       <img src={level > 0 ? medium : low} />
  //       <img src={level > 1 ? medium : low} />
  //       <img src={level > 2 ? medium : low} />
  //     </>
  //   );
  // };

  return (
    <div
      className="summary-item"
      onClick={() => {
        handleOperationIndClick({});
        setTimeout(() => {
          handleOperationIndClick(data);
        }, 100);
      }}
    >
      <div className="top-section">
        <div className="assigned-to-title">
          <div className="assigned-to">
            <Avatar
              style={{
                color: '#ffffff',
                backgroundColor: '#366376',
                fontSize: '8px',
                fontWeight: 600,
                fontFamily: 'Montserrat',
                lineHeight: '9.75px',
              }}
              size={24}
              src={error_mlogo}
            />
          </div>
          <span className="ticket-number">Error #{data?.error_code}</span>
        </div>
        <div className="severity-flag">
          {getSeverity(data?.indicator_level)}
        </div>
      </div>
      <div className="copy-severity">
        <div className="description-date">
          <div className="description">{data?.indicator_name}</div>
          <span className="reboot-hitch-not-responding verticalEllipsis">
            {data?.description}
          </span>
          <div className="creator-time-193">
            <span className="pm-196">
              {wrapDateTime(data?.created_date_time)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorCode;
