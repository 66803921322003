import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  Row,
  Tag,
  Tooltip,
  message,
  notification,
} from 'antd';
import deleteIcon from '../../../../assets/images/del_icon.png';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../../locale/en_translate.json';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import usePaginate from '../../../../hooks/usePaginate';
import { ApplicationContext } from '../../../../context/AppContext';
import { getDateTime, initScroller } from '../../../../constants/Common';
import { useTranslation } from 'react-i18next';
import '.././style.css';
import CustomSelect from '../../../common/CustomSelect';
import copyIcon from '../../../../assets/images/copy_icon.png';
import { cancelBasestationSurvey, getBasestationSurveyData } from '../../API';
import CropOrgFilter from '../../../common/CropOrgFilter';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import TableExt from '../../../widget/TableExt';
import constants from '../../../../constants/constant';
import useColumns from '../../../../hooks/useColumns';
import { mapNexusBaseStationSurveyData } from '../../../../lib/dataFormat';
import AddButton from '../../../common/AddButton';
import BaseStationAddSurvey from './BaseStationAddSurvey';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const { DOWNLOAD_SIZE } = constants;
const title = 'BaseStationSurvey';
let columnsData: any[] = [];

const BasestationSurvey = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('BaseStationSurvey');
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [addSurvey, setAddSurvey] = useState<boolean>(false);
  const init = useRef(true);
  const [status, setStatus] = useState<string>('');
  const resolveFilterData = [
    { name: `${t(translate.supportTicket.started)}`, value: 'Started' },
    { name: `${t(translate.supportTicket.inProgress)}`, value: 'Inprogress' },
    { name: `${t(translate.supportTicket.completed)}`, value: 'Completed' },
    { name: `${t(translate.supportTicket.failed)}`, value: 'Failed' },
  ];
  const wrapResult = async (
    page_number: number,
    page_size: number,
    searchKey: string,
    customer_org_id: any,
    status?: string,
  ) => {
    const result = await getBasestationSurveyData(
      page_number,
      page_size,
      searchKey,
      customer_org_id,
      status,
    );
    return {
      result,
      searchKey: search,
    };
  };
  const getBasestationSurvuy = async () => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
        selectedOrgId,
        status,
      );

      if (searchString.current !== searchKey) return;
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = data.map((item, index) => Object.assign({ ...item, id: index }));
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = false;
      setLoading(false);
    }
  };

  const loadBasestationSurvuy = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestationSurvuy();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestationSurvuy();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (init.current && selectedOrgId.length > 0) {
      loadBasestationSurvuy();
      init.current = false;
    }
  }, [userDetails, selectedOrgId]);

  useEffect(() => {
    if (!init.current && selectedOrgId.length > 0) {
      loadBasestationSurvuy();
      init.current = false;
    }
  }, [pageNumber, search, selectedOrgId, status]);

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.baseStationSurvey.name)}`,
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => <span>{name}</span>,
      },
      {
        title: `${t(translate.baseStationSurvey.organization)}`,
        dataIndex: 'organization',
        key: 'organization',
        render: (organization: string, item: any) => (
          <span>{item?.organization.name}</span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        render: (serial_number: string) => <span>{serial_number}</span>,
      },

      {
        title: `${t(translate.logViewer.createdBy)}`,
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (createdBy: string, item: any) => (
          <span>
            {' '}
            {item?.user
              ? `${item?.user?.first_name} ${item?.user?.last_name}`
              : '-'}
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.status)}`,
        dataIndex: 'status',
        key: 'status',
        render: (status: string, item: any) => (
          <span>
            {item?.previous_survey?.status ? (
              <div style={{ width: '130px' }}>
                <Tag color={getTagColor(item?.previous_survey)}>
                  {item?.previous_survey?.status}
                </Tag>

                {item?.previous_survey?.status === 'Inprogress' && (
                  <Tooltip title="Cancel Survey">
                    <img
                      src={deleteIcon}
                      alt="Delete"
                      height="24"
                      width="24"
                      className="marginLeftIcon"
                      onClick={() =>
                        CancelSurvey(
                          item.name,
                          item.serial_number,
                          item.previous_survey?.id,
                        )
                      }
                    />
                  </Tooltip>
                )}
              </div>
            ) : (
              '- '
            )}
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.completion)}`,
        dataIndex: 'completion',
        key: 'completion',
        render: (completion: string, item: any) => (
          <span>
            {item.previous_survey?.percentage_of_completion ||
            item.previous_survey?.percentage_of_completion === 0
              ? `${item.previous_survey?.percentage_of_completion} %`
              : '-'}
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.start_time)}`,
        dataIndex: 'start_time',
        key: 'start_time',
        render: (start_time: string, item: any) => (
          <span>
            {item?.previous_survey?.created_date_time
              ? getDateTime(item?.previous_survey?.created_date_time)
              : '-'}
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.hours)}`,
        dataIndex: 'hours',
        key: 'hours',
        render: (hours: string, item: any) => (
          <span>{item?.previous_survey?.duration_in_hours || '-'}</span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.remarks)}`,
        dataIndex: 'remarks',
        key: 'remarks',
        render: (remarks: string, item: any) => (
          <span>{item?.remarks || '-'}</span>
        ),
      },

      {
        title: `${t(translate.baseStationSurvey.latitude)}`,
        dataIndex: 'latitude',
        key: 'latitude',
        render: (latitude: string, item: any) => (
          <span>
            <div className="geoPoint">
              {item?.previous_survey?.status === 'Completed' &&
              item?.previous_survey?.survey_point?.lat ? (
                <p>
                  {item?.previous_survey?.survey_point?.lat}

                  <img
                    alt=""
                    src={copyIcon}
                    onClick={() => {
                      copyToClipBoard(item?.previous_survey?.survey_point?.lat);
                      notification.info({ message: 'Latitude Copied' });
                    }}
                    className="marginLeftIcon"
                  />
                </p>
              ) : (
                '-'
              )}{' '}
            </div>
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.longitude)}`,
        dataIndex: 'longitude',
        key: 'longitude',
        render: (longitude: string, item: any) => (
          <span>
            <div className="geoPoint">
              {item?.previous_survey?.status === 'Completed' &&
              item?.previous_survey?.survey_point?.long ? (
                <p>
                  {item?.previous_survey?.survey_point?.long}

                  <img
                    alt=""
                    src={copyIcon}
                    onClick={() => {
                      copyToClipBoard(
                        item?.previous_survey?.survey_point?.long,
                      );
                      notification.info({ message: 'Longitude Copied' });
                    }}
                    className="marginLeftIcon"
                  />
                </p>
              ) : (
                '-'
              )}
            </div>
          </span>
        ),
      },
      {
        title: `${t(translate.baseStationSurvey.altitude)}`,
        dataIndex: 'altitude',
        key: 'altitude',
        render: (altitude: string, item: any) => (
          <span>
            <div className="geoPoint">
              {item?.previous_survey?.status === 'Completed' &&
              item?.previous_survey?.survey_point?.alt ? (
                <p>
                  {item?.previous_survey?.survey_point?.alt}

                  <img
                    alt=""
                    src={copyIcon}
                    onClick={() => {
                      copyToClipBoard(item?.previous_survey?.survey_point?.alt);
                      notification.info({ message: 'Altitude Copied' });
                    }}
                    className="marginLeftIcon"
                  />
                </p>
              ) : (
                '-'
              )}
            </div>
          </span>
        ),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const getTagColor = (survey: any) => {
    const { status = '' } = survey;
    // eslint-disable-next-line no-nested-ternary
    return status === 'Completed'
      ? 'green'
      : status === 'failed'
      ? 'red'
      : 'blue';
  };

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const cancelBaseStation = async (serial_number: string, id: number) => {
    try {
      const response = await cancelBasestationSurvey(serial_number, id);
      activity.current = 'refresh';
      loadBasestationSurvuy();
      notification.success({ message: response.msg });
    } catch (error: any) {
      notification.error({ message: error?.err });
    }
  };

  const CancelSurvey = async (
    basestationName: string,
    serial_number: string,
    id: number,
  ) => {
    confirm({
      title: `Are you sure you want to cancel ${basestationName} survey?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        cancelBaseStation(serial_number, id);
      },
      okButtonProps: {
        className: 'okBtn',
      },
      cancelButtonProps: {
        className: 'canelBtn',
      },
    });
  };
  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };
  const handleRefresh = () => {
    activity.current = 'refresh';
    loadBasestationSurvuy();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          page,
          DOWNLOAD_SIZE,
          search,
          selectedOrgId,
          status,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusBaseStationSurveyData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleSubmit = () => {
    if (userDetails && selectedOrgId.length) {
      loadBasestationSurvuy();
    } else {
      message.warning('Please select Crop');
    }
  };
  const closeBaseStation = () => {
    setAddSurvey(false);
    handleRefresh();
  };

  return (
    <>
      <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
        <div className="mainContent">
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper tabCardWidget_wrapper customer_wrapper">
                <div className="filters_card personnelCard bssFilters">
                  <div
                    onClick={() => goBack()}
                    className="back-button-bdr backtoCardsBtn"
                  />
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search w160"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />

                  <CropOrgFilter handleOrgs={handleOrg} isDealer />

                  <CustomSelect
                    label="Status"
                    cssClass="min_width"
                    value={status}
                    setValue={(id: any) => {
                      activity.current = 'filter';
                      setStatus(id);
                    }}
                    options={resolveFilterData}
                    optionKey="value"
                    optionDisplay="name"
                    isAll
                  />
                  <Button
                    className="submitBtn"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Submit
                  </Button>

                  <AddButton
                    label="Add"
                    onClick={() => {
                      setAddSurvey(true);
                    }}
                  />
                  <TableExt
                    handleRefresh={handleRefresh}
                    handleDownload={handleDownload}
                    downloadData={downloadData}
                    placeholder="Search"
                    csvHeaders={headers}
                    csvData={csvData}
                    csvFilename={title}
                    tableRef={tableRef}
                    tableName="BaseStationSurvey"
                    handleCallback={(args: any[]) => {
                      setColumns(args);
                    }}
                    columnsData={columnsData}
                    toggleColumnsStatus={toggleColumnsStatus}
                  />
                </div>

                <div className="common_table fullHeight">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loading}
                    handleLoadMore={handleLoadMore}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    filename={
                      total > 0 ? 'BaseStation Surveys' : 'BaseStation Survey'
                    }
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
        {addSurvey && (
          <BaseStationAddSurvey
            showBaseStation={addSurvey}
            closeBaseStation={closeBaseStation}
          />
        )}
      </Layout>
    </>
  );
};

export default BasestationSurvey;
