import { Progress } from 'antd';
import React from 'react';
import { convertMinutesToHoursAndMins } from '../../constants/Common';

interface Props {
  data?: any;
  install?: boolean;
  estTime?: boolean;
}

export const CommonProgressBar: React.FC<Props> = ({
  data,
  install,
  estTime,
}) => {
  return (
    <div className="progressContainer">
      <div className="tractorContainer instTxt">
        {install && <div>Installing</div>}
        <div className="estTime">
          {estTime && (
            <>
              {data?.ota_estimated_time > 0 && (
                <div>
                  Est. {convertMinutesToHoursAndMins(data?.ota_estimated_time)}
                </div>
              )}
              <div>{`${Math.round(data?.otaPercentage)}%`}</div>
            </>
          )}
          {!install && (
            <>
              <div>{`${Math.round(data?.otaDownloadPercentage)}%`}</div>
            </>
          )}
        </div>
      </div>
      {install ? (
        <Progress percent={data?.otaPercentage} />
      ) : (
        <Progress percent={data?.otaDownloadPercentage} />
      )}
    </div>
  );
};
