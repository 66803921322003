import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  getGroupMembers,
  getGroups,
  getMaintainenceTickets,
  getTicketCategory,
  getTicketSummary,
} from '../../API';
import { ApplicationContext } from '../../../../context/AppContext';
import { Button, Input, message, notification, Row, Tag } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import usePaginate from '../../../../hooks/usePaginate';
import { fromToDateWrapper, initScroller } from '../../../../constants/Common';
import constants from '../../../../constants/constant';
import { mapNexusTicketsData } from '../../../../lib/dataFormat';
import { SearchOutlined } from '@ant-design/icons';
import TableExt from '../../../widget/TableExt';
import CropOrgFilter from '../../../common/CropOrgFilter';
import CustomSelect from '../../../common/CustomSelect';
import {
  getColorBasedOnInteraction,
  getColorBasedOnProgressHours,
  getSeverityIcon,
  resolveFilterData,
  stringColumnSort,
  ticketPriority,
} from '../../common/common';
import CustomMultiSelectMode from '../../common/CustomMultiSelectMode';
import MultiSelect from '../../common/MultiSelect';
import moment from 'moment';
import SeverityFilter from '../../common/SeverityFilter';
import totalResolved from '../../../../assets/images/completed_sp_ticket.svg';
import total_unresolved from '../../../../assets/images/open_sp_ticket.svg';
import total_inprogress from '../../../../assets/images/inprogress_sp_ticket.svg';
import useColumns from '../../../../hooks/useColumns';
import CustomDate from '../../../common/CustomDate';
import AddButton from '../../../common/AddButton';
import '.././style.css';
import AddTicket from './AddTicket';
import TicketDetails from './TicketDetails';
import { useHistory } from 'react-router-dom';

let columnsData: any[] = [];
const title = 'Tickets';
const { DOWNLOAD_SIZE } = constants;

const SupportTickets = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [loader, setLoader] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [search, setSearch] = useState('');
  const searchString = useRef('');
  const [total, setTotal] = useState(0);
  const init = useRef(true);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('Tickets');
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [selectseverityIds, setselectseverityIds] = useState<any[]>([]);
  const [status, setStatus] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedUserId, setSelectedUserId] = useState<any>('');
  const [ticketPriorityId, setTicketPriorityId] = useState<any>([]);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [sortColumn] = useState<string>(
    'organization' || 'duration' || 'team' || 'assignee' || 'ticket_priority',
  );
  const [dataSummary, setDataSummary] = useState<any>();
  const [sortOrder] = useState<string>('desc');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showTicketModal, setShowTicketModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    search: string,
    fromDateTime: any,
    toDateTime: any,
    status: any,
    selectseverityIds: any,
    selectedOrdId: any,
    sortColumn: string,
    sortOrder: string,
    selectedGroupId: string[],
    selectedUserId: number,
    ticketPriorityId: number | undefined,
    selectedCategory: string,
  ) => {
    const result = await getMaintainenceTickets(
      pageNumber,
      pageSize,
      search,
      fromDateTime,
      toDateTime,
      status,
      selectseverityIds,
      selectedOrdId,
      sortColumn,
      sortOrder,
      selectedGroupId,
      selectedUserId,
      ticketPriorityId,
      selectedCategory,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getTickets = async () => {
    const fromDateTime = dateFilter
      ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
      : '';
    const toDateTime = dateFilter
      ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
      : '';

    try {
      if (checkActivity()) initScroller();
      setLoader(true);

      const { result, searchKey } = await wrapResult(
        pageNumber,
        pageSize,
        search,
        fromDateTime ? fromDateTime?.getTime() : '',
        toDateTime ? toDateTime?.getTime() : '',
        status,
        selectseverityIds,
        selectedOrg,
        sortColumn,
        sortOrder,
        selectedGroupId,
        selectedUserId,
        ticketPriorityId,
        selectedCategory,
      );
      if (searchString.current !== searchKey) return;
      setTotal(result._metadata?.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapNexusTicketsData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = false;
      setLoader(false);
    }
  };

  const getTicketsCount = async () => {
    try {
      const data = await getTicketSummary(selectedOrg, selectedGroupId);
      setDataSummary(data);
    } catch (error: any) {
      notification.error(error.message);
    }
  };
  useEffect(() => {
    if (selectedOrg.length > 0) {
      getTicketsCount();
    }
  }, [selectedOrg, selectedGroupId]);
  const loadTickets = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTickets();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTickets();
    }
  };

  const getPriorityLabel = (priority: number) => {
    switch (priority) {
      case 0:
        return 'P0';
      case 1:
        return 'P1';
      case 2:
        return 'P2';
      case 3:
        return 'P3';
      default:
        return '-';
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: `${t(translate.serviceticket.ticket)}`,
        dataIndex: 'ticketId',
        key: 'ticketId',
      },
      {
        title: `${t(translate.serviceticket.organization)}`,
        dataIndex: 'organization',
        ellipsis: true,
        key: 'organization',
        render: (organization: string, data: any) => (
          <span
            data-testid={`${organization}-${data.ticket_description}-Tickets`}
          >
            {organization}
          </span>
        ),
      },
      {
        title: `${t(translate.serviceticket.equipment)}`,
        dataIndex: 'equipmentName',
        key: 'equipmentName',
        render: (equipmentName: string, data: any) => (
          <span
            data-testid={`${equipmentName}-${data.ticket_description}-Tickets`}
          >
            {equipmentName}
          </span>
        ),
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'equipmentName'),
      },
      {
        title: `${t(translate.serviceticket.ticketDescription)}`,
        dataIndex: 'ticket_description',
        key: 'ticket_description',
        width: '15%',
        render: (ticket_description: string) => (
          <span data-testid={`${ticket_description}-Tickets`}>
            {ticket_description}
          </span>
        ),
      },
      {
        title: `${t(translate.serviceticket.teamId)}`,
        dataIndex: 'team',
        key: 'team',
      },
      {
        title: `${t(translate.serviceticket.assignTo)}`,

        dataIndex: 'assignee',
        key: 'assignee',
      },
      {
        title: `${t(translate.serviceticket.tags)}`,
        dataIndex: 'tags',
        key: 'tags',
        width: '16%',
        render: (records: Array<string>, data: any) =>
          records?.length > 0
            ? records.map(
                (val: any) =>
                  val && (
                    <Tag
                      color="blue"
                      key={val}
                      style={{ marginTop: 4 }}
                      data-testid={`${val}-${data.ticket_description}-Tickets`}
                    >
                      {val}
                    </Tag>
                  ),
              )
            : '-',
      },
      {
        title: `${t(translate.serviceticket.ticketPriority)}`,

        dataIndex: 'ticket_priority',
        key: 'ticket_priority',
        render: (ticket_priority: number) => (
          <div>{getPriorityLabel(ticket_priority)}</div>
        ),
      },
      {
        title: `${t(translate.serviceticket.severity)}`,
        dataIndex: 'ticket_level',
        key: 'ticket_level',
        render: (level: any, data: any) => (
          <div
            className="description"
            data-testid={`${level}-${data.ticket_description}-Tickets`}
          >
            <img className="mr8" src={getSeverityIcon(level, 1)} alt="sort" />
            <img className="mr8" src={getSeverityIcon(level, 2)} alt="sort" />
            <img className="mr8" src={getSeverityIcon(level, 3)} alt="sort" />
          </div>
        ),
      },
      {
        title: `${t(translate.serviceticket.status)}`,
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: `${t(translate.serviceticket.lastInteractedWith)}`,
        dataIndex: 'lastInteractedWith',
        key: 'lastInteractedWith',
        render: (lastInteractedWith: number, ticketInfo: any) => (
          <Tag
            color={
              ticketInfo.status === 'inprogress'
                ? getColorBasedOnInteraction(ticketInfo)
                : ''
            }
          >
            {lastInteractedWith}
          </Tag>
        ),
      },
      {
        title: `${t(translate.serviceticket.durationHours)}`,

        dataIndex: 'durationHours',
        key: 'durationHours',
        render: (durationHours: number, ticketInfo: any) => (
          <>
            <Tag
              color={
                ticketInfo.status === 'open'
                  ? getColorBasedOnProgressHours(ticketInfo)
                  : ''
              }
            >
              {durationHours}
            </Tag>
          </>
        ),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTickets();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      const fromDateTime = dateFilter
        ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
        : '';
      const toDateTime = dateFilter
        ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
        : '';
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          page,
          DOWNLOAD_SIZE,
          search,
          fromDateTime ? fromDateTime?.getTime() : '',
          toDateTime ? toDateTime?.getTime() : '',
          status,
          selectseverityIds,
          selectedOrg,
          sortColumn,
          sortOrder,
          selectedGroupId,
          selectedUserId,
          ticketPriorityId,
          selectedCategory,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusTicketsData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    if (init.current && selectedOrg.length > 0) {
      loadTickets();
      init.current = false;
    }
  }, [
    userDetails,
    pageNumber,
    search,
    toDateTime,
    status,
    selectseverityIds,
    selectedOrg,
    sortColumn,
    sortOrder,
    selectedUserId,
    ticketPriorityId,
    selectedGroupId,
    selectedCategory,
  ]);

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    } else {
      setToDateTime('');
    }
  }, [dateFilter]);

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
    } else {
      setToDateTime(null);
    }
  };

  const getSupportGroupList = async () => {
    try {
      const groupData = await getGroups(userDetails.organization_id);
      setGroupsList(groupData?.map(({ name, id }: any) => ({ name, id })));
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getCategory = async () => {
    try {
      const groupData = await getTicketCategory('support_ticket_category');

      setCategoryList(groupData);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getAllUsersList = async () => {
    try {
      const records = await getGroupMembers(
        userDetails.organization_id,
        selectedGroupId,
      );

      records &&
        records.length > 0 &&
        records?.map((ele: any) => {
          ele.fullname = `${ele?.first_name}  ${ele?.last_name}`;
          return ele;
        });
      setUsersList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails?.organization) {
      getSupportGroupList();
      getCategory();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails?.organization && selectedGroupId) {
      getAllUsersList();
    }
  }, [userDetails, selectedGroupId]);
  const handleOrg = (ids: any) => setSelectedOrg(ids);

  const handleGroupFilter = (value: string[]) => {
    setPageNumber(1);
    setUsersList([]);
    setSelectedUserId('');
    setSelectedGroupId(value);
  };

  const selectSeverityLevel = async (e: any) => {
    setselectseverityIds(e);
  };

  const selectStatus = (e: any) => {
    setStatus(e);
  };

  const onChangeCategory = (e: any) => {
    setSelectedCategory(e);
  };

  const onClose = (state?: boolean) => {
    if (state) {
      activity.current = 'refresh';
      loadTickets();
    }
    setShowForm(false);
  };

  const handleSubmit = () => {
    if (userDetails && selectedOrg.length) loadTickets();
    else {
      message.warning('Please select Crop');
    }
  };

  const handleRecord = (record: any) => {
    setSelectedRecord(record);
    setShowTicketModal(true);
  };

  return (
    <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
      <div className="mainContent">
        <div className="gridTabsContainer tractorHealthMark">
          <div className={'gridItem'}>
            <img src={total_unresolved} alt="total_unresolved" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">
                {t(translate.serviceticket.open)}
              </div>
              <div className="assignNum">{dataSummary?.open}</div>
            </div>
          </div>
          <div className={'gridItem'}>
            <img src={total_inprogress} alt="assignedToMe" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">
                {t(translate.serviceticket.inProgressSummary)}
              </div>
              <div className="assignNum"> {dataSummary?.inprogress}</div>
            </div>
          </div>
          <div className={'gridItem'}>
            <img src={totalResolved} alt="totalResolved" />
            <div className="widget_content">
              <div className="assignTxt cardTitle">
                {t(translate.serviceticket.completed)}
              </div>
              <div className="assignNum"> {dataSummary?.completed}</div>
            </div>
          </div>
        </div>
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper msupTickets">
              <div className="filters_card personnelCard ad_filters_card filtersHealthScreen">
                <div
                  onClick={() => goBack()}
                  className="back-button-bdr backtoCardsBtn"
                />
                <Input
                  data-testid="searchDirectoryInputField-Employees"
                  addonBefore={<SearchOutlined />}
                  placeholder="Search Directory"
                  className="common_search w160"
                  onChange={(e) => handleSearch(e)}
                  autoComplete="off"
                />

                <CropOrgFilter handleOrgs={handleOrg} isDealer />
                <CustomDate
                  label="Start Date - End Date"
                  cssClass="min_width"
                  value={dateFilter}
                  onChange={(dates: any) => {
                    setDateFilter(dates);
                  }}
                />

                <SeverityFilter
                  onChange={selectSeverityLevel}
                  value={selectseverityIds}
                  label="Select Severity"
                />

                <CustomMultiSelectMode
                  label="Status"
                  testId="statusDropdownField-Tickets"
                  cssClass="min_width"
                  value={status}
                  setValue={(id: any) => {
                    selectStatus(id);
                  }}
                  options={resolveFilterData}
                  optionKey="value"
                  optionDisplay="name"
                />
                <MultiSelect
                  handleSelect={handleGroupFilter}
                  options={groupsList}
                  label="Select Groups"
                  selectedValue={selectedGroupId}
                />
                <CustomSelect
                  label="Category"
                  cssClass="min_width"
                  value={selectedCategory}
                  setValue={(id: any) => {
                    onChangeCategory(id);
                  }}
                  options={categoryList}
                  optionKey="name"
                  optionDisplay="name"
                  isAll
                />
                <CustomSelect
                  label="User"
                  cssClass="min_width"
                  value={selectedUserId}
                  setValue={(id: any) => {
                    setPageNumber(1);
                    setSelectedUserId(id);
                  }}
                  options={usersList}
                  optionKey="id"
                  optionDisplay="fullname"
                  isAll
                />
                <CustomMultiSelectMode
                  label="Ticket Priority"
                  cssClass="min_width"
                  value={ticketPriorityId}
                  setValue={(id: any) => {
                    setTicketPriorityId(id);
                  }}
                  options={ticketPriority}
                  optionKey="value"
                  optionDisplay="name"
                />

                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loader}
                >
                  Submit
                </Button>

                <AddButton
                  label="Add Ticket"
                  onClick={() => {
                    setShowForm(true);
                  }}
                />

                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  placeholder="Search Tickets"
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  sortColumns={['created_date_time', 'name']}
                  tableRef={tableRef}
                  tableName="Tickets"
                  handleCallback={(args: any[]) => {
                    setColumns(args);
                  }}
                  columnsData={columnsData}
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>

              <div className="common_table ticket_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tickets"
                  onRowClick={(record) => handleRecord(record)}
                  xScroll={900}
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {showForm && <AddTicket onClose={onClose} toggleWidget={showForm} />}
      {showTicketModal && (
        <TicketDetails
          toggleWidget={showTicketModal}
          onClose={() => setShowTicketModal(false)}
          ticketId={selectedRecord.id}
        />
      )}
    </Layout>
  );
};

export default SupportTickets;
