/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import neutral_tractor from '../../assets/images/neutral_tractor.svg';
import alert_circle_red from '../../assets/images/update_alert_circle.png';
import check_circle_fill from '../../assets/images/check_circle_fill.svg';
import { Button, Collapse, notification } from 'antd';
import { getSelectedTractors, otaInstall } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getInstallList } from '../../constants/types';

const { Panel } = Collapse;

interface Props {
  closeAndUpdate: () => void;
  tractorIds: any;
  setLoader: (data: boolean) => void;
}
const validationsText = {
  tractorBattery: 'Battery below 20%',
  tractorErrorCode:
    'Tractor has no relevant error codes with a severity level of 2 or 3.',
  tractorMotorOff: 'Motor is On',
  internetConnection:
    'Tractor needs strong internet connection with a bandwidth greater than 10mbps.',
  offline: 'Tractor is offline',
  tractorUse: 'Tractor in use',
  computerOffline: 'Computers offline',
};

export const InstallNowLeft: React.FC<Props> = ({
  closeAndUpdate,
  tractorIds,
  setLoader,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [disable, setDisable] = useState<boolean>(true);
  const [tractorsList, setTractorsList] = useState<getInstallList[]>([]);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [openList, setOpenList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  const validateNwStatus = (tractor: getInstallList) => {
    if (
      !tractor?.ota_download?.ota_download_availability_status &&
      !tractor?.fleet_health.network?.status
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fleetStatus =
      tractorsList &&
      tractorsList?.length > 0 &&
      tractorsList.every(
        (d: getInstallList) =>
          d.fleet_health.autonomy?.status &&
          d.fleet_health.motor?.status &&
          validateNwStatus(d) &&
          d.fleet_health.soc?.status &&
          d.fleet_health.tractor_connection?.status &&
          d.fleet_health.tractor_in_use.status &&
          d.fleet_health.computer_online.status,
      );
    if (selectedIds.length > 0 && fleetStatus) {
      setDisable(false);
    } else setDisable(true);
  }, [selectedIds, tractorsList]);

  useEffect(() => {
    const shouldOpenPanel = tractorsList
      .map((item, index) => ({
        index,
        fleetHealth: item.fleet_health,
      }))
      .filter(({ fleetHealth }) => {
        return Object.values(fleetHealth).some(
          (status) => status.status === false,
        );
      })
      .map(({ index }) => `${index}`);
    setOpenList(shouldOpenPanel);
  }, [tractorsList]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorIds)
      getTractorsList();
  }, [userDetails, tractorIds]);

  const getTractorsList = async () => {
    try {
      setLoader(true);
      const records = await getSelectedTractors(
        userDetails.organization.api_url,
        tractorIds,
      );
      const ids =
        records &&
        records.length > 0 &&
        records.map((d: getInstallList) => d.id);
      setSelectedIds(ids);
      setTractorsList(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const otaInstallUpdate = async () => {
    try {
      setLoading(true);
      setShowComplete(false);
      const payload = {
        tractor_ids: selectedIds,
        organization_id: userDetails.organization.id,
      };
      await otaInstall(userDetails.organization.api_url, payload);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
        setShowComplete(true);

        setTimeout(() => {
          setShowComplete(false);
          notification.success({
            message: 'OTA installation process inprogress',
          });
          closeAndUpdate();
        }, 2000);
      }, 2000);
    }
  };

  const removeSchItems = (id: number) => {
    const updatedSelectedIds = [...selectedIds];
    updatedSelectedIds.splice(updatedSelectedIds.indexOf(id), 1);
    setSelectedIds(updatedSelectedIds);
    const filterList = tractorsList.filter((d: any) => d.id !== id);
    if (filterList.length === 0) {
      closeAndUpdate();
    }
    setTractorsList(filterList);
  };

  const renderValidationText = (item: any, status: string) => {
    return (
      <div key={status} className="panelTxt grayColor">
        <img src={alert_circle_red} alt="alert" className="mr10" />
        {item}
      </div>
    );
  };

  return (
    <div className="schUpdateGrid installGrid">
      <div>
        <div className="tractorContainer borderBtm">
          <div>
            <img src={neutral_tractor} alt="" />
            <span className="otaHead">Selected Tractors</span>
          </div>
          <div className="tractorsLength">{tractorsList?.length}</div>
        </div>
        <div className="swVersionTxt">
          <Collapse
            bordered={false}
            defaultActiveKey={openList}
            activeKey={openList}
            onChange={setOpenList}
          >
            {tractorsList &&
              tractorsList.length > 0 &&
              tractorsList?.map((item: any, i: any) => {
                const hasData =
                  item?.fleet_health &&
                  (item?.fleet_health.tractor_connection.status === false ||
                    item?.fleet_health.tractor_in_use.status === false ||
                    item?.fleet_health.soc.status === false ||
                    item?.fleet_health.network.status === false ||
                    item?.fleet_health.motor.status === false ||
                    item?.fleet_health.autonomy.status === false ||
                    item?.fleet_health?.computer_online.status === false);
                return hasData ? (
                  <Panel
                    header={
                      <div className="serialContainer height0 mb10">
                        <div className="fleetContent">{item?.name} </div>
                        <div className="removeCon">
                          <div
                            className="removeTxt"
                            onClick={() => removeSchItems(item.id)}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    }
                    key={i}
                    showArrow={false}
                  >
                    {item?.fleet_health?.tractor_connection.status === false &&
                      renderValidationText(
                        validationsText.offline,
                        'tractor_connection',
                      )}
                    {item?.fleet_health?.tractor_in_use.status === false &&
                      renderValidationText(
                        validationsText.tractorUse,
                        'tractor_in_use',
                      )}
                    {item?.fleet_health?.autonomy.status === false &&
                      renderValidationText(
                        validationsText.tractorErrorCode,
                        'autonomy',
                      )}
                    {item?.fleet_health?.motor.status === false &&
                      renderValidationText(
                        validationsText.tractorMotorOff,
                        'motor',
                      )}
                    {item?.ota_download?.ota_download_availability_status ===
                      false &&
                      item?.fleet_health?.network.status === false &&
                      renderValidationText(
                        validationsText.internetConnection,
                        'network',
                      )}
                    {item?.fleet_health?.soc.status === false &&
                      renderValidationText(
                        validationsText.tractorBattery,
                        'soc',
                      )}
                    {item?.fleet_health?.computer_online.status === false &&
                      renderValidationText(
                        validationsText.computerOffline,
                        'computer_online',
                      )}
                  </Panel>
                ) : (
                  <div className="serialContainer plr15">
                    <div className="fleetContent">{item?.name} </div>
                    <div className="removeCon mr15">
                      <div
                        className="removeTxt"
                        onClick={() => removeSchItems(item.id)}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
          </Collapse>
        </div>
        <div className="margin16">
          <Button
            className={showComplete ? 'confirmBtn' : 'nextBtn'}
            onClick={otaInstallUpdate}
            disabled={disable}
            loading={loading}
          >
            {showComplete ? (
              <>
                <img src={check_circle_fill} alt="" className="mr4" /> Update
                Started
              </>
            ) : (
              ' Start Update'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
