import React, { useState } from 'react';
import { Button, Card, Tag } from 'antd';

import tractorImage from '../../../assets/images/a_db_img.svg';
import xiconClose from '../../../assets/images/X_icon-01.svg';
import './styles.scss';

const { Meta } = Card;

interface Props {
  onClose: (state?: boolean) => void;
}

const AnalyticCard: React.FC<Props> = ({ onClose }) => {
  return (
    <Card
      hoverable
      className="ADCCard"
      cover={
        <>
          <img
            src={xiconClose}
            alt=""
            className="ADCCloseIcon"
            onClick={() => onClose()}
          />
          <img alt="example" src={tractorImage} height="127" />
        </>
      }
    >
      <div className="ADCCardContent">
        <div className="an_n_heading">
          <div className="adcContentWrapper">
            <div className="newTagSec">
              <Tag className="newTag">NEW</Tag>
            </div>
            <Meta
              className="ADCCardMetaData"
              title="Analytics Dashboard"
              description="Now, you can track weekly and monthly work done by implement types and operators. Review map coverage and tractor usage all in one place."
            />
          </div>
          <div className="ADCCardFooter">
            <div className="ADCCardFooterLeft">
              <Button className="ADCFooterBtn" onClick={() => onClose(true)}>
                Take a Look
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AnalyticCard;
