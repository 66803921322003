/* eslint-disable no-console */
// Import necessary packages
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import './../css/global.scss';
import './../css/styles.scss';
import alert_circle from './../images/alert_circle.svg';
import pauseBtn from './../images/pause.svg';
import playBtn from './../images/playbtn.svg';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

export interface Task {
  id: number;
  title: string;
  startTime: string; // Format: HH:mm (e.g., "09:00")
  endTime: string; // Format: HH:mm (e.g., "17:00")
}

import moment from 'moment';
import { ControlBar, Player } from 'video-react';
import { ApplicationContext } from '../../../context/AppContext';
import { getOperationsWithIds, getOperationVideos } from '../services/api';
import useAnalyticsStore from './../store/operationAnalytics';
import tractorAnalyticDetailsStore from './../store/tractorAnalyticDetails';

import { SelectedChunk } from '../../../lib/types';
import ProgressChart from '../../charts/ProgressChart';
import DynamicCustomBarHandler from '../../common/DynamicCustomBarHandler';
import {
  getTimeFromTimestampAmPm,
  ProgressData,
  selectedPath,
  timeDiffInSec,
} from '../common';
import { selectedCameraMap } from '../constants';
import { cameraName, camerasVideos } from './data';
import OperationTractorDetailsMap from './operationTractorDetailsMap';

let gCurrentTime = 5;
let gCurrentVideoIndex = 0;
let gDriveActionAndTime: selectedPath | undefined = undefined;
let gPlayVideo = false;

interface Props {
  setJngData: (data: any) => void;
  setJngLoader: (loading: boolean) => void;
  setShowGuardrailModel: (state: boolean) => void;
  setShowIndicatorModel: (state: boolean) => void;
  setShowTicketPopup: (state: boolean) => void;
  setToggleTicketWidget: (state: boolean) => void;
  setTicketId: (ticketId: any) => void;
  setGuardrailData: (guardrailData: any) => void;
  setErrorDetails: (errorDetails: any) => void;
  showGuardrailModel: boolean;
  showIndicatorModel: boolean;
  showTicketPopup: boolean;
  toggleTicketWidget: boolean;
  guardrailData: any;
  errorDetails: any;
  ticketId: any;
  indLoader: boolean;
  snapLoader: boolean;
  setSnapLoader: (state: boolean) => void;
  setIndLoader: (state: boolean) => void;
}
const MapAndCamera: React.FC<Props> = ({
  setJngData,
  setJngLoader,
  setShowGuardrailModel,
  setShowIndicatorModel,
  setShowTicketPopup,
  setToggleTicketWidget,
  setTicketId,
  setGuardrailData,
  setErrorDetails,
  showGuardrailModel,
  showIndicatorModel,
  showTicketPopup,
  toggleTicketWidget,
  guardrailData,
  errorDetails,
  ticketId,
  indLoader,
  snapLoader,
  setSnapLoader,
  setIndLoader,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);

  const [selectedCamera, setSelectedCamera] =
    useState<string>(selectedCameraMap);
  const { selectedDate } = useAnalyticsStore();
  const { selectedTractor } = tractorAnalyticDetailsStore();
  const [operations, setOperations] = useState([]);
  const [allVideos, setAllVideos] = useState<any>([]);
  const [cameras, setCameras] = useState<any>({});
  const [operationListLoader, setOperationListLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  // const [snapLoader, setSnapLoader] = useState(false);
  const [pathLoader, setPathLoader] = useState(false);
  // const [indLoader, setIndLoader] = useState(false);
  const [hasZoomed, setHasZoomed] = useState(false);
  const [onPathClick, setOnPathClick] = useState(false);
  const iv = useRef<any>();
  const timerRef = useRef<any>(null);
  const [duration, setDuration] = useState(0);
  const ini = useRef(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [selectedObject, setSelectedObject] = useState<any>();
  const [selectedChunk, setSelectedChunk] = useState<SelectedChunk | null>();
  const [chunks, setChunks] = useState<SelectedChunk[]>([]);
  const [seekTime, setSeekTime] = useState<number>(0);
  const [onClickForMapAndChart, setOnclickForMapAndChart] = useState(false);

  useEffect(() => {
    gCurrentVideoIndex = currentVideoIndex;
  }, [currentVideoIndex]);
  const checkVideos = () => {
    return Object.values(videoRef.current).map((item) => item !== undefined);
  };
  const [driveActionAndTime, setDriveActionAndTime] = useState<selectedPath>();
  const [progressData, setProgressData] = useState<null | ProgressData[]>(null);
  const videoRef = useRef<any>({
    front_left: undefined,
    front_right: undefined,
    FrontCamera: undefined,
    RearCamera: undefined,
    PTOCamera: undefined,
  });
  const errorRef = useRef<any>({
    front_left: undefined,
    front_right: undefined,
    FrontCamera: undefined,
    RearCamera: undefined,
    PTOCamera: undefined,
  });
  const [prevDriveUuid, setPrevDriveUuid] = useState<null | string>(null);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedDate &&
      selectedTractor &&
      selectedTractor.tractorId
    ) {
      getOperationsWithIdsHandler();
      setHasZoomed(false);
    }

    return () => {
      setPrevDriveUuid(null);
      setAllVideos([]);
      setProgressData([]);
    };
  }, [userDetails, selectedDate, selectedTractor]);

  const getOperationsWithIdsHandler = async () => {
    setOperationListLoader(true);
    try {
      const resp = await getOperationsWithIds(
        userDetails.organization.api_url,
        moment(selectedDate).startOf('day').toDate().getTime(),
        moment(selectedDate).endOf('day').toDate().getTime(),
        selectedTractor.implementName,
        selectedTractor.tractorId,
      );
      setOperations(resp);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setOperationListLoader(false);
    }
  };

  const getVideoDetails = async () => {
    try {
      if (driveActionAndTime) {
        const resp = await getOperationVideos(
          userDetails.organization.api_url,
          moment(selectedDate).startOf('day').toDate().getTime(),
          moment(selectedDate).endOf('day').toDate().getTime(),
          selectedTractor.implementName,
          selectedTractor.tractorId,
          driveActionAndTime.drive_action_uuid,
        );
        setAllVideos(resp[driveActionAndTime.drive_action_uuid]);
        loadVideo(
          resp[driveActionAndTime.drive_action_uuid],
          driveActionAndTime.created_date_time,
        );
      }
    } catch (error: any) {
      console.log('error in getting videos', error);
    }
  };

  useEffect(() => {
    gDriveActionAndTime = driveActionAndTime;
    // setCameras({});
    // setSelectedObject({});
    if (driveActionAndTime) {
      setPrevDriveUuid(driveActionAndTime?.drive_action_uuid);
      if (
        prevDriveUuid === null ||
        (prevDriveUuid !== null &&
          prevDriveUuid !== driveActionAndTime?.drive_action_uuid)
      ) {
        getVideoDetails();
      } else {
        loadVideo(allVideos, driveActionAndTime.created_date_time);
      }
    }
  }, [driveActionAndTime]);

  const loadVideo = (item: any, time?: number) => {
    if (!time && item && item.length > 0) {
      if (cameras !== item[0]?.videos) {
        setLoading(true);
        setCameras(item[0]?.videos);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      if (selectedObject !== item[0]) setSelectedObject(item[0]);
    }
    time &&
      item &&
      item.map((driveListItem: any) => {
        if (
          time >= driveListItem.video_start_time &&
          driveListItem.video_finish_time > time
        ) {
          if (cameras !== driveListItem.videos) {
            setLoading(true);
            setCameras(driveListItem.videos);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          if (selectedObject !== driveListItem)
            setSelectedObject(driveListItem);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [onClickForMapAndChart]);

  useEffect(() => {
    if (cameras && Object.keys(cameras).length && gPlayVideo) {
      setPlayVideo(false);
      setTimeout(() => {
        setPlayVideo(true);
      }, 1000);
    }
  }, [cameras]);

  useEffect(() => {
    gPlayVideo = playVideo;
    handlePausePlay(playVideo);
  }, [playVideo]);

  const handlePausePlay = (playVideo: boolean) => {
    Object.values(videoRef.current)
      .filter((item) => item)
      .forEach((item: any) => {
        if (item) {
          !playVideo && item.pause();
          playVideo && item.play();
        }
      });
  };

  useEffect(() => {
    if (videoRef && duration) {
      clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        const currentTime = Object.values(videoRef.current)
          .filter((item) => item)
          .filter((item: any) => {
            const { player } = item.getState();
            return !player.error;
          })
          .map((item: any) => {
            const { player } = item.getState();
            return Math.round(player.currentTime);
          });
        const avg = currentTime.reduce((a, b) => a + b, 0) / currentTime.length;
        gCurrentTime = Math.round(avg);
        if (
          gCurrentTime &&
          gCurrentTime > duration - 5 &&
          gCurrentVideoIndex + 1 < videos.length
        ) {
          setRefresh(!refresh);
          setCurrentVideoIndex(gCurrentVideoIndex + 1);
        }
      }, 5000);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [duration, videoRef]);

  const loadDuration = () => {
    if (checkVideos().includes(true) && ini.current) {
      const durations = Object.values(videoRef.current)
        .filter((item) => item)
        .map((item: any) => {
          const { player } = item.getState();
          return Math.round(player.duration);
        });
      setDuration(Math.max(...durations));
    }
  };

  useEffect(() => {
    loadDuration();
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  useEffect(() => {
    const checkReadyState = () => {
      ini.current = false;
      if (!checkVideos().includes(true)) return;
      const readyStates = Object.keys(videoRef.current)
        .filter((item) => item)
        .every((item: any) => {
          const item1 = videoRef.current[item];
          if (item1?.getState()) {
            const { player } = item1.getState();
            errorRef.current[item] = Boolean(player?.error);
            return player.readyState === 4;
          }
          return false;
        });
      if (readyStates) {
        ini.current = true;
        setRefresh(!refresh);
        clearInterval(iv.current);
      }
    };

    if (Object.keys(cameras).length)
      iv.current = setInterval(() => {
        checkReadyState();
      }, 1000);

    return () => clearInterval(iv.current);
  }, [cameras]);

  const noVideo = (camera: string) => (
    <div className="camera-down">
      {!isLoading && (
        <div className="camLbl camLbl-color">{cameraName[camera]}</div>
      )}

      {isLoading && 'Loading...'}
      {!isLoading && (
        <div className="camera-down-wrapper">
          <div className="nocam-icon">
            <img src={alert_circle} height="18" width="18" />
          </div>
          <div className="cam-down-txt">Camera Down</div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    selectedObject &&
      selectedObject.is_video &&
      selectedObject.video_start_time &&
      gDriveActionAndTime &&
      gDriveActionAndTime.created_date_time &&
      handleSeekPlay(
        timeDiffInSec(
          selectedObject.video_start_time,
          gDriveActionAndTime?.created_date_time,
        ),
      );
  }, [selectedObject]);

  const handleSeekPlay = (seconds: number) => {
    Object.values(videoRef.current)
      .filter((item) => item)
      .forEach((item: any) => {
        if (item) {
          // item.actions.seek(seconds);
        }
      });
  };

  useEffect(() => {
    if (hasZoomed) setPlayVideo(false);
    if (!hasZoomed) {
      setSelectedChunk(null);
      setChunks([]);
    }
  }, [hasZoomed]);

  useEffect(() => {
    setJngLoader(
      operationListLoader || isLoading || snapLoader || pathLoader || indLoader,
    );
  }, [operationListLoader, isLoading, snapLoader, pathLoader, indLoader]);

  return (
    <div className="coverage-map2">
      <div className="map-tag-line ">
        <span>
          {hasZoomed
            ? 'Switch views by selecting different camera angles.'
            : 'Explore drive sessions and videos via the timeline or review incidents directly on the map.'}

          <span className="wloader-small">
            <div
              className={
                operationListLoader ||
                isLoading ||
                snapLoader ||
                pathLoader ||
                indLoader
                  ? 'wave-loader-small'
                  : 'hide'
              }
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </span>
        </span>
        {hasZoomed && !playVideo && <span className="paused-txt">PAUSED</span>}
      </div>
      <div className={`hide-controls ${hasZoomed ? 'grid-container-map' : ''}`}>
        <div
          className={`camera-block ${
            selectedCameraMap === selectedCamera ? 'main-map' : 'item1'
          }`}
          onClick={(e) => {
            setSelectedCamera(selectedCameraMap);
            e.stopPropagation();
          }}
        >
          <OperationTractorDetailsMap
            operations={operations}
            setDriveActionAndTime={setDriveActionAndTime}
            driveActionAndTime={driveActionAndTime as selectedPath}
            selectedCamera={selectedCamera}
            setSnapLoader={setSnapLoader}
            setPathLoader={setPathLoader}
            setIndLoader={setIndLoader}
            setProgressData={setProgressData}
            setJngData={setJngData}
            hasZoomed={hasZoomed}
            setHasZoomed={setHasZoomed}
            onPathClick={onPathClick}
            setOnPathClick={setOnPathClick}
            playVideo={playVideo}
            selectedChunk={selectedChunk as SelectedChunk}
            setSelectedChunk={setSelectedChunk}
            chunks={chunks as SelectedChunk[]}
            setSeekTime={setSeekTime}
            setPlayVideo={setPlayVideo}
            setOnclickForMapAndChart={setOnclickForMapAndChart}
            onClickForMapAndChart={onClickForMapAndChart}
            setShowGuardrailModel={setShowGuardrailModel}
            setShowIndicatorModel={setShowIndicatorModel}
            setShowTicketPopup={setShowTicketPopup}
            setToggleTicketWidget={setToggleTicketWidget}
            setTicketId={setTicketId}
            setGuardrailData={setGuardrailData}
            setErrorDetails={setErrorDetails}
            showGuardrailModel={showGuardrailModel}
            showIndicatorModel={showIndicatorModel}
            showTicketPopup={showTicketPopup}
            toggleTicketWidget={toggleTicketWidget}
            guardrailData={guardrailData}
            errorDetails={errorDetails}
            ticketId={ticketId}
          />
          {hasZoomed && selectedCamera === selectedCameraMap && (
            <span className="timeBottom">
              {driveActionAndTime?.created_date_time &&
              getTimeFromTimestampAmPm(driveActionAndTime?.created_date_time)
                ? getTimeFromTimestampAmPm(
                    driveActionAndTime?.created_date_time,
                  )
                : '00:00'}
            </span>
          )}
        </div>
        {hasZoomed &&
          camerasVideos.map((camera: any, index: number) => (
            <>
              <div
                className={`map-camera-grid camera-block ${
                  camera !== selectedCamera ? 'item' + (index + 2) : 'main-map'
                }`}
                onClick={(e) => {
                  camera !== selectedCamera &&
                    cameras[camera] &&
                    setSelectedCamera(camera);
                  e.stopPropagation();
                }}
              >
                {!isLoading && cameras[camera] ? (
                  <>
                    <div className="camLbl">{cameraName[camera]}</div>
                    <Player
                      // autoPlay
                      playsInline
                      src={cameras[camera] as string}
                      ref={(player: any) => {
                        videoRef.current[camera] = player;
                      }}
                    >
                      <ControlBar disableCompletely={false} />
                    </Player>
                  </>
                ) : (
                  <>{noVideo(camera)}</>
                )}
                {camera === selectedCamera && (
                  <span className="timeBottom">
                    {driveActionAndTime?.created_date_time &&
                    getTimeFromTimestampAmPm(
                      driveActionAndTime?.created_date_time,
                    )
                      ? getTimeFromTimestampAmPm(
                          driveActionAndTime?.created_date_time,
                        )
                      : '00:00'}
                  </span>
                )}
              </div>
            </>
          ))}
      </div>
      <div
        className={
          hasZoomed ? 'jumpandgo-section' : 'jumpandgo-section VideoTimeLine'
        }
      >
        {!hasZoomed && progressData !== null && progressData?.length > 0 && (
          <ProgressChart
            data={progressData}
            chartId="progress-bar"
            selectedDate={selectedDate}
            onClick={setDriveActionAndTime}
            setHasZoomed={setHasZoomed}
            setOnPathClick={setOnPathClick}
            onPathClick={onPathClick}
          />
        )}
        {hasZoomed && (
          <DynamicCustomBarHandler
            setSelectedCamera={setSelectedCamera}
            playVideo={playVideo}
            setPlayVideo={setPlayVideo}
            setHasZoomed={setHasZoomed}
            setDriveActionAndTime={setDriveActionAndTime}
            driveActionAndTime={driveActionAndTime as selectedPath}
            selectedChunk={selectedChunk as SelectedChunk}
            setSelectedChunk={setSelectedChunk}
            chunks={chunks as SelectedChunk[]}
            setChunks={setChunks}
            seekTime={seekTime}
            setSeekTime={setSeekTime}
            setOnclickForMapAndChart={setOnclickForMapAndChart}
            onClickForMapAndChart={onClickForMapAndChart}
          />
        )}
        {/* {hasZoomed && (
          <CustomBarHandler
            setHasZoomed={setHasZoomed}
            setDriveActionAndTime={setDriveActionAndTime}
            driveActionAndTime={driveActionAndTime as selectedPath}
          />
        )} */}
      </div>
      {hasZoomed && (
        <div className="video-play-controls">
          <div className="screen-types"></div>
          <div className="play-controls">
            {/* <img src={back45} /> */}
            {playVideo && (
              <img src={pauseBtn} onClick={() => setPlayVideo(!playVideo)} />
            )}
            {!playVideo && (
              <img src={playBtn} onClick={() => setPlayVideo(!playVideo)} />
            )}

            {/* <img src={fwd45} /> */}
          </div>
          <div className="settings">{/* <img src={setting} /> */}</div>
        </div>
      )}
      {/* {hasZoomed && (
        <div className="time-legend">
          <div className="autonomous">
            {driveActionAndTime?.created_date_time &&
            getTimeFromTimestampAmPm(driveActionAndTime?.created_date_time)
              ? getTimeFromTimestampAmPm(driveActionAndTime?.created_date_time)
              : '00:00'}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default memo(MapAndCamera);
