/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Button, DatePicker, Layout, notification, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import translate from '../../../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';

import constants from '../../../../constants/constant';
import { ApplicationContext } from '../../../../context/AppContext';
import { TractorsList } from '../../../../constants/types';
import usePaginate from '../../../../hooks/usePaginate';
import { dateColumnSort, stringColumnSort } from '../../common/common';
import { getIndicatorImages } from '../../../basestation/Common';
import { initScroller } from '../../../../constants/Common';
import CustomSelect from '../../../common/CustomSelect';
import { Content } from 'antd/lib/layout/layout';
import CropOrgFilter from '../../../common/CropOrgFilter';
import TableExt from '../../../widget/TableExt';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { getFleetIndicatorHistory, getFleetOrgTractorsList } from '../../API';
import { mapNexusFleetIndHistoryData } from '../../../../lib/dataFormat';
import FleetIndicatorDetails from './FleetIndicatorDetails';
import CustomMultiSelectTractor from '../../../common/CustomMultiSelectTractor';
import { useHistory } from 'react-router-dom';

const { DOWNLOAD_SIZE } = constants;
const { RangePicker } = DatePicker;

interface StatusListType {
  name: string;
  value: string;
}

const title = 'FleetIndicatorHistory';
let columns: any = [];

const FleetIndactor: React.FC = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const defaultStartDate = moment().subtract(1, 'days').startOf('day');
  const defaultEndDate = moment().endOf('day');
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [pageSize] = useState<any>(25);
  const [tractorId, setTractorId] = useState<any>();
  const [tractors, setTractorsData] = useState<TractorsList[]>([]);
  const [indicatorKey, setIndicatorKey] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<any>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleColumnsStatus, setToggleColumnsStatus] = useState(false);
  const init = useRef(true);

  const statusList: StatusListType[] = [
    {
      name: `${t(translate.fleetIndicatorHistory.tractorCommunication)}`,
      value: 'Tractor_Communication',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorAutomation)}`,
      value: 'Tractor_Automation',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorDrivability)}`,
      value: 'Tractor_Drivability',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorData)}`,
      value: 'Tractor_Data',
    },
    {
      name: `${t(translate.fleetIndicatorHistory.tractorEnergy)}`,
      value: 'Tractor_Energy',
    },
  ];
  const [showModal, setShowModal] = useState<boolean>(false);
  const [indicatorDetails, setFleetIndicatorDetails] = useState<any>();
  const [headers, setHeaders] = useState<any>([]);
  const [initialize, setInitialize] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);

  const [state] = APPReducer;
  const { organizations } = state;
  const [selectedOrg, setSelectedOrg] = useState<any[]>([]);
  const [selectedOrdId, setSelectedOrdId] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    columns = [
      {
        title: `${t(translate.baseStation.tractorName)}`,
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'name'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.baseStation.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'serial_number'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.baseStation.tractorPin)}`,
        dataIndex: 'tractor_pin',
        key: 'tractor_pin',
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'tractor_pin'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.taskmaster.starttime)}`,
        dataIndex: 'start_time',
        key: 'start_time',
        sorter: (a: any, b: any) => dateColumnSort(a, b, 'start_time'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.tractorDetails.indicatorName)}`,
        dataIndex: 'indicator_name',
        key: 'indicator_name',
        sorter: (a: any, b: any) => stringColumnSort(a, b, 'indicator_name'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.tractorDetails.errorcode)}`,
        dataIndex: 'error_code',
        key: 'error_code',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.tractorDetails.software_version)}`,
        dataIndex: 'software_version',
        key: 'software_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.tractorDetails.firmware_version)}`,
        dataIndex: 'firmware_version',
        key: 'firmware_version',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.myTask.description)}`,
        dataIndex: 'description',
        key: 'description',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (description: string, levelObj: any) => (
          <div className="description">
            <img
              width="25px"
              height="25px"
              src={getIndicatorImages(
                levelObj.indicator_name,
                levelObj.indicator_level,
                true,
              )}
            />
            <Tooltip title={description}>
              <div className="description-text">{description}</div>
            </Tooltip>
          </div>
        ),
      },
      {
        title: `${t(translate.tractorDetails.ResolvedTime)}`,
        dataIndex: 'resolved_date_time',
        key: 'resolved_date_time',
        sorter: (a: any, b: any) => dateColumnSort(a, b, 'resolved_date_time'),
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.MyJumpAndGo.duration)}`,
        dataIndex: 'Duration',
        key: 'Duration',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
    ];

    const header: any = [];
    if (columns) {
      columns.map((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, []);

  const handleOrg = (ids: any) => {
    setSelectedOrdId(ids);
    setTractorsData([]);
    setTractorId([]);
  };

  const statusSelect = (e: string) => {
    setIndicatorKey(e);
  };

  const handleTractor = async (id: any) => {
    activity.current = 'filter';
    setTractorId(id);
  };

  const getRow = (record: any) => {
    setFleetIndicatorDetails(record);
    setShowModal(true);
  };

  const handleRefresh = () => {
    activity.current = 'refresh';
    loadFleetIndicators();
  };

  const wrapResult = async (
    fromdate: string,
    todate: string,
    tractorId: any,
    pageNumber: number,
    pageSize: number,
    indicatorKey: any,
    selectedOrg1: any,
  ) => {
    const result = await getFleetIndicatorHistory(
      fromdate,
      todate,
      tractorId || '',
      pageNumber,
      pageSize,
      indicatorKey || '',
      selectedOrg1,
    );
    return {
      result,
    };
  };

  const getFleetIndicators = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const selectedOrg1 = selectedOrdId?.map((item: any) => item).join(',');
      const fromDate =
        dateFilter?.length > 0
          ? moment(dateFilter[0]).startOf('day').toDate().getTime().toString()
          : '';
      const toDate =
        dateFilter?.length > 0
          ? moment(dateFilter[1]).endOf('day').toDate().getTime().toString()
          : '';

      const { result } = await wrapResult(
        fromDate,
        toDate,
        tractorId || '',
        pageNumber,
        pageSize,
        indicatorKey,
        selectedOrg1,
      );

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];

      data = mapNexusFleetIndHistoryData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      setSubmitLoader(false);
      if (!init.current) init.current = false;
    }
  };

  const loadFleetIndicators = () => {
    if (!userDetails.organization || selectedOrdId.length === 0) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getFleetIndicators();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getFleetIndicators();
    }
  };

  useEffect(() => {
    if (
      !init.current &&
      userDetails &&
      userDetails.organization &&
      selectedOrdId?.length > 0
    ) {
      loadFleetIndicators();
      init.current = false;
    }
  }, [userDetails, selectedOrdId, pageNumber]);

  useEffect(() => {
    if (
      init.current &&
      userDetails &&
      userDetails.organization &&
      selectedOrdId?.length > 0
    ) {
      loadFleetIndicators();
      init.current = false;
    }
  }, [selectedOrdId]);

  useEffect(() => {
    if (selectedOrdId.length) loadTractors();
    else setTractorsData([]);
  }, [selectedOrdId]);

  const loadTractors = async () => {
    try {
      const response: { records: any[] } = await getFleetOrgTractorsList(
        selectedOrdId,
      );
      let data = Array.isArray(response.records) ? response.records : [];
      data = data.map(({ id, name }) =>
        Object.assign({ value: id, title: name }),
      );
      setTractorsData(data);
      const tractorIds = data.map(({ value }) => String(value));
      setTractorId([...tractorIds]);
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      const selectedOrg1 = selectedOrdId?.map((item: any) => item).join(',');
      const fromDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[0]).getTime().toString()
          : '';
      const toDate =
        dateFilter?.length > 0
          ? new Date(dateFilter[1]).getTime().toString()
          : '';
      // eslint-disable-next-line no-restricted-syntax
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        // eslint-disable-next-line no-await-in-loop
        const { result } = await wrapResult(
          fromDate,
          toDate,
          tractorId || '',
          page,
          DOWNLOAD_SIZE,
          indicatorKey,
          selectedOrg1,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusFleetIndHistoryData(tdata);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }
      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    loadFleetIndicators();
  };

  const onDateRangeChange = (dates: any) => {
    setDateFilter(dates);
  };

  const disabledDates = (current: any) =>
    (current && current > moment()) ||
    (current && current <= moment('12-31-2022'));

  return (
    <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper msupTickets">
              <div className="filters_card personnelCard ad_filters_card filtersHealthScreen">
                <div
                  onClick={() => goBack()}
                  className="back-button-bdr backtoCardsBtn"
                />
                <CropOrgFilter handleOrgs={handleOrg} isDealer />
                <CustomMultiSelectTractor
                  handleTreeSelect={handleTractor}
                  selectedId={tractorId}
                  list={tractors}
                  label="Tractors"
                />
                <CustomSelect
                  label="Select Indicator"
                  cssClass="min_width"
                  value={indicatorKey}
                  setValue={(id: any) => {
                    activity.current = 'filter';
                    statusSelect(id);
                  }}
                  options={statusList}
                  optionKey="value"
                  optionDisplay="name"
                  isAll
                />
                <RangePicker
                  allowClear={false}
                  format="MM-DD-YYYY"
                  value={dateFilter}
                  onChange={onDateRangeChange}
                  disabledDate={disabledDates}
                />
                <Button
                  className="submitBtn"
                  onClick={handleSubmit}
                  loading={loader}
                >
                  {t(translate.buttons.submit)}
                </Button>

                <TableExt
                  handleRefresh={handleRefresh}
                  handleDownload={handleDownload}
                  downloadData={downloadData}
                  csvHeaders={headers}
                  csvData={csvData}
                  csvFilename={title}
                  tableName="TractorUsage1"
                  toggleColumnsStatus={toggleColumnsStatus}
                />
              </div>

              <div className="common_table ticket_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename={'Fleet Indicator Historys'}
                  xScroll={200}
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {indicatorDetails && showModal && (
        <FleetIndicatorDetails
          showModal={showModal}
          handleCancel={() => setShowModal(false)}
          indicatorDetails={indicatorDetails}
        />
      )}
    </Layout>
  );
};

export default FleetIndactor;
