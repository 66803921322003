import { Avatar } from 'antd';
import React from 'react';
import { useSnap } from '../../../constants/customHooks';
import { getUserName, wrapDateTime } from '../common';

import snapshot_icon from '../images/snapshot_alt.svg';
import './styles.scss';

interface Props {
  data: any;
  handleOperationSnapClick: (snap: any) => void;
}

const Snapshot: React.FC<Props> = ({ data, handleOperationSnapClick }) => {
  const { transaction_id, tractor_id } = data;
  const [snapshots] = useSnap(transaction_id, String(tractor_id.id));

  const { first_name, last_name } = snapshots?.created_by || {};

  return (
    <div
      className="snapshot-item summary-item"
      onClick={() => {
        handleOperationSnapClick({});
        setTimeout(() => {
          handleOperationSnapClick(data);
        }, 100);
      }}
    >
      <div className="top-section">
        <div className="sshotHBlock">
          <div className="assigned-to-title">
            <div className="assigned-to">
              <Avatar
                style={{
                  color: '#ffffff',
                  backgroundColor: '#366376',
                  fontSize: '8px',
                  fontWeight: 600,
                  fontFamily: 'Montserrat',
                  lineHeight: '9.75px',
                }}
                size={24}
                src={snapshot_icon}
              />
            </div>
            <span className="ticket-number">Snapshot</span>
          </div>
        </div>
        <div className="sshotDTime">
          <span>{getUserName(first_name, last_name)}</span>
          <span>.</span>
          <span>{wrapDateTime(data?.created_date_time)}</span>
        </div>
      </div>
      <div className="snapshotContainer">
        {snapshots.map((snapshot: any) => {
          return (
            <>
              <div className="sshotImgCam">
                <span>
                  <img
                    className="snapshotImg"
                    key={snapshot.camera}
                    src={snapshot.camera_image_url}
                    width={100}
                  />
                </span>
                <span className="sShotCamName">{snapshot.camera_name}</span>
              </div>
            </>
          );
        })}
      </div>
      {/* <div className="description-date">
        <div className="creator-time-193">
          <span className="pm-196">
            {wrapDateTime(data?.created_date_time)}
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default Snapshot;
