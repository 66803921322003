/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import Layout from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import './ImpStyle.css';
import { ApplicationContext } from '../../context/AppContext';
import { Button, Modal, notification } from 'antd';
import hitch_icon2 from '../../assets/images/hitch_implement.svg';
import alert_triangle from '../../assets/images/alert_triangle.svg';
import pto_off_icon from '../../assets/images/pto_icon.svg';
import pto_on_icon from '../../assets/images/pto_on_icon.svg';
import ehitch_icon from '../../assets/images/ehitch_icon.svg';
import ehitch_white_icon from '../../assets/images/ehitch_white_icon.svg';
import hitch_float_mode from '../../assets/images/hitch_float_mode.svg';
import hitch_float_mode_white from '../../assets/images/hitch_float_mode_white.svg';
import dimension_1 from '../../assets/images/dimesion_1.svg';
import dimension_2 from '../../assets/images/dimestion_2.svg';
import info_icon from '../../assets/images/info_icon.svg';
import edit_pencile_outline from '../../assets/images/edit_pencile_outline.svg';
import hitch_implement_normal from '../../assets/images/hitch_implement_normal.svg';
import noimplementSmall from '../../assets/images/no-implement-small.svg';
import impMinImage from '../../assets/images/imp-min-image.svg';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import {
  getCategoryList,
  getManufactureList,
  getManufactureModelsList,
  getptoRpmList,
  imageUploadMethod,
  saveImm,
  SaveImplements,
} from '../../constants/Api';
import CustomInput from '../common/CustomInput';
import CustomSelect from '../common/CustomSelect';
import CustomSelectSearch from '../common/CustomSelectSearch';
import AppLoader from '../common/AppLoader';
import CustomSelect3 from '../common/CustomSelect3';
import { wrapResult } from './util';
import ImageCrop from '../widget/ImageCrop';
import translate from '../../locale/en_translate.json';
import {
  delay,
  randomNumber,
  selectTypeImplement,
  selectTypeImplementUnit,
} from '../../constants/Common';

interface Props {
  globalImplement: boolean;
  closeImplement: () => void;
  globalImplementData: any;
  setAddNewImplement: (data: boolean) => void;
}

const GlobalImplementModal: React.FC<Props> = ({
  globalImplement,
  closeImplement,
  globalImplementData,
  setAddNewImplement,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer, user } = useContext(ApplicationContext);
  const [manufactures, setManufactures] = useState<any[]>([]);
  const [newmanufactur, setnewManufactur] = useState<any>();
  const [selectManufactur, setselectManufactur] = useState<any>('');
  const [models, setModels] = useState<any[]>([]);
  const [newModal, setnewModal] = useState<any>();
  const [selectModel, setselectModel] = useState<any>();
  const [selectModalName, setSelectModalName] = useState<any>('');
  const [implementType, setImpleteType] = useState<any>([]);
  const [selectType, setSelectType] = useState<any>('');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [selectAttachment, setSelectAttachment] = useState<any>();
  const [exclaFlag, setexclaFlag] = useState<boolean>(false);
  const [implementId, setImplementId] = useState<any>();
  const [hight, setHeight] = useState<string>('');
  const [width, setWidth] = useState<string>('');
  const [length, setLength] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [weightFlag, setweightFlag] = useState<boolean>(false);
  const [autodriveFlag, setAutodriveFlag] = useState<boolean>(false);
  const [inptoFlag, setInptoFlag] = useState<boolean>(false);
  const [outptoFlag, setoutptoFlag] = useState<boolean>(false);
  const [inRPM, setInRPM] = useState<any>(540);
  const [outRPM, setOutRPM] = useState<any>(540);
  const [inSpeed, setInSpeed] = useState<any>();
  const [inGear, setInGear] = useState<any>();
  const [inHitchFlag, setInHitchFlag] = useState<boolean>(true);
  const [outHitchFlag, setOutHitchFlag] = useState<boolean>(true);
  const [inHeight, setInHeight] = useState<any>(0);
  const [outHeight, setOutHeight] = useState<any>(0);
  const [in_row_activation_distance, setin_row_activation_distance] =
    useState<any>();
  const [out_row_activation_distance, setout_row_activation_distance] =
    useState<any>();
  const [true_activation_distance, settrue_activation_distance] =
    useState<any>();

  const [validations, setValidations] = useState<any>({
    implementId: true,
    manufactur: true,
    model: true,
    type: true,
    attachment: true,
    enabled: false,
  });
  const [inPosition, setinPosition] = useState<any>('Position');
  const [outPosition, setoutPosition] = useState<any>('Position');
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [Isautonomyflag, setIsautonomyflag] = useState<boolean>(false);
  const [Isautonomimplement, setIsautonomyimplement] = useState<boolean>(false);
  const [tstate] = APPReducer;
  const [loader, setLoader] = useState<boolean>(false);
  const [ingearSpeedData, setInGearSpeedData] = useState<any>();
  const [inRowGearSpeed, setInRowGearSpeed] = useState<number>(0);
  const initSearch = useRef<boolean>(false);
  const searchString = useRef<string>('');
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');
  const [applicationRate, setapplication_rate] = useState<any>(0);
  const [currentCapacity, setcurrent_capacity] = useState<any>(0);
  const [showDimensions, setShowDimensions] = useState<boolean>(true);

  useEffect(() => {
    if (
      implementId &&
      selectManufactur &&
      selectModalName &&
      selectType &&
      selectAttachment &&
      isDuplicate === false
    ) {
      if (selectType === 'Seeder') {
        if (
          (applicationRate && currentCapacity) ||
          (applicationRate === 0 && currentCapacity === 0) ||
          (applicationRate && currentCapacity === 0) ||
          (applicationRate === 0 && currentCapacity)
        ) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
      } else if (selectType) {
        setIsDisable(false);
      }
    } else {
      setIsDisable(true);
    }
  }, [
    implementId,
    selectManufactur,
    selectModalName,
    selectType,
    isDuplicate,
    applicationRate,
    currentCapacity,
    selectAttachment,
  ]);

  useEffect(() => {
    getManufactureListData();
    getImplementType();
    getAttachmeantList();
  }, []);

  useEffect(() => {
    if (inRPM || inRPM === '' || inRPM === 0) {
      getInptoRpmList(inRPM);
    }
  }, [inRPM]);

  useEffect(() => {
    if (selectType) {
      implementType.filter((ele: any) => {
        if (ele.name === selectType) {
          setIsautonomyflag(ele.is_autonomy);
        }
      });
    }
  }, [selectType]);

  const getInptoRpmList = async (value: any) => {
    try {
      setInGearSpeedData([]);
      setInSpeed('');
      setInGear('');
      setInRowGearSpeed(0);
      const result: any = await getptoRpmList(
        userDetails.organization?.api_url,
        value,
      );
      if (result && result.length > 0) {
        const data = result.map((ele: any, i: number) => {
          ele.id = i;
          return ele;
        });
        if (data) {
          setInGearSpeedData(data);
          setInSpeed(data[0]?.speed);
          setInGear(data[0]?.gear);
        }
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (selectManufactur && selectManufactur?.trim() !== '')
      getManufactureModelListData();
  }, [selectManufactur]);

  useEffect(() => {
    if (
      selectManufactur &&
      selectManufactur === 'Monarch Tractor' &&
      selectModalName &&
      selectModalName === 'MK-V Feed Pusher' &&
      selectType &&
      selectType === 'Feed Pusher'
    ) {
      setShowDimensions(false);
    } else {
      setShowDimensions(true);
    }
  }, [selectManufactur, selectModalName, selectType]);

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) saveImplement();
  }, [validations]);

  const getManufactureListData = async () => {
    try {
      const result: any = await getManufactureList(
        userDetails.organization?.api_url,
        userDetails.organization.fleet.id,
      );
      if (newmanufactur && result) {
        const i: any = result[result.length - 1];
        setnewManufactur('');
        setselectManufactur(i.name);
      }
      if (Array.isArray(result))
        setManufactures(
          result
            .filter((a: any) => a.name)
            .sort((a: any, b: any) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
            ),
        );
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getManufactureModelListData = async () => {
    try {
      if (selectManufactur?.trim() !== '') {
        const result: any = await getManufactureModelsList(
          userDetails.organization?.api_url,
          userDetails.organization.fleet.id,
          selectManufactur,
        );
        if (newModal && result) {
          const i: any = result[result.length - 1];
          setnewModal('');
          setSelectModalName(i.model);
          setselectModel(i.config_imm_uuid);
        }
        setModels(result);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  const onSelect = (text: any) => {
    emptydata();
    setselectManufactur(text);
    models.map((ele) => {
      if (ele?.config_imm_uuid === text) {
        setHeight(ele?.height ? ele?.height.toString() : '');
        setWidth(ele?.width ? ele?.width.toString() : '');
        setLength(ele?.length ? ele?.length.toString() : '');
        setWeight(ele?.weight ? ele?.weight.toString() : '');
        setInptoFlag(
          ele?.imp_ctrl_in_row_pto ? ele?.imp_ctrl_in_row_pto : false,
        );
        setoutptoFlag(
          ele?.imp_ctrl_out_of_row_pto ? ele?.imp_ctrl_out_of_row_pto : false,
        );

        setOutRPM(
          ele?.imp_ctrl_out_of_row_pto_rpm
            ? ele?.imp_ctrl_out_of_row_pto_rpm
            : 540,
        );
        setInRPM(
          ele?.imp_ctrl_in_row_pto_rpm ? ele?.imp_ctrl_in_row_pto_rpm : 540,
        );

        setinPosition(
          ele?.imp_ctrl_in_row_3pt_hitch_mode
            ? ele?.imp_ctrl_in_row_3pt_hitch_mode
            : 'Position',
        );
        setoutPosition(
          ele?.imp_ctrl_out_of_row_3pt_hitch_mode
            ? ele?.imp_ctrl_out_of_row_3pt_hitch_mode
            : 'Position',
        );
        setInHeight(
          ele?.imp_ctrl_in_row_3pt_hitch_height
            ? ele?.imp_ctrl_in_row_3pt_hitch_height
            : 0,
        );
        setOutHeight(
          ele?.imp_ctrl_out_of_row_3pt_hitch_height
            ? ele?.imp_ctrl_out_of_row_3pt_hitch_height
            : 0,
        );
        setSelectType(
          ele?.implement_type ? ele?.implement_type.toString() : '',
        );
        setSelectAttachment(ele?.attachment_type);
        setIsautonomyimplement(ele?.is_autonomy_implement);
        setAutodriveFlag(false);
        setInSpeed(ele?.imp_ctrl_in_row_tractor_speed);
        setInGear(ele?.imp_ctrl_in_row_gear);
        const [fSpeed] = ingearSpeedData.filter((item: any) => {
          const { speed, gear } = item;
          const { imp_ctrl_in_row_tractor_speed, imp_ctrl_in_row_gear } = ele;
          return (
            speed === imp_ctrl_in_row_tractor_speed &&
            gear === imp_ctrl_in_row_gear
          );
        });
        setInRowGearSpeed(fSpeed?.id || 0);
      }
    });
  };

  const addItem = () => {
    emptydata();
    saveManufacturer(true);
  };
  const onSelectModal = (text: any) => {
    const raw = JSON.parse(text);
    setSelectModalName(raw.model);
    setselectModel(raw.config_imm_uuid);
    setIsautonomyflag(false);
    models.map((ele) => {
      if (ele?.config_imm_uuid === raw.config_imm_uuid) {
        setHeight(ele?.height ? ele?.height.toString() : '');
        setWidth(ele?.width ? ele?.width.toString() : '');
        setLength(ele?.length ? ele?.length.toString() : '');
        setWeight(ele?.weight ? ele?.weight.toString() : '');

        setInptoFlag(
          ele?.imp_ctrl_in_row_pto ? ele?.imp_ctrl_in_row_pto : false,
        );
        setoutptoFlag(
          ele?.imp_ctrl_out_of_row_pto ? ele?.imp_ctrl_out_of_row_pto : false,
        );
        setOutRPM(
          ele?.imp_ctrl_out_of_row_pto_rpm
            ? ele?.imp_ctrl_out_of_row_pto_rpm
            : 540,
        );
        setInRPM(
          ele?.imp_ctrl_in_row_pto_rpm ? ele?.imp_ctrl_in_row_pto_rpm : 540,
        );

        setinPosition(
          ele?.imp_ctrl_in_row_3pt_hitch_mode
            ? ele?.imp_ctrl_in_row_3pt_hitch_mode
            : 'Position',
        );
        setoutPosition(
          ele?.imp_ctrl_out_of_row_3pt_hitch_mode
            ? ele?.imp_ctrl_out_of_row_3pt_hitch_mode
            : 'Position',
        );
        setInHeight(
          ele?.imp_ctrl_in_row_3pt_hitch_height
            ? ele?.imp_ctrl_in_row_3pt_hitch_height
            : 0,
        );
        setOutHeight(
          ele?.imp_ctrl_out_of_row_3pt_hitch_height
            ? ele?.imp_ctrl_out_of_row_3pt_hitch_height
            : 0,
        );
        setSelectType(
          ele?.implement_type ? ele?.implement_type.toString() : '',
        );
        setSelectAttachment(ele?.attachment_type);
        setIsautonomyimplement(true);
        setAutodriveFlag(false);
        setInSpeed(ele?.imp_ctrl_in_row_tractor_speed);
        setInGear(ele?.imp_ctrl_in_row_gear);
        const [fSpeed] = ingearSpeedData.filter((item: any) => {
          const { speed, gear } = item;
          const { imp_ctrl_in_row_tractor_speed, imp_ctrl_in_row_gear } = ele;
          return (
            speed === imp_ctrl_in_row_tractor_speed &&
            gear === imp_ctrl_in_row_gear
          );
        });
        setInRowGearSpeed(fSpeed?.id || 0);
      }
    });
  };
  const addItemModal = () => {
    saveManufacturer();
  };

  const emptydata = () => {
    models.length = 0;
    setModels([]);
    setselectModel('');
    setSelectModalName('');
    setnewModal('');
    setHeight('');
    setWidth('');
    setLength('');
    setWeight('');
    setSelectType('');
    setIsautonomyimplement(false);
    setAutodriveFlag(false);
    setInptoFlag(false);
    setoutptoFlag(false);
    setOutRPM(540);
    setInRPM(540);
    setinPosition('Position');
    setoutPosition('Position');
    setInHeight(0);
    setOutHeight(0);
  };

  const getImplementType = async () => {
    try {
      const cropsdata = await getCategoryList(
        userDetails.organization?.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const getAttachmeantList = async () => {
    try {
      const attachmeant = await getCategoryList(
        userDetails.organization?.api_url,
        'attachment_type',
      );
      setAttachments(attachmeant);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const saveImplement = async () => {
    const data: any = {};
    if (inptoFlag && outptoFlag) {
      if (
        inSpeed &&
        inGear &&
        inPosition &&
        outPosition &&
        inHeight >= 0 &&
        outHeight >= 0
      ) {
        data.is_autonomy_full_filled = true;
      } else {
        data.is_autonomy_full_filled = false;
      }
    } else if (inptoFlag && !outptoFlag) {
      if (inSpeed && inGear && inPosition && inHeight >= 0) {
        data.is_autonomy_full_filled = true;
      } else {
        data.is_autonomy_full_filled = false;
      }
    } else if (!inptoFlag && outptoFlag) {
      if (outPosition && outHeight >= 0) {
        data.is_autonomy_full_filled = true;
      } else {
        data.is_autonomy_full_filled = false;
      }
    } else if (!inptoFlag && !outptoFlag) {
      data.is_autonomy_full_filled = true;
    }
    (data.config_imm_uuid = selectModel),
      (data.name = implementId.trim()),
      (data.manufacturer = selectManufactur),
      (data.model = selectModalName),
      (data.implement_type = selectType),
      (data.attachment_type = selectAttachment),
      (data.width = width),
      (data.length = length),
      (data.height = hight),
      (data.weight = weight);
    (data.imp_ctrl_in_row_pto = inptoFlag),
      (data.imp_ctrl_in_row_pto_rpm = inRPM),
      (data.imp_ctrl_in_row_tractor_speed = inSpeed),
      (data.imp_ctrl_in_row_gear = inGear);
    (data.imp_ctrl_out_of_row_pto = outptoFlag),
      (data.imp_ctrl_out_of_row_pto_rpm = outRPM);
    data.imp_ctrl_out_of_row_tractor_speed = inSpeed;
    data.imp_ctrl_out_row_gear = inGear;
    data.imp_ctrl_in_row_3pt_hitch_height = inHeight;
    data.imp_ctrl_in_row_3pt_hitch_mode = inPosition;
    data.imp_ctrl_out_of_row_3pt_hitch_mode = outPosition;
    data.imp_ctrl_out_of_row_3pt_hitch_height = outHeight;
    data.is_autonomy_implement = Isautonomyflag;
    data.application_rate = applicationRate || '';
    data.current_capacity = currentCapacity || '';
    (data.in_row_activation_distance = in_row_activation_distance || ''),
      (data.out_row_activation_distance = out_row_activation_distance || ''),
      (data.true_activation_distance = true_activation_distance || '');
    try {
      setLoader(true);
      const response = await SaveImplements(
        userDetails.organization?.api_url,
        userDetails.organization.fleet.id,
        data,
      );
      if (response && fileUrlData) {
        handleUpload(response);
      } else {
        closeImplement();
        setAddNewImplement(false);
      }
      notification.success({
        message: `Implement Added`,
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleUpload = async (response: any) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('image', fileUrlData);
      formData.append('category_id', response?.id);
      formData.append('category_name', 'IMPLEMENTS');
      await imageUploadMethod(
        userDetails.organization?.api_url,
        userDetails.organization.id,
        formData,
      );
      closeImplement();
      // setRefresh();
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setLoader(false);
    }
  };

  const saveManufacturer = async (isManufacturer = false) => {
    const data = {
      manufacturer: isManufacturer ? newmanufactur : selectManufactur,
      model: newModal ? newModal : '',
      classification_id: Math.floor(100000 + randomNumber() * 900000),
    };
    try {
      setLoader(true);
      const response = await saveImm(
        userDetails.organization?.api_url,
        userDetails.organization.fleet.id,
        data,
      );
      if (response) {
        notification.success({
          message: response.msg,
        });
        await delay(300);
        if (isManufacturer) getManufactureListData();
        getManufactureModelListData();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const onSelectType = (text: any) => {
    const raw = JSON.parse(text);
    setSelectType(raw?.name);
    setIsautonomyflag(raw?.is_autonomy);
    setapplication_rate(0);
    setcurrent_capacity(0);
    getEmpty();
  };

  useEffect(() => {
    if (selectAttachment) selectWeight(weight);
  }, [selectAttachment]);

  const selectWeight = (value: any) => {
    if (selectAttachment === undefined) {
      setweightFlag(false);
      if (Number(value)) {
        setweightFlag(true);
      }
    }
    if (selectAttachment === 'Drawbar') {
      setweightFlag(false);
      if (Number(value) > 5500) {
        setweightFlag(true);
      } else {
        setweightFlag(false);
      }
    }
    if (selectAttachment === '3 PT Hitch') {
      setweightFlag(false);
      if (Number(value) > 1650) {
        setweightFlag(true);
      } else {
        setweightFlag(false);
      }
    }
    if (value === '') {
      setweightFlag(true);
      setAutodriveFlag(false);
    }
    setWeight(value);
  };

  const onSelectAttachment = (text: any) => {
    setSelectAttachment(text);
  };
  const OnInSpeed = (text: any) => {
    setInSpeed(text?.speed);
    setInGear(text?.gear);
    setInRowGearSpeed(text?.id);
  };

  const saveImmData = () => {
    setValidations({
      ...validations,
      implementId:
        implementId && implementId.trim() !== '' && !isDuplicate ? true : false,
      manufactur: selectManufactur ? true : false,
      model: selectModalName ? true : false,
      type: selectType ? true : false,
      attachment: selectAttachment ? true : false,
      enabled: true,
    });
  };

  const getEmpty = () => {
    setInHeight('');
    setOutHeight('');
    setoutPosition('Position');
    setinPosition('Position');
    setOutRPM(540);
    setInRPM(540);
    setoutptoFlag(false);
    setInptoFlag(false);
    setInHitchFlag(true);
    setOutHitchFlag(true);
  };

  const getunavalible = useMemo(() => {
    const data = [String(width), String(hight), String(weight), String(length)];

    const status = data.filter((ele: any) => {
      return (
        typeof ele === undefined ||
        typeof ele === null ||
        (typeof ele === 'string' && ele.trim() === '') ||
        (typeof ele === 'number' && ele === 0)
      );
    });

    if ((Isautonomyflag || Isautonomimplement) && !status) {
      getEmpty();
      return true;
    } else {
      return false;
    }
  }, [Isautonomyflag, width, hight, weight, length, Isautonomimplement]);

  const init = async () => {
    let state = true;
    try {
      const { result, searchKey } = await wrapResult(
        userDetails.organization?.api_url,
        userDetails.organization.fleet.id,
        implementId,
      );
      if (!initSearch.current && implementId?.trim() !== '')
        initSearch.current = true;
      if (searchString.current !== searchKey) return;
      const { records } = result;
      const frecords = records.filter(
        (item: any) => item.name === searchKey?.trim(),
      );
      state = frecords.length ? true : false;
    } catch (error) {
      state = false;
    }
    setIsDuplicate(state);
  };

  useEffect(() => {
    if (globalImplementData) {
      console.log(globalImplementData, 'globalImplementData');
      setImplementId(globalImplementData?.name);
      setselectManufactur(globalImplementData?.manufacturer);
      setselectModel(globalImplementData?.config_imm_uuid);
      setSelectModalName(globalImplementData?.model);
      setSelectType(globalImplementData?.implement_type);
      setSelectAttachment(globalImplementData?.attachment_type);
      setWeight(globalImplementData?.weight);
      setHeight(globalImplementData?.height);
      setLength(globalImplementData?.length);
      setWidth(globalImplementData?.width);
      setapplication_rate(globalImplementData?.application_rate);
      setcurrent_capacity(globalImplementData?.current_capacity);
      setIsautonomyflag(globalImplementData?.is_autonomy_implement);
      setFileUrl(globalImplementData?.image_url);
      setIsautonomyimplement(false);
    }
  }, [globalImplementData]);

  return (
    <Modal
      visible={globalImplement}
      onCancel={closeImplement}
      closable={false}
      bodyStyle={{ height: 'auto' }}
      className="createImpModal ad-settings"
      footer={[
        <Button key="back" onClick={closeImplement} className="impCancelBtn">
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          onClick={saveImmData}
          className="addImplBtn"
          disabled={isDisable}
        >
          {t(translate.headers.addimplements)}
        </Button>,
      ]}
    >
      <Layout>
        <div className="impWidget addImplementWidget widget_card newImpContainer">
          <div className="userProfileIcon mar0">
            {fileUrl ? (
              <img src={fileUrl} alt="" className="userProfileImg" />
            ) : (
              <>
                <div className="userProfileIconNormal">
                  <img src={hitch_implement_normal} alt="" />
                </div>
                <div className="userProfileIconHover">
                  <img src={hitch_icon2} alt="" />
                </div>
              </>
            )}
            <div className="editProfileIcon editImplementIcon">
              <ImageCrop
                setFileUrl={setFileUrl}
                setfileUrlData={setfileUrlData}
                icon={edit_pencile_outline}
              ></ImageCrop>
            </div>
          </div>
          <div className="impFileds">
            <div className="impFileds-wrapper">
              <div className="w304">
                <CustomInput
                  onBlur={init}
                  label="Implement ID"
                  testId="inputImplementId-AddImplement"
                  value={implementId}
                  cssClass="width100per"
                  setValue={(value) => {
                    searchString.current = value;
                    setImplementId(value);
                  }}
                  required={true}
                  validations={validations.implementId && !isDuplicate}
                  maxLength={30}
                  type="AlphaNum"
                />
                {isDuplicate && (
                  <label className="error-text">
                    {t(translate.errorMessages.idExist)}
                  </label>
                )}
              </div>
              <div className="flex4Colum">
                <CustomSelectSearch
                  label="Manufacturer"
                  cssClass="min_width"
                  value={selectManufactur}
                  options={manufactures}
                  optionKey="name"
                  required={true}
                  searchmethod={setnewManufactur}
                  onSelect={onSelect}
                  searchName={newmanufactur}
                  additem={addItem}
                  validations={validations.manufactur}
                  optionDisplay="name"
                  testId="selectManufacturerModel-AddImplement"
                />

                <CustomSelectSearch
                  label="Model"
                  cssClass="min_width"
                  value={selectModalName}
                  options={models}
                  testId="selectModel-AddImplement"
                  optionKey="config_imm_uuid"
                  required={true}
                  searchmethod={setnewModal}
                  onSelect={onSelectModal}
                  searchName={newModal}
                  additem={addItemModal}
                  validations={validations.model}
                  optionDisplay="model"
                  jsonFormat={true}
                ></CustomSelectSearch>
                <CustomSelect
                  label="Type"
                  cssClass="min_width"
                  value={selectType}
                  testId="selectTypeModel-AddImplement"
                  setValue={onSelectType}
                  options={implementType}
                  optionKey="name"
                  required={true}
                  validations={validations.type}
                  jsonFormat={true}
                ></CustomSelect>

                <CustomSelect
                  label="Attachment"
                  cssClass="min_width"
                  value={selectAttachment}
                  testId="selectAttachmentModel-AddImplement"
                  setValue={onSelectAttachment}
                  options={attachments}
                  optionKey="name"
                  required={true}
                  validations={validations.attachment}
                ></CustomSelect>
              </div>
              {showDimensions && (
                <>
                  <div className="displayFlex flex-direction">
                    <div className="implementSideHead">
                      <p className="overline2 mb12">
                        {t(translate.addImplement.dimensions)}{' '}
                        <span>({t(translate.addImplement.optional)})</span>
                      </p>
                      <span
                        className="exclamationIcon"
                        onClick={() => setexclaFlag(!exclaFlag)}
                      >
                        <img src={info_icon} alt="" />
                      </span>
                      {exclaFlag && (
                        <div className="dimesionsPopup">
                          <h4 className="headline4">
                            {t(translate.addImplement.implementMeasure)}
                          </h4>

                          <div className="dimensionFlex w100Per">
                            <div className="w50Per">
                              <h6 className="Button2">
                                {' '}
                                {t(translate.addImplement.width)}
                              </h6>
                              <p className="body4 measureColor">
                                {t(translate.addImplement.widthDesc)}{' '}
                              </p>
                            </div>
                            <div className="w50Per">
                              <h6 className="Button2">
                                {' '}
                                {t(translate.addImplement.weight)}
                              </h6>
                              <p className="body4 measureColor">
                                {t(translate.addImplement.weightDesc)}
                              </p>
                            </div>
                          </div>
                          <div className="dimensionFlex w100Per mt10">
                            <div className="w50Per">
                              <h6 className="Button2">
                                {t(translate.addImplement.length)}
                              </h6>
                              <div className="flexCenter">
                                <img
                                  src={dimension_2}
                                  alt=""
                                  className="mr15"
                                />
                                <p className="body4 measureColor">
                                  {t(translate.addImplement.lengthDesc)}
                                </p>
                              </div>
                            </div>
                            <div className="w50Per">
                              <h6 className="Button2">
                                {t(translate.addImplement.height)}
                              </h6>
                              <div className="flexCenter">
                                <img
                                  src={dimension_1}
                                  alt=""
                                  className="mr15"
                                />
                                <p className="body4 measureColor">
                                  {t(translate.addImplement.heightDesc)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <>
                      <div className="flex4Colum">
                        <div className="unitsHidden">
                          <CustomInput
                            label="Width"
                            maxLength={10}
                            value={width}
                            testId="widthInputField-AddImplement"
                            setValue={setWidth}
                            showLabel="IN"
                            type="Number"
                          />
                        </div>

                        <CustomInput
                          label={selectTypeImplement(selectType)}
                          maxLength={10}
                          value={weight}
                          testId="weightInputField-AddImplement"
                          cssClass="width100per"
                          setValue={selectWeight}
                          showLabel={selectTypeImplementUnit(selectType)}
                          type="Number"
                        />
                        <div className="unitsHidden">
                          <CustomInput
                            label="Length"
                            maxLength={10}
                            value={length}
                            testId="lengthtInputField-AddImplement"
                            setValue={setLength}
                            showLabel="IN"
                            type="Number"
                          />
                        </div>
                        <div className="unitsHidden">
                          <CustomInput
                            label="Height"
                            maxLength={10}
                            value={hight}
                            testId="heightInputField-AddImplement"
                            setValue={setHeight}
                            showLabel="IN"
                            type="Number"
                          />
                        </div>
                      </div>
                      {selectAttachment === undefined && (
                        <p className="weightAlertMsg overline3">
                          <img src={alert_triangle} alt="" />{' '}
                          <span>
                            {t(translate.errorMessages.NoAttatchment)}
                          </span>
                        </p>
                      )}

                      {selectAttachment &&
                        selectAttachment === 'Drawbar' &&
                        weightFlag && (
                          <p className="weightAlertMsg overline3">
                            <img src={alert_triangle} alt="" />{' '}
                            <span>
                              {t(translate.errorMessages.drawbarAttatchment)}
                            </span>
                          </p>
                        )}
                      {selectAttachment &&
                        selectAttachment === '3 PT Hitch' &&
                        weightFlag && (
                          <p className="weightAlertMsg overline3">
                            <img src={alert_triangle} alt="" />{' '}
                            <span>
                              {t(translate.errorMessages.hitchAttatchment)}
                            </span>
                          </p>
                        )}
                    </>
                  </div>
                </>
              )}

              {tstate?.showAutoDriveConnect &&
                (Isautonomyflag || Isautonomimplement) &&
                weight &&
                width &&
                length &&
                hight && (
                  <div className="autodriveSettings addImpSpl">
                    <div className="autoDrive-setting-new">
                      <div>
                        <h5 className="overline2 newImpDrive">
                          {weight && width && length && hight && (
                            <>
                              {!autodriveFlag ? (
                                <RightOutlined
                                  onClick={() =>
                                    setAutodriveFlag(!autodriveFlag)
                                  }
                                  data-testid="autoDriveSetting-AddImplement"
                                />
                              ) : (
                                <DownOutlined
                                  onClick={() =>
                                    setAutodriveFlag(!autodriveFlag)
                                  }
                                  data-testid="autoDriveSetting-AddImplement"
                                />
                              )}
                            </>
                          )}{' '}
                          {(Isautonomyflag || Isautonomimplement) && (
                            <span>
                              {t(translate.addImplement.autodriveSettings)}
                            </span>
                          )}{' '}
                          {weight && width && length && hight && (
                            <span className="subTxt">
                              ({t(translate.addImplement.optional)})
                            </span>
                          )}
                        </h5>
                        {getunavalible && (
                          <p className="autodriveSettings addImpSpl">
                            {t(translate.errorMessages.fieldEmpty)}
                          </p>
                        )}
                        <p className="createImpSpl">
                          {t(translate.addImplement.defaultSettingsInfo)}
                          <span> Implement tracking is....?</span>
                        </p>
                      </div>
                      <div>
                        {autodriveFlag && (
                          <>
                            <>
                              <div className="settings-row-new">
                                <div className="inrow-settins ad-row-box">
                                  <div className="setting-txt-head">
                                    {t(translate.addImplement.inRow)}
                                  </div>
                                  <div className="ad-setting-sec">
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.pto)}
                                      </span>
                                      <span>
                                        <div
                                          className={
                                            inptoFlag ? 'ptoOn' : 'ptoOff'
                                          }
                                          data-testid="inRowButton-AddImplement"
                                          onClick={() => {
                                            setInptoFlag(!inptoFlag);
                                            setInRPM(540);
                                          }}
                                        >
                                          <img
                                            src={
                                              inptoFlag
                                                ? pto_on_icon
                                                : pto_off_icon
                                            }
                                            alt=""
                                          />
                                          <span>
                                            {inptoFlag ? 'ON' : 'OFF'}
                                          </span>
                                        </div>
                                      </span>
                                      <span>
                                        {inptoFlag && (
                                          <CustomInput
                                            label="RPM"
                                            testId="inRowRPM-AddImplement"
                                            value={inRPM}
                                            setValue={setInRPM}
                                            type="Number"
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.drive)}
                                      </span>
                                      <span className="fields-width">
                                        {' '}
                                        <CustomSelect3
                                          label="Speed"
                                          cssClass="w100Per msDD"
                                          value={
                                            ingearSpeedData.length
                                              ? inRowGearSpeed
                                              : ''
                                          }
                                          setValue={(value: any) => {
                                            OnInSpeed(ingearSpeedData[value]);
                                          }}
                                          testId="driveSpeedDropdownField-AddImplement"
                                          options={ingearSpeedData}
                                          optionDisplay="speed"
                                          optionDisplay2="gear"
                                        />
                                      </span>
                                    </div>
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.hitch)}
                                      </span>
                                      <span>
                                        <div
                                          className={
                                            inHitchFlag === false
                                              ? 'hitchImg hitchImgActive'
                                              : 'hitchImg'
                                          }
                                          data-testid="hitchInRowFloatIcon-AddImplement"
                                          onClick={() => {
                                            setInHitchFlag(false);
                                            setinPosition('Float');
                                            setInHeight(0);
                                          }}
                                        >
                                          <img
                                            src={
                                              !inHitchFlag &&
                                              inPosition === 'Float'
                                                ? hitch_float_mode_white
                                                : hitch_float_mode
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span className="hposTxt">
                                          {t(translate.addImplement.float)}
                                        </span>
                                      </span>
                                      <span className="">
                                        <div
                                          className={
                                            inHitchFlag === true
                                              ? 'hitchImg hitchImgActive'
                                              : 'hitchImg'
                                          }
                                          data-testid="hitchInRowPositionIcon-AddImplement"
                                          onClick={() => {
                                            setInHitchFlag(true);
                                            setinPosition('Position');
                                          }}
                                        >
                                          <img
                                            src={
                                              inHitchFlag
                                                ? ehitch_white_icon
                                                : ehitch_icon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span className="hposTxt">
                                          {t(translate.addImplement.position)}
                                        </span>
                                      </span>
                                      <span className="fields-width">
                                        {console.log(inPosition, 'sadsdb')}
                                        {inPosition === 'Position' && (
                                          <CustomInput
                                            label="Height"
                                            testId="inRowHeightInputField-AddImplement"
                                            value={inHeight}
                                            showLabel="%"
                                            setValue={setInHeight}
                                            type="Per"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="outrow-settins ad-row-box">
                                  <div className="setting-txt-head">
                                    {t(translate.addImplement.outOfRow)}
                                  </div>
                                  <div className="ad-setting-sec">
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.pto)}
                                      </span>
                                      <span>
                                        <div
                                          className={
                                            outptoFlag ? 'ptoOn' : 'ptoOff'
                                          }
                                          data-testid="outOfRowButton-AddImplement"
                                          onClick={() => {
                                            setoutptoFlag(!outptoFlag);
                                            setOutRPM(540);
                                          }}
                                        >
                                          <img
                                            src={
                                              outptoFlag
                                                ? pto_on_icon
                                                : pto_off_icon
                                            }
                                            alt=""
                                          />
                                          <span>
                                            {outptoFlag ? 'ON' : 'OFF'}
                                          </span>
                                        </div>
                                      </span>
                                      <span>
                                        {outptoFlag && (
                                          <CustomInput
                                            label="RPM"
                                            testId="outOfRowRPM-AddImplement"
                                            value={outRPM}
                                            setValue={setOutRPM}
                                            type="Number"
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.drive)}
                                      </span>
                                      <span className="driveTxt">
                                        {' '}
                                        {t(translate.addImplement.outOfRowHint)}
                                      </span>
                                    </div>
                                    <div className="ad-setting-item">
                                      <span className="status2">
                                        {t(translate.addImplement.hitch)}
                                      </span>
                                      <span>
                                        <div
                                          className={
                                            outHitchFlag === false
                                              ? 'hitchImg hitchImgActive'
                                              : 'hitchImg'
                                          }
                                          data-testid="hitchOutOfRowFloatIcon-AddImplement"
                                          onClick={() => {
                                            setOutHitchFlag(false);
                                            setoutPosition('Float');
                                            setOutHeight(0);
                                          }}
                                        >
                                          <img
                                            src={
                                              !outHitchFlag &&
                                              outPosition === 'Float'
                                                ? hitch_float_mode_white
                                                : hitch_float_mode
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span className="hposTxt">
                                          {t(translate.addImplement.float)}
                                        </span>
                                      </span>
                                      <span>
                                        <div
                                          className={
                                            outHitchFlag === true
                                              ? 'hitchImg hitchImgActive'
                                              : 'hitchImg'
                                          }
                                          data-testid="hitchOutOfRowPositionIcon-AddImplement"
                                          onClick={() => {
                                            setOutHitchFlag(true);
                                            setoutPosition('Position');
                                          }}
                                        >
                                          <img
                                            src={
                                              outHitchFlag
                                                ? ehitch_white_icon
                                                : ehitch_icon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span className="hposTxt">
                                          {t(translate.addImplement.position)}
                                        </span>
                                      </span>
                                      <span className="fields-width">
                                        {console.log(outPosition, 'sadsdb')}
                                        {outPosition === 'Position' && (
                                          <CustomInput
                                            label="Height"
                                            testId="outOfRowHeightInputField-AddImplement"
                                            value={outHeight}
                                            showLabel="%"
                                            setValue={setOutHeight}
                                            type="Per"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {user?.isSupportUser() && (
                                <>
                                  <div className="impFlexRow mt20">
                                    <div className="impFlexLbl status2">
                                      {t(translate.addImplement.activation)}
                                    </div>
                                    <div className="impFlexCont">
                                      <div className="impFlex3 impFlex3Bt">
                                        <div className="w100Per">
                                          <CustomInput
                                            label="Entry Row Distance"
                                            value={in_row_activation_distance}
                                            setValue={
                                              setin_row_activation_distance
                                            }
                                            type="Range"
                                            showLabel="IN"
                                            min={-80}
                                            max={80}
                                          />
                                        </div>
                                      </div>
                                      <div className="impFlex3">
                                        <div className="w100Per">
                                          <CustomInput
                                            label="Exit Row Distance"
                                            value={out_row_activation_distance}
                                            setValue={
                                              setout_row_activation_distance
                                            }
                                            type="Range"
                                            showLabel="IN"
                                            min={-80}
                                            max={80}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="impFlexRow">
                                    <div className="impFlexLbl status2">
                                      {t(translate.addImplement.trueactivation)}
                                    </div>
                                    <div className="impFlexCont">
                                      <div className="impFlex3">
                                        <div className="w100Per">
                                          <CustomInput
                                            label="Distance"
                                            value={true_activation_distance}
                                            setValue={
                                              settrue_activation_distance
                                            }
                                            type="Number"
                                            showLabel="IN"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                            <div>
                              {selectType === 'Seeder' && (
                                <>
                                  <h5 className="overline2 mt20">
                                    <span>
                                      {t(
                                        translate.addImplement
                                          .seederRefillSettings,
                                      )}
                                    </span>
                                  </h5>
                                  <p className="body3">
                                    {t(translate.addImplement.seederDesc)}
                                  </p>
                                  <div className="seedFieldFlex mb10 mt20">
                                    <CustomInput
                                      label="Seed Quantity"
                                      maxLength={10}
                                      value={currentCapacity}
                                      setValue={setcurrent_capacity}
                                      type="Number"
                                      required={true}
                                    />

                                    <CustomInput
                                      label="Application Rate"
                                      maxLength={10}
                                      value={applicationRate}
                                      setValue={setapplication_rate}
                                      type="Number"
                                      required={true}
                                    />
                                  </div>
                                  <p className="body3 seedTxt fMed">
                                    <span className="greenExclamationIcon">
                                      <img src={info_icon} alt="" />
                                    </span>
                                    {t(translate.addImplement.seederAlert)}
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className="imp-min-helptxt-sec"
                        style={{ display: 'none' }}
                      >
                        <img src={impMinImage} />
                        <span className="imp-minTxt">
                          This implement can only operate in a minimum row width
                          of 74 inches.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              <div className="loaderIcon">
                <AppLoader loader={loader} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Modal>
  );
};

export default GlobalImplementModal;
