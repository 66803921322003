/* eslint-disable no-console */
import { Icon, Stroke, Style } from 'ol/style';
// import NewTractorIcon from './../../assets/images/standBy.png';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import * as proj from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Fill, Text } from 'ol/style';

import bulkTractors from './../../assets/images/bulk_tractors.svg';
import orgDairyIconImage from './../../assets/images/crop_blue.svg';
import orgVineYardIconImage from './../../assets/images/crop_purple.svg';
import ground_zero from './../../assets/images/ground_zero.svg';
import tractorIconImage from './../../assets/images/tractor__normal.svg';
import selectedTractorNoUser from './../../assets/images/tractor-icons/selected_tractor_nouser.svg';

import chTractor1 from './../../assets/images/CHTractor1.png';
import cnhTractor from './../../assets/images/CNHTractor.png';
import tractorAutoDrive from './../../assets/images/ts_autodrive.png';
import tractorFaulty from './../../assets/images/ts_available.png';
import tractorCharging from './../../assets/images/ts_charging.png';
import tractorManual from './../../assets/images/ts_manual.png';
import tractorOff from './../../assets/images/ts_offline.svg';
import tractorCopyCat from './../../assets/images/ts_routine.png';
import tractorVineRow from './../../assets/images/ts_rowfollow.png';
import tractorService from './../../assets/images/ts_service.png';
import tractorAvailable from './../../assets/images/ts_service.svg';
import tractorShadow from './../../assets/images/ts_shadow.png';

import highCellular from './../../assets/images/high.svg';
import lowCellular from './../../assets/images/low.svg';
import mediumCellular from './../../assets/images/medium.svg';
import fullWifi from './../../assets/images/wFull.svg';
import lowWifi from './../../assets/images/wLow.svg';
import mediumWifi from './../../assets/images/wMedium.svg';

import conv3 from './../../assets/images/wFull_w.svg';
import conv1 from './../../assets/images/wLow_w.svg';
import conv2 from './../../assets/images/wMedium_w.svg';

import { notification } from 'antd';
import moment from 'moment';
import { Coordinate } from 'ol/coordinate';
import Polygon from 'ol/geom/Polygon';
import { getArea } from 'ol/sphere';
import { MapFeature, UserDetails } from '../../constants/types';
import { getPolyCoordinates } from './api';
import {
  AdminHeartBeat,
  AdminTractor,
  Localization,
  OrgTractor,
} from './types';
import {
  getBatteryStatus,
  getDateTime,
  indicatorsOrder,
  timeDuration,
  tractorConstantsNew,
  getDateTimes,
  getDiffTime,
  convertBytesToMB,
  getFullDate,
  getTimeDifference,
} from '../../constants/Common';
import { POLYGON } from '../../constants/constant';

export const orgTractorId = 'org_tractor_id_';
export const adminNoneAttached = 'None Attached';
export const adminHOVER = 'HOVER';

export const vineYard = 'vineyard';
export const dairy = 'dairy';
export const Tractor = 'tractor';
export const Organization = 'organization';
export const tractorIcon = new Style({
  image: new Icon({
    src: tractorIconImage,
  }),
});
export const baseLocationIcon = new Style({
  image: new Icon({
    src: ground_zero,
    scale: 0.2,
  }),
  zIndex: 100,
});
export const adminPriority: any = {
  DRAWLAYER: 13,
  TRACTOR: 12,
  REFILL_SEEDER: 11,
  BASESTATION: 11,
  ROUTINE: 10.1,
  PATH: 10,
  PREVIEWPATH: 9.5,
  SNAP: 10.5,
  Ticket: 10.5,
  INDICATOR: 10.5,
  SPEED: 10.5,
  CHARGEPOINTS: 10.1,
  WAYPOINT: 10.1,
  VINE: 8,
  VINEROW: 7,
  VINEBLOCK: 6,
  ABLANE: 7,
  POLY_LINE: 7,
  ABBLOCK: 5.5,
  POLYGON: {
    blue: 5,
    green: 4,
    red: 3,
    yellow: 2,
    white: 1,
    brown: 1.5,
  },
};

export const displayRecenterAllViewPortSetUpAdmin = (
  features: Feature<Geometry>[],
  map: any,
  padding = 20,
): void => {
  if (features && map) {
    const source = new VectorSource();
    source.addFeatures(features);
    const view = map?.getView();
    view &&
      view?.fit(source.getExtent(), {
        constrainResolution: false,
        padding: [padding, padding, padding, padding],
        duration: 2000,
      });
  }
};

export const orgTractorStyleFun = (feature: any) => {
  const size = feature.get('features').length;
  let style = new Style({});
  if (size === 1) {
    const feat = feature.get('features');
    const selected = feat[0].get('selected');
    style = tractorStyle(1, selected);
    feature.set('name', feat[0].get('name'));
    // feature.set('selected', feat[0].get('selected'));
  }
  if (size > 1) {
    const feat = feature.get('features');
    let names = '';
    feat.map((fe: any, index: number) => {
      names = `${names} ${fe.get('name')}${
        index < feat.length - 1 ? ', ' : ''
      } `;
    });
    feature.set('name', names);
    style = tractorStyle(size);
  }
  return style;
};

export const orgStyleFun = (feature: any) => {
  const size = feature.get('features').length;
  let style = new Style({});
  if (size === 1) {
    const feat = feature.get('features');
    feature.set('name', feat[0].get('name'));
    const name = feat[0].get('name');
    const cropType = feat[0].get('cropType');
    const str = getFirstChars(name);
    style = orgStyle(1, str, cropType);
  }
  if (size > 1) {
    const feat = feature.get('features');
    let names = '';
    feat.map((fe: any, index: number) => {
      names = `${names} ${fe.get('name')}${
        index < feat.length - 1 ? ', ' : ''
      } `;
    });
    feature.set('name', names);
    style = orgStyle(size, feat.length);
  }
  return style;
};
export const tractorStyle = (count: number, selected = false) => {
  if (count === 1) {
    let src = tractorIconImage;
    if (selected) {
      src = selectedTractorNoUser;
    }
    return new Style({
      image: new Icon({
        src,
        scale: 0.6,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new Icon({
        src: bulkTractors,
        scale: 0.8,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        font: '12px Montserrat-SemiBold',
      }),
    });
  }
};
export const orgStyle = (count: number, str = '', cropType = '') => {
  if (count === 1) {
    let img = orgVineYardIconImage;
    if (cropType === dairy) {
      img = orgDairyIconImage;
    }
    return new Style({
      image: new Icon({
        src: img,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: str,
        fill: new Fill({
          color: '#fff',
        }),
        font: '12px Montserrat-SemiBold',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new Icon({
        src: bulkTractors,
        scale: 0.8,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        font: '12px Montserrat-SemiBold',
      }),
    });
  }
};

export const getFirstChars = (name: string) => {
  let resp = '';
  const words = name.split(' ');
  if (words && words.length === 1) {
    resp = `${words[0]?.charAt(0)}`;
  }
  if (words && words.length > 1) {
    resp = `${words[0]?.charAt(0)}${words[1]?.charAt(0)}`;
  }
  return resp.toLocaleUpperCase();
};
export const adminTractorConst = {
  off: {
    key: 'off',
    value: 'Offline',
    class: 'admin_t_off',
    image: 'ts_off',
    color: '#CECECF',
  },
  available: {
    key: 'available',
    value: 'Available',
    class: 'admin_t_available',
    image: 'ts_available',
    color: '#EC9322',
  },
  charging: {
    key: 'charging',
    value: 'Charging',
    class: 'admin_t_charging',
    image: 'ts_charging',
    color: '#738023',
  },
  standby: {
    key: 'standby',
    value: 'Manual',
    class: 'admin_t_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  copycat: {
    key: 'copycat',
    value: 'Routines',
    class: 'admin_t_copycat',
    image: 'ts_routine',
    color: '#546CB2',
  },
  follow_me: {
    key: 'follow_me',
    value: 'Shadow',
    class: 'admin_t_follow_me',
    image: 'ts_shadow',
    color: '#366376',
  },
  manual: {
    key: 'manual',
    value: 'Manual',
    class: 'admin_t_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  vinerow: {
    key: 'vinerow',
    value: 'Row Follow',
    class: 'admin_t_vinerow',
    image: 'ts_rowfollow',
    color: '#366376',
  },
  remote_av: {
    key: 'remote_av',
    value: 'Autodrive',
    class: 'admin_t_remote_av',
    image: 'ts_autodrive',
    color: '#ec9322',
  },
  default: {
    key: 'default',
    value: 'Manual',
    class: 'admin_t_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  faulty: {
    key: 'faulty',
    value: 'Error',
    class: 'admin_t_faulty',
    image: 'ts_faulty',
    color: '#D43D38',
  },
  service: {
    key: 'service',
    value: 'Service',
    class: 'admin_t_service',
    image: 'ts_service',
    color: '#CFCFCF',
  },
};
export const getAdminBatteryStatus = (heartbeat: any): boolean =>
  heartbeat &&
  heartbeat.tractor_model &&
  heartbeat.tractor_model.length > 0 &&
  Number(
    heartbeat?.tractor_model?.split(',')[adminMqttConstants.CHARGINGSTATUS],
  ) === 1;

export const getAdminOperationStatus = (heartBeat: AdminHeartBeat): string => {
  if (heartBeat) {
    const charge = getAdminBatteryStatus(heartBeat);
    const battery = Math.trunc(
      Number(heartBeat?.tractor_model?.split(',')[adminMqttConstants.ENERGY]),
    );
    const n2Status = Boolean(
      Number(
        heartBeat?.system_model?.split(',')[adminMqttConstants.N2_POWER_STATUS],
      ),
    );
    const { lockout_details } = heartBeat;
    const status =
      lockout_details && lockout_details.is_tractor_lockout ? true : false;
    if (status) {
      return adminTractorConst.service.key;
    }
    if ((battery === -1 || !n2Status) && !heartBeat.is_simulator) {
      return adminTractorConst.faulty.key;
    }
    if (charge) {
      return adminTractorConst.charging.key;
    }
    return heartBeat?.drive_action_details &&
      heartBeat?.drive_action_details?.current_drive_action
      ? heartBeat?.planning_manager && heartBeat?.planning_manager.planner_name
        ? heartBeat?.planning_manager?.planner_name
        : adminTractorConst.manual.key
      : adminTractorConst.available.key;
  }
  return adminTractorConst.off.key;
};

export const getAdminStatusForTractor = (
  planner_name: string,
  drive_action_created_at: string,
): string => {
  let text = '';
  planner_name =
    planner_name && planner_name.toLowerCase()
      ? planner_name.toLowerCase()
      : '';
  if (drive_action_created_at && drive_action_created_at === AdminATOM) {
    planner_name =
      planner_name === adminTractorConst.standby.key ||
      planner_name === adminTractorConst.manual.key
        ? adminTractorConst.remote_av.key
        : planner_name;
  }

  switch (planner_name) {
    case adminTractorConst.off.key:
      text = adminTractorConst.off.value;
      break;
    case adminTractorConst.available.key:
      text = adminTractorConst.available.value;
      break;
    case adminTractorConst.charging.key:
      text = adminTractorConst.charging.value;
      break;
    case adminTractorConst.manual.key:
      text = adminTractorConst.manual.value;
      break;
    case adminTractorConst.standby.key:
      text = adminTractorConst.standby.value;
      break;
    case adminTractorConst.copycat.key:
      text = adminTractorConst.copycat.value;
      break;
    case adminTractorConst.follow_me.key:
      text = adminTractorConst.follow_me.value;
      break;
    case adminTractorConst.vinerow.key:
      text = adminTractorConst.vinerow.value;
      break;
    case adminTractorConst.remote_av.key:
      text = adminTractorConst.remote_av.value;
      break;
    case adminTractorConst.faulty.key:
      text = adminTractorConst.faulty.value;
      break;
    case adminTractorConst.service.key:
      text = adminTractorConst.service.value;
      break;
    default:
      text = '';
      break;
  }
  return text;
};

export const AdminATOM = 'ATOM';

export const getAdminStatusClass = (
  isAvailable: string,
  drive_action_created_at: string,
): string => {
  let text = '';
  if (drive_action_created_at && drive_action_created_at === AdminATOM) {
    isAvailable =
      isAvailable === adminTractorConst.standby.key ||
      isAvailable === adminTractorConst.manual.key
        ? adminTractorConst.remote_av.key
        : isAvailable;
  }

  switch (isAvailable) {
    case adminTractorConst.off.key:
      text = adminTractorConst.off.class;
      break;
    case adminTractorConst.charging.key:
      text = adminTractorConst.charging.class;
      break;
    case adminTractorConst.available.key:
      text = adminTractorConst.available.class;
      break;
    case adminTractorConst.manual.key:
      text = adminTractorConst.manual.class;
      break;
    case adminTractorConst.standby.key:
      text = adminTractorConst.standby.class;
      break;
    case adminTractorConst.copycat.key:
      text = adminTractorConst.copycat.class;
      break;
    case adminTractorConst.follow_me.key:
      text = adminTractorConst.follow_me.class;
      break;
    case adminTractorConst.vinerow.key:
      text = adminTractorConst.vinerow.class;
      break;
    case adminTractorConst.remote_av.key:
      text = adminTractorConst.remote_av.class;
      break;
    case adminTractorConst.faulty.key:
      text = adminTractorConst.faulty.class;
      break;
    case adminTractorConst.service.key:
      text = adminTractorConst.service.class;
      break;
    default:
      text = adminTractorConst.off.class;
      break;
  }
  return text;
};
export const adminMqttConstants = {
  PTOSTATE: 3,
  FNR: 5,
  SEATBELTSTATE: 21,
  GEAR: 43,
  RPM: 40,
  LEFTHITCH: 45,
  RIGHTHITCH: 47,
  ENERGY: 74,
  PSIFLOW: 92,
  VELOCITY: 6,
  ROLL: 4,
  PITCH: 5,
  TRACTORLOCATIONLAT: 1,
  TRACTORLOCATIONLONG: 0,
  BEARING: 5,
  IICSTATE: 0,
  CHARGINGSTATUS: 102,
  BREAK: 52,
  SEATOCCUPIED: 20,
  TURNSIGNAL: 55,
  HOOKUPCONTROL: 103,
  WORKFRONTLIGHT: 57,
  WORKREARLIGHT: 58,
  WORKLEFTLIGHT: 59,
  WORKRIGHTLIGHT: 60,
  LOWLOCALIZATION: 12,
  TIREANGLE: 41,
  HEADLIGHT: 54,
  FRONTWORKINGLIGHT: 57,
  REARWORKINGLIGHT: 58,
  LEFTSIDEWORKINGLIGHT: 59,
  RIGHTSIDEWORKINGLIGHT: 60,
  POWER100V: 108,
  POWER240V: 109,
  TARGETGEAR: 110,
  NOSPEED: 15,
  CHARGINGTIME: 111,
  SCREENON: 116,
  ESSTOP: 9,
  POWEROFF: 2,
  XAVIER1_POWER_STATUS: 10, // front left, fronT right
  XAVIER2_POWER_STATUS: 17, // pto
  XAVIER3_POWER_STATUS: 24, // front
  XAVIER4_POWER_STATUS: 31, // rear
  XAVIER6_POWER_STATUS: 167, // rear and pto

  XAVIER1_SYSTEM_NODE: 150, // front left, fron right
  XAVIER2_SYSTEM_NODE: 151, // pto
  XAVIER3_SYSTEM_NODE: 152, // front
  XAVIER6_SYSTEM_NODE: 155, // rear and pto
  INTERNET_SPEED: 174,
  N2_POWER_STATUS: 5, // system model
  ACTIVE_NETWORk: 209, // system model
};

export const isLowLocalizationAdmin = (localization: Localization): boolean => {
  return localization?.gps_status === 'NO Solution';
};

export const orgTractorFeatId = (orgId: number): string =>
  `${orgTractorId}${orgId}`;
export const adminRecenterTheLocation = (
  latLang: any,
  map: any,
  zoom?: number,
): void => {
  let prop: any = { duration: 3000, center: proj.fromLonLat(latLang) };
  if (zoom) {
    prop = { ...prop, zoom };
  }
  map && map?.getView().animate(prop);
};

export const filterByValue = (
  array: OrgTractor[],
  string: string,
): OrgTractor[] => {
  return array.filter((item: { name: string }) => {
    if (item.name && item.name.toLowerCase().includes(string)) {
      return item;
    }
  });
};

export const filterByOrgValue = (
  array: OrgTractor[],
  category: string,
): OrgTractor[] => {
  return array.filter((item: OrgTractor) => {
    if (item.category && item.category === category) {
      return item;
    }
  });
};

export const recenterTheItem = (
  layer: any,
  mapRef: any,
  itemId: string,
  zoom = 19,
): void => {
  // const layer: VectorLayer<any> | any = layer;
  if (layer) {
    const feat = layer.getSource();
    const feature: any = feat.getSource().getFeatureById(itemId);
    if (feature) {
      const point = feature.getGeometry().getCoordinates();
      const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
      adminRecenterTheLocation(coordinates, mapRef, zoom);
    }
  }
};
export const setTractorSelected = (layer: any, itemId: string): void => {
  if (layer) {
    const feat = layer.getSource();
    const features = layer?.getSource()?.getSource()?.getFeatures();
    features?.map((feature: any) => {
      feature.set('selected', false);
    });
    const feature: any = feat.getSource().getFeatureById(itemId);
    if (feature) {
      feature.set('selected', true);
    }
  }
};

export const getAdminImplementName = (tractor: AdminTractor): string => {
  return tractor?.heartbeat?.implement?.current_implement &&
    tractor?.heartbeat?.implement?.current_implement.toLowerCase() === 'none'
    ? adminNoneAttached
    : tractor?.heartbeat?.implement?.current_implement || adminNoneAttached;
};

export const batteryValue = (tractor: AdminTractor): number => {
  return Math.trunc(
    Number(
      tractor?.heartbeat?.tractor_model?.split(',')[adminMqttConstants.ENERGY],
    ),
  ) > 0
    ? Math.trunc(
        Number(
          tractor?.heartbeat?.tractor_model?.split(',')[
            adminMqttConstants.ENERGY
          ],
        ),
      )
    : 0;
};

export const getAdminTractorImage = (
  planner_name: string,
  tractorModel: string,
): string => {
  let text = '';
  if (tractorModel?.toUpperCase() === 'NH') {
    return cnhTractor;
  } else if (tractorModel?.toUpperCase() === 'CIH') {
    return chTractor1;
  } else {
    switch (planner_name) {
      case adminTractorConst.off.key:
        text = tractorOff;
        break;
      case adminTractorConst.available.key:
        text = tractorAvailable;
        break;
      case adminTractorConst.charging.key:
        text = tractorCharging;
        break;
      case adminTractorConst.manual.key:
        text = tractorManual;
        break;
      case adminTractorConst.standby.key:
        text = tractorManual;
        break;
      case adminTractorConst.copycat.key:
        text = tractorCopyCat;
        break;
      case adminTractorConst.follow_me.key:
        text = tractorShadow;
        break;
      case adminTractorConst.vinerow.key:
        text = tractorVineRow;
        break;
      case adminTractorConst.remote_av.key:
        text = tractorAutoDrive;
        break;
      case adminTractorConst.faulty.key:
        text = tractorFaulty;
        break;
      case adminTractorConst.service.key:
        text = tractorService;
        break;
      default:
        text = tractorManual;
        break;
    }
    return text;
  }
};
export const getAdminTractorStatusIcon = (planner_name: string): string => {
  let text = '';
  switch (planner_name) {
    case adminTractorConst.off.key:
      text = adminTractorConst.off.image;
      break;
    case adminTractorConst.available.key:
      text = adminTractorConst.available.image;
      break;
    case adminTractorConst.charging.key:
      text = adminTractorConst.charging.image;
      break;
    case adminTractorConst.manual.key:
      text = adminTractorConst.manual.image;
      break;
    case adminTractorConst.standby.key:
      text = adminTractorConst.standby.image;
      break;
    case adminTractorConst.copycat.key:
      text = adminTractorConst.copycat.image;
      break;
    case adminTractorConst.follow_me.key:
      text = adminTractorConst.follow_me.image;
      break;
    case adminTractorConst.vinerow.key:
      text = adminTractorConst.vinerow.image;
      break;
    case adminTractorConst.remote_av.key:
      text = adminTractorConst.remote_av.image;
      break;
    case adminTractorConst.faulty.key:
      text = adminTractorConst.faulty.image;
      break;
    case adminTractorConst.service.key:
      text = adminTractorConst.service.image;
      break;
    default:
      text = adminTractorConst.manual.image;
      break;
  }
  return text;
};

export const adminBatteryValue = (tractor: AdminTractor): number => {
  return Math.trunc(
    Number(
      tractor?.heartbeat?.tractor_model?.split(',')[adminMqttConstants.ENERGY],
    ),
  ) > 0
    ? Math.trunc(
        Number(
          tractor?.heartbeat?.tractor_model?.split(',')[
            adminMqttConstants.ENERGY
          ],
        ),
      )
    : 0;
};

export const getAdminSpeed = (heartbeat: AdminHeartBeat | undefined): number =>
  getAdminSpeedFromVelocity(
    heartbeat?.localization?.velocity as unknown as string,
  );

export const getAdminSpeedFromVelocity = (
  velocity: string | undefined,
): number =>
  velocity
    ? Number((Number(velocity) * 2.237).toFixed(1).replaceAll('-', ''))
    : 0;

export const getAdminFNR = (heartBeat: AdminHeartBeat | undefined): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > 0) {
      const fnr = Number(data[adminMqttConstants.FNR]);
      if (fnr === 1) {
        return 'F';
      } else if (fnr === 2) {
        return 'R';
      }
    }
  }
  return 'N';
};

export const adminSecondsToHours = (timeInSeconds: number): string => {
  let hours: string | number = Math.floor(timeInSeconds / 3600);
  if (hours.toString().length === 1) {
    hours = `0${hours}`;
  }
  /**
   * Gets remaining seconds.
   * @function remainingSeconds
   * @returns the seconds.
   */
  const remainingSeconds = timeInSeconds % 3600;
  let minutes: string | number = Math.floor(remainingSeconds / 60);
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  }
  if (hours || minutes) return `${hours}:${minutes}`;
  else return '-';
};

export const getAdminOperatorNameByHeartBeat = (heartbeat: any): string => {
  let text = '';
  if (heartbeat && heartbeat.drive_action_details) {
    const { operator_firstname, operator_lastname } =
      heartbeat.drive_action_details;
    if (operator_firstname) text += operator_firstname;
    if (operator_lastname) text += ' ' + operator_lastname;
  }
  return text;
};
export const adminIsEmptyValue = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (typeof value === 'number' && isNaN(value)) return true;
  if (value === null) return true;
  return false;
};
export const getAdminConnectivityImg = (state: number, hover = false) => {
  let img =
    state === 0 || state === 1 ? fullWifi : state === 2 ? mediumWifi : lowWifi;
  if (hover)
    img = state === 0 || state === 1 ? conv3 : state === 2 ? conv2 : conv1;
  return img;
};
export const adminWrapIndictorsData = (arr: any) => {
  const data = arr.reduce((a: any, c: any) => {
    a[c.indicator_key] = c.indicator_level;
    return a;
  }, {});
  return data;
};

export const getAdminNetworkTypeImg = (record: any, hover = false): string => {
  const { indicators: data } = record;
  const indicatorsData = adminWrapIndictorsData(data);
  const state = indicatorsData['Tractor_Communication'];
  const content = record?.heartbeat?.system_model?.split(',');
  const type =
    content && content?.length >= 210
      ? content[adminMqttConstants.ACTIVE_NETWORk]
      : '';
  let img = '';
  if (type === 'cellular') {
    img =
      state === 0 || state === 1
        ? highCellular
        : state === 2
        ? mediumCellular
        : lowCellular;
  } else {
    img =
      state === 0 || state === 1
        ? fullWifi
        : state === 2
        ? mediumWifi
        : lowWifi;
  }

  if (hover)
    img = state === 0 || state === 1 ? conv3 : state === 2 ? conv2 : conv1;
  return img;
};

export const getCameraViewAllow = (
  heartBeat: AdminHeartBeat | undefined,
  camera: string,
  status: string,
): boolean => {
  // Ignore Work Bench Tractors
  if (heartBeat?.tractor_serial_number.includes('MTBENCH')) {
    return true;
  }

  // if offline dont allow to click
  if (status === adminTractorConst.off.key) {
    return false;
  }
  if (heartBeat && heartBeat.system_model) {
    const data = heartBeat.system_model.split(',').map((item) => Number(item));
    if (data && data.length) {
      const XAVIER1_POWER_STATUS = data[adminMqttConstants.XAVIER1_POWER_STATUS]
        ? true
        : false; // left, right
      const XAVIER2_POWER_STATUS =
        data[adminMqttConstants.XAVIER2_POWER_STATUS] ||
        data[adminMqttConstants.XAVIER6_POWER_STATUS]
          ? true
          : false; // pto
      const XAVIER3_POWER_STATUS = data[adminMqttConstants.XAVIER3_POWER_STATUS]
        ? true
        : false; // front
      const XAVIER4_POWER_STATUS =
        data[adminMqttConstants.XAVIER4_POWER_STATUS] ||
        data[adminMqttConstants.XAVIER6_POWER_STATUS]
          ? true
          : false; // rear

      if (
        camera === adminTractorCameras.FRONT_LEFT ||
        camera === adminTractorCameras.FRONT_RIGHT
      )
        return XAVIER1_POWER_STATUS;
      if (camera === adminTractorCameras.PTO) return XAVIER2_POWER_STATUS;
      if (camera === adminTractorCameras.FRONT) return XAVIER3_POWER_STATUS;
      if (camera === adminTractorCameras.REAR) return XAVIER4_POWER_STATUS;
    }
  }
  return false;
};

export const adminTractorCameras = {
  FRONT: 'front',
  REAR: 'rear',
  PTO: 'pto',
  FRONT_LEFT: 'front_left',
  FRONT_RIGHT: 'front_right',
};

export const getAdminRpm = (
  heartbeat: AdminHeartBeat | undefined,
  tractor_model = '',
): string => {
  if (heartbeat && heartbeat.tractor_model) {
    const data = heartbeat.tractor_model.split(',');
    if (data && data.length > 0) {
      let rpm = Number(data[adminMqttConstants.RPM]);
      const tractorModel =
        tractor_model && tractor_model.length > 0
          ? tractor_model.toLowerCase()
          : '';
      // if beta
      if (tractorModel === 'beta') {
        rpm = rpm / 8;
        return rpm.toFixed();
      } else if (tractorModel === 'pilot') {
        rpm = rpm / 10.36;
        return rpm.toFixed();
      } else {
        rpm = rpm / 8.46;
        return rpm.toFixed();
      }
    }
  }
  return '0';
};

export const getAdminPTOState = (
  heartBeat: AdminHeartBeat | undefined,
): boolean => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');

    if (data && data.length > 0) {
      return Boolean(data[adminMqttConstants.PTOSTATE] === `1` ? true : false);
    }
  }
  return false;
};

export const getAdminGearText = (heartbeat: AdminHeartBeat): string => `
  ${getAdminGearRange(getAdminGear(heartbeat))}${getAdminGearRangeNumber(
  getAdminGear(heartbeat),
)}
              
`;
export const getAdminGearRange = (gear: number): string => {
  if ([1, 2, 3, 10].includes(gear)) {
    return 'L';
  }
  if ([4, 5, 6, 11].includes(gear)) {
    return 'M';
  }
  if ([7, 8, 9, 12].includes(gear)) {
    return 'H';
  }

  return '';
};

export const getAdminGearRangeNumber = (gear: number) => {
  if ([1, 4, 7, 10].includes(gear)) {
    return 1;
  }
  if ([2, 5, 8, 11].includes(gear)) {
    return 2;
  }
  if ([3, 6, 9, 12].includes(gear)) {
    return 3;
  }
  return '-';
};

export const getAdminGear = (heartBeat: AdminHeartBeat | undefined): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > adminMqttConstants.GEAR) {
      return Number(data[adminMqttConstants.GEAR]);
    }
  }
  return 0;
};

export const getAdminHeight = (
  heartBeat: AdminHeartBeat | undefined,
): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      return Number(
        (
          (Number(data[adminMqttConstants.LEFTHITCH]) +
            Number(data[adminMqttConstants.RIGHTHITCH])) /
          2
        ).toFixed(),
      );
    }
  }
  return 0;
};

export const getAdminFloatText = (
  heartBeat: AdminHeartBeat | undefined,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      const hookUpMode = Number(data[adminMqttConstants.HOOKUPCONTROL]);
      if (hookUpMode === 0) {
        return 'POSITION';
      } else if (hookUpMode === 1) {
        return 'CLASSIC';
      } else if (hookUpMode === 2) {
        return 'FLOAT';
      } else if (hookUpMode === 3) {
        return 'MANUAL ADJUST';
      }
    }
  }
  return '';
};

export const getAdminDateStamp = (timestamp: number): string => {
  return moment(timestamp).format('YYYY-MM-D h:mm:ss A');
};

export const adminHourMeter = (tractor: AdminTractor): string => {
  return (Number(tractor?.heartbeat?.hour_meter) / 3600).toFixed();
};
export const impactFilters = [
  { name: 'Lifetime', value: 'Lifetime' },
  { name: 'Year', value: 'Yearly' },
  { name: 'Month', value: 'Monthly' },
  { name: 'Week', value: 'Weekly' },
  { name: 'Day', value: 'Daily' },
];
export const operationsUsageFilters = [
  { name: 'Cumulative', value: 'Cumulative' },
  { name: 'Year', value: 'Yearly' },
  { name: 'Month', value: 'Monthly' },
  { name: 'Day', value: 'Daily' },
];

export const restrictDecimal = (data: number): number => {
  return Math.round(data);
};

export const extractHours = (timeString: string) => {
  if (timeString) {
    const hours = timeString.split(':')[0];
    return Number(hours);
  }
  return 0;
};

export const adminCenterTheLocation = (
  latLang: any,
  map: any,
  zoom?: number,
): void => {
  let prop: any = { duration: 2000, center: proj.fromLonLat(latLang) };
  if (zoom) {
    prop = { ...prop, zoom };
  }
  map && map?.getView().animate(prop);
};

export const defaultSelectedOrg = (org: any) => {
  if (org.account_type === 'external_customer') return true;
  return false;
};

export const adminCalculateDistance = (points: any[]): number => {
  let [lat1, lon1] = points[0];
  let [lat2, lon2] = points[1];
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  const r = 6371;

  // calculate the result
  return c * r * 0.621371;
};
export const adminDistanceForGoodPoints = 200;

export const adminCalPtsDistance = (paths: any): number => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = adminCalculateDistance(points) * 5280;
  }
  return distance;
};

export const adminCalPtsDistanceActualAllow = (paths: any): boolean => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = adminCalculateDistance(points);
  }
  return distance * 5258 < adminDistanceForGoodPoints ? true : false;
};

export const adminPathConstants = {
  // Manual Driven
  MANUAL: 'manual',
  STANDBY: 'standby',
  ROW_FOLLOW: 'vine_row',

  // Autonomy Driven
  SHADOW_MODE: 'follow_me',
  ROUTINES: 'copycat',
  REMOTE_AV: 'remote_av',
  PREVIEW_PATH: 'preview_path',
  ROUTINE: 'routine',
};

export const adminPathColorConstants = {
  MANUAL: '#67AFB7',
  STANDBY: '#67AFB7',
  DEFAULT: '#67AFB7',
  ROW_FOLLOW: '#67AFB7',
  SHADOW_MODE: '#67AFB7',

  ROUTINES: '#EC9322',
  REMOTE_AV: '#EC9322',
  PREVIEW_PATH: '#F1CE90',
  ROUTINE: '#F1CE90', // used preview path color code
};
export const adminPathBorderColorConstants = {
  MANUAL: '#4F8591',
  STANDBY: '#4F8591',
  ROW_FOLLOW: '#4F8591',
  DEFAULT: '#4F8591',
  SHADOW_MODE: '#4F8591',

  ROUTINES: '#9A6B4E',
  REMOTE_AV: '#9A6B4E',
  PREVIEW_PATH: '#E9881F',
  ROUTINE: '#E9881F', // used preview path color code
};
export const getAdminPathColor = (color: string): string => {
  switch (color) {
    case adminPathConstants.MANUAL:
      return adminPathColorConstants.MANUAL;
    case adminPathConstants.SHADOW_MODE:
      return adminPathColorConstants.SHADOW_MODE;
    case adminPathConstants.ROW_FOLLOW:
      return adminPathColorConstants.ROW_FOLLOW;
    case adminPathConstants.ROUTINES:
      return adminPathColorConstants.ROUTINES;
    case adminPathConstants.STANDBY:
      return adminPathColorConstants.STANDBY;
    case adminPathConstants.REMOTE_AV:
      return adminPathColorConstants.REMOTE_AV;
    case adminPathConstants.PREVIEW_PATH:
      return adminPathColorConstants.PREVIEW_PATH;
    case adminPathConstants.ROUTINE:
      return adminPathColorConstants.ROUTINE;

    default:
      return adminPathColorConstants.DEFAULT;
  }
};

export const getAdminPathBorderColor = (color: string): string => {
  switch (color) {
    case adminPathConstants.MANUAL:
      return adminPathBorderColorConstants.MANUAL;
    case adminPathConstants.SHADOW_MODE:
      return adminPathBorderColorConstants.SHADOW_MODE;
    case adminPathConstants.ROW_FOLLOW:
      return adminPathBorderColorConstants.ROW_FOLLOW;
    case adminPathConstants.ROUTINES:
      return adminPathBorderColorConstants.ROUTINES;
    case adminPathConstants.STANDBY:
      return adminPathBorderColorConstants.STANDBY;
    case adminPathConstants.REMOTE_AV:
      return adminPathBorderColorConstants.REMOTE_AV;
    case adminPathConstants.PREVIEW_PATH:
      return adminPathBorderColorConstants.PREVIEW_PATH;
    case adminPathConstants.ROUTINE:
      return adminPathBorderColorConstants.ROUTINE;

    default:
      return adminPathBorderColorConstants.DEFAULT;
  }
};
export const getAdminPathFeatureStyle = (planner: string, zoom = 10) => {
  const color = getAdminPathColor(planner);
  const borderColor = getAdminPathBorderColor(planner);
  return getAdminPathStyle(color, borderColor, zoom);
};
export const getAdminPathStyle = (
  color: string,
  borderColor: string,
  zoom = 10,
) => {
  if (zoom >= 18.5) {
    // console.log('below 20meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 10,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 7,
        }),
        zIndex: 501,
      }),
    ];
  } else if (zoom < 18.5 && zoom >= 16.5) {
    // console.log(' 20meters to 100 meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 12,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 8,
        }),
        zIndex: 501,
      }),
    ];
  } else if (zoom < 16.5 && zoom >= 15) {
    // console.log(' 100meters to 200 meters');
    return [
      new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 14,
        }),
        zIndex: 500,
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: 9,
        }),
        zIndex: 501,
      }),
    ];
  }
  //  else {
  // console.log('more then 200 meters');
  // }
  // more then 200 meters
  return [
    new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 7,
      }),
      zIndex: 500,
    }),
    new Style({
      stroke: new Stroke({
        color,
        width: 4,
      }),
      zIndex: 501,
    }),
  ];
};

export const suggestedPolygon = 'suggested-green';

export const drawAdminPoly = async (
  userDetails: UserDetails,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  poly: any,
  suggested = false,
) => {
  let polygonFeature = null;
  let polygonItem: MapFeature | null = null;
  let vertices = [];
  const id = `polygon_${poly.id}`;
  const { color }: { color: string } = poly;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    coordinates = await getPolyCoordinates(
      organization.api_url,
      poly.polygon_uuid,
    );
    vertices = coordinates;
    coordinates = prepareAdminCoordinates(coordinates);
    let satelightPolyStyle = null;
    if (coordinates && coordinates.length > 0) {
      const zIndex = suggested
        ? adminPriority.POLYGON['suggestedGreen']
        : adminPriority.POLYGON[color];
      polygonFeature = new Feature({
        type: 'Polygon',
        pointType: 'Polygon',
        geometry: new Polygon([coordinates]),
        zIndex,
        polygon_uuid: poly.polygon_uuid,
        color: suggested ? suggestedPolygon : color,
        id: poly.id,
        remarks: poly.remarks,
        poly,
        label: poly.name,
        polyName: poly.name,
      });
      const area = getAdminAreaOfPolygon(polygonFeature);
      if (area > 0) {
        // calculated area in acre and final value rounded
        polygonFeature.set(
          'label',
          poly?.name + ' ' + Math.round(area) + ' ' + 'acre',
        );
      }
      polygonFeature.setId(id);
      satelightPolyStyle = getAdminSateLightPolyStyle(color, zIndex, suggested);
      const roadMapPolyStyle = getAdminRoadMapPolyStyle(
        color,
        zIndex,
        suggested,
      );
      polygonFeature.setStyle(satelightPolyStyle);
      polygonFeature.set('satelightPolyStyle', satelightPolyStyle);
      polygonFeature.set('roadMapPolyStyle', roadMapPolyStyle);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polygonFeature);
    }
    polygonItem = {
      id: poly.id,
      name: poly.name,
      type: POLYGON,
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color: poly.color,
      polygon_uuid: poly.polygon_uuid,
      vertices,
      suggested: suggested ? true : false,
      remarks: poly?.remarks,
    };
    return { polygonFeature, polygonItem };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { polygonFeature, polygonItem };
  }
};
export const AdminSELECTED = 'SELECTED';

export const getAdminRoadMapPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
) => {
  //  'DEFAULT' | 'HOVER' | 'SELECTED',
  const rgbColor = getAdminRoadMapRGBColor(color, suggested);
  const rgbFillColor = getAdminRoadMapRGBFillColor(color, suggested);
  return new Style({
    fill: new Fill({
      color: color === 'white' ? 'rgba(255,255,255,0.1)' : rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width: color === 'white' ? 4 : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const getAdminSateLightPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
  type = '',
) => {
  const rgbColor = getAdminSatelightRGBColor(color, suggested);
  const rgbFillColor = getAdminSatelightRGBFillColor(color, suggested, type);
  return new Style({
    fill: new Fill({
      color: rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width:
        color === 'brown' || color === 'white' || type === AdminSELECTED
          ? 6
          : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const getAdminSatelightRGBColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#328D3B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#7C5032';
    case 'abBlock':
      return '#032d57';
    case 'vineLaneGreen':
      return '#379526';
    case 'vineLaneYellow':
      return '#rgba(235, 211, 107, 0.15)';
    default:
      return color;
  }
};

export const getAdminSatelightRGBFillColor = (
  color: string,
  suggested = false,
  type = '',
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      return suggested
        ? 'rgba(148, 204, 161, 0.72)'
        : `rgba(148, 204, 161,${
            type === AdminSELECTED || type === adminHOVER ? 0.8 : 0.22
          })`;
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.40)';
    case 'brown':
      return 'rgba(186, 156, 119, 0.1)';
    case 'abBlock':
      return 'rgba(3, 45, 87, 0.12)';
    case 'vineLaneGreen':
      return '#A3D5D8A0';
    case 'vineLaneYellow':
      return '#EBD36B';

    default:
      return color;
  }
};

export const getAdminRoadMapRGBColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#2FB53B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#808285';
    case 'abBlock':
      return '#032d57';
    default:
      return color;
  }
};

export const getAdminRoadMapRGBFillColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      return suggested
        ? 'rgba(148, 204, 161, 0.72)'
        : 'rgba(216, 230, 217, 0.77)';
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.78)';
    case 'brown':
      return 'rgba(234, 212, 189. 0.96)';
    case 'abBlock':
      return 'rgba(3, 45, 87, 0.12)';
    default:
      return color;
  }
};
export const getAdminAreaOfPolygon = (polygonFeature: any): number => {
  if (polygonFeature) {
    const geo: any = polygonFeature?.getGeometry();
    const area = getArea(geo);
    let output;
    if (area > 0) {
      output = Math.round(area * 100) / 100;
      output = output / 4046.85642;
      return Number(output.toFixed(2));
    }
  }
  return 0;
};

export const prepareAdminCoordinates = (vertices: any): Coordinate[] => {
  const coordinates: Coordinate[] = [];
  if (vertices && vertices.length > 0) {
    vertices.map((point: any) => {
      if (point.latitude !== null && point.longitude !== null) {
        coordinates.push(proj.fromLonLat([point.longitude, point.latitude]));
      }
    });
  }
  return coordinates;
};

export const getAdminLatitudeAndLongitude = (
  location: [number, number],
  base: [number, number],
  reverse = false,
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const deltaLat = location[0];
  const deltaLon = location[1];
  const newLatitude = base[0] + deltaLat / rEarth;
  const newLongitude =
    base[1] + deltaLon / rEarth / Math.cos(newLatitude * 0.01745);
  // default we send the lat after long
  return reverse ? [newLongitude, newLatitude] : [newLatitude, newLongitude];
};

export const adminPolygonHoverLabel = (map: any): void => {
  let selected: any = null;
  map &&
    map.on('pointermove', function (e: any) {
      if (selected !== null && selected.get('type') === 'Polygon') {
        selected.getStyle()?.getText()?.setText('');
        selected.setStyle(selected.getStyle());
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        selected = f;
        if (selected.get('type') === 'Polygon') {
          f.getStyle()?.getText()?.setText(f.get('label'));
          f.setStyle(f.getStyle());
        }
        return true;
      });
    });
};

export const getAdminNameFromList = (
  list: OrgTractor[],
  id: number,
): string => {
  let name = '';
  list &&
    list.length &&
    list.forEach((listItem: OrgTractor) => {
      if (listItem.id && id && listItem.id === id) {
        name = listItem.name;
      }
    });
  return name;
};

export const adminAlignItems = (ts: AdminTractor[], id: number) => {
  const data = ts.reduce(
    (a: any, c: AdminTractor) => {
      if (c.isAvailable === adminTractorConst.faulty.key) {
        a['faulty'].push(c);
      } else if (
        id &&
        id === c?.heartbeat?.drive_action_details?.current_operator_id &&
        c.isAvailable !== adminTractorConst.off.key &&
        c.isAvailable !== adminTractorConst.charging.key
      ) {
        a['inuse'].push(c);
      } else if (
        c &&
        c?.heartbeat &&
        c?.heartbeat?.drive_action_details &&
        c?.heartbeat?.drive_action_details?.current_operator_id > 0 &&
        c.isAvailable !== adminTractorConst.off.key &&
        c.isAvailable !== adminTractorConst.charging.key
      ) {
        a['otherInuse'].push(c);
      } else if (c.isAvailable === adminTractorConst.available.key) {
        a['available'].push(c);
      } else if (c.isAvailable === adminTractorConst.charging.key) {
        a['charging'].push(c);
      } else {
        a['others'].push(c);
      }
      return a;
    },
    {
      inuse: [],
      otherInuse: [],
      available: [],
      charging: [],
      faulty: [],
      others: [],
    },
  );
  const result: any = Object.values(data).reduce(
    (a: any, c: any) => (a = [...a, ...c]),
    [],
  );
  return result;
};

export const cropTypes = [
  { title: 'Vineyard', value: 'Vineyard' },
  { title: 'Dairy', value: 'Dairy' },
  { title: 'Raisins', value: 'Resins' },
  { title: 'Apples', value: 'Apples' },
  { title: 'Blueberries', value: 'Blueberries' },
];

export const extractMonths = (dateString: string) => {
  const parts = dateString.split('-');

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  return `${month}/${day}`;
};

export const extractMonthsYears = (dateString: string) => {
  const parts = dateString.split('-');

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  return `${month}/${day}/${year}`;
};

export const getMonthName = (monthNumber: number): string | null => {
  if (monthNumber < 1 || monthNumber > 12) {
    return null; // Invalid month number
  }

  const shortMonths: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return shortMonths[monthNumber - 1];
};

export const appendSort = (url: string, sortInfo: any) => {
  console.log('sort info', sortInfo);
  const searchParams = new URLSearchParams(sortInfo);
  return `${url}&${searchParams}`;
};
export const getKeyValue = (
  tractor: any,
  status: any,
  isNumberType = true,
): string => {
  if (tractor && tractor?.system_model_data) {
    const data = tractor?.system_model_data
      .split(',')
      .map((item: any) => (isNumberType ? Number(item) : item));
    if (data && data.length) {
      return data[status];
    }
  }
  return '-';
};

export const getActiveNetwork = (
  tractor: any,
  status: any,
  isNumberType = true,
): string => {
  if (tractor && tractor?.system_model) {
    const data = tractor?.system_model
      .split(',')
      .map((item: any) => (isNumberType ? Number(item) : item));
    if (data && data.length) {
      return data[status];
    }
  }
  return '-';
};

export const mapTractorsData = (data: any[]) =>
  data.map((d: any, index: any) => ({
    name: d.name,
    serial_number: d.serial_number,
    customer_organization_id: d.organization?.id,
    customer_organization_name: d.organization?.name,
    model_id: d.tractor_model?.id,
    model_name: d.tractor_model,
    id: d.id,
    startTime: getDateTime(d.created_date_time),
    tractor_pin: d.tractor_pin ? d.tractor_pin : '-',
    installation_key: d.installation_key ? d.installation_key : '-',
    radio_channel_number:
      d?.radio_channel_number !== null ? d?.radio_channel_number : '',
    radio_id: d?.radio_id !== null ? d?.radio_id : '',
    ip_address: d.ip_address ? d.ip_address : '',
    n2_ip_address: d.n2_ip_address ? d?.n2_ip_address : '',
    voucher_uid: d?.voucher_uid ? d?.voucher_uid : '',
    connection: d.connection,
    remote_access_status: index % 2 === 0 ? 'Enabled' : 'Disabled',
    clientNodeName: d.clientNodeName || '-',
  }));

export const mapGoldBulidData = (data: any[]): any[] =>
  data.map((record: any) => ({
    software_version: record.software_version,
    compatable_firmware_version: record.compatable_firmware_version,
    created_by: record.created_by,
    created_date_time: record.created_date_time
      ? getDateTime(record.created_date_time)
      : '-',
    build_status: record.build_status,
    remarks: record.remarks,
    git_hash: record.git_hash ? record.git_hash : '-',
    comments: record.release_notes,
    golden_build_url: record.golden_build_url,
    compatible_versions: record.compatible_versions,
  }));

export const mapMarkGoldBulidData = (data: any[]): any[] =>
  data.map((record: any) => ({
    software_version: record.software_version,
    firmware_version: record.compatable_firmware_version ?? '-',
    created_by: record.created_by,
    created_date_time: record.created_date_time
      ? getDateTime(record.created_date_time)
      : '-',
    build_status: record.build_status,
    remarks: record.remarks,
    git_hash: record.git_hash ? record.git_hash : '-',
    comments: record.release_notes ?? '-',
    golden_build_url: record.golden_build_url,
    compatible_versions: record.compatible_versions,
    approved_by: record.meta_data
      ? record.meta_data.first_name + ' ' + record.meta_data.last_name
      : '-',
    model: record.model,
  }));

export const mapIndicatorsData = (data: any[]): any[] =>
  data.map((record: any) => {
    const startTime = getDateTime(record.created_date_time);
    const EndTime = getDateTime(record.resolved_date_time);
    return {
      id: record.id,
      name: record.tractor.name,
      description: record.description ? record.description : '',
      level: record.indicator_level,
      start_time: record.created_date_time
        ? getDateTimes(record.created_date_time)
        : '',
      indicator_name: record.indicator_name,
      explanation: record.explanation ?? '-',
      indicator_level: record.indicator_level,
      error_code: record.error_code,
      resolved_date_time: record.resolved_date_time
        ? getDateTimes(record.resolved_date_time)
        : '',
      Duration: record.resolved_date_time
        ? getDiffTime(startTime, EndTime)
        : '',
    };
  });
export const mapTractorHealthData = (data: any[], orgObj: any): any[] =>
  data.map((record: any) => {
    const heartbeatFirmware = record?.tractor_model_data?.split(',');
    const heartbeatFirmwareVersion =
      heartbeatFirmware?.length >= 122 && heartbeatFirmware[121];
    const downloadSpeedNumber = Number(
      Number(record.network_download_speed),
    ).toFixed(1);
    const uploadSpeedNumber = Number(
      Number(record.network_upload_speed),
    ).toFixed(1);
    const socNumber = Number(record.tractor_model?.soc?.split('.')[0]);
    const indicatorData: any[] = [];

    indicatorsOrder?.forEach((arrangeIndicator: any) => {
      record?.indicators?.forEach((indicator: any) => {
        if (arrangeIndicator === indicator?.indicator_name) {
          indicatorData?.push(indicator);
        }
      });
    });

    const last_online_time = record?.connection?.last_online_time
      ? getDateTime(record?.connection?.last_online_time)
      : '-';
    return {
      ...record,
      id: record.id,
      jng:
        record?.connection === 'online'
          ? getOperationNewStatus(record)
          : tractorConstantsNew.off.key,
      isAvailable:
        record?.connection === 'online'
          ? getOperationNewStatus(record).toLowerCase()
          : tractorConstantsNew.off.key,
      lockout_details: orgObj?.lockout_details?.lockout_by ? 'Yes' : 'No',
      last_online_time: record?.connection?.last_online_time
        ? getDateTime(record?.connection?.last_online_time)
        : '-',
      last_offline_time: record?.connection?.last_offline_time
        ? getDateTime(record?.connection?.last_offline_time)
        : '-',
      org_id: record?.organization?.id,
      orgName: record?.organization?.name,
      daily_hour_meter: record.hour_meter
        ? (Number(record.hour_meter) / 3600).toFixed()
        : '-',
      offline_time:
        last_online_time !== '-'
          ? timeDuration(new Date(record?.connection?.last_online_time))
          : '-',
      tractor_hours:
        record?.heartbeat?.hour_meter === 'None' ||
        record?.heartbeat?.hour_meter === '0'
          ? ' - '
          : (Number(record.heartbeat?.hour_meter) / 3600).toFixed(),
      org_uuid: record?.orgnization?.org_uuid,
      soc: record?.soc ? record?.soc : '-',
      name: record.name,
      clientNodeName: record.clientNodeName,
      tractor_pin: record.tractor_pin || '-',
      serial_number: record.serial_number,
      date_time: record.heartbeat?.date_time
        ? getDateTime(record.heartbeat?.date_time)
        : '-',
      drive_action_details:
        record?.tractor?.connection === 'online' && record.drive_action_details
          ? record.drive_action_details?.operator_firstname
          : '-',
      status: record.tractor?.connection ? record.tractor?.connection : '',
      firmware_version: record?.heartbeat?.firmware_version ?? '-',
      software_version: record?.heartbeat?.software_version ?? '-',
      velocity:
        record?.tractor?.connection === 'online' &&
        record.localization?.velocity
          ? Number(
              (Number(record.localization?.velocity) * 2.237)
                .toFixed(1)
                .replaceAll('-', ''),
            )
          : '-',
      api_url: record?.organization?.api_url,
      active_network: getKeyValue(
        record,
        adminMqttConstants.ACTIVE_NETWORk,
        false,
      ),
      indicatorsData: indicatorData,

      uploadSpeed:
        record.network_upload_speed && Number(uploadSpeedNumber) >= 0
          ? `${Number(Number(record.network_upload_speed)).toFixed(1)} Mbps`
          : '-',

      downloadSpeed:
        record.network_download_speed && Number(downloadSpeedNumber) >= 0
          ? `${Number(Number(record.network_download_speed)).toFixed(1)} Mbps`
          : '-',
    };
  });

export const getOperationNewStatus = (tractor: any): string => {
  if (tractor) {
    const charge = getBatteryStatus(tractor.heartbeat.tractor_model);
    const battery = Math.trunc(
      Number(
        tractor.heartbeat.tractor_model?.split(',')[adminMqttConstants.ENERGY],
      ),
    );
    const n2Status = Boolean(
      Number(
        tractor.heartbeat?.system_model?.split(',')[
          adminMqttConstants.N2_POWER_STATUS
        ],
      ),
    );
    const { lockout_details } = tractor.heartbeat;
    const status = !!(lockout_details && lockout_details.is_tractor_lockout);
    if (status) {
      return tractorConstantsNew.service.key;
    }
    if ((battery === -1 || !n2Status) && !tractor.is_simulator) {
      return tractorConstantsNew.faulty.key;
    }
    if (charge) {
      return tractorConstantsNew.charging.key;
    }
    return tractor.drive_action_details &&
      tractor.drive_action_details.current_drive_action
      ? tractor.planning_manager && tractor.planning_manager.planner_name
        ? tractor.planning_manager.planner_name
        : tractorConstantsNew.manual.key
      : tractorConstantsNew.available.key;
  }
  return tractorConstantsNew.off.key;
};

export const tractorStatusSort: any = (list: any[]) => {
  const temp = list.reduce(
    (acc: any, item: any) => {
      acc[item.isAvailable].push(item);
      return acc;
    },
    {
      [tractorConstantsNew.standby.key]: [],
      [tractorConstantsNew.manual.key]: [],
      [tractorConstantsNew.vinerow.key]: [],
      [tractorConstantsNew.follow_me.key]: [],
      [tractorConstantsNew.copycat.key]: [],
      [tractorConstantsNew.remote_av.key]: [],
      [tractorConstantsNew.available.key]: [],
      [tractorConstantsNew.service.key]: [],
      [tractorConstantsNew.charging.key]: [],
      [tractorConstantsNew.faulty.key]: [],
      [tractorConstantsNew.off.key]: [],
    },
  );
  list = [
    ...temp[tractorConstantsNew.standby.key],
    ...temp[tractorConstantsNew.manual.key],
    ...temp[tractorConstantsNew.vinerow.key],
    ...temp[tractorConstantsNew.follow_me.key],
    ...temp[tractorConstantsNew.copycat.key],
    ...temp[tractorConstantsNew.remote_av.key],
    ...temp[tractorConstantsNew.available.key],
    ...temp[tractorConstantsNew.service.key],
    ...temp[tractorConstantsNew.charging.key],
    ...temp[tractorConstantsNew.faulty.key],
    ...temp[tractorConstantsNew.off.key],
  ];
  return list;
};

export const barColors = [
  '#67afb7',
  '#f1ce90',
  '#ed9322',
  '#b2bEb5',
  '#fdbf6f',
  '#cab2d6',
  '#a6cee3',
  '#b2df8a',
  '#fb9a99',
  '#33a02c',
  '#6a3d9a',
  '#ffff99',
  '#b15928',
  '#1f78b4',
  '#e31a1c',
  '#ff7f00',
];

export const dateFilters = [
  { name: 'Day', value: '1' },
  { name: 'Month', value: '13' },
];

export const mapHistoryData = (data: any[]) =>
  data.map((d: any) => ({
    tractorName: d.tractor_name,
    data_consumed: d.data_consumed ? convertBytesToMB(d.data_consumed) : '-',
    consumedDate: d?.consumed_date ? getFullDate(d?.consumed_date) : '-',
    consumed_date: d?.consumed_date || '-',
    filesUpload: d?.total_files_uploaded || '-',
    organization: d.organization_name || '-',
  }));

export const mapMarkTractorHealthData = (data: any[]): any[] =>
  data.map((record: any) => {
    return {
      id: record.id,
      jng: record.connection,
      last_online_time: record?.tractor_last_online_date_time
        ? getDateTime(record?.tractor_last_online_date_time)
        : '-',
      org_id: record?.org_id,
      org_type: record.org_type,
      permitted_mismatch: record.permitted_mismatch,
      organization_name: record?.org_name,
      tractor_name: record.tractor_name,
      clientNodeName: record.clientNodeName,
      tractor_pin: record.tractor_pin || '-',
      serial_number: record.serial_number,
      status: record?.connection ? record?.connection : '',
      tractor_firmware_version: record?.firmware_version ?? '-',
      tractor_software_version: record?.software_version ?? '-',
      goldBuild: record?.is_gold_build,
    };
  });

export const getMarkTractorStatusClass = (connection: string): string =>
  `fleetMsgTxt ${connection === 'online' ? 't_available' : 't_off'}`;

export const markFilters = [
  { name: 'Year', value: 'Yearly' },
  { name: 'Month', value: 'Monthly' },
  { name: 'Week', value: 'Weekly' },
  { name: 'Day', value: 'Daily' },
];

export const mapMarkTickets = (data: any[]): any[] =>
  data.map((ele: any) => {
    ele.completed_date_time = ele.completed_date_time
      ? getDateTimes(ele.completed_date_time)
      : '';
    ele.date_time = ele.created_date_time;
    ele.created_date_time = ele.created_date_time
      ? getDateTimes(ele.created_date_time)
      : '';
    ele.completed_by_user = ele.completed_by_user
      ? ele.completed_by_user.first_name + ' ' + ele.completed_by_user.last_name
      : '';
    ele.created_by_user = ele.created_by_user
      ? ele.created_by_user.first_name + ' ' + ele.created_by_user.last_name
      : '';
    ele.assignee = ele.assignee
      ? ele.assignee.first_name + ' ' + ele.assignee.last_name
      : '';
    ele.equipmentName = ele.equipment ? ele.equipment.name : 'Other';
    ele.ticket_level = ele.ticket_level.toString();
    ele.tractorId = ele.tractor_id;
    ele.ticketStatus = ele.status;
    ele.ticket_id = ele?.ticket_id || '-';
    return ele;
  });

export const mapBasestationStatusData = (data: any[]): any[] =>
  data.map((record: any) => ({
    ...record,
    basestation: record.basestation,
    connection: record.connection,
    organization: record.organization,
    jng: record.connection && record.connection.status,
    basestation_model: record.basestation?.basestation_model || '-',

    isAvailable:
      record.connection && record?.connection?.status === 'online'
        ? 'online'
        : 'offline',
    last_online_time: record?.connection?.last_online_time
      ? getDateTime(record?.connection?.last_online_time)
      : '-',
    last_offline_time: record?.connection?.last_offline_time
      ? getDateTime(record?.connection?.last_offline_time)
      : '-',
    org_id: record?.organization?.id,
    org_uuid: record?.organization?.org_uuid,
    orgName: record?.organization?.name,
    customerName: record?.organization?.name,
    date_time: record?.heartbeat?.epoch_ts
      ? getDateTimes(record?.heartbeat?.epoch_ts)
      : '-',
    name: record.basestation?.name,
    model: record.basestation?.basestation_model || '-',
    serial_number: record.basestation.serial_number,
    status: record.connection && record.connection?.status,
    api_url: record?.organization?.api_url,
    active_network: record?.heartbeat?.system_status?.active_network,
    software_version: record?.heartbeat?.basestation_software_version ?? '-',
    router_status: record.basestation?.router_status ? 'Online' : 'Offline',
    sbc_status: record.basestation?.sbc_status ? 'Online' : 'Offline',
    ip_address: record.basestation?.ip_address,
    rtkConfigured: record?.heartbeat?.system_status?.radio_rtk_status,
    tractorsConnected: record?.heartbeat?.tractors_connected?.List,
  }));

export const mapFleetIndHistoryData = (data: any[]): any[] =>
  data.map((record: any) => ({
    name: record.tractor.name,
    start_time: getDateTimes(record.created_date_time),
    indicator_name: record.indicator_name,
    description: record.description,
    indicator_level: record.indicator_level,
    error_code: record.error_code,
    resolved_date_time: record.resolved_date_time
      ? getDateTimes(record.resolved_date_time)
      : '-',
    Duration: record.resolved_date_time
      ? getTimeDifference(record.resolved_date_time, record.created_date_time)
      : '-',
    Description: record.description,
    Issue: record.subsystem,
    counterMeasure: record.counter_measure,
    software_version: record.software_version,
    firmware_version: record.firmware_version,
  }));
