import { Button, Image } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import closeGreen from '../../assets/images/closeGreen.svg';
import msgIcon from '../../assets/images/msgIcon.svg';
import loading from '../../assets/images/refresh_icon.svg';
import {
  checkForCNHITractor,
  formatCameraText,
  getDateTime,
  removeUnderScore,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { ATOM, TICKET_TYPES } from '../../constants/constant';
import { useCameraSnap, useSnap } from '../../constants/customHooks';
import { SnapShot } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import TicketsCreateEdit from '../tickets/TicketsCreateEdit';
import './styles.css';

const GUARD_RAILS = 'GUARD_RAILS';

interface Props {
  Close: () => void;
  snapshotFlag: boolean;
  snapshotList: any;
  hideAnalytics?: boolean;
  showLoader?: boolean;
}
const SnapshotView: React.FC<Props> = ({
  Close,
  snapshotFlag,
  snapshotList,
  hideAnalytics,
  showLoader,
}: Props) => {
  const { t } = useTranslation();

  const { APPReducer, privilegeChecker } = useContext(ApplicationContext);
  const [showAnalytics, setShowAnalytics] = useState<boolean>(true);

  const [cameraData] = useCameraSnap({
    guard_rail_triggered_uuid: snapshotList?.transaction_id,
    created_at: snapshotList?.created_at,
  });

  const { transaction_id: transactionId, tractorId, tractor_id } = snapshotList;
  const [state, dispatch] = APPReducer;
  const [toggleTicketWidget, setToggleTicketWidget] = useState<boolean>(false);
  const [tractorVisable, settractorVisable] = useState<boolean>(false);
  const [snapshot, loader, handleRefresh] = useSnap(
    transactionId,
    tractorId || tractor_id,
  );
  const { tractorsMap } = state;
  const tractor = tractorsMap.get(tractorId || tractor_id);

  const fSnapshots = useMemo(
    () =>
      checkForCNHITractor(tractor.tractor_model)
        ? snapshot.filter(({ camera_name }: any) => camera_name !== 'pto')
        : snapshot,
    [snapshot, tractor],
  );

  useEffect(() => {
    hideAnalytics && setShowAnalytics(false);
  }, [hideAnalytics]);

  const handleVideos = () => {
    const { date_time, created_date_time, tractorId, tractor_id } =
      snapshotList;
    const videoTime = date_time || created_date_time;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId || tractor_id,
      },
    });
  };

  const onCloseTicketWidget = async () => {
    setToggleTicketWidget(false);
    settractorVisable(false);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (snapshotFlag ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn" data-testid="snapclose-SnapshortView">
          <img
            id={applicationIds.snapshotScreen.snapshotCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>

        <div className="txtCenter tcktInfo posRel errorTcktTitle">
          <span className="headline3">
            {snapshotList.created_at === 'GUARD_RAILS'
              ? 'Guardrails'
              : snapshotList.created_at === 'JUMP_AND_GO'
              ? 'Jump And Go'
              : 'Snapshot'}
          </span>
          <h6 className="snapDate overline1">
            {typeof snapshotList?.created_date_time === 'number'
              ? getDateTime(snapshotList?.created_date_time)
              : snapshotList?.created_date_time}
          </h6>
        </div>
        <div className="profileView rightWidgetView">
          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span className="subTitles headline4">
                {t(translate.snapShotView.tractor)}
              </span>
              <span className="subdesc body2">{snapshotList?.tractorName}</span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span
                className="subTitles headline4"
                data-testid="creatorName-SnapshotView"
              >
                {t(translate.snapShotView.operator)}
              </span>
              <span className="subdesc body2">{snapshotList?.name}</span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span className="subTitles headline4">
                {snapshotList?.created_at === ATOM
                  ? 'Captured On'
                  : 'Creation Type'}
              </span>
              <span
                className="subdesc body2"
                style={{ textTransform: 'capitalize' }}
              >
                {snapshotList?.created_at === GUARD_RAILS
                  ? removeUnderScore(snapshotList?.guard_rail_type)
                  : removeUnderScore(snapshotList?.created_at)}

                {/* <img
                  src={ptoPG}
                  alt="ptoPG"
                  height="22px"
                  style={{ marginLeft: 6 }}
                /> */}
              </span>
            </h5>
          </div>

          {snapshotList?.created_at === GUARD_RAILS && (
            <div className="proRowType rowGapBtm3">
              <h5 className="subFlex">
                <span className="subTitles headline4">
                  {t(translate.snapShotView.camera)}
                </span>
                <span className="subdesc body2">
                  {' '}
                  {formatCameraText(cameraData?.camera)}
                </span>
              </h5>
            </div>
          )}

          <div className="proRowType">
            <h5 className="subFlex">
              <span className="subTitles headline4">
                {t(translate.snapShotView.images)}
              </span>
              {showLoader && (
                <span className="cursor" onClick={handleRefresh}>
                  <img src={loading} alt="loading" width="24" height="20" />
                </span>
              )}
            </h5>
          </div>

          <div className="camWrapper snapsContainer">
            <AppLoader loader={loader} />
            <Image.PreviewGroup>
              {!loader &&
                fSnapshots.map((item: SnapShot, index: number) => (
                  <div key={index}>
                    {item.camera_image_url !== '' ? (
                      <div className="camBox">
                        <div className="camBoxContent">
                          <Image src={item.camera_image_url} className="sImg" />
                        </div>
                        <div className="camBoxHeads">{item.title}</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
            </Image.PreviewGroup>
          </div>
          <div className="proRowType rowGapTop36">
            <h5 className="headline4">
              {t(translate.snapShotView.ticketsAndTasks)}
            </h5>
            <h6 className="proRoleValue createTcktBtn mt20">
              {privilegeChecker(privilagesConstants.Create_a_fleet_ticket) && (
                <div
                  id={applicationIds.snapshotScreen.addSnapshotTicket}
                  onClick={() => {
                    setToggleTicketWidget(true);
                    settractorVisable(true);
                  }}
                  className="commentBtnBlk"
                >
                  <span className="addCBtn addTcktCBtn">
                    <img src={msgIcon} />
                  </span>
                  <span
                    className="addCommentBtn ticketComments headline5"
                    data-testid="createTicketHeader-SnapshotView"
                  >
                    {t(translate.snapShotView.createTicketTitle)}
                  </span>
                </div>
              )}
            </h6>
          </div>
          <div className="proRowType mt32">
            <Button
              type="primary"
              className="employeeCreate w160 Button"
              onClick={handleVideos}
            >
              {t(translate.snapShotView.viewVideos)}
            </Button>
          </div>
          {/* {showAnalytics && snapshotList.drive_action_uuid && (
            <div
              className="analyticsTxt"
              onClick={() => {
                dispatch({
                  type: SET_LAST_TAB_INDEX,
                  payload: {
                    catlog: 'Analytics',
                    tab: 'Operations',
                  },
                });
                dispatch({
                  type: EXECUTE,
                  payload: {
                    routeRefresh: !state.routeRefresh,
                    jAGDAction: snapshotList,
                  },
                });
                Close();
              }}
            >
              {t(translate.snapShotView.seeAnalytics)}
            </div>
          )} */}
        </div>

        {toggleTicketWidget && (
          <TicketsCreateEdit
            tractorId={snapshotList?.tractor_id?.id || snapshotList?.tractor_id}
            Close={onCloseTicketWidget}
            snapShotId={snapshotList?.id}
            toggleWidget={toggleTicketWidget}
            tractorVisable={tractorVisable}
            ticketCreationType={TICKET_TYPES.SNAPSHOT}
          />
        )}
      </div>
    </Layout>
  );
};

export default SnapshotView;
