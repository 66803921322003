import { Col, Menu, notification, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { dealerResourceDownload } from '../../../../constants/Api';
import { AllConst } from '../../../../constants/constant';
import { ApplicationContext } from '../../../../context/AppContext';
import { Viewer } from '../../../HelpCenter/common/Viewer';
import useArticles from '../../../HelpCenter/hooks/useArticle';
import AppLoader from '../../../common/AppLoader';
import doc_icon from './../../../../assets/images/doc_icon.svg';
import img_icon from './../../../../assets/images/img_icon.svg';
import { default as pdf_icon } from './../../../../assets/images/pdf_icon.svg';
import video_icon from './../../../../assets/images/video_icon.svg';
import xls_icon from './../../../../assets/images/xls_icon.svg';
import './styles.css';

interface Props {
  selectedMediaType: string;
  setSelectedMediaType: (selectedMediaType: string) => void;
  mediaTypes: string[];
  dataList: any[];
}
export const mediaImageTypes: any = {
  pdf: pdf_icon,
  image: img_icon,
  video: video_icon,
  xls: xls_icon,
  docx: doc_icon,
};

const ResourceContent: React.FC<Props> = ({
  selectedMediaType,
  setSelectedMediaType,
  mediaTypes,
  dataList,
}) => {
  const { userDetails } = useContext(ApplicationContext);

  const [loader, setLoader] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [mediaBaseDataList, setMediaBaseDataList] = useState<any[]>([]);

  useEffect(() => {
    if (selectedMediaType === AllConst) {
      setMediaBaseDataList(dataList);
    } else {
      const list = getMediaType(selectedMediaType);
      setMediaBaseDataList(list);
    }
  }, [selectedMediaType, dataList]);

  const getMediaType = (mediaType: string) => {
    const list = dataList.filter(
      (dataItem) => dataItem.media_type === mediaType,
    );
    return mediaType === AllConst
      ? dataList && dataList.length
        ? dataList
        : []
      : list && list.length
      ? list
      : [];
  };

  const fileDownload = async (file_name: string, title: string) => {
    setLoader(true);
    try {
      const resp = await dealerResourceDownload(
        userDetails.organization.api_url,
        {
          file_name,
        },
      );
      resp.msg && notification.success({ message: resp.msg });
      if (resp.file_url) {
        const response = await fetch(resp.file_url);
        if (!response.ok) {
          throw new Error('Failed to fetch the file');
        }
        const blob = await response.blob();
        // Create a URL for the file and trigger download
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        const extension = file_name.split('.').pop();
        link.download = title + '.' + extension; // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Release memory
        window.URL.revokeObjectURL(downloadUrl);
      }
      // console.log('resp', resp);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <div className="left_section_scroll">
          <div className="byCat_section">
            <h2 className="byCat_head">By Category</h2>
            <div className="categoryItemsList">
              <ul id="menu-center">
                <Menu selectedKeys={selectedKeys} mode="vertical">
                  {mediaTypes &&
                    mediaTypes.map((mediaType: any, i: number) => {
                      return (
                        <Menu.Item
                          key={mediaType}
                          className={`${
                            selectedMediaType === mediaType ? 'selected' : ''
                          }`}
                          onClick={() => setSelectedMediaType(mediaType)}
                        >
                          <span className="dataId " data-id={mediaType}>
                            {mediaType}
                          </span>
                          <span>({getMediaType(mediaType)?.length})</span>
                        </Menu.Item>
                      );
                    })}
                </Menu>
              </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <div className="tabHead_Content">
          <div className="videoContainer gap10" style={{ width: '69vw' }}>
            <h1 className="filesHead">Files</h1>
            <div className="trainingVideosContainer gap20">
              {mediaBaseDataList.map((dataItem) => (
                <>
                  {dataItem.file_url && dataItem.file_url !== '' && (
                    <>
                      <div className="fileCard">
                        <div
                          className="fileDownload on-hover-hand-change"
                          onClick={() =>
                            fileDownload(dataItem.file_url, dataItem.title)
                          }
                        ></div>
                        <img
                          src={mediaImageTypes[dataItem.media_type]}
                          alt="logo"
                          className="fileTypeIcon"
                          height="40"
                          width="40"
                        />
                        <span className="fileDescription">
                          {dataItem.title}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
            {/* {subCategories &&
              subCategories.map((ele: any, i: number) => (
                <div id={`${ele.id}`}>
                  <div className="tabHead_section">
                    <div className="tabHead_count">
                      {i == 0 && (
                        <>
                          <h1 className="tabTitle_head">{title}</h1>
                          <p className="tabTitle_desc">
                            {subCategories?.length &&
                              subCategories[0]?.description}
                          </p>
                        </>
                      )}
                      <span>{ele?.name}</span>
                    </div>
                  </div>
                  <>content</>
                  <DocumentView key={ele.id} subCategoryId={ele.id} />
                </div>
              ))} */}
            <AppLoader loader={loader} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

interface Props1 {
  subCategoryId: number;
}

const DocumentView: React.FC<Props1> = ({ subCategoryId }) => {
  const { data, setSubCategoryId, loader } = useArticles();

  useEffect(() => {
    if (subCategoryId) setSubCategoryId(subCategoryId);
  }, [subCategoryId]);

  return (
    <div className="tabHead_Content">
      <AppLoader loader={loader} />
      <Viewer data={data} />
    </div>
  );
};

export default ResourceContent;
