import { Col, notification, Row, Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDealerResources } from '../../../../constants/Api';
import { AllConst } from '../../../../constants/constant';
import { ApplicationContext } from '../../../../context/AppContext';
import RoutesConstants from '../../../../routes/RoutesConstant';
import folder_icon from './../../../../assets/images/folder_icon.svg';
import hc_monarch_logo from './../../../HelpCenter/images/hc_monarch_logo.svg';
import ResourceContent from './ResourceContent';
import ResourceSearch from './ResourceSearch';

const { TabPane } = Tabs;
const Resources: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [categories, setCategories] = useState<string[]>([]);
  const [mediaTypes, setMediaTypes] = useState<string[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const { userDetails } = useContext(ApplicationContext);

  const [category] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedMediaType, setSelectedMediaType] = useState(AllConst);
  useEffect(() => {
    if (userDetails && userDetails.organization)
      getDealerCategoryResourcesHandler();
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && selectedCategory) {
      getDealerCategoryResourcesHandler(selectedCategory);
    }
  }, [userDetails, selectedCategory]);
  const getDealerCategoryResourcesHandler = async (
    selectedCategory?: string,
  ) => {
    setLoader(true);
    try {
      const resp = await getDealerResources(
        userDetails.organization.api_url,
        selectedCategory ? selectedCategory : '',
      );
      if (
        !selectedCategory &&
        resp &&
        resp.category_types &&
        resp.category_types.length > 0
      ) {
        setSelectedCategory(resp.category_types[0]);
        setSelectedMediaType(AllConst);
        setCategories(resp.category_types);
      }
      if (selectedCategory && resp && resp.data && resp.data.length > 0) {
        setDataList(resp.data);
        const mTypes = resp.data.map(
          (data: { media_type: string }) => data.media_type,
        );
        const uniqueArray = mTypes.filter(
          (item: string, index: number) => mTypes.indexOf(item) === index,
        );
        uniqueArray.unshift(AllConst);
        setMediaTypes(uniqueArray);
      }
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="common_tabs">
      <div className="help_center_container">
        <div className="hc_top_bg"></div>
        <div className="hc_logo_section mb47">
          <div
            className="flex cursorPointer"
            onClick={() => {
              history.push(RoutesConstants.DealerMaintenance);
            }}
          >
            <div>
              <img src={hc_monarch_logo} alt="logo" />
              <h4 className="hc_title_top">Resources</h4>
            </div>
          </div>
          <div className="top_search_bar">
            <Row gutter={[20, 0]}>
              <Col xl={{ span: 24, offset: 0 }} className="hc_search">
                {/* <ResourceSearch flag={false}></ResourceSearch> */}
              </Col>
            </Row>
          </div>
        </div>
        <div className="hc_tabs_section">
          <Tabs
            defaultActiveKey="1001"
            activeKey={selectedCategory}
            centered
            onChange={(id) => {
              setSelectedCategory(id);
            }}
          >
            {categories.map((category: string) => {
              return (
                <TabPane
                  tab={
                    <div className="resourceTab">
                      <span>
                        <img src={folder_icon} alt="logo" />
                      </span>
                      <span className="categoryName">{category}</span>
                    </div>
                  }
                  key={category}
                >
                  <ResourceContent
                    mediaTypes={mediaTypes}
                    dataList={dataList}
                    selectedMediaType={selectedMediaType}
                    setSelectedMediaType={setSelectedMediaType}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default Resources;
