import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Space, Table, message, notification } from 'antd';
import { ApplicationContext } from '../../../context/AppContext';
import { getAutodriveReportData } from '../api';
import moment from 'moment';
import CustomSelect from '../../common/CustomSelect';
import loading_logo from '../../../assets/images/loading_logo.gif';

import driving_hours from './images/hours.svg';
import kms_driven from './images/kms_driven.svg';
import t_tractor from '../images/t_tractor.svg';
import other_stops from './images/other_stops.svg';
import total_loc from './images/total_loc.svg';
import object_detection from './images/object_detection.svg';

import { impactFilters } from '../Common';

import './style.css';
import { wrapText } from '../../../lib/utilities';
import CropOrgFilter from '../../common/CropOrgFilter';

const { RangePicker } = DatePicker;

interface Props {
  handleSelectCustomer?: (selectedCustomer: any) => void;
}

const AutoDrivereport: React.FC<Props> = ({ handleSelectCustomer }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const [filter, setFilter] = useState<string>('Daily');
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    from_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    // to_date:
    //   moment().endOf('year') > moment().endOf('month')
    //     ? moment().endOf('month').format('YYYY-MM-DD')
    //     : moment().endOf('year').format('YYYY-MM-DD'),
    type: 'Daily',
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [summary, setSummary] = useState<any>(null);
  const init = useRef<boolean>(true);

  const getAutodrive = async () => {
    try {
      setLoading(true);
      const data: any = await getAutodriveReportData(
        userDetails.organization.api_url,
        filterObj?.from_date,
        filterObj?.to_date,
        selectedOrg,
      );
      if (data?.auto_drive_data) {
        setData(data?.auto_drive_data);
        setTotal(data?.auto_drive_data?.length);
      }
      if (data?.auto_drive_data_summary) {
        const { auto_drive_data_summary: summaryData } = data;
        setSummary({
          ...summary,
          kmsDriven: summaryData.total_driven_distance,
          drivingHours: summaryData.total_driving_hours,
          humanDetections: summaryData.total_human_detections,
          totalLoc: summaryData.total_loc,
          objectDetections: summaryData.total_object_detections,
          otherStops: summaryData.total_other_stops,
          totalTractors: summaryData.total_tractors,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOrg = (ids: string[]) => {
    setSelectedOrg(ids);
  };

  useEffect(() => {
    if (selectedOrg.length && init.current) {
      handleSubmit();
      init.current = false;
    }
  }, [selectedOrg]);

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Lifetime')
      setFilterObj({
        ...filterObj,
        from_date: '',
        to_date: '',
        type: filter,
      });
    if (value === 'Yearly') {
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('year').format('YYYY-MM-DD'),
        to_date:
          moment(to_date).endOf('year') > moment(to_date).endOf('month')
            ? moment(to_date).endOf('month').format('YYYY-MM-DD')
            : moment(to_date).endOf('year').format('YYYY-MM-DD'),
      });
    }
    if (value === 'Weekly' || value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      // const from_date = moment(`${filterObj.year}-${filterObj.month}`)
      //   .startOf('month')
      //   .format('YYYY-MM-DD');
      // const to_date = moment(`${filterObj.year}-${filterObj.month}`)
      //   .endOf('month')
      //   .format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('month').format('YYYY-MM-DD'),
        to_date: moment(to_date).endOf('month').format('YYYY-MM-DD'),
        type: value,
      });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2023'))
    );
  };

  const onYealy = (date: any, dateString: string) => {
    setFilterObj({
      ...filterObj,
      year: Number(dateString),
      type: 'Yearly',
    });
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onWeekly = (date: any) => {
    const week = moment(date).week();
    const from_date = moment(date)
      .day('Sunday')
      .week(week)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      from_date,
      to_date,
      type: 'Weekly',
    });
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const handleSubmit = () => {
    if (userDetails && selectedOrg.length) getAutodrive();
    else {
      message.warning('Please select an organization');
      setData([]);
    }
  };

  const onRowClick = (record: any) => {
    const { from_date, to_date } = filterObj;
    const selectedCustomer: any = {
      customer_id: record.customer_id,
      customer_name: record.customer_name,
      name: record.customer_name,
      totalHours: record.driving_hours,
      fromDate: from_date,
      toDate: to_date,
      filterObj,
      filter,
    };
    handleSelectCustomer && handleSelectCustomer(selectedCustomer);
  };

  const columns: any = [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 200,
      fixed: 'left',
      headerBg: 'red',
    },
    {
      title: 'Number of Tractors',
      dataIndex: 'number_of_tractors',
      key: 'number_of_tractors',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Driving Hours',
      dataIndex: 'driving_hours',
      key: 'driving_hours',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Number of Rows',
      dataIndex: 'number_of_rows',
      key: 'number_of_rows',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Number of headland turns',
      dataIndex: 'number_of_headland_turns',
      key: 'number_of_headland_turns',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Stops in the Row',
      children: [
        {
          title: 'Object Detections',
          children: [
            {
              title: '# of Object Detections',
              dataIndex: 'number_of_stops_in_the_row_object_detections',
              key: 'number_of_stops_in_the_row_object_detections',
              width: 150,
            },
            {
              title: 'Soft resume for object detections',
              dataIndex: 'stops_in_the_row_object_detections_soft_resume',
              key: 'stops_in_the_row_object_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_in_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_object_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: 'Human Detections',
          children: [
            {
              title: '# of Human Detections',
              dataIndex: 'number_of_stops_in_the_row_human_detections',
              key: 'number_of_stops_in_the_row_human_detections',
              width: 150,
            },
            {
              title: 'Soft resume for human detections',
              dataIndex: 'stops_in_the_row_human_detections_soft_resume',
              key: 'stops_in_the_row_human_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_in_the_row_human_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_human_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: '# of stops due to Monarch Remote Monitoring Team',
          dataIndex:
            'number_of_stops_in_the_row_monarch_remote_monitoring_team',
          key: 'number_of_stops_in_the_row_monarch_remote_monitoring_team',
          width: 200,
        },
        {
          title: '# of stops due to connectivity loss',
          dataIndex: 'number_of_stops_in_the_row_connectivity_loss',
          key: 'number_of_stops_in_the_row_connectivity_loss',
          width: 200,
        },
        {
          title: '# of all other stops',
          dataIndex: 'number_of_stops_in_the_row_all_other_stops',
          key: 'number_of_stops_in_the_row_all_other_stops',
          width: 200,
        },
      ],
    },
    {
      title: 'Stops out of the Row',
      children: [
        {
          title: 'Object Detections',
          children: [
            {
              title: '# of Object Detections',
              dataIndex: 'number_of_stops_out_of_the_row_object_detections',
              key: 'number_of_stops_out_of_the_row_object_detections',
              width: 150,
            },
            {
              title: 'Soft resume for object detections',
              dataIndex: 'stops_out_of_the_row_object_detections_soft_resume',
              key: 'stops_out_of_the_row_object_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_out_of_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_out_of_the_row_object_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: 'Human Detections',
          children: [
            {
              title: '# of Human Detections',
              dataIndex: 'number_of_stops_out_of_the_row_human_detections',
              key: 'number_of_stops_out_of_the_row_human_detections',
              width: 150,
            },
            {
              title: 'Soft resume for human detections',
              dataIndex: 'stops_out_of_the_row_human_detections_soft_resume',
              key: 'stops_out_of_the_row_human_detections_soft_resume',
              width: 200,
            },
            {
              title: '# of "Resume" commands from remote operator',
              dataIndex:
                'stops_out_of_the_row_human_resume_commands_from_remote_operator',
              key: 'stops_out_of_the_row_human_resume_commands_from_remote_operator',
              width: 200,
            },
          ],
        },
        {
          title: '# of stops due to Monarch Remote Monitoring Team',
          dataIndex:
            'number_of_stops_out_of_the_row_monarch_remote_monitoring_team',
          key: 'number_of_stops_out_of_the_row_monarch_remote_monitoring_team',
          width: 200,
        },
        {
          title: '# of stops due to connectivity loss',
          dataIndex: 'number_of_stops_out_of_the_row_connectivity_loss',
          key: 'number_of_stops_out_of_the_row_connectivity_loss',
          width: 200,
        },
        {
          title: '# of all other stops',
          dataIndex: 'number_of_stops_out_of_the_row_all_other_stops',
          key: 'number_of_stops_out_of_the_row_all_other_stops',
          width: 200,
        },
      ],
    },
  ];
  return (
    <>
      <div className="widgets_block3">
        <div className="gridItem summaryCard">
          <img src={t_tractor} alt="Kms Driven" />
          <div className="widget_content">
            <div className="assignTxt">Tractors</div>
            <div className="assignNum">{summary?.totalTractors || '-'}</div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={driving_hours} alt="Kms Driven" />
          <div className="widget_content">
            <div className="assignTxt">Driving Hours</div>
            <div className="assignNum">{summary?.drivingHours || '-'}</div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={kms_driven} alt="Kms Driven" />
          <div className="widget_content">
            <div className="assignTxt">Kms Driven (Kms)</div>
            <div className="assignNum">
              {wrapText(summary?.kmsDriven) || '-'}
            </div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={object_detection} alt="Co2 Saved" />
          <div className="widget_content">
            <div className="assignTxt">Object Detections</div>
            <div className="assignNum">{summary?.objectDetections || '-'}</div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={object_detection} alt="Energy Used" />
          <div className="widget_content">
            <div className="assignTxt">Human Detections</div>
            <div className="assignNum">{summary?.humanDetections || '-'}</div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={total_loc} alt="Driving Hours" />
          <div className="widget_content">
            <div className="assignTxt">LOC Count</div>
            <div className="assignNum">{summary?.totalLoc || '-'}</div>
          </div>
        </div>
        <div className="gridItem summaryCard">
          <img src={other_stops} alt="Driving Hours" />
          <div className="widget_content">
            <div className="assignTxt">Other Stops</div>
            <div className="assignNum">{summary?.otherStops || '-'}</div>
          </div>
        </div>
      </div>

      <div className="common_wrapper">
        <div className="filters_card">
          <div>
            <Space size="large">
              <div className="ad_filters_card ad_filters_card_new">
                <div className="ad_displayFlex">
                  <CropOrgFilter handleOrgs={handleOrg} />
                  <CustomSelect
                    label="Filters"
                    cssClass="ad_min_width"
                    value={filter}
                    setValue={handleFilter}
                    options={impactFilters}
                    optionKey="value"
                    optionDisplay="name"
                  />
                  {filter === 'Monthly' && (
                    <DatePicker
                      allowClear={false}
                      format="MMM-YYYY"
                      value={moment(`${filterObj.year}-${filterObj.month}`)}
                      onChange={onMonthly}
                      disabledDate={disabledDates}
                      picker="month"
                    />
                  )}
                  {filter === 'Weekly' && (
                    <DatePicker
                      allowClear={false}
                      value={moment(filterObj.from_date)}
                      onChange={onWeekly}
                      disabledDate={disabledDates}
                      picker="week"
                    />
                  )}
                  {filter === 'Yearly' && (
                    <DatePicker
                      allowClear={false}
                      value={moment(`${filterObj.year}-01-01`)}
                      onChange={onYealy}
                      disabledDate={disabledDates}
                      picker="year"
                    />
                  )}
                  {filter === 'Daily' && (
                    <RangePicker
                      allowClear={false}
                      value={[
                        moment(filterObj.from_date),
                        moment(filterObj.to_date),
                      ]}
                      onChange={onDateRangeChange}
                      disabledDate={disabledDates}
                    />
                  )}
                  <Button
                    className="submitBtn"
                    onClick={handleSubmit}
                    // disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Space>
          </div>
        </div>
        <Table
          className="autoDriveReportTbl"
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={{
            indicator: (
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            ),
            spinning: loading,
          }}
          bordered
          size="middle"
          scroll={{
            x: 'calc(700px + 50%)',
            y: 240,
          }}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: (event) => {
                onRowClick && onRowClick(record);
              }, // click row
            };
          }}
        />
        <div className="reportTotal">
          <b>Total:</b> {total} Autodrive Performance Reports
        </div>
      </div>
    </>
  );
};

export default AutoDrivereport;
