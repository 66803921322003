import { Avatar } from 'antd';
import React from 'react';
import { fetchName, wrapDateTime } from '../common';
import low from '../images/low.svg';
import medium from '../images/medium.svg';
import Spinner from '../../common/Spinner';

interface Props {
  ticket: any;
  getDetailsById: (ticket: any) => void;
}

const Ticket: React.FC<Props> = ({ ticket, getDetailsById }) => {
  const getSeverity = (level: number) => {
    return (
      <>
        <img src={level > 0 ? medium : low} />
        <img src={level > 1 ? medium : low} />
        <img src={level > 2 ? medium : low} />
      </>
    );
  };

  return (
    <div className="ticket-item" onClick={() => getDetailsById(ticket)}>
      <div className="top-section">
        <div className="assigned-to-title">
          <div className="assigned-to">
            <Avatar
              style={{
                color: '#ffffff',
                backgroundColor: '#366376',
                fontSize: '8px',
                fontWeight: 600,
                fontFamily: 'Montserrat',
                lineHeight: '9.75px',
              }}
              size={24}
              src={ticket?.created_by_user?.profile_url}
            >
              {ticket?.created_by_user &&
                fetchName(
                  ticket?.created_by_user?.first_name,
                  ticket?.created_by_user?.last_name,
                )}
            </Avatar>
          </div>
          <span className="ticket-number">Ticket #{ticket?.ticketId}</span>
        </div>
        {ticket?.status === 'resolved' ? (
          <button className="status-rsl">
            <span className="resolved">Resolved</span>
          </button>
        ) : (
          <button className="status-fwd">
            <span className="forwarded">Forwarded</span>
          </button>
        )}
      </div>
      <div className="copy-severity">
        <div className="description-date">
          <span className="reboot-hitch-not-responding verticalEllipsis">
            {ticket?.description}
          </span>
          <div className="creator-time-193">
            <span className="c-name">
              {ticket?.createdBy &&
                fetchName(
                  ticket?.createdBy?.first_name,
                  ticket?.createdBy?.last_name,
                )}
            </span>
            <div className="ellipse-195"></div>
            <span className="pm-196">{wrapDateTime(ticket?.date)}</span>
          </div>
        </div>
        <div className="severity-flag">{getSeverity(ticket?.ticketLevel)}</div>
      </div>
    </div>
  );
};

interface TicketLoaderProps {
  loader: boolean;
}

export const TicketLoader: React.FC<TicketLoaderProps> = ({ loader }) => {
  return (
    <div className="empty-ticket-block">
      {loader ? <Spinner loader={loader} /> : 'No Tickets Created'}
    </div>
  );
};

export default Ticket;
