/* eslint-disable no-console */
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';
import {
  IndicatorStyle,
  OperationIndicatorStyle,
  OperationTicketStyle,
  SpeedStyle,
  TicketStyle,
  priority,
} from '../constants/Common';
import Cluster from 'ol/source/Cluster';
import { handleSameLocationFeatures } from '../components/operationalAnalytics/common';

const pointStyle: any = {
  SPEED: SpeedStyle,
  INDICATOR: IndicatorStyle,
  Ticket: TicketStyle,
  OPERATION_INDICATOR: OperationIndicatorStyle,
  Operation_Ticket: OperationTicketStyle,
};

export const drawLayer = (data: any, pointType: string) => {
  if (data && data.length > 0) {
    const features: Feature<any>[] = [];
    data?.map((item: any) => {
      item.name = item.name || pointType;
      if (item && item.latitude && item.longitude) {
        const coordinates = [item.latitude, item.longitude];
        const style = pointStyle[pointType](1);

        const feature = new Feature({
          geometry: new Point(
            proj.fromLonLat([coordinates[1], coordinates[0]]),
          ),
          name: item.name,
          pointType: pointType,
          content: item,
          style,
          point: [coordinates[1], coordinates[0]],
        });
        feature.setStyle(style);
        features.push(feature);
      }
    });

    const source = new VectorSource({
      features: features,
    });
    const clusterSource = new Cluster({
      distance: 10,
      source: source,
    });
    const vectorLayer = new VectorLayer({
      source: clusterSource,
      zIndex: priority[pointType],
      style: function (feature: any) {
        const size = feature.get('features').length;
        let style = new Style({});
        if (size === 1) {
          const feat = feature.get('features');
          style = pointStyle[pointType](1);

          feature.set('name', feat[0].get('name'));
          feature.set('pointType', feat[0].get('pointType'));
          feature.set('content', feat[0].get('content'));
        }
        if (size > 1) {
          const feat = feature.get('features');
          let names = '';
          feat.map((fe: any, index: number) => {
            names = `${names} ${fe.get('name')}${
              index < feat.length - 1 ? ', ' : ''
            } `;
          });
          feature.set('name', names);
          style = pointStyle[pointType](size);
        }
        return style;
      },
    });
    return vectorLayer;
  }
};

export const drawForClusterLayer = (data: any, pointType: string) => {
  if (data && data.length > 0) {
    const features: Feature<any>[] = [];
    data?.map((item: any) => {
      item.name = item.name || pointType;
      if (item && item.latitude && item.longitude) {
        const coordinates = [item.latitude, item.longitude];
        const style = pointStyle[pointType](1);

        const feature = new Feature({
          geometry: new Point(
            proj.fromLonLat([coordinates[1], coordinates[0]]),
          ),
          name: item.name,
          pointType: pointType,
          content: item,
          style,
          point: [coordinates[1], coordinates[0]],
        });
        feature.setStyle(style);
        features.push(feature);
      }
    });

    const source = new VectorSource({
      features: features,
    });
    const clusterSource = new Cluster({
      distance: 10,
      source: source,
    });
    const vectorLayer = new VectorLayer({
      source: clusterSource,
      zIndex: priority[pointType],
      style: function (feature: any) {
        const size = feature.get('features').length;
        let style = new Style({});
        if (size === 1) {
          const feat = feature.get('features');
          style = pointStyle[pointType](1);

          feature.set('name', feat[0].get('name'));
          feature.set('pointType', feat[0].get('pointType'));
          feature.set('content', feat[0].get('content'));
          feature.set('arrContent', [feat[0].get('content')]);
        }
        if (size > 1) {
          const feat = feature.get('features');
          style = pointStyle[pointType](1);
          const arrContent: any[] = [];
          feat.map((fe: any) => {
            arrContent.push(fe.get('content'));
          });
          feature.set('pointType', feat[0].get('pointType'));
          feature.set('arrContent', arrContent);

          style = pointStyle[pointType](size);
        }
        return style;
      },
    });
    return vectorLayer;
  }
};
export const drawLayerWithOutCluster = (
  data: any,
  pointType: string,
  distance = 5,
  layer: any,
) => {
  if (data && data.length > 0) {
    const features: Feature<any>[] = [];
    data?.map((item: any) => {
      item.name = item.name || pointType;
      if (item && item.latitude && item.longitude) {
        const coordinates = [item.latitude, item.longitude];
        const style = pointStyle[pointType]();
        const feature = new Feature({
          geometry: new Point(
            proj.fromLonLat([coordinates[1], coordinates[0]]),
          ),
          driveActionUuid: item?.drive_action_uuid,
          name: item.name,
          pointType: pointType,
          content: item,
          style,
          point: [coordinates[1], coordinates[0]],
        });
        feature.setStyle(style);
        features.push(feature);
      }
    });
    handleSameLocationFeatures(features, distance);
    const source = new VectorSource({
      features: features,
    });
    layer && layer?.getSource()?.getSource()?.addFeatures(features);
    const vectorLayer = new VectorLayer({
      source: source,
      zIndex: priority[pointType],
      style: pointStyle[pointType](),
    });
    return vectorLayer;
  }
};
