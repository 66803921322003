/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Modal, notification, Space, Tag } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import { getFleetDiagnostics } from '../../constants/Api';
import { fleetObject } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { mapDiagnosticData } from '../../lib/dataFormat';

interface Props {
  iconsFlag: boolean;
}

export const getTags = (details: any) => {
  const { functionality_affected } = details;
  let tags = <></>;
  if (functionality_affected && functionality_affected.trim() !== '') {
    tags = functionality_affected
      .split(functionality_affected.includes(';') ? ';' : ',')
      .map((element: string, key: number) => (
        <Tag key={key} className="tagsName">
          {element}
        </Tag>
      ));
  }
  return tags;
};
export const getSeverity = (level: number) => {
  let images;
  switch (level) {
    case 1:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 2:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 3:
      images = (
        <>
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
        </>
      );
      break;
    default:
      images = (
        <>
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
  }
  return <Space>{images}</Space>;
};

const DiagnosticsModal: React.FC<Props> = ({ iconsFlag }: Props) => {
  const { closeModal, tractorObj } = useContext(RemoteDriveAppCtx);
  const { userDetails } = useContext(ApplicationContext);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilter] = useState<fleetObject[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const { t } = useTranslation();
  const [dateFilter, setDateFilter] = useState<any>([
    moment().subtract(1, 'day'),
    moment(),
  ]);
  const columns = [
    {
      title: `${t(translate.headers.severity)}`,
      dataIndex: 'severity',
      key: 'indicator_level',
      width: '12%',
      render: (severity: number) => {
        return getSeverity(severity);
      },
    },
    {
      title: `${t(translate.headers.dateTime)}`,
      dataIndex: 'start_time',
      key: 'created_date_time',
      width: '18%',
    },
    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '12%',
      render: (error_code: any) => <>{error_code}</>,
    },
    {
      title: `${t(translate.headers.indicatorType)}`,
      dataIndex: 'indicator_type',
      key: 'indicator_type',
      // width: '12%',
    },
    {
      title: `${t(translate.headers.issue)}`,
      // width: '160px',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      render: (description: string) => {
        return (
          <div className="description">
            <div className="diagnosticsdesc">{description}</div>
          </div>
        );
      },
    },
  ];
  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorObj) {
      fleetIndicatorHistoryList();
    }
  }, [userDetails, pageNumber, pageSize]);

  const fleetIndicatorHistoryList = async () => {
    const { organization } = userDetails;
    const page = pageNumber;
    try {
      // if (loading) return;
      setLoading(true);
      const fleetView = await getFleetDiagnostics(
        organization.api_url,
        tractorObj?.id ? tractorObj?.id : 0,
        'unresolved',
        '',
        '',
        '',
        '',
        '',
        page,
        pageSize,
        '',
        {
          columnKey: 'created_datetime',
          order: 'descend',
        },
      );
      // const fleetView = await getModalFleetDiagnostics(
      //   organization.api_url,
      //   tractorObj?.id ? tractorObj?.id : 0,
      //   dateFilter[0].toDate().getTime(),
      //   dateFilter[1].toDate().getTime(),
      //   page,
      //   pageSize,
      // );
      const { _metadata } = fleetView;
      setTotalCount(_metadata.total_records_count);
      let data =
        fleetView && fleetView.records && fleetView.records.length > 0
          ? fleetView.records
          : [];
      if (data.length === 0) {
        setFilter([]);
      }
      data = mapDiagnosticData(data);
      if (data.length === 0) {
        setHasMore(false);
      } else {
        if (data.length > 0) {
          setFilter([...filterData, ...data]);
        } else {
          setFilter(data);
        }
        if (data.length < 25) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup termspopup diagnosticModal wVw talertPopup"
        title={tractorObj?.name}
        visible={iconsFlag}
        onCancel={closeModal}
        // width={1150}
        footer={false}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon">
              <div className="tblDft farmTabsTbl hoverable dsTbl">
                <InfiniteScrollTable
                  xScroll={1200}
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  handleLoadMore={handleLoadMore}
                  filename={'ALERTS'}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DiagnosticsModal;
