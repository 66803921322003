import { Avatar } from 'antd';
import React from 'react';
import { wrapDateTime } from '../common';
import hazard_alt from '../images/hazard_alt.svg';
import hazard_camera from '../images/hazards_camera.svg';
import hazard_human_detection from '../images/hazards_human_detection.svg';
import hazard_vehicle from '../images/hazards_vehicle.svg';

import { useSnap } from '../../../constants/customHooks';

export const guardRailsMap: any = {
  human_protection: 'Human Detected',
  path_protection: 'Obstacle Detected',
  pto_protection: 'Pto Protection',
  vehicle_presence: 'Vehicle Detected',
  camera_clarity: 'Poor camera clarity',
  implement_guardrail: 'Implement Protection',
};

export const guardRailsImageMap: any = {
  human_protection: hazard_human_detection,
  path_protection: hazard_human_detection,
  pto_protection: hazard_alt,
  vehicle_presence: hazard_vehicle,
  camera_clarity: hazard_camera,
  implement_guardrail: hazard_alt,
};

interface Props {
  data: any;
  handleOperationSnapClick: (snap: any) => void;
}

const Hazard: React.FC<Props> = ({ data, handleOperationSnapClick }) => {
  const { transaction_id, tractor_id } = data;
  const [snapshots = []] = useSnap(transaction_id, String(tractor_id?.id));

  const [snapshot] = snapshots.filter(
    ({ camera_name }: any) => camera_name === 'front',
  );

  return (
    <div
      className="hazard-item summary-item"
      onClick={() => {
        handleOperationSnapClick({});
        setTimeout(() => {
          handleOperationSnapClick(data);
        }, 100);
      }}
    >
      <div className="hazard-top-section">
        <div className="assigned-to-title">
          <div className="assigned-to">
            <Avatar
              style={{
                color: '#ffffff',
                backgroundColor: '#366376',
                fontSize: '8px',
                fontWeight: 600,
                fontFamily: 'Montserrat',
                lineHeight: '9.75px',
              }}
              size={24}
              src={guardRailsImageMap[data.guard_rail_type]}
            />
          </div>
          <span className="ticket-number">
            {guardRailsMap[data.guard_rail_type]}
          </span>
        </div>
        <div className="creator-time-193">
          <span className="pm-196">
            {wrapDateTime(data?.created_date_time)}
          </span>
        </div>
      </div>

      <div className="hazard-img-block">
        <img src={snapshot?.camera_image_url} width={''} />
      </div>
    </div>
  );
};

export default Hazard;
