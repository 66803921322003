import RoutesConstants from '../routes/RoutesConstant';
import {
  LS_RAV_COMMANDS_KEY,
  getLocalStorageData,
  tractorConstantsNew,
} from './Common';
import { MosEventsResp, TractorsList } from './types';

import camera_poor from '../assets/images/camera_poor.svg';
import health_concern from '../assets/images/errorCode_icon.svg';
import cattle from '../assets/images/cattle.svg';
import vehicle from '../assets/images/vehicle.svg';
import humanprotection_icon from '../assets/images/humanprotection_icon.svg';
import low_localization from '../assets/images/low_localization.svg';
import obstacle from '../assets/images/obstacle.svg';
import obstacle_detected from '../assets/images/obstacle_detected.svg';
import path_deviation from '../assets/images/path_deviation.svg';
import poor_connectivity from '../assets/images/poor_connectivity.svg';
import heavy_feed_amount_icon from './../assets/images/heavy.svg';
import light_feed_amount_icon from './../assets/images/light.svg';
import medium_feed_amount_icon from './../assets/images/medium.svg';
import translate from './../locale/en_translate.json';

export default Object.freeze({
  DOWNLOAD_SIZE: 100,
  TRACTOR_COMMUNICATION: 'Tractor_Communication',
  TRACTOR_AUTOMATION: 'Tractor_Automation',
  TRACTOR_DRIVABILITY: 'Tractor_Drivability',
  TRACTOR_DATA: 'Tractor_Data',
  HITCH_AND_IMPLEMENT: 'Hitch_and_Implement',
  TRACTOR_ENERGY: `Tractor_Energy`,
  RAMDATA: {
    4: 'nuc1_cpu_ram',
    9: 'nuc2_cpu_ram',
    14: 'xavier1_ram',
    21: 'xavier2_ram',
    28: 'xavier3_ram',
    35: 'xavier4_ram',
  },
  TEMPDATA: {
    2: 'nuc1_cpu_temp',
    7: 'nuc2_cpu_temp',
    12: 'xavier1_cpu_temp',
    19: 'xavier2_cpu_temp',
    26: 'xavier3_cpu_temp',
    33: 'xavier4_cpu_temp',
  },

  USAGEDATA: {
    1: 'nuc1_cpu_usage',
    6: 'nuc2_cpu_usage',
    11: 'xavier1_cpu_usage',
    18: 'xavier2_cpu_usage',
    25: 'xavier3_cpu_usage',
    32: 'xavier4_cpu_usage',
  },

  POWERSTATUSDATA: {
    0: 'nuc1_power_status',
    5: 'nuc2_power_status',
    10: 'xavier1_power_status',
    17: 'xavier2_power_status',
    24: 'xavier3_power_status',
    31: 'xavier4_power_status',
  },
  DISKUSAGEDATA: {
    3: 'nuc1_disk_usage',
    8: 'nuc2_disk_usage',
    13: 'xavier1_disk_usage',
    20: 'xavier2_disk_usage',
    27: 'xavier3_disk_usage',
    34: 'xavier4_disk_usage',
  },
  SYSTEM_MODEL: {
    1: 'CPU Usage',
    2: 'Temperature',
    3: 'RAM',
    4: 'Power Status',
    5: 'Disk Usage',
  },

  TRACTOR_MODEL: {
    0: 'llc_state',
  },

  TractorModalData: [
    {
      id: 1,
      name: 'Thermals',
    },
    {
      id: 2,
      name: 'Localization',
    },
    {
      id: 3,
      name: 'Drive',
    },
    {
      id: 4,
      name: 'Battery',
    },
    {
      id: 5,
      name: 'Hydraulics',
    },
    {
      id: 6,
      name: 'System Status',
    },
  ],
  LocalisationData: [
    {
      data_index: 7,
      data_name: 'XY Accuracy',
    },
    {
      data_index: 12,
      data_name: 'Good Localization',
    },
    {
      data_index: 8,
      data_name: 'Heading Accuracy',
    },
  ],
  DriveData: [
    {
      data_index: 43,
      data_name: 'Gear',
    },
    {
      data_index: 42,
      data_name: 'Motor Torque Newton Meters',
    },
    {
      data_index: 41,
      data_name: 'Steering Tire Angle Degrees',
    },
    {
      data_index: 45,
      data_name: 'Three Point Position Lh Percent',
    },
    {
      data_index: 47,
      data_name: 'Three Point Position Rh Percent',
    },
    {
      data_index: 40,
      data_name: 'Traction Motor Speed Rpm',
    },
  ],
  BAtteryData: [
    {
      data_index: 84,
      data_name: 'Hv Batt Current Amps',
    },
    {
      data_index: 73,
      data_name: 'Hv Batt Voltage Volts',
    },
    {
      data_index: 100,
      data_name: 'Power From Battery',
    },
  ],
  HydraulicsData: [
    {
      data_index: 50,
      data_name: 'Accumulator Pressure Bars',
    },
    {
      data_index: 48,
      data_name: 'Brake Pressure Lh Bars',
    },
    {
      data_index: 49,
      data_name: 'Brake Pressure Rh Bars',
    },
    {
      data_index: 51,
      data_name: 'Transmission Pressure Bars',
    },
  ],

  PowerData: [
    {
      data_index: 10,
      data_name: 'Xavier1 Power Status',
    },
    {
      data_index: 17,
      data_name: 'Xavier2 Power Status',
    },
    {
      data_index: 167,
      data_name: 'Xavier6 Power Status',
    },
    {
      data_index: 150,
      data_name: 'Xavier1 System Node',
    },
    {
      data_index: 151,
      data_name: 'Xavier2 System Node',
    },
    {
      data_index: 155,
      data_name: 'Xavier6 System Node',
    },
  ],

  ThermalsData: [
    {
      data_index: 82,
      data_name: 'Hv Batt Cell Temp Avg Degc',
    },
    {
      data_index: 64,
      data_name: 'Temp Coolant Charger Degc',
    },
    {
      data_index: 65,
      data_name: 'Temp Coolant Dcdc Degc',
    },
    {
      data_index: 68,
      data_name: 'Temp Coolant Motor Controller Degc',
    },
    {
      data_index: 69,
      data_name: 'Temp Coolant Motor Degc',
    },
    {
      data_index: 67,
      data_name: 'Temp Coolant Radiator Out Degc',
    },
    {
      data_index: 71,
      data_name: 'Temp Hydraulics Sump Degc',
    },
  ],
});

export const distanceForGoodPoints = 200;
export const rangeForAnalytics = 750;
export const REMOTE_ACCESS_GRANT = 'remote_access_grant';
export const APP_TYPE = 'app_type';
export const HOVER = 'HOVER';
export const SELECTED = 'SELECTED';
export const defaultSetUpKeys = {
  mapId: '',
  isBlockSelected: false,
  isFirstTime: false,
  isLastTime: false,
  polygonUuid: '',
  startWith: '',
  startIndex: 0,
  endIndex: 0,
  everyFilter: '4',
  laneFilterContent: [],
  startIndexUuid: '',
  endIndexUuid: '',
};

export const BadLocalization = 'BAD_LOCALIZATION';
export const SystemError2 = 7001;
export const error_message: any = {
  7001: 'All the required nodes not running - Autodrive Monitor',
  7002: 'Polygon Errors - Map could not be built - Multiple white polygons, empty polygons, etc',
  7003: 'Low localization',
  7004: 'Steer Bias Calibration Error: Please drive manually for 10-15 and try again',
  7005: 'Seatbelt state change',
  7006: 'Driver occupancy state change',
  7007: 'Path Tracking Controller in Error State',
  7008: 'Row follow in error state',
  7009: 'Autonomy Not Enabled',
  7010: 'Paused by remote e-stop',
  7101: 'Path could not be generated for current request',
  7102: 'Tractor not in correct position to start routine. Please move the tractor and try again',
  7103: 'Selected implement is incompatible with Autodrive. Please select one of the recommended implements or contact Monarch.',
  7120: 'Internal Messaging Error',
  7121: 'Implement Data mismatch',
  7301: 'System error 5',
  400001:
    'You have been disconnected from the tractor due to inactivity for 10 mins. Please re-connect to start a new Autodrive',
  76001: 'Camera Down - Front',
  76002: 'Camera Down - Front Right',
  76003: 'Camera Down - Rear Right',
  76004: 'Camera Down - Rear ',
  76005: 'Camera Down - Rear Left',
  76006: 'Camera Down - Front Left',
  76007: 'Camera Down - Hitch',
  76008: 'Camera Down - Driver',
  76009: 'Camera Visibility - Front',
  76010: 'Camera Visibility - Front Right',
  76011: 'Camera Visibility - Rear Right',
  76012: 'Camera Visibility - Rear ',
  76013: 'Camera Visibility - Rear Left',
  76014: 'Camera Visibility - Front Left',
  76015: 'Camera Visibility - Hitch',
  76016: 'Camera Visibility - Driver',
  76017: 'Compute Down - X1',
  76018: 'Compute Down - X2',
  76019: 'Compute Down - X3',
  76020: 'Compute Down - X4',
  76021: 'Compute Down - X5',
  76022: 'Compute Down - X6',
  76023: 'Compute Down - N1',
  76024: 'Compute Down - N2',
  76025: 'Camera Detections Down - Front',
  76026: 'Camera Detections Down - Front Right',
  76027: 'Camera Detections Down - Rear Right',
  76028: 'Camera Detections Down - Rear ',
  76029: 'Camera Detections Down - Rear Left',
  76030: 'Camera Detections Down - Front Left',
  76031: 'Camera Detections Down - Hitch',
  76032: 'Camera Detections Down - Driver',
  76100: 'Human Detection Triggered',
  76200: 'Path Presence Triggered',
  76300: 'Vehicle Presence Triggered',
  76400: 'PTO Protection',
  76500: 'Hitch Protection',
  76600: 'Roll Protection',
  76700: 'Pitch Protection',
  76800: 'Speed Protection',
  76900: 'Slip Protection',
  77000: 'Guardrails Generic',
};
export const heartBeatTopic = 'HeartBeat';
export const AUTO_DRIVE_ERROR_TOPIC = 'autodrive_error';
export const AUTO_DRIVE_MONITOR_STATUS = 'autodrive_monitor_status';
export const INTERNAL_MESSAGE = 'internal_message';
export const AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY = 'autodrive_interrupt_msg';
export const AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY_ARRAY =
  'autodrive_interrupt_msg_array';
export const AUTO_DRIVE_METRIC_ESTIMATED_TIME_ARRIVAL =
  'autodrive_metric_estimated_time_arrival';
export const AUTO_DRIVE_METRIC_REFILL_POSE2D = 'autodrive_metric_refill_pose2D';

export const AUTO_LOGOUT_CODE = 400001;

export const CLEAR_COMMAND = '7000';
export const POSE_MODEL_STREAMING = 'PoseModel_streaming_';
export const TRACTOR_STATUS = 'tractor_status';
export const MOS_EVENTS_TOPIC = 'mos_events_';
export const VINE_ROWS = 'vine_rows_';

export const PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK =
  'planning_manager_remote_av_execution_fbk';

// eslint-disable-next-line no-useless-escape
export const speCharRegX = /^[a-zA-Z0-9!@#$%\^\s&*)(+=._-]*$/;
// on page load show the layers in mapmaker
export const listVisibility = {
  WAYPOINT: false,
  POLYGON: true,
  VINE: false,
  VINEROW: false,
  POLY_LINE: false,
  FEED_LINE: true,
  VINEBLOCK: false,
  ROUTE: false,
  TRAVELLEDROUTE: false,
  MARKS: false,
  WINEROWHEAD: false,
  VINELANE: false,
  JUMPANDGO: false,
  VINE_ROW_HEAD: false,
  ABLINE: false,
  ABBLOCK: false,
  SURVEYVINROW: false,
  SURVEY_VINE_ROW_NEW: false,
  CHARGEPOINTS: false,
  PREVIEWSURVEYVINROW: true,
};
// MAP maker constants
export const SURVEY_VINE_ROW = 'SURVEYVINEROW';
export const SURVEY_VINE_ROW_NEW = 'SURVEY_VINE_ROW_NEW';
export const AB_BLOCK = 'ABBLOCK';
export const POLY_LINE = 'POLY_LINE';
export const FEED_LANE = 'FEED_LANE';
export const types = {
  POLYGON: 'Polygon',
  VINELANEHEAD: 'VINELANEHEAD',
  FEED_LANE: 'FEED_LANE',
};

export const AB_LINE = 'ABLINE';
export const ROUTE = 'ROUTE';
export const TRACTOR = 'TRACTOR';
export const VINE = 'VINE';
export const VINE_ROW = 'VINEROW';
export const VINE_BLOCK = 'VINEBLOCK';
export const POLYGON = 'POLYGON';
export const WAY_POINT = 'WAYPOINT';
export const JUMP_AND_GO_MAP = 'JUMPANDGO';
export const REMOTE_AV_MAP = 'REMOTEAV';
export const VINE_LANE = 'VINELANE';
export const CHARGE_POINTS = 'CHARGEPOINTS';
export const TRAVELLED_ROUTE = 'TRAVELLEDROUTE';

export const SURVEY_ID_CONSTANT = 'survey_vine_row_';
export const SURVEY_NEW_ID_CONSTANT = 'survey_vine_row_new_';
export const POLY_LINE_ID_CONSTANT = 'poly_line_';
export const getStep = (step: string): string => {
  step = step.toLocaleLowerCase();
  switch (step) {
    case 'step_1':
      return 'Step 1';
    case 'step_2':
      return 'Step 2';
    case 'step_3':
      return 'Step 3';
    case 'step_4':
      return 'Step 4';
    case 'step_5':
      return 'Step 5';
    case 'step_6':
      return 'Step 6';
    default:
      return step;
  }
};

export const STATUS_LIST: any[] = [
  { name: 'Connectivity', value: 'Tractor_Communication' },
  { name: 'Driveability', value: 'Tractor_Drivability' },
  { name: 'Autonomy', value: 'Tractor_Automation' },
  { name: 'Battery & Charger', value: 'Tractor_Energy' },
  { name: 'Hitch & Implement', value: 'Hitch_and_Implement' },
];

export const getDriveActionUuid = (
  globalTractorList: TractorsList[],
  tractor_id: number,
): string => {
  const [tractor] = globalTractorList.filter(
    (tractor: TractorsList) => tractor.id == tractor_id,
  );
  return tractor?.heartbeat?.drive_action_details?.current_drive_action
    ? tractor?.heartbeat?.drive_action_details?.current_drive_action
    : '';
};

export const isInRemoteAvRunningState = (tractor: TractorsList): boolean => {
  // if tractor is running don't clear the command
  return tractor?.heartbeat?.planning_manager?.planner_name ===
    tractorConstantsNew.remote_av.key
    ? false
    : true;
};

export const interruptMessageTypes = {
  loc: 'loc',
  tractor_lost_connection: 'tractor_lost_connection',
  autonomy_disabled: 'autonomy_disabled',
  remote_estop: 'remote_estop',
  guardrails_blue_polygon: 'guardrails_blue_polygon',
  guardrails_white_polygon: 'guardrails_white_polygon',
  steer_calibration_error: 'steer_calibration_error',
  tractor_moved_off_path: 'tractor_moved_off_path',
  guardrails_slip: 'guardrails_slip',
  vinerow_vision_error: 'vinerow_vision_error',
  row_follow_tracking_error: 'row_follow_tracking_error',
  firmware_fault_other: 'firmware_fault_other',
  lost_localization: 'lost_localization',
  implement_refill: 'implement_refill',
  firmware_fault: 'firmware_fault',
  driver_occupancy_state_change: 'driver_occupancy_state_change',
  seatbelt_state_change: 'seatbelt_state_change',
  guardrails_human: 'guardrails_human',
  guardrails_human_front: 'guardrails_human_front',
  guardrails_path_presence: 'guardrails_path_presence',
  guardrails_implement_collision: 'guardrails_implement_collision',
  poor_camera_visibility: 'poor_camera_visibility',
  firmware_fault_feedpusher: 'firmware_fault_feedpusher',
  guardrails_cow: 'guardrails_cow',
  vehicle_presence: 'vehicle_presence',
};

export const getInterruptTitle = (details: string): string => {
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return 'Human Detected';
    case interruptMessageTypes.guardrails_path_presence:
      return 'Obstacle Detected';
    case interruptMessageTypes.guardrails_implement_collision:
      return 'Implement Collision Avoidance';

    case interruptMessageTypes.lost_localization:
      return 'Poor GPS Signal';

    case interruptMessageTypes.implement_refill:
      return 'Refill Seeder Now';

    case interruptMessageTypes.guardrails_slip:
      return 'Excessive Slipping';
    case interruptMessageTypes.firmware_fault:
      return 'Health Concern';
    case interruptMessageTypes.firmware_fault_other:
      return 'Health Concern';
    case interruptMessageTypes.tractor_moved_off_path:
      return 'Path deviation';
    case interruptMessageTypes.tractor_lost_connection:
      return 'Poor Connectivity';
    case interruptMessageTypes.steer_calibration_error:
      return 'Steering Out Of Calibration';
    case interruptMessageTypes.poor_camera_visibility:
      return 'Poor Camera Visibility';
    // commented the human front interrupt
    // case interruptMessageTypes.guardrails_human_front:
    //   return 'Human Detected';
    case interruptMessageTypes.seatbelt_state_change:
      return 'Autodrive Paused by On-Seat Operator';
    case interruptMessageTypes.driver_occupancy_state_change:
      return 'Autodrive Paused by On-Seat Operator';
    case interruptMessageTypes.remote_estop:
      return 'Autodrive Paused';
    case interruptMessageTypes.autonomy_disabled:
      return 'Autodrive Disabled';
    case interruptMessageTypes.vinerow_vision_error:
      return 'Row Follow Unavailable';
    case interruptMessageTypes.row_follow_tracking_error:
      return 'Row Follow Unavailable';
    case interruptMessageTypes.loc:
      return 'Health Concern';

    case interruptMessageTypes.firmware_fault_feedpusher:
      return 'Feed Pusher Error';
    case interruptMessageTypes.guardrails_cow:
      return 'Cow Blocking Path';
    case interruptMessageTypes.vehicle_presence:
      return 'Vehicle Blocking Path';

    case interruptMessageTypes.guardrails_white_polygon:
      return 'Exiting Farm Boundary';

    case interruptMessageTypes.guardrails_blue_polygon:
      return 'Mapped Obstacle in Path';

    default:
      return '';
  }
};

const getFirstArrayByPriority1 = (mainArray: any) => {
  const obj1: any = Object.keys(mainArray)
    .reduce((a: any[], c) => {
      const ob = mainArray[c];
      ob.key = c;
      a.push(ob);
      return a;
    }, [])
    .reduce((a: any, c) => {
      if (Array.isArray(a[c.metadata.details])) {
        a[c.metadata.details].push(c);
      } else {
        a[c.metadata.details] = [c];
      }
      return a;
    }, {});

  return (keys: any) => {
    const [result] = Object.keys(keys)
      .map((a, c) => {
        if (typeof obj1[a] !== 'undefined') return obj1[a];
        return false;
      })
      .filter((i) => i);
    return result;
  };
};

export const getFirstArrayByPriority = (mainArray: any[]) => {
  return getFirstArrayByPriority1(mainArray)(interruptMessageTypes);
};

export const TIMER_DURATION = 60 * Number(process.env.REACT_APP_TIMER_DURATION); // sec x min
export const WINGSPANAI = 'wingspanai';
// list will not check before Connect
export const doNotCheckTheseCodesBeforeConnect = [
  // first list camera visibility
  76009, 76010, 76011, 76012, 76013, 76014, 76015, 76016,
  // second list guard rails
  76100, 76150, 76200, 76300, 76400, 76500, 76600, 76700, 76800, 76900, 77000,
];
// poor camera visibility errors
export const cameraVisibilityErrorCodes = [
  76009, 76010, 76011, 76012, 76013, 76014, 76015, 76016,
];

export const checkTheseErrorCodesAfterRemoteAvStart = [
  76001, 76002, 76003, 76004, 76005, 76006, 76007, 76008, 76009, 76010, 76011,
  76012, 76013, 76014, 76015, 76016, 76017, 76018, 76019, 76020, 76021, 76022,
  76023, 76024, 76025, 76026, 76027, 76028, 76029, 76030, 76031, 76032,
];

export const TAB_CLOSED = 'tabClosed';

export const envVar = {
  FarmObservations: 'FarmObservations',
};
export const AutoDriveTabs = {
  Block: 'block',
  PATH: 'path',
  Parameter: 'parameter',
};
export const clearCommandFrom = 'RDDetails-- clearAll';

export const TICKET_TYPES = Object.freeze({
  INDICATOR_ERROR_CODE: 'INDICATOR_ERROR_CODE',
  MAINTENANCE: 'MAINTENANCE',
  SNAPSHOT: 'SNAPSHOT',
});

export const interruptKey = (interruptMsg: MosEventsResp): string =>
  `${interruptMsg?.metadata?.details}_${interruptMsg?.metadata?.type}_${interruptMsg?.message}_${interruptMsg?.tractor_id}`;

export const interruptPauseKey = (interruptMsg: MosEventsResp): string =>
  `${interruptMsg?.metadata?.details}_${interruptMsg?.metadata?.type}_${PAUSE}_${interruptMsg?.tractor_id}`;

export const dataSource = [
  {
    id: '1',
    name: 'Every Row',
    // disabled: true,
  },
  {
    id: '2',
    name: 'Every Other',
    // disabled: true,
  },
  // {
  //   id: '3',
  //   name: 'Every Third',
  //   disabled: true,
  // },
  {
    id: '4',
    name: 'Every Fourth',
  },
];

export const getCommand = (tractorId: number) => {
  const commandsObj = getLocalStorageData(LS_RAV_COMMANDS_KEY, true);
  if (commandsObj && commandsObj[tractorId]) {
    const command = commandsObj[tractorId];
    return command[command.length - 1];
  }
  return {};
};
// => true ->diff, => false -- same
export const findArrayDifference = (arr1: string[], arr2: string[]) => {
  // Find elements in arr1 that are not in arr2
  const difference = arr1.filter((item) => !arr2.includes(item));
  // You can also find elements in arr2 that are not in arr1 by swapping the arrays
  const difference2 = arr2.filter((item) => !arr1.includes(item));
  return difference &&
    difference.length === 0 &&
    difference2 &&
    difference2.length === 0
    ? false
    : true;
};
export const getImplementType = (details: { implement_type: string }): string =>
  details?.implement_type
    ? details?.implement_type?.toLowerCase() === 'seeder'
      ? 'Seeding'
      : details?.implement_type
    : '';
export const handleSeederShow = (details: {
  implement_type: string;
}): boolean =>
  details &&
  details.implement_type &&
  details?.implement_type.toLowerCase() === 'seeder'
    ? true
    : false;

export const SYSTEM_PAUSE = 'system_pause';
export const MOS_CLOUD = 'mos_cloud';
export const STOP = 'stop';
export const USER_INITIATED = 'user_initiated';

export const PAUSE = 'pause';
export const HARD = 'hard';
export const SOFT = 'soft';
export const CONTINUE_LATER = 'continue_later';
export const RESUME = 'resume';
export const IGNORE_AND_CONTINUE = 'ignore_and_continue';
export const GUARDRAILS = 'guardrails';
export const REMOTE_AV = 'remote_av';

export const ATOM = 'ATOM';
export const WEB = 'web';
export const MOBILE = 'mobile';

export const GILES = 'GILES';
export const GUARD_RAILS = 'GUARD_RAILS';
export const JUMP_AND_GO = 'JUMP_AND_GO';

export const PAUSED = 'PAUSED';
export const redBdr = 'redBdr';
export const STOPPED = 'STOPPED';
export const tractorEndPopDefault = 99999;

export const Buffer10 = 10;
export const Buffer30 = 30;
export const Buffer60 = 60;

export const firmware_fault_other = {
  category: 'autodrive_interrupt_msg',
  message: 'pause',
  organization_id: 0,
  tractor_id: 0,
  timestamp: 0,
  metadata: {
    details: 'firmware_fault_other',
    category: 'system_pause',
    type: 'hard',
    errors: [],
  },
};

export const NONE = 'None';

export const brownColor = 'brown';

export const adminRoutes = [
  RoutesConstants.Admin,
  RoutesConstants.ImpactDashBoard,
  RoutesConstants.DealersFarmProfile,
  RoutesConstants.DealersMaintenance,
  RoutesConstants.DealerAnalytics,
  RoutesConstants.DealerPlanning,
  RoutesConstants.markMaintenance,
];

export const indicatorsOrder = [
  'Tractor Communication',
  'Tractor Drivability',
  'Tractor Automation',
  'Tractor Data',
  'Tractor Energy',
  'Hitch&Implement',
];

export const getPayloadForEndOperation = (aDIM: MosEventsResp) => {
  const details = aDIM?.metadata?.details;
  const defaultEndPayload = {
    action: STOP,
    details: '',
    category: USER_INITIATED,
    type: '',
  };
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_path_presence:
      return defaultEndPayload;
    case interruptMessageTypes.poor_camera_visibility:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_slip:
      return defaultEndPayload;
    case interruptMessageTypes.firmware_fault:
      return {
        action: STOP,
        details: interruptMessageTypes.firmware_fault,
        category: SYSTEM_PAUSE,
        type: HARD,
      };
    case interruptMessageTypes.firmware_fault_other:
      return {
        action: STOP,
        details: interruptMessageTypes.firmware_fault,
        category: SYSTEM_PAUSE,
        type: HARD,
      };
    case interruptMessageTypes.lost_localization:
      return defaultEndPayload;
    case interruptMessageTypes.tractor_moved_off_path:
      return {
        action: STOP,
        details: interruptMessageTypes.tractor_moved_off_path,
        category: SYSTEM_PAUSE,
        type: HARD,
      };
    // tractor lost connection remove end operation
    // case interruptMessageTypes.tractor_lost_connection:
    //   return {
    //     action: STOP,
    //     details: '',
    //     category: USER_INITIATED,
    //     type: '',
    //   };
    case interruptMessageTypes.steer_calibration_error:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_human_front:
      return defaultEndPayload;
    case interruptMessageTypes.seatbelt_state_change:
      return defaultEndPayload;
    case interruptMessageTypes.driver_occupancy_state_change:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_implement_collision:
      return defaultEndPayload;
    case interruptMessageTypes.implement_refill:
      return defaultEndPayload;
    case interruptMessageTypes.autonomy_disabled:
      return defaultEndPayload;
    case interruptMessageTypes.remote_estop:
      return defaultEndPayload;

    case interruptMessageTypes.row_follow_tracking_error:
      return {
        action: STOP,
        details: interruptMessageTypes.row_follow_tracking_error,
        category: USER_INITIATED,
        type: '',
      };
    case interruptMessageTypes.vinerow_vision_error:
      return {
        action: STOP,
        details: interruptMessageTypes.vinerow_vision_error,
        category: USER_INITIATED,
        type: '',
      };
    case interruptMessageTypes.loc:
      return {
        action: STOP,
        details: interruptMessageTypes.loc,
        category: USER_INITIATED,
        type: '',
      };

    case interruptMessageTypes.guardrails_cow:
      return defaultEndPayload;
    case interruptMessageTypes.firmware_fault_feedpusher:
      return defaultEndPayload;
    case interruptMessageTypes.vehicle_presence:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_blue_polygon:
      return defaultEndPayload;
    case interruptMessageTypes.guardrails_white_polygon:
      return defaultEndPayload;
    default:
      return null;
  }
};
export const getPayloadForContinueOperation = (
  aDIM: MosEventsResp,
  userId: number,
) => {
  const type = aDIM?.metadata?.type;
  const details = aDIM?.metadata?.details;
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_human,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.guardrails_human,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    case interruptMessageTypes.guardrails_path_presence:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_path_presence,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.guardrails_path_presence,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    case interruptMessageTypes.guardrails_implement_collision:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_implement_collision,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.guardrails_implement_collision,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    case interruptMessageTypes.poor_camera_visibility:
      return {
        action: RESUME,
        details: interruptMessageTypes.poor_camera_visibility,
        category: SYSTEM_PAUSE,
        type: HARD,
      };

    case interruptMessageTypes.guardrails_human_front:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_human_front,
            category: SYSTEM_PAUSE,
            type: HARD,
          }
        : null;
    case interruptMessageTypes.seatbelt_state_change:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.seatbelt_state_change,
            category: SYSTEM_PAUSE,
            type: HARD,
          }
        : null;
    case interruptMessageTypes.firmware_fault:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.firmware_fault,
            category: SYSTEM_PAUSE,
            type: HARD,
          }
        : null;
    case interruptMessageTypes.driver_occupancy_state_change:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.driver_occupancy_state_change,
            category: SYSTEM_PAUSE,
            type: HARD,
          }
        : null;

    case interruptMessageTypes.lost_localization:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.lost_localization,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: '',
          }
        : null;
    case interruptMessageTypes.guardrails_slip:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_slip,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: '',
          }
        : null;

    case interruptMessageTypes.loc:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.loc,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: '',
          }
        : null;
    case interruptMessageTypes.firmware_fault_feedpusher:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.firmware_fault_feedpusher,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.firmware_fault_feedpusher,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    case interruptMessageTypes.guardrails_cow:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.guardrails_cow,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.guardrails_cow,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    case interruptMessageTypes.vehicle_presence:
      return type === HARD
        ? {
            action: RESUME,
            details: interruptMessageTypes.vehicle_presence,
            category: SYSTEM_PAUSE,
            type: HARD,
            uids: aDIM?.metadata?.uids,
          }
        : {
            action: IGNORE_AND_CONTINUE,
            category: GUARDRAILS,
            details: interruptMessageTypes.vehicle_presence,
            planner: REMOTE_AV,
            operator_id: userId,
          };
    // case interruptMessageTypes.tractor_lost_connection:
    //   return {
    //     action: RESUME,
    //     details: 'tractor_lost_connection',
    //     category: 'system',
    //     type: HARD,
    //   };
    default:
      return null;
  }
};

export const getInterruptImage = (aDIM: MosEventsResp) => {
  const details = aDIM?.metadata?.details;
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return humanprotection_icon;
    case interruptMessageTypes.guardrails_human_front:
      return humanprotection_icon;
    case interruptMessageTypes.guardrails_path_presence:
      return obstacle;
    case interruptMessageTypes.poor_camera_visibility:
      return camera_poor;
    case interruptMessageTypes.lost_localization:
      return low_localization;
    case interruptMessageTypes.firmware_fault:
      return health_concern;
    case interruptMessageTypes.tractor_moved_off_path:
      return path_deviation;
    case interruptMessageTypes.tractor_lost_connection:
      return poor_connectivity;
    case interruptMessageTypes.guardrails_slip:
      return health_concern;
    case interruptMessageTypes.seatbelt_state_change:
      return health_concern;
    case interruptMessageTypes.driver_occupancy_state_change:
      return health_concern;
    case interruptMessageTypes.guardrails_implement_collision:
      return obstacle_detected;
    case interruptMessageTypes.vinerow_vision_error:
      return health_concern;
    case interruptMessageTypes.row_follow_tracking_error:
      return health_concern;
    case interruptMessageTypes.loc:
      return health_concern;
    case interruptMessageTypes.autonomy_disabled:
      return health_concern;
    case interruptMessageTypes.remote_estop:
      return health_concern;
    case interruptMessageTypes.firmware_fault_feedpusher:
      return health_concern;
    case interruptMessageTypes.vehicle_presence:
      return vehicle;
    case interruptMessageTypes.guardrails_cow:
      return cattle;

    case interruptMessageTypes.guardrails_white_polygon:
      return health_concern;
    case interruptMessageTypes.guardrails_blue_polygon:
      return health_concern;
    default:
      return health_concern;
  }
};

export const HEAVY_VALUE = 2;
export const MEDIUM_VALUE = 1.5;
export const LIGHT_VALUE = 1;

export const getFeedTypeImage = (feedAmount: number): string => {
  switch (feedAmount) {
    case HEAVY_VALUE:
      return heavy_feed_amount_icon;
    case MEDIUM_VALUE:
      return medium_feed_amount_icon;
    case LIGHT_VALUE:
      return light_feed_amount_icon;

    default:
      return medium_feed_amount_icon;
  }
};

export const NewOperation = 'NEW';
export const OldOperation = 'OLD';

export const vineYardAutoDrive = 'vineyard';
export const dairyAutoDrive = 'dairy';
export const getDescriptionForInterrupt = (details: string): string => {
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return translate.AutoDrive.AutoDriveInterrupt.guardrailsHumanAutodrive;
    case interruptMessageTypes.guardrails_path_presence:
      return translate.AutoDrive.AutoDriveInterrupt
        .guardrailsPathPresenceAutodrive;
    case interruptMessageTypes.lost_localization:
      return translate.AutoDrive.AutoDriveInterrupt.lostLocalizationHard1;
    case interruptMessageTypes.steer_calibration_error:
      return translate.AutoDrive.AutoDriveInterrupt.steerCalibrationError1;
    case interruptMessageTypes.firmware_fault:
      return translate.AutoDrive.AutoDriveInterrupt.firmwareFault1;
    // case interruptMessageTypes.firmware_fault_other:
    //   return translate.AutoDrive.AutoDriveInterrupt.firmwareFaultOther;
    case interruptMessageTypes.poor_camera_visibility:
      return translate.AutoDrive.AutoDriveInterrupt
        .poorCameraVisibilityAutodrive;
    case interruptMessageTypes.guardrails_human_front:
      return translate.AutoDrive.AutoDriveInterrupt
        .guardrailsHumanFrontAutodrive;
    case interruptMessageTypes.tractor_moved_off_path:
      return translate.AutoDrive.AutoDriveInterrupt.tractorMovedOffPath1;
    case interruptMessageTypes.tractor_lost_connection:
      return translate.AutoDrive.AutoDriveInterrupt.tractorLostConnectionHard1;
    case interruptMessageTypes.guardrails_slip:
      return translate.AutoDrive.AutoDriveInterrupt.guardrailsSlip1;
    case interruptMessageTypes.seatbelt_state_change:
      return translate.AutoDrive.AutoDriveInterrupt
        .seatbeltStateChangeAutodrive;
    case interruptMessageTypes.driver_occupancy_state_change:
      return translate.AutoDrive.AutoDriveInterrupt
        .driverOccupancyStateChangeAutoDrive;
    case interruptMessageTypes.guardrails_implement_collision:
      return translate.AutoDrive.AutoDriveInterrupt
        .guardrailsImplementCollision;
    case interruptMessageTypes.implement_refill:
      return translate.AutoDrive.AutoDriveInterrupt.implementRefillAutoDrive;
    case interruptMessageTypes.remote_estop:
      return translate.AutoDrive.AutoDriveInterrupt.remoteEStopAutodrive;
    // case interruptMessageTypes.autonomy_disabled:
    //   return translate.AutoDrive.AutoDriveInterrupt.autonomyDisabled;
    case interruptMessageTypes.vinerow_vision_error:
      return translate.AutoDrive.AutoDriveInterrupt.VinerowVisionError1;
    case interruptMessageTypes.row_follow_tracking_error:
      return translate.AutoDrive.AutoDriveInterrupt.RowFollowTrackingError1;
    case interruptMessageTypes.firmware_fault_feedpusher:
      return translate.AutoDrive.AutoDriveInterrupt.feedPusherErrorAutodrive;
    case interruptMessageTypes.guardrails_cow:
      return translate.AutoDrive.AutoDriveInterrupt.cowBlockingPath1;
    case interruptMessageTypes.vehicle_presence:
      return translate.AutoDrive.AutoDriveInterrupt.vehicleBlockingPath1;
    case interruptMessageTypes.loc:
      return translate.AutoDrive.AutoDriveInterrupt.locHard1;
    default:
      return '';
  }
};
export const dairySpeedList = [
  {
    id: 1,
    name: '0.5 MPH',
    value: 0.5,
  },
  {
    id: 2,
    name: '1   MPH',
    value: 1,
  },
  {
    id: 3,
    name: '1.5 MPH',
    value: 1.5,
  },
  {
    id: 4,
    name: '2   MPH',
    value: 2,
  },
  {
    id: 5,
    name: '2.5 MPH',
    value: 2.5,
  },
  {
    id: 6,
    name: '3   MPH',
    value: 3,
  },
  {
    id: 7,
    name: '3.5 MPH',
    value: 3.5,
  },
  {
    id: 8,
    name: '4   MPH',
    value: 4,
  },
  {
    id: 9,
    name: '4.5 MPH',
    value: 4.5,
  },
];
export const connectionData = [
  { name: 'Online', value: 1 },
  { name: 'Offline', value: 0 },
];

export const groupList = [
  { name: 'None', value: 'none' },
  { name: 'Digital First', value: 'Digital First' },
  {
    name: 'Digital Incident Response Team',
    value: 'Digital Incident Response Team',
  },
  { name: 'Service', value: 'Service' },
  { name: 'Software', value: 'Software' },
  { name: 'Firmware', value: 'Firmware' },
  { name: 'Controls', value: 'Controls' },
  { name: 'Hardware/Engineering', value: 'Hardware/Engineering' },
  { name: 'Customer', value: 'Customer' },
  { name: 'Digital', value: 'Digital' },
  { name: 'DIRT', value: 'DIRT' },
];
export const resolvedByData = [
  { name: 'Service', value: 'Service' },
  { name: 'Dealer', value: 'Dealer' },
  { name: 'Field Support', value: 'Field Support' },
  { name: 'Customer', value: 'Customer' },
  { name: 'Digital', value: 'Digital' },
];

export const repairTypeData = [
  { name: 'Physical', value: 'Physical' },
  { name: 'Firmware', value: 'Firmware' },
  { name: 'Software', value: 'Software' },
  { name: 'Rework', value: 'Rework' },
];

export const repairLocationData = [
  { name: 'In Field', value: 'In Field' },
  { name: 'Dealership', value: 'Dealership' },
  { name: 'MT Service', value: 'MT Service' },
];
export const travelRequiredData = [
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' },
];

export const warrantyRepairData = [
  { name: 'Yes', value: 'true' },
  { name: 'No', value: 'false' },
];

export const noImplementCode = 'No Implement';
export const noImplementGlobalIMMCode = '0000000000';

export const SATILITE_LAYER_URL =
  'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga';
export const AllConst = 'All';
